// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material UI
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import useStyles from './styles.js';

// Slices
import { resetValues, selectRunId } from '../../Pages/NewProject/NewProjectSlice.js';
import { resetMeta, selectCampaignsCounts, selectDemoData, selectInformation, selectProjectMetadata, selectProjectsLoaded, selectProjectsSort, selectUserPermissions, setActiveOption } from '../../AppSlice.js';
import { setAlertDialogOpen } from '../AlertDialog/AlertDialogSlice.js';

// Components
import ProjectsCollapsibleTable from './ProjectsTable.jsx';

// Common
import { check_permission_limit, get_permission_limit } from '../../global/helpers.js';
import { User, checkPermission } from '../../global/user.jsx'
import appApiService from '../../AppService.js';
import TimelineChart from '../Run/Charts/TimelineChart/TimelineChart.js';
import HistoryChart from '../Run/Charts/HistoryChart/HistoryChart.jsx';
import Legend from '../Run/Charts/Legend/Legend.jsx';
import Loader from '../Loader/Loader.jsx';
import moment from 'moment/moment.js';
import HeaderContainer from '../Headers/HeaderContainer/HeaderContainer.jsx';


const ApiService = new appApiService();

const Project = ({ resetProjects }) => {
  const classes = useStyles();
  const runId = useSelector(selectRunId)
  const dispatch = useDispatch()
  const history = useHistory()
  const campaignsCountById = useSelector(selectCampaignsCounts)
  const sort = useSelector(selectProjectsSort)
  const isAnnuities = localStorage.getItem("accountType") === "annuities"

  // The "information" is response from API
  const information = useSelector(selectInformation)
  const metadata = useSelector(selectProjectMetadata)

  const userPermissions = useSelector(selectUserPermissions)
  const demo = useSelector(selectDemoData)
  const isLoaded = useSelector(selectProjectsLoaded)
  const [projectsList, setProjectsList] = useState([])

  const isNewProspects = userPermissions?.find(({ action }) => action === "new_prosepcts_tab")

  useEffect(() => {
    return () => {
      dispatch(resetMeta())
    }
  }, [dispatch])

  useEffect(() => {
    if (runId) {
      resetProjects()
    } else {
      resetProjects()
      dispatch(resetValues())
    }
  }, [runId])

  useEffect(() => {
    setProjectsList(
      information?.projects
        .filter(({ is_archived }) => !is_archived) 
        .sort((a, b) => {
          const key = sort?.replace("-", "")
          const order = sort?.includes("-") ? -1 : 1
          if (key === "created_at") {
            return order * (moment(a?.created_at) > moment(b?.created_at) ? 1 : -1)
          }
          if (["total_policies", "prospects", "aum"].includes(key)) {
            return order * (a[key] > b[key] ? 1 : -1)
          }          
          if (key === "campaigns") {
            const campaignsA = a.runs.reduce((a, run) => a + (campaignsCountById[run.run_id] || 0), 0)
            const campaignsB = b.runs.reduce((a, run) => a + (campaignsCountById[run.run_id] || 0), 0)
            return order * (campaignsA > campaignsB ? 1 : -1)
          }          
          return 0
        }) 
    )
  }, [information, campaignsCountById, sort])


  const handleCreateProject = () => {
    // Block new projects for anonym
    if (User().isAnonym) return;

    if (checkPermission('create_project')) {
      if (check_permission_limit(userPermissions, 'run_limit', information.count) || demo) {
        ApiService._getDataSources((response) => {
          if (!response.data?.count) {
            dispatch(setActiveOption(''))
            history.push("/firstlogin")
          } else {
            sessionStorage.removeItem("current_id")
            dispatch(setActiveOption(''))
            history.push("/NewProject")
          }
        }, (error) => {
          console.log(error)
          sessionStorage.removeItem("current_id")
          dispatch(setActiveOption(''))
          history.push("/NewProject")
        })
      } else {
        dispatch(setAlertDialogOpen({
          open: true,
          title: 'Limit',
          message: `Your account is limited for ${get_permission_limit(userPermissions, 'run_limit')} project`
        }));
      }
    }
  }

  if (information === null) return ('')

  return (

    <Grid container className={classes.rootContent} data-testid="projectsPageWrapper">
      {!isLoaded && <Loader/>}

      <HeaderContainer
        title="Projects"
        actionText="Create Project"
        actionPermission={checkPermission('create_project')}
        actionDisabled={!checkPermission('create_project') || isNewProspects}
        onActionClick={handleCreateProject}
      />
      <Grid item container justifyContent="space-between" alignItems="center">

        {/* Project history */}
          <Grid container direction="column" className={classes.projectsWrapper}>

            <Grid item container direction="column" className={classes.metaContainer}>
              <Grid container className={classes.metaTitle}>
                <Typography>
                  Projects Performance
                </Typography>
              </Grid>

              <Grid container justifyContent="center">

                <HistoryChart
                  originalData={metadata?.premium_by_project ? metadata.premium_by_project : []}
                  aumData={metadata?.potential_premium_by_project ? metadata.potential_premium_by_project : []}
                  potentialData={metadata?.total_premium_by_project ? metadata.total_premium_by_project : []}
                  titles={[
                    "Estimated Premium",
                    `${isAnnuities ? "Potential AUM Upside" : "Potential Premium Upside"}`,
                    "Potential Performance with Atidot",
                  ]}
                />

                <Grid container justifyContent="center">
                  <Legend data={metadata?.total_premium_by_project.map(({ id }, index) => ({ id, index }))}/>
                </Grid>

              </Grid>
            </Grid>

            <Grid container direction="column" className={classes.metaContainer} style={{ paddingBottom: 0 }}>
              <Grid container className={classes.metaTitle}>
                <Typography>
                  Projects Timeline
                </Typography>
              </Grid>

              <Grid container>
                <div style={{ width: "100%" }}>
                  <TimelineChart title={`${isAnnuities ? "Targeted AUM" : "Targeted Premium"}`} chartData={metadata?.potential_premium_by_project_and_product ? metadata.potential_premium_by_project_and_product : []} />
                </div>
              </Grid>
            </Grid>

            <Grid container direction="column" className={classes.metaContainer}>

              <Grid item xs={12} data-testid="projectsWrapper">

                {
                  projectsList?.length > 0
                    ? <ProjectsCollapsibleTable projects={projectsList} resetProjects={resetProjects} />
                    : <Grid container className={classes.noProjectsGrid}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      data-testid="noProjects"
                    >
                      <Typography variant="h3"> No Projects yet </Typography>
                    </Grid>
                }

              </Grid>
            </Grid>

          </Grid>


      </Grid>



    </Grid>

  );
}

export default Project;