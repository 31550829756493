import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom"
import { getLocalTime, getRanges } from "../../global/helpers"
import Calendar from "./Calendar/Calendar"
import TimeSlotPicker from "./TimeSlotPicker/TimeSlotPicker"
import { utcToZonedTime } from "date-fns-tz";
import timezones from "timezones-list"
import { useStyles } from "./styles"
import axios from "axios"
import InformationPopup from "../Popups/InformationPopup/InformationPopup"

const mockTimeSlots = [
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721634300,
        "end_time": 1721636100
    },
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721636100,
        "end_time": 1721637900
    },
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721637900,
        "end_time": 1721639700
    },
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721639700,
        "end_time": 1721641500
    },
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721641500,
        "end_time": 1721643300
    },
    {
        "emails": [
            "vlad@atidot.com"
        ],
        "start_time": 1721643300,
        "end_time": 1721645100
    },
]
 
const SchedulerComponent = ({ stepped = false, agent, leadID }) => {
    const now = new Date()
    const classes = useStyles() 
    const history = useHistory()
    const { name, agent_id } = useParams() 
    const [ loading, setLoading ] = useState(true)
    const [ confirmOpen, setConfirmOpen ] = useState(false)
    const [ step, setStep ] = useState(1)
    const [ slots, setSlots ] = useState([])
    const [ date, setDate ] = useState(now)
    const [ duration, setDuration ] = useState("30")
    const [ timezone, setTimezone ] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone?.replace("Kiev", "Kyiv"))
    const [ time, setTime ] = useState(null)

    const handleConfirmClose = (e, value) => {
        setConfirmOpen(false)
        setTime(null)
    }
    
    const handleDateChange = (value) => {
        setSlots([])
        const timeNowLocal = new Date()
        const timeNow = utcToZonedTime(timeNowLocal, timezone)
        if (timeNow.getHours() < 8) {
            timeNow.setHours(8, 0, 0, 0)
        }
        const newValue = utcToZonedTime(value, timezone)
        newValue.setHours(8, 0, 0, 0)
        setDate(now.toDateString() !== value.toDateString() ? newValue : timeNow)
        setStep(2)
    }

    const handleDurationChange = (e, value) => {
        setDuration(value)
    }

    const handleTimezoneChange = (e, tz) => {
        setTimezone(tz.props.value)
    }
   
    const handleRenderSelectedTimezone = (value) => {
        const maxLength = stepped ? 40 : 57
        const item = timezones.find(({ tzCode }) => tzCode === value)?.name
        return item?.length > maxLength ? item.slice(0, maxLength)+"..." : item
    }
    
    const handleSlotClick = (timestamp) => {
        setConfirmOpen(true)
        setTime(timestamp)
    }

    const handleSubmit = () => {
        if (!name) {
            axios.post(`${process.env.REACT_APP_API_URL}/scheduler`, {
                lead_id: leadID,
                agent_id: parseInt(agent_id),
                title: "test",
                description: "",
                time,
                timezone: timezone?.replace("Kyiv", "Kiev"),
                duration: parseInt(duration)
            },
            { headers: {"Access-Key": process.env.REACT_APP_ACCESS_KEY}})
            .then((response) => {
                history.push("/scheduler-success")
            })
            .catch((error) => {
                setConfirmOpen(false)
                console.log(error)
            })
        }
    }
    
    // get time slots data
    useEffect(() => {
        if (agent?.calendar_id) {
            setLoading(true)
            // Calculate time slots
            let slotsTemp = [] 
            const ranges = getRanges(agent.working_hours, date)

            if (ranges.length) {
                const firstRange = ranges[0]

                axios.get(`${process.env.REACT_APP_API_URL}/scheduler/availability?start_time=${firstRange[0]}&end_time=${firstRange[1]}&participant=${agent.calendar_id}&duration_minutes=${duration}&interval_minutes=${duration}`, { headers: {"Access-Key": process.env.REACT_APP_ACCESS_KEY}})
                .then((response) => {
                    slotsTemp = [...response.data.time_slots]
                    if (slotsTemp.length < 5 || !ranges[1]) {
                        setSlots(response.data.time_slots?.slice(0, 6))
                        setLoading(false)
                    } else {
                        axios.get(`${process.env.REACT_APP_API_URL}/scheduler/availability?start_time=${ranges[1][0]}&end_time=${ranges[1][1]}&participant=${agent.calendar_id}&duration_minutes=${duration}&interval_minutes=${duration}`, { headers: {"Access-Key": process.env.REACT_APP_ACCESS_KEY}})
                            .then((res) => {
                                setSlots([...slotsTemp, ...res.data.time_slots]?.slice(0, 6))
                                setLoading(false)
                            })
                    }
                })
                .catch((error) => console.log(error))
            } else {
                setLoading(false)
            }
        } else if (name) {
            setLoading(true)
            setSlots(mockTimeSlots)
            setTimeout(() => setLoading(false), 1500)
        }
    }, [agent, duration, date])

    return stepped ? (
        <div style={{ maxWidth: 369, height: 570, background: "#211E32" }}>
            {
                step === 1 ? 
                <Calendar
                    fullWidth
                    date={date}
                    name={name}
                    time={time}
                    handleDateChange={handleDateChange}
                />
                :
                <TimeSlotPicker
                    fullWidth
                    name={name}
                    date={date}
                    loading={loading}
                    timezone={timezone}
                    duration={duration}
                    slots={slots}
                    time={time}
                    maxLength={31}
                    handleDurationChange={handleDurationChange}
                    handleSlotClick={handleSlotClick}
                    handleTimezoneChange={handleTimezoneChange}
                    handleRenderSelectedTimezone={handleRenderSelectedTimezone}
                />

            }

            <InformationPopup
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={handleSubmit}
                title={`You want to schedule a call on ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()},  ${date.getFullYear()}, ${time ? getLocalTime(time*1000, timezone) : ""}?`}
                closeText="Cancel"
                confirmText="Schedule"
                buttonDirection="right"
            />
        </div>
    ) 
    : (
        <Grid container justifyContent="space-between" className={classes.schedulerContainer}>
            <Calendar
                date={date}
                name={name}
                time={time}
                handleDateChange={handleDateChange}
            />
            <TimeSlotPicker
                name={name}
                date={date}
                loading={loading}
                timezone={timezone}
                duration={duration}
                slots={slots}
                time={time}
                handleDurationChange={handleDurationChange}
                handleSlotClick={handleSlotClick}
                handleTimezoneChange={handleTimezoneChange}
                handleRenderSelectedTimezone={handleRenderSelectedTimezone}
            />
            
            <InformationPopup
                open={confirmOpen}
                onClose={handleConfirmClose}
                onConfirm={handleSubmit}
                title={`You want to schedule a call on ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()},  ${date.getFullYear()}, ${time ? getLocalTime(time*1000, timezone) : ""}?`}
                closeText="Cancel"
                confirmText="Schedule"
                buttonDirection="right"
            />
        </Grid>
    )
}

export default SchedulerComponent