import { useEffect, useRef, useState } from "react"
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import FormInput from "../FormInput";
import { UIViewButton } from "../../ui/buttons";
import { ReactComponent as IconPlus } from '../../../Assets/icon-plus.svg'
import { ReactComponent as IconMinus } from '../../../Assets/icon-minus-alt.svg'

const MailerConfigForm = ({ disabled, data, handleSubmit, setData }) => {
    const classes = useStyles()
    const formRef = useRef()
    const [ fields, setFields ] = useState({
        field1: ""
    })
    const [ fieldNames, setFieldNames ] = useState({
        field1_name: "SENDGRID_API_KEY"
    })
    
    const handleChangeData = (e) => {
        if (e.target.value === "sendgrid") {
            setFieldNames({
                field1_name: "SENDGRID_API_KEY"
            })
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
        
    const handleChangeField = (e) => {
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    }
        
    const handleChangeFieldName = (e) => {
        setFieldNames({
            ...fieldNames,
            [e.target.name]: e.target.value
        })
    }    
    
    const handleAddFieldName = () => {
        const id = Object.keys(fieldNames).length + 1
        setFieldNames({
            ...fieldNames,
            [`field${id}_name`]: ""
        })
        setFields({
            ...fields,
            [`field${id}`]: ""
        })
    }    
    
    const handleRemoveFieldName = () => {
        const newNames = Object.keys(fieldNames)
        const newValues = Object.keys(fields)
        newNames.pop()
        newValues.pop()
        setFieldNames(Object.assign({}, ...newNames.map((item) => ({ [item]: fieldNames[item] }))))
        setFields(Object.assign({}, ...newValues.map((item) => ({ [item]: fields[item] }))))
    }

    useEffect(() => {
        if (data.provider_settings) {
            const settings = Object.keys(data.provider_settings)
            if (settings.length) {
                const names = Object.assign(
                    {}, 
                    ...settings.map((item, idx) => ({ [`field${idx+1}_name`]: item }))
                )
                const values = Object.assign(
                    {}, 
                    ...settings.map((item, idx) => ({ [`field${idx+1}`]: data.provider_settings[item] }))
                )
                setFieldNames(names)
                setFields(values)
            }
        }
    }, [data.provider_settings])

    return (                
    <ValidatorForm ref={formRef} onSubmit={(e) => handleSubmit(e, fieldNames, fields)}>
        <Grid container direction="row" alignItems="flex-start" className={classes.formContainer}>
            <Grid item container>
                <Grid item container className={classes.selectContainer}>
                    <Typography className={classes.inputTitle}>Provider *</Typography>
                    
                    <SelectValidator
                    name="provider_type"
                    type="provider_type"
                    fullWidth
                    variant="outlined"
                    value={data.provider_type}
                    onChange={handleChangeData}
                    className={classes.select}
                    validators={['required']}
                    errorMessages={['Provider is required']}
                    required
                    disabled
                    data-testid="provider_type"
                    >
                        <MenuItem value={"sendgrid"}>Sendgrid</MenuItem>
                    </SelectValidator>
                </Grid>
                {
                    Object.keys(fieldNames)?.length ?
                    Object.keys(fieldNames)?.map((key, idx) => (
                        <Grid item container key={key} justifyContent="flex-end" className={classes.settingItemContainer}>
                            <Grid item container className={classes.keyContainer}>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Variable Name *</Typography>
                                </Grid>
                                <FormInput
                                    type={key}
                                    name={key}
                                    disabled
                                    placeholder=""
                                    value={fieldNames[key]}
                                    onChange={handleChangeFieldName}
                                    autoFocus
                                    data-testid={key}
                                    validators={fields[`field${idx+1}`] ? ['required'] : []}
                                    errorMessages={fields[`field${idx+1}`] ? ['Variable Name is required'] : []}
                                    />
                            </Grid>
                            <Grid item container className={classes.valueContainer}>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Value</Typography>
                                </Grid>
                                <FormInput
                                    type={`field${idx+1}`}
                                    name={`field${idx+1}`}
                                    placeholder=""
                                    value={fields[`field${idx+1}`]}
                                    onChange={handleChangeField}
                                    data-testid={`field${idx+1}`} />
                            </Grid>
                            <Grid item container className={classes.addContainer}>
                                <Grid item container alignItems="center" className={classes.buttonsContainer}>
                                    <IconPlus className={classes.fieldButton} onClick={handleAddFieldName}/>
                                    {idx+1 === Object.keys(fieldNames)?.length && <IconMinus className={classes.fieldButton} onClick={handleRemoveFieldName}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    : <Grid item container className={classes.settingItemContainer} style={{ paddingRight: 30, paddingTop: 34 }}>
                        
                        <Grid item container alignItems="center" justifyContent="flex-end" style={{ height: 44 }}>
                            <IconPlus className={classes.fieldButton} onClick={handleAddFieldName}/>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid container className={classes.confirmOptions}>
                    <UIViewButton
                        type="submit"
                        style={{ height: 42, width: 120 }}
                        data-testid="submitButton"
                        disabled={disabled}
                    >
                        Save
                    </UIViewButton>
            </Grid>
        </Grid>
</ValidatorForm>
    )

}

export default MailerConfigForm