import React from "react";
import { priorityColors } from "../../../../global/constants";
import styled from "styled-components";

const Tag = styled('div')({
    padding: "6px 0",
    borderRadius: 6,
    textAlign: "center",
    textTransform: "capitalize", 
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "18px"
})

const PriorityTag = ({ value, postfix = ""}) => (
    <Tag style={{ background: priorityColors[value?.toLowerCase()].background, color: priorityColors[value?.toLowerCase()].color, width: priorityColors[value?.toLowerCase()].width }}>
        {value}{postfix}
    </Tag>
)

export default PriorityTag