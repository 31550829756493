import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  segmentationRoot: {
    width: 634, 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 100,
    paddingBottom: 60
  },
  segmentationContent: {
    gap: 24,
    maxWidth: 374,
    "@media (max-width:1280px)": {
      maxWidth: 334,
    }
  },
  segmentationTitle: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: "40px",
      display: "none",
  },
  segmentationDrawerContainer: {
    zIndex: "900!important", 
    "& .MuiBackdrop-root": {
      left: 80
    }
  },
  segmentationDrawer: {
    width: "auto", 
    "&.MuiDrawer-paperAnchorLeft": {
      left: 80
    }
  },
  closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: 24,
      top: 12
  },
  backButton: {
      cursor: "pointer",
      position: "absolute",
      left: 24,
      top: 12
  },
  cardRoot: {
      boxShadow: "0px 5px 4px 0px #5F69830D",
      border: "1px solid #EDF1F8",
      borderRadius: 6, 
      padding: "15px 30px", 
      "&:hover": {
        border: "3px solid #383ECB"
      },
      "& .segment-card--button": {
        maxWidth: "unset",
        width: "100%", 
        height: 44, 
        "&:hover": {
          background: "#383ECB",
          color: "#FFFFFF"
        }
      },
      "@media (max-width:1280px)": {
        padding: "10px 20px", 
        "& .segment-card--button": {
          height: 40
        }
      }
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "22px",
  },
  cardContent: {
    gap: 8,
    paddingTop: 10,
    paddingBottom: 10,
  },
  cardItem: {
    gap: 16, 
    "& svg": {
      width: 34,
      height: 34,
      "@media (max-width:1280px)": {
        width: 30,
        height: 30,
      }
    }
  },
  cardItemTitle: {
    fontSize: 10,
    lineHeight: "12px",
  },
  cardItemText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    "@media (max-width:1280px)": {
      fontSize: 16,
      lineHeight: "18px"
    }
  },
}))

export default useStyles
