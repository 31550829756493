import React, { Component } from 'react';  
import tableau from 'tableau-api';  

class Tableau extends Component { 
    componentDidMount() {
        this.initViz()
      }
     
     
    initViz() {
        const vizUrl = 'https://us-west-2b.online.tableau.com/t/atidot/views/poc_example_haven/platformexample/e8340880-4f0d-45fe-9c62-101ee9bc556b/Platform_tableau';
        const vizContainer = this.vizContainer;
        const options = {
            width: "100%",
            height: "95vh",
        };
        let viz = new window.tableau.Viz(vizContainer, vizUrl, options)
    }

  render() {  
    return (  
      <div className='vizDiv' ref={(div) => { this.vizContainer = div }}>  
      </div>  
    )  
  }  
}  


export default Tableau;