import ApiService from "../global/service";

export default class DataHandlerApiService {

    getConnectors = (requestHandler, errorHandler) => ApiService.get('data/connector', {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)

    postConnector = (headers, data, requestHandler, errorHandler) => ApiService.post('data/connector', headers, requestHandler, errorHandler, data, process.env.REACT_APP_DH_API_URL)

    _getConnectorsBroker = (type, requestHandler, errorHandler) => ApiService.get(`data/${type}/connector`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)
    
    _postConnectorBroker = (type, data, requestHandler, errorHandler) => ApiService.post(`data/${type}/connector`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_DH_API_URL)

}