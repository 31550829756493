import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resetProducts, selectIds, selectImpacts, setActiveTab, setImpactsData, setProjectIds, setProjectLabels, setProspectsHeaders, setRunsMeta } from "../BookAnalysisSlice";
import BookAnalysisService from "../BookAnalysisService";
import HistoryChart from "../../../Components/Run/Charts/HistoryChart/HistoryChart";
import Legend from "../../../Components/Run/Charts/Legend/Legend";
import UIDistribution from "../../../Components/Demo/Charts/UIDestribution/UIDestribution";
import { defaultChartColors, defaultColumnsAnnuities, defaultColumnsLife } from "../../../global/constants";
import ProductsChart from "../../../Components/Run/Charts/ProductsChart/ProductsChart";
import { UIViewButton } from "../../../Components/ui/buttons";
import Loader from "../../../Components/Loader/Loader";
import { selectUserCompany, setColumns } from "../../../AppSlice";
import appApiService from "../../../AppService";

const useStyles = makeStyles(() => ({
    titleContainer: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
      color: "#312E40",
      display: "none",
      paddingBottom: 24
    },
    historyChartContainer: {
        border: "1px solid rgba(194, 194, 194, 0.50)",
        borderRadius: 6,
        boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)",
        marginBottom: 6,
    },
    modelsDataContainer: {
        "& .model-chart--container": {
            maxWidth: "25%",
            flexBasis: "25%"
        },
        "& .products-chart--container": {
            maxWidth: "50%",
            flexBasis: "50%",
            padding: "0 6px",
        },
        height: 275
    },
    projectsHistoryTitle: {
      alignItems: 'center',
      flexDirection: 'column',
      alignContent: 'center',
      paddingTop: 12,
      "& .MuiTypography-root": {
        paddingBottom: "20px",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#312E40"
      },
      border: "1px solid rgba(194, 194, 194, 0.50)",
      borderRadius: 6,
      boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)",
    },
    projectsHistoryChart: {
      width: 214,
      height: 214
    },
    legendContainer: {
        "& .chartLegend": {
            margin: 0,
            marginTop: 24,
        },
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: 24
    },
    backdrop: {
        position: "fixed",
        height: "100vh",
        background: "#00000095",
        top: 0,
        left: 80,
        zIndex: 1000
    }
}))

const ApiService = new BookAnalysisService()
const AppService = new appApiService()

const Impacts = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const data = useSelector(selectImpacts)
    const projectIds = useSelector(selectIds)
    const user = useSelector(selectUserCompany)
    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isBroker = localStorage.getItem("accountType") === "broker"

    const [ loading, setLoading] = useState(false)

    
    useEffect(() => {
        if (!isBroker) {
            dispatch(resetProducts())
        
            AppService._getRuns(
                localStorage.getItem("companyID"),
                (res) => {
                    const defaultColumns = isAnnuities ? defaultColumnsAnnuities : defaultColumnsLife
    
                    // Load actuall data
                    if (res.data?.count !== 0 || user === "platform-anonym") {
                        // Load columns configuration
                        const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                        const tmp = {}
                        if (columnsConfig?.length > 0) {
                            Object.assign(tmp, ...Object.keys(defaultColumns)
                                .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                            dispatch(setColumns(
                                tmp
                            ))
                        } else {
                            Object.assign(tmp, ...Object.keys(defaultColumns)
                                .map((key) => ({[key]: defaultColumns[key]})))
                            dispatch(setColumns(
                                tmp
                            ))
                        }
    
                        const meta = []
                        const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                            meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "",})
                            return project_id
                        })
                        if (!projectIds?.length) {
                            dispatch(setProjectIds(ids))
                            dispatch(setProjectLabels(meta))
                            dispatch(setRunsMeta(res.data))
    
                        }
                    } else {
                        // Load demo data
                        AppService._getDemoRunsData((res) => {
                            history.push("/book?demo")                        
                            const meta = []
                            const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                                meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "",})
                                return project_id
                            })
                            if (!projectIds?.length) {
                                dispatch(setProjectIds(ids))
                                dispatch(setProjectLabels(meta))
    
                            }
                            dispatch(setColumns(defaultColumns))
                        },
                        (error) => { console.log(error) })
                    }
    
                }, 
            (error) => { console.log(error);}
            )            
        }

    }, [dispatch])

    useEffect(() => {
        if (projectIds?.length && !isBroker) {
            setLoading(true)
            ApiService._getImpactByProducts(
                history.location.search?.includes("demo") || user === "platform-anonym",
                projectIds,
                (response) => {
                    dispatch(setImpactsData(response.data))
                    setTimeout(() => setLoading(false), 1500)
                },
                () => {}
            )
        }
    }, [dispatch, projectIds])

    useEffect(() => {
        if (projectIds?.length) {
            ApiService._getProductsByIDs(history.location.search?.includes("demo") || user === "platform-anonym", projectIds, (response) => {
                if (response.data?.total_count === 0) {
                    ApiService._getDemoProducts((response) => {
                        dispatch(setProspectsHeaders(response.data))
                    }, (error) => console.log(error))
                } else {
                    dispatch(setProspectsHeaders(response.data))
                }
            }, (error) => console.log(error))
        }
    }, [projectIds, dispatch])

    return (
        <Grid container direction="column"> 
            <Grid container justifyContent="center" className={classes.main}>
                {loading && <Loader/>}
                <Grid container  className={classes.titleContainer}>
                    {`Your portfolio can perform ${data.impact?.toFixed(2)}% better`}
                </Grid>

                <Grid container justifyContent="center" className={classes.historyChartContainer}>
                    <HistoryChart
                        className="book"
                        justify="space-around"
                        originalData={data?.original}
                        aumData={data?.potential}
                        potentialData={data?.total}
                        titles={[
                            `${isAnnuities ? "Original Portfolio performance" : "Estimated annualized performance"}`,
                            `${isAnnuities ? "Potential AUM Upside" : "Your potential Premium Upside"}`,
                            "Potential Performance with Atidot",
                        ]}
                    />
                </Grid>

                <Grid container justifyContent="space-between" className={classes.modelsDataContainer}>
                    <Grid item container className="model-chart--container">
                        <Grid container className={classes.projectsHistoryTitle}>
                            <Typography>{`${isAnnuities ? "Retained AUM" : "Retention Upside"}`}</Typography>

                            <div className={classes.projectsHistoryChart}>
                            <UIDistribution
                                id="lapse"
                                paddingRight={0}
                                chartData={data?.lapse}
                                showTable={false}
                                showLegend
                                totalText={""}
                                toolTipText=""
                                innerRadius={95}
                                colorList={defaultChartColors} 
                                emptyValue="-"/>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item container className="products-chart--container">
                        
                        <Grid container className={classes.projectsHistoryTitle}>
                            <ProductsChart
                                colorList={defaultChartColors}
                                chartData={data?.products ? data.products : []} 
                                titleLapse={isAnnuities ? "Retained AUM" : "Retention Annual Premium"} 
                                titleUI={isAnnuities ? "New Business AUM" : "New Business Annual Premium"} 
                                keys={data?.keys}
                                isAnnuities={isAnnuities}/>
                        </Grid>
                    </Grid>
                    <Grid item container className="model-chart--container">
                        <Grid container className={classes.projectsHistoryTitle}>
                            <Typography>New Business Upside</Typography>

                            <div className={classes.projectsHistoryChart}>
                            <UIDistribution
                                id="ui"
                                paddingRight={0}
                                chartData={data?.ui}
                                showTable={false}
                                showLegend
                                totalText={""}
                                toolTipText=""
                                innerRadius={95}
                                colorList={defaultChartColors}
                                emptyValue="-"
                                isData={Object.keys(data.ui).length > 0} />
                            </div>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" className={classes.legendContainer}>
                    <Legend data={data?.keys?.map((value, index) => ({ id: value, index }))}/>
                </Grid>     
            </Grid>       
            <Grid container justifyContent="center" style={{ padding: "42px 0", display: "none" }}>
                <UIViewButton disableRipple onClick={() => dispatch(setActiveTab("Immediate Opportunities"))}>
                    View Opportunities
                </UIViewButton>
            </Grid>
        </Grid>
    )
}

export default Impacts