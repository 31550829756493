import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    slice: 0
}

const name = 'tables'

export const TableSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setSlice: (state, action) => {
            state.slice = action.payload
        },
    }
})

export const { setSlice } = TableSlice.actions

export const selectSlice = (state) => state.tables.slice


export default TableSlice.reducer