import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTableName,
  setTableName,
  selectProjectId,
  selectDBType,
  setDBType
} from "../../../Pages/NewProject/NewProjectSlice";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import newProjectApiService from "../../../Pages/NewProject/NewProjectService";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginTop: 36,
    maxWidth: 556,
    "@media (min-width: 1440px)": {
      maxWidth: 756
    }
  },
  DatabaseConnBackground: {
    justifyContent: "center",
    minHeight: "30vh",
    padding: 50,
  },
  customInput: {
    display: "flex",
    flexFlow: "row wrap",
    width: "100%",
    marginBottom: "20px",
  },
  customNameInput: {
    display: "flex",
    width: "100%",
    height: "50px",
    backgroundColor: "#ffffff",
    border: "1px solid #d9e1ee",
    borderRadius: 8,
    outline: "0px",
    padding: "0 20px",
    margin: "5px 0px",
    "&:focus-within": {
      border: "1px solid #171b59",
    },
  },
  lblNombre: {
    margin: "4px 0px",
    fontWeight: "500",
    color:'#312e40',
  height: '18px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.38',
  letterSpacing: 'normal',
  },
  customTitle: {
    margin: "30px 0px",
  },
  maxWidth: {
    width: "100%",
  },
  TabPanelStyle: {
    padding: 0,
    paddingTop: 24,
  },
  loadingButton: {
    borderRadius: 8,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: "#ecf1f9 ",
    color: "#363ed3 ",
    "&:hover": {
      backgroundColor: "#ecf1f9 ",
    },
  },
  testingButton: {
    borderRadius: 8,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: "#363ed3",
    "&:hover": {
      backgroundColor: "#363ed3 ",
    },
    color: "white",
  },
  selectBody: {
    color: "red",
    "& .select__menu": {
      color: "red",
      fontWeight: "200",
    },
  },
  alertErrorStyle: {
    border: "none",
    width: "60%",
    color: "#d82832",
    "& .MuiAlert-icon": {
      color: "#d82832",
      marginRight: "6px",
    },
  },
  alertSuccessStyle: {
    border: "none",
    width: "60%",
    color: "#0bcb47",
    "& .MuiAlert-icon": {
      color: "#0bcb47",
      marginRight: "6px",
    },
  },
  styleInput: {
    height: "100%",
    padding: "0",
    width: "100%",
    border: "none",
    outline: "none",
    "&:-internal-autofill-selected": {
      boxShadow: "0 0 0 30px white inset !important",
      /* backgroundColor:'white !important' */
    },
  },
  nextButton: {
      color: "#fff",
      background: "#0BCB47",
      width: 250,
      height: 50,
      borderRadius: 8,
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "16px",
      textAlign: "center"
  },
  nextButtonContainer: {
      paddingTop: 54
  },
}));

function DatabaseConn({ onDBConneted, onS3Connected, onNext }) {
  const classes = useStyles();
  const initialFocus = {
    DBName: false,
    Host: false,
    Port: false,
    Username: false,
    Password: false,
    Accesskey: false,
    Secretkey: false,
    Bucket: false,
    Filename: false,
    FilePath: false,
    Url: false
  }
  const [DBName, setDBName] = React.useState("");
  const [activeTab, setActiveTab] = React.useState("1");
  const [host, setHost] = React.useState("");
  const [port, setPort] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [filePath, setFilePath] = React.useState("");
  const [accessKey, setAccessKey] = React.useState("");
  const [secretKey, setSecretKey] = React.useState("");
  const [bucket, setBucket] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [ButtonText, setButtontext] = React.useState("Test Connection");
  const [loadingConnection, setLoadingConnection] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState("");
  const [severityAlert, setSeverityAlert] = React.useState("");
  const [tablesList, setTablesList] = React.useState([]);
  const [isFull, setIsFull] = React.useState(false);
  const dispatch = useDispatch();
  const tableName = useSelector(selectTableName);
  const projectId = useSelector(selectProjectId);
  const DBType = useSelector(selectDBType);
  const [focus, setFocus] = React.useState(initialFocus);
  const ApiService = new newProjectApiService();
  const handleFocusDBName = () => {
    setFocus({
      ...initialFocus,
      DBName: true,
    });
  };
  const handleFocusHost = () => {
    setFocus({
      ...initialFocus,
      Host: true,
    });
  };
  const handleFocusPort = () => {
    setFocus({
      ...initialFocus,
      Port: true,
    });
  };
  const handleFocusUsername = () => {
    setFocus({
      ...initialFocus,
      Username: true,
    });
  };
  const handleFocusPassword = () => {
    setFocus({
      ...initialFocus,
      Password: true,
    });
  };
  const handleFocusBucket = () => {
    setFocus({
      ...initialFocus,
      Bucket: true
    })
  }
  const handleFocusAccessKey = () => {
    setFocus({
      ...initialFocus,
      Accesskey: true
    })
  }
  const handleFocusSecretKey = () => {
    setFocus({
      ...initialFocus,
      Secretkey: true
    })
  }
  const handleFocusFilename = () => {
    setFocus({
      ...initialFocus,
      Filename: true
    })
  }
  const handleFocusFilepath = () => {
    setFocus({
      ...initialFocus,
      FilePath: true
    })
  }
  const handleFocusUrl = () => {
    setFocus({
      ...initialFocus,
      Url: true
    })
  }

  const handleAway = () => {
    setFocus(false);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleDBTypeChange = (event) => {
    dispatch(setDBType(event.target.value))
  }

  React.useEffect(() => {
    const generalDBData = (
      DBName !== "" &&
      DBType !== "" &&
      DBType !== "s3" &&
      DBType !== "url" &&
      host !== "" &&
      port !== "" &&
      username !== "" &&
      password !== ""
    )
    const awsData = (
      DBName !== "" &&
      DBType !== "" &&
      DBType === "s3" &&
      accessKey !== "" &&
      secretKey !== "" &&
      bucket !== "" &&
      fileName !== "" &&
      filePath !== ""
    )
    if (
      generalDBData ||
      awsData ||
      url !== ""
    ) {
      setIsFull(true);
    } else {
      setIsFull(false);
    }
  }, [DBName, DBType, host, port, username, password, bucket, accessKey, secretKey, fileName, filePath, url]);

  React.useEffect(() => {
    if(DBType === "postgres") {
      setPort(5432)
    } else if(DBType === "mysql") {
      setPort(3306)
    } else {
      setPort("")
    }
  }, [DBType])

  function testConnectionClick() {
    setDisplayMessage("");
    setSeverityAlert("");
    setTablesList([]);
    setLoadingConnection(true);
    setButtontext("Testing Connection");
    setFocus(false);
    if (DBType !== "url") {
      const payload = (DBType === "postgres" || DBType === "mysql")  ?
        {
          credentials: {
            database: DBName,
            type: DBType,
            user: username,
            password: password,
            host: host,
            port: parseInt(port),
          },
          type: "db"
        } : {
          type: "s3",
          source_name: DBName,
          credentials: {
            user: username,
            access_key_id: accessKey,
            secret_access_key: secretKey,
            bucket_name: bucket,
            file_name: fileName,
            file_path: filePath,
          },
        }
      ApiService._postDataBaseConnection(projectId ? projectId : null, payload, (response) => {
        setLoadingConnection(false);
        setTablesList(DBType !== "s3" ? response.data.table_name : []);
        onDBConneted(response)

        setDisplayMessage("Successful Connection");
        setSeverityAlert("success");
      }, (error) => {
        console.log(error);
        setLoadingConnection(false);

        setDisplayMessage(
          "Unable to connect to the database, please check your credentials"
        );
        setSeverityAlert("error");
      })
    } else {
      ApiService._postS3BucketUrl(projectId, {
        url: url,
        source_name: DBName
      }, (response) => {
        setLoadingConnection(false);
        onS3Connected(response)

        setDisplayMessage("Successful Connection");
        setSeverityAlert("success");
      }, (error) => {
        console.log(error);
        setLoadingConnection(false);

        setDisplayMessage(
          "Unable to connect to the database, please check your credentials"
        );
        setSeverityAlert("error");
      })
    }
  }

  return (
    <ClickAwayListener onClickAway={handleAway}>
      <Grid container direction="column"
          alignItems="center"
          spacing={0}>
        <Grid >
          <Typography variant="h4" className={classes.customTitle}>
            <Box sx={{ fontWeight: 500, m: 1 }}>Data source parameters</Box>
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={0}
          className={classes.root}
          data-testid="dbConnWrapper"
        >
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6} className={classes.maxWidth}>
              <div className={classes.customInput}>
                <label className={classes.lblNombre}>DB Type</label>
                <Select
                  value={DBType}
                  onChange={handleDBTypeChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.customNameInput}
                  data-testid="dbType"
                  sx={{ color: "gray", '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#363ed3',
                } }}
                >
                  <MenuItem value={"postgres"}>PostgreSQL</MenuItem>
                  <MenuItem value={"mysql"}>MySQL</MenuItem>
                  <MenuItem value={"s3"}>AWS S3</MenuItem>
                  <MenuItem value={"url"}>URL</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.maxWidth}>
              <div className={classes.customInput}>
                <label className={classes.lblNombre}>Name</label>
                <div className={classes.customNameInput}>
                  <input
                    id="Name"
                    value={DBName}
                    type=" text"
                    required
                    className={classes.styleInput}
                    onChange={(event) => setDBName(event.target.value)}
                    onFocus={handleFocusDBName}
                  ></input>
                  <div
                    style={{
                      width: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {DBName && focus.DBName ? (
                      <CloseIcon
                        style={{ color: "lightgray" }}
                        fontSize="small"
                        onClick={(event) => setDBName("")}
                      />
                    ) : null}
                    {DBName && !focus.DBName ? (
                      <CheckIcon style={{ color: "#0bcb47" }} fontSize="small" />
                    ) : null}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.maxWidth}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={activeTab}>
                  <Box sx={{ height:'40px', borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      TabIndicatorProps={{ sx: { backgroundColor: "#363ed3", marginBottom:'7px' } }}
                      onChange={handleTabChange}
                      selected={true}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="General"
                        value="1"
                        sx={{
                          "text-transform": "capitalize",
                          "font-weight": "500",
                          "font-family": 'Inter',
                          "font-size":'14px',
                          padding:'0px 0px',
                          marginRight:'10px',
                          color: "#D3D3D3",
                          'line-height': 1.29,
                          "&.Mui-selected": {
                            color: "#312e40",
                          },
                        }}
                      />
                      <Tab
                        label="Tab Name"
                        value="2"
                        sx={{
                          "text-transform": "capitalize",
                          "font-weight": "500",
                          "font-family": 'Inter',
                          "font-size":'14px',
                          padding:'0px 0px',
                          marginRight:'10px',
                          color: "#D3D3D3",
                          "&.Mui-selected": {
                            color: "black",
                          },
                        }}
                      />
                      <Tab
                        label="Tab Name"
                        value="3"
                        sx={{
                          "text-transform": "capitalize",
                          "font-weight": "500",
                          "font-family": 'Inter',
                          "font-size":'14px',
                          padding:'0px 0px',
                          marginRight:'10px',
                          color: "#D3D3D3",
                          "&.Mui-selected": {
                            color: "black",
                          },
                        }}
                      />
                      <Tab
                        label="Tab Name"
                        value="4"
                        sx={{
                          "text-transform": "capitalize",
                          "font-weight": "500",
                          "font-family": 'Inter',
                          "font-size":'14px',
                          padding:'0px 0px',
                          color: "#D3D3D3",
                          "&.Mui-selected": {
                            color: "black",
                          },
                        }}
                      />
                    </TabList>
                  </Box>
                  {(DBType === "postgres" || DBType === "mysql") ? (<TabPanel value="1" sx={{ padding: 0, paddingTop: 3 }}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Host</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="Host"
                              value={host}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) => setHost(event.target.value)}
                              onFocus={handleFocusHost}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {host && focus.Host ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setHost("")}
                                />
                              ) : null}
                              {host && !focus.Host ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Port</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="Port"
                              value={port}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) => setPort(event.target.value)}
                              onFocus={handleFocusPort}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {port && focus.Port ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setPort("")}
                                />
                              ) : null}
                              {port && !focus.Port ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>User</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="User"
                              value={username}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setUsername(event.target.value)
                              }
                              onFocus={handleFocusUsername}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {username && focus.Username ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setUsername("")}
                                />
                              ) : null}
                              {username && !focus.Username ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Password</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="Password"
                              value={password}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setPassword(event.target.value)
                              }
                              onFocus={handleFocusPassword}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {password && focus.Password ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setPassword("")}
                                />
                              ) : null}
                              {password && !focus.Password ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>) : 
                  DBType === "s3" ? 
                  (<TabPanel value="1" sx={{ padding: 0, paddingTop: 3 }}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Bucket Name</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="Bucket"
                              value={bucket}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) => setBucket(event.target.value)}
                              onFocus={handleFocusBucket}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {bucket && focus.Bucket ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setBucket("")}
                                />
                              ) : null}
                              {bucket && !focus.Bucket ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Access Key</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="Access"
                              value={accessKey}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) => setAccessKey(event.target.value)}
                              onFocus={handleFocusAccessKey}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {accessKey && focus.Accesskey ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setAccessKey("")}
                                />
                              ) : null}
                              {accessKey && !focus.Accesskey ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>User</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="User"
                              value={username}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setUsername(event.target.value)
                              }
                              onFocus={handleFocusUsername}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {username && focus.Username ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setUsername("")}
                                />
                              ) : null}
                              {username && !focus.Username ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Secret Access Key</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="SecretKey"
                              value={secretKey}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setSecretKey(event.target.value)
                              }
                              onFocus={handleFocusSecretKey}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {secretKey && focus.Secretkey ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setSecretKey("")}
                                />
                              ) : null}
                              {secretKey && !focus.Secretkey ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>File Name</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="FileName"
                              value={fileName}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setFileName(event.target.value)
                              }
                              onFocus={handleFocusFilename}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {fileName && focus.Filename ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setFileName("")}
                                />
                              ) : null}
                              {fileName && !focus.Filename ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>File Path</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="FilePath"
                              value={filePath}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setFilePath(event.target.value)
                              }
                              onFocus={handleFocusFilepath}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {filePath && focus.FilePath ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setFilePath("")}
                                />
                              ) : null}
                              {filePath && !focus.FilePath ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel> ) :
                  (
                    <TabPanel value="1" sx={{ padding: 0, paddingTop: 3 }}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        <div className={classes.customInput}>
                          <label className={classes.lblNombre}>Url</label>
                          <div className={classes.customNameInput}>
                            <input
                              id="url"
                              value={url}
                              type=" text"
                              className={classes.styleInput}
                              onChange={(event) =>
                                setUrl(event.target.value)
                              }
                              onFocus={handleFocusUrl}
                            ></input>
                            <div
                              style={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {url && focus.Url ? (
                                <CloseIcon
                                  style={{ color: "lightgray" }}
                                  fontSize="small"
                                  onClick={(event) => setFilePath("")}
                                />
                              ) : null}
                              {url && !focus.Url ? (
                                <CheckIcon
                                  style={{ color: "#0bcb47" }}
                                  fontSize="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    </TabPanel>
                  )
                  }
                  <TabPanel value="2"></TabPanel>
                  <TabPanel value="3"></TabPanel>
                  <TabPanel value="4"></TabPanel>
                </TabContext>
              </Box>
            </Grid>
            <Grid item style={{ display: "contents" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  className={
                    loadingConnection
                      ? classes.loadingButton
                      : classes.testingButton
                  }
                  style={{ textTransform: "none" }}
                  onClick={testConnectionClick}
                  disabled={!isFull}
                  data-testid="testButton"
                >
                  {ButtonText}
                  {loadingConnection ? (
                    <CircularProgress
                      style={{
                        color: "#363ed3",
                        marginLeft: "7px",
                        width: "13px",
                        height: "13px",
                      }}
                    />
                  ) : null}
                </Button>
                {displayMessage !== "" && severityAlert !== "" ? (
                  <Alert
                    variant="outlined"
                    severity={severityAlert}
                    className={
                      severityAlert === "error"
                        ? classes.alertErrorStyle
                        : classes.alertSuccessStyle
                    }
                    iconMapping={{
                      success: <CheckIcon fontSize="inherit" />,
                    }}
                    data-testid={severityAlert !== "error" ? "testConnSuccess" : "testConnError"}
                  >
                    {displayMessage}
                  </Alert>
                ) : null}
              </Grid>
            </Grid>
            {severityAlert === "success" && DBType !== "s3" && DBType !== "url"  ? (
              <Grid item xs={12}>
                <div className={classes.customInput}>
                  <label className={classes.lblNombre}>Tables Name</label>
                  <Select
                    value={tableName}
                    onChange={(event) =>
                      dispatch(setTableName(event.target.value))
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="Choose a Tables Name"
                    className={classes.customNameInput}
                    sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#363ed3',
                  }}}
                  >
                    {tablesList.map((value, idx) => (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Grid container justifyContent="center" className={classes.nextButtonContainer}>
            <Button onClick={onNext} className={classes.nextButton} disabled={severityAlert !== "success"}>Next</Button>
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}

export default DatabaseConn;
