import React from "react";
import InformationPopup from "../InformationPopup/InformationPopup";
import { Checkbox, FormControlLabel, FormGroup, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as IconCheck } from "../../../Assets/icon-checkbox.svg"

const useStyles = makeStyles(() => ({
    container: {
        "& .MuiPaper-root": {
            width: 354,
            justifyContent: "flex-start"
        },
        "& .MuiDialogContent-root": {
            background: "#FFF",
            width: 330,
            borderRadius: 6
        },
        "& .action-btn--close,.action-btn--confirm": {
            minWidth: 128
        },
        "& .popup-head": {
            position: "absolute",
            "& svg": {
                position: "relative",
                margin: 0,
                bottom: 30,
                right: 8
            }
        },
    },
    checkBoxProps: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px"
        },
        "& .MuiTypography-h1": {
            fontFamily: "Roboto",
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "18px",
            color: "#000",
        },
        "& .MuiSvgIcon-root": {
            width: 14,
            height: 14,
        },
        "& .MuiFormControlLabel-root": {
            width: "100%",
            height: 32,
            margin: 0,
            border: "1px solid #D9E1EE",
        },
        "& .subtitle": {
            fontSize: 14,
            lineHeight: "130%",
            padding: "24px 0",
        },
        "& svg": {
            marginBottom: 0
        }
    },
    checkLabel: {
        fontSize: 13,
        textTransform: "capitalize"
    },
    checkContainerActive: {
        background: "#363ED312",
        color: "#6A6C71",
    },

}));

const ColumnsPopup = ({ open, handleClose, handleApply, handleChange, options }) => {
    const classes = useStyles()

    return (
            <InformationPopup
                className={classes.container}
                open={open}
                onClose={handleClose}
                onConfirm={handleApply}
                closeText="Discard"
                confirmText="Apply"
                closeIcon={false}
                buttonDirection="right"
                children={
                    <div className={classes.checkBoxProps}>
                        <Typography variant="h1">Prospects Table Column Config</Typography>
                        <Typography className="subtitle">Please choose the columns you would<br/>like to be displayed in the prospects table</Typography>
                        <FormGroup>
                            {
                                Object.keys(options)
                                ?.filter((title) => !["AUM Upside", "Premium Upside"].includes(title))
                                ?.map((title) => {
                                    return <FormControlLabel
                                    className={options[title] ? classes.checkContainerActive : ""}
                                    key={title}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={options[title]}
                                            onChange={(e) => handleChange(title, e.target.checked)}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.checkLabel}
                                        >
                                            {title}
                                        </Typography>
                                    }
                                    />
                                })
                            }
                        </FormGroup>
                    </div>
                }
            />
          
    )
}

export default ColumnsPopup