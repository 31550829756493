import React, { useState } from "react";
import { useStyles } from "./styles";
import InformationPopup from "../../InformationPopup/InformationPopup";
import FormInput from "../../../Forms/FormInput";
import BookAnalysisService from "../../../../Pages/BookAnalysis/BookAnalysisService";
import { ReactComponent as CloseIcon } from "../../../../Assets/icon-close-alt.svg"
import { Typography } from "@material-ui/core";

const ApiService = new BookAnalysisService()

const NotePopup = ({
    open,
    onClose,
    policyId
}) => {
    const classes = useStyles()
    const [note, setNote] = useState("")

    const handleCloseNotes = (status) => {
        setNote("")
        onClose(status)
    }

    const handleAddNotes = () => {
            ApiService._postAddProspectNote(
                {
                    policy_id: policyId,
                    note
                },
                () => {
                    handleCloseNotes(true)
                },
                (error) => { 
                    handleCloseNotes()
                    console.log(error);
                }
            )
    }

    return (
        <InformationPopup
                open={open}
                onClose={handleCloseNotes}
                onConfirm={handleAddNotes}
                confirmText="Add Note"
                closeText="Cancel"
                title={"Add Note"}
                CloseIcon={CloseIcon}
                className={classes.editPopup}
            >
                <Typography className={classes.editInputLabel}>Description</Typography>
                <FormInput
                    placeholder="Start note...." 
                    multiline
                    rows={3}
                    maxRows={5}
                    validate={false}
                    className={classes.editInput}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
        </InformationPopup>
    )
}

export default NotePopup