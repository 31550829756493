import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import MagicImg from "../../Assets/magic.png"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectRunError, selectRunFinished, selectRunsProgress, setMenuDisabled, setRunFinished } from "../../AppSlice";
import './style.css'
import { User } from "../../global/user";
import appApiService from "../../AppService";

const ApiService = new appApiService();

const Magic = ({ displayTime = 6000, blinkInterval = 1500, demo = false }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const runningProjects = useSelector(selectRunsProgress)
    const runError = useSelector(selectRunError)
    const status = useSelector(selectRunFinished)
    const animationRef = useRef(null)
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const [ show, setShow ] = useState(0)

    const redirectToBook = useCallback(() => {
        console.log(runError);
        dispatch(setRunFinished({
            ui: false,
            lapse: false
        }))
        history.push(runError ? "/dashboard" : "/book")      
    }, [history, dispatch, runError])

    useEffect(() => {
        if (!animationRef.current) {
            animationRef.current = setInterval(() => setShow((s) => s === 3 ? 0 : s+1), blinkInterval)
        }
    }, [animationRef, show])

    useEffect(() => {
        if (demo) {

            setTimeout(() => history.push("/book"), displayTime)
        }
    }, [history, displayTime, demo])

    useEffect(() => {
        if (!demo) {
            if (runningProjects?.length === 0 && status.lapse && (status.ui || isBroker)) {
                ApiService._getRuns(
                    localStorage.getItem("userID"),
                    (response) => {
                        redirectToBook()
                    },
                    () => {
                        redirectToBook()
                        dispatch(setMenuDisabled(false))
                    }
                )

            }
        }
    }, [runningProjects, status, demo, dispatch, redirectToBook])

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
            
            <div className="circle circle-inner" style={{ display: show >= 1 ? "flex" : "none" }}>
                </div>
                <div className="circle circle-mid" style={{ display: show >= 2 ? "flex" : "none" }}>
                </div>
                <div className="circle circle-outer" style={{ display: show === 3 ? "flex" : "none"}}>
            </div>

            <img className="image" src={MagicImg} alt="Making the magic happen"/>

        </Grid>
    )
}

export default Magic