import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// MUI
import { makeStyles, Grid, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";

// Components
import { ReactComponent as HelpIcon } from '../../Assets/icon-help.svg';
import { ReactComponent as ErrorIcon } from '../../Assets/icon-mapper-error.svg';
import SubModel from "./SubModel";
import InformationPopup from "../Popups/InformationPopup/InformationPopup";
import { NextButton } from "../ui/buttons";

// Redux
import { selectAutoMapping, selectCsvInfo, selectMapperError, selectRunModel, selectRunModels, selectValidationError, setCsvInfo, setEmptyKeys, setMapperOptions, setValidationError } from "../../Pages/NewProject/NewProjectSlice";

// Common
import { nFormatter } from "../../global/helpers";
import { demoCsv, demoCsvBroker } from "./constants";
import { User } from "../../global/user";
import MapperContentContainer from "./MapperContentContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import DemoFile from "../../Assets/mock_term_wl_1.csv"
import DemoBrokerFile from "../../Assets/mock_500_customer_list_202400207.csv"


const useStyles = makeStyles(() => ({
    tableStyle: {
        padding: 15,
        "& table": {
            borderRadius: 6,
            boxShadow: '0px 0px 0px 2px #e2e8f2',
        },
    },
    headerTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 36,
            fontWeight: 400,
            lineHeight: "42px",
            color: "#000000",
            textTransform: "capitalize",
            paddingBottom: 6,
            "@media (min-width:1440px)": {
                paddingBottom: 45
            }
        },
    },
    headerText: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#000000",
            textAlign: "center",
            paddingBottom: 20,
            "@media (min-width:1440px)": {
                paddingTop: 62
            }
        },
        "& span": {
            textDecoration: "underline",
            cursor: "pointer"
        },
    },
    helperTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 15,
            fontWeight: 500,
            lineHeight: "18px",
            color: "#000000",
            textTransform: "capitalize"
        },
    },
    helperIcon: {
        cursor: "pointer",
        paddingLeft: 11,
        visibility: "hidden"
    },
    errorContainer: {
        paddingTop: 136,
        paddingBottom: 28
    },
    errorText: {
        "& .MuiTypography-root": {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "100%",
            color: "#FF4B62",
            paddingBottom: 18
        },
        "@media (min-width:1440px)": {
            paddingBottom: 62
        }
    },
    nextButtonContainer: {
    },
    nextButton: {
        color: "#fff",
        background: "#0BCB47",
        width: 250,
        height: 50,
        borderRadius: 8,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center"
    },
    dividedHelpersText: {
        maxWidth: 500,
        paddingRight: 34,
        paddingLeft: 24,
        paddingBottom: 7
    },
    helpersText: {
        maxWidth: 740,
        paddingBottom: 7
    }
}))

const MapperComponentBroker = ({ outSideRef, onExecute, demo = false }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const csvInfo = useSelector(selectCsvInfo)
    const autoMapping = useSelector(selectAutoMapping)
    const mapperError = useSelector(selectMapperError)
    const validationError = useSelector(selectValidationError)
    const runModel = useSelector(selectRunModel)
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [popoverAnchor, setPopoverAnchor] = useState(null)
    const [fieldSelection, setFieldSelection] = useState({})
    const [headers, setHeaders] = React.useState([])
    const [columnsToParse, setColumnToParse] = React.useState([])
    const [dividedModels, setDividedModels] = useState({})
    const [dividedModelsOptional, setDividedModelsOptional] = useState({})
    const [menuIsOpen, setMenuIsOpen] = useState({
        "policy": false,
        "product": false,
        "issue_date": false,
        "age": false,
        "gender": false,
        "zip_code": false,
        "premium": false,
        "face_amount": false
    })
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker || history.location.pathname === "/mapper/broker";

    const handleSelectChange = (newValue, itemValue) => {
        setFieldSelection(prevState => ({ ...prevState, [itemValue]: newValue }));
        setNextButtonDisabled(false)
        dispatch(setEmptyKeys([]))
    }

    const handleMenuOpen = (itemValue) => {
        setMenuIsOpen(prevState => ({ ...prevState, [itemValue]: !prevState[itemValue] }));
    }

    const handlePopoverOpen = (ref) => {
        setPopoverAnchor(ref)
        setPopoverOpen(true)
    }


    const handleClickNext = () => {
        setNextButtonDisabled(true)
        const requiredFields = [
            "policy",
            "term",
            "product",
            "issue_date",
            "age_at_issue",
            "gender",
            "zip_code",
            "annualized_premium",
            "face_amount"
        ];
        const emptyValues = [];
        requiredFields.forEach((id) => {
            if (!Object.entries(fieldSelection)?.find((item) => item[0] === id && item[1])) {
                emptyValues.push(id)
            }
        });
        dispatch(setEmptyKeys(emptyValues))
        !emptyValues.length && onExecute(() => setNextButtonDisabled(false))
    }

    useEffect(() => {
        if (autoMapping?.length && runModel?.toLowerCase() !== "ui") {
        }
    }, [autoMapping, dispatch, runModel])

    useEffect(() => {
        if (demo) {
            dispatch(setCsvInfo(isBroker ? demoCsvBroker : demoCsv))
        }
    }, [demo, dispatch])

    useEffect(() => {
        const HeadersTemp = []
        csvInfo?.headers?.forEach((header) => {
            HeadersTemp.push({ value: header, label: header })
        })
        setHeaders(HeadersTemp.sort((a, b) => a.value > b.value ? 1 : -1))
    }, [csvInfo])

    useEffect(() => {
        const models = {}
        const modelsOptional = {}
            models["gray"] = {
                data: [{ id: "policy", label: "Policy ID", tooltip: "Policy ID: uniquely identifies the policy within an existing book of business" }],
                title: ""
            }
            models["red"] = {
                data: [
                    { id: "term", label: "Term", tooltip: "Term: Number of years the policy remains in effect." },
                    { id: "product", label: "Product Type", tooltip: "Product Type: High level type of product (Term, Whole Life, Variable Life etc.)" },
                    { id: "issue_date", label: "Issue Date", tooltip: "Issue Date: the date on which the policy’s coverage began" }
                ],
                title: "POLICY"
            }
    
            models["yellow"] = {
                data: [
                    { id: "age_at_issue", label: "Age At Issue", tooltip: "Age At Issue: Insured age when policy was issued." },
                    { id: "gender", label: "Gender", tooltip: "Gender: Gender of the insured." },
                    { id: "zip_code", label: "Zip Code", tooltip: "Zip Code: the zip code in which the policyholder lives" }
                ],
                title: "POLICYHOLDER"
            }
            models["blue"] = {
                data: [
                    { id: "annualized_premium", label: "Annual Premium", tooltip: "Annual Premium: the dollar amount the policyholder pays each year" },
                    { id: "face_amount", label: "Face Amount", tooltip: "Face Amount: Total death benefit paid by the policy." }
                ],
                title: "COVERAGE"
            }

            modelsOptional["purple"] = {
                data: [
                    { id: "customer_id", label: "Customer ID", tooltip: "Customer ID: uniquely identifies the customer within an existing book of business" },
                    { id: "agent_id", label: "Agent ID", tooltip: "Agent ID: uniquely identifies the agent within an existing book of business (Optional)" },
                    { id: "cash", label: "Cash Value", tooltip: "Cash Value: Accumulated savings component of permanent life insurance policies. (Optional)" },
                    { id: "payment_interval", label: "Payment Interval", tooltip: "How often a policyholder pays (monthly, quarterly etc.) (Optional)" }
                ]
            }
            modelsOptional["cayan"] = {
                data: [
                    { id: "State", label: "State", tooltip: "State: the state of residence of the policyholder (Optional)" },
                    { id: "Address", label: "Address", tooltip: "Address: the home address of the policyholder (Optional)" },
                ]
            }
            modelsOptional["violet"] = {
                data: [
                    { id: "first_name", label: "First Name", tooltip: "First Name: first name of the policyholder (Optional)" },
                    { id: "last_name", label: "Last Name", tooltip: "Last Name: last name of the policyholder (Optional)" },
                ]
            }
            modelsOptional["green"] = {
                data: [
                    { id: "phone", label: "Phone", tooltip: "Phone: the phone number of the policyholder (Optional)" },
                    { id: "email", label: "Email", tooltip: "Email: the email address of the policyholder (Optional)" }
                ]
            }
            setDividedModelsOptional(modelsOptional)
        setDividedModels(models)
        setColumnToParse(["Premium", "premium", "face_amount", "FaceAmount", "Face Amount", "AnnualPremium"])
    }, [runModel])

    useEffect(() => {
        console.log(fieldSelection);
        dispatch(setMapperOptions(
            Object.assign({}, ...Object.entries(fieldSelection)
            .filter((item) => item[0] !== 'null')
            .map((item) => ({ [item[1]]: item[0] })))
        ))
    }, [fieldSelection, dispatch, runModel])

    const add3dots = (text) => text.length > 10 ? text.slice(0, 10) + '...' : text

    return (
        <Grid container justifyContent="center">
            {mapperError.present &&
                <Grid item container justifyContent="center" alignContent="center" className={classes.errorContainer}>
                    <ErrorIcon />
                </Grid>
            }
            <Grid item container justifyContent="center" direction="column" xs={12}>
                <Grid item container justifyContent="center" className={classes.headerTitle}>
                    <Typography>Match Data Fields</Typography>
                </Grid>
            </Grid>
            {!mapperError.present &&
                <Grid container justifyContent="center">
                    {
                    <Grid container className={classes.helpersText} direction="row" justifyContent="space-between" alignItems="baseline" >
                        <Grid container xs={2}>
                            <Grid item className={classes.helperTitle}>
                                <Typography>Field Name</Typography>
                            </Grid>
                            <Grid item className={classes.helperIcon}>
                                <HelpIcon />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.helperTitle}>
                            <Typography>Model Field</Typography>
                        </Grid>
                    </Grid>
                    }
                    <Grid container justifyContent="center">
                                <Grid container xs={6} alignItems="flex-start" style={{ maxWidth: 500, paddingRight: 10 }}>
                                    <MapperContentContainer
                                            data={dividedModels}
                                            fieldSelection={fieldSelection}
                                            menuIsOpen={menuIsOpen}
                                            headers={headers}
                                            handlePopoverOpen={handlePopoverOpen}
                                            handleSelectChange={handleSelectChange}
                                            handleMenuOpen={handleMenuOpen}
                                            outSideRef={outSideRef}
                                        />
                                </Grid>
                                <Grid container xs={6} alignItems="flex-start" style={{ maxWidth: 500, paddingLeft: 10 }}>
                                    <MapperContentContainer
                                            data={dividedModelsOptional}
                                            fieldSelection={fieldSelection}
                                            menuIsOpen={menuIsOpen}
                                            headers={headers}
                                            handlePopoverOpen={handlePopoverOpen}
                                            handleSelectChange={handleSelectChange}
                                            handleMenuOpen={handleMenuOpen}
                                            outSideRef={outSideRef}
                                            optional
                                        />
                                </Grid>
                    </Grid>
                    {
                        !mapperError.present ?
                            <Grid item container justifyContent="center" className={classes.headerText}>
                                {demo ?
                                        <div>
                                            <Typography>
                                                The demo file has been uploaded and default mapping has been applied.
                                                You can review the <a 
                                                href={isBroker ? DemoBrokerFile : DemoFile}
                                                download
                                                >demo file</a> here.
                                                <br/>
                                                To commence the full Demo with your data file, <Link to="/registration">please create an account</Link>
                                            </Typography>
                                        </div>
                                    : <Typography>To ensure prediction results are possible, please match the column title to the predefined model field</Typography>
                                }
                            </Grid>
                            :
                            <Grid item container justifyContent="center" className={classes.errorText}>
                                {mapperError.message === "" && <Typography>Cannot resolve your file fields.</Typography>}
                                {mapperError.message !== "" && <Typography>{mapperError.message}</Typography>}
                            </Grid>
                    }
                    <Grid container justifyContent="center" className={classes.nextButtonContainer}>
                        <NextButton onClick={handleClickNext} className={classes.nextButton} disabled={nextButtonDisabled}>Next</NextButton>
                    </Grid>
                </Grid>
            }
            <Popover
                id="simple-popover"
                open={popoverOpen}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <div className={classes.ResumePopover}>
                    <TableContainer className={classes.tableStyle}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead style={{ borderBottom: '2px solid #e2e8f2' }} >
                                <TableRow className={classes.tableHeader}>
                                    {
                                        csvInfo?.headers?.map((item, index) => {


                                            return (index === 0 ?
                                                <TableCell style={{ borderBottom: 'none' }} className={classes.tableFirstCell} key={index} align="center">{item}</TableCell>
                                                :
                                                index === csvInfo?.headers?.length - 1 ?
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.tableLastCell} key={index} align="center">{item}</TableCell>
                                                    :
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.tableCell} key={index} align="center">{item}</TableCell>

                                            )
                                        })



                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {csvInfo?.data_first_rows?.slice(0, 3).map((row, index) => (
                                    <TableRow style={{ borderBottom: '2px solid #e2e8f2' }}
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {row.map((cell, index2) => {
                                            return (

                                                <TableCell style={{ borderBottom: 'none' }} className={classes.TableCellBody} key={index2} align="center">{columnsToParse.includes(csvInfo.headers[index2]) && parseFloat(cell)  ? "$" + nFormatter(cell, 0) : add3dots(cell)}</TableCell>
                                            )
                                        })
                                        }


                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                </div>
            </Popover>
            <InformationPopup
                open={validationError.present}
                title={validationError.title}
                message={validationError.message}
                onClose={() => dispatch(setValidationError({ present: false, message: "" }))}
                closeText="OK"
                error />
        </Grid>

    )
}

export default MapperComponentBroker