import './UIbyState.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLayoutEffect, useRef } from 'react';
import NoDataIndicator from '../NoDataIndicator/NoDataIndicator';

am4core.useTheme(am4themes_animated);

export default function UIbyState({paddingRight, chartData, title, currency, hasData = true}) {

  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("UIbyState", am4charts.XYChart);

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "id";
    categoryAxis.numberFormatter.numberFormat = `'${currency}'#.a`;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.4;
    categoryAxis.renderer.cellEndLocation = 0.6;
    categoryAxis.renderer.grid.template.strokeWidth = 0;

    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ]

    let  valueAxis = chart.xAxes.push(new am4charts.ValueAxis()); 

// Create series
    function createSeries() {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "value";
      series.dataFields.categoryY = "id";
      series.columns.template.tooltipText = "{valueX}";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;
      
      
      series.columns.template.fill = am4core.color("#4b54ff")
      series.columns.template.strokeOpacity = 0; series.columns.template.column.cornerRadiusBottomRight = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;

      var valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{valueX}";
      valueLabel.label.horizontalCenter = "left";
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.fontSize = 12;
      valueLabel.label.truncate = false;

      var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      categoryLabel.label.text = "{name}";
      categoryLabel.label.horizontalCenter = "right";
      categoryLabel.label.dx = -10;
      categoryLabel.label.fill = am4core.color("#fff");
      categoryLabel.label.hideOversized = false;
      categoryLabel.label.truncate = false;
    }

    chart.mouseWheelBehavior="panY"
    chart.strokeLinejoin="round"
    chart.numberFormatter.numberFormat=`'${currency}'#.a`
    chart.zoomOutButton.disabled=true
    chart.data = chartData.filter(data => data.value != 0)
    
    let topContainer = chart.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.width = am4core.percent(100);
    
    let axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.text = title;
    axisTitle.fontWeight = 700;
    axisTitle.fontFamily = "Roboto";
    axisTitle.align = "left";
    axisTitle.fontSize=16

    
    if (chart.data.length > 10) {
      categoryAxis.renderer.minGridDistance = 0.1;
      categoryAxis.start = 0;
      categoryAxis.end = 0.4;
    }

    if(chartData?.length) {
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.startGrip.disabled = true;
      chart.scrollbarY.endGrip.disabled = true;
    }
    

    createSeries();
    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [chartData]);

   
   useLayoutEffect(() => {
    chartRef.current.paddingRight= paddingRight
}, [paddingRight]);
  
  return (
        <div className='BarsBox'>
          {!hasData && <NoDataIndicator/>}
          <div id="UIbyState" style={hasData ? {} : { opacity: .2 }}></div>
          <div style={{position: 'relative', zIndex:2, bottom: 20, width:'98%', height:16, backgroundColor: 'white', }}></div>
        </div>
  );
}
