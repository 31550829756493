import { Divider, Grid } from "@material-ui/core";
import React from "react";
import RunHeaderItem from "./RunHeaderItem/RunHeaderItem";
import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";
import NumberFormat from "react-number-format";

const HeaderReport = ({ run, titles = ["Run Date", "Prediction Start Date", "Prediction Period", "Prospects", "Premium Upside"] }) => {
    const currency = getCountryCurrencySymbol()
    const isCluster = run?.model?.toLowerCase() === "cluster"

    return (
        <>
            <RunHeaderItem 
                title={titles[0]}
                value={run.date}
                toolTipInfoText="Latest model run or data update"
                />

            <Divider/>

            <RunHeaderItem 
                title={titles[1]}
                value={isCluster ? <NumberFormat value={run?.count ? run.count : 0} displayType="text" thousandSeparator/> : run.effectiveDate}
                applyStyle={isCluster}
                toolTipInfoText={isCluster ? "All prospects discovered by Atidot models" : "Latest model run or data update"}
                 />            
            
            <Divider/>

           {titles.length > 2 ? 
                <RunHeaderItem 
                    title={titles[2]}
                    value={`${run.duration} months`}
                    toolTipInfoText="Run duration"
                    applyStyle
                />
            : <Grid container className="header--item-5"/>
            }

            <Divider/>

            {titles.length > 3 ? 
            <RunHeaderItem 
                title={titles[3]}
                value={<NumberFormat value={run?.count ? run.count : 0} displayType="text" thousandSeparator/>}
                toolTipInfoText="All prospects discovered by Atidot models"
                applyStyle
            />
            : <Grid container className="header--item-5"/>
            }

            <Divider/>



            {titles.length > 4 ? 
                <RunHeaderItem 
                    title={titles[4]}
                    value={nFormatter(run?.policiesActual ? run.policiesActual : 0, 2, currency).toUpperCase()}
                    toolTipInfoText="This is the sum of the contract values of the prospects identified in the run."
                />
            : <Grid container className="header--item-5"/>
            }
        </>
    )
}

export default HeaderReport