import React, { useEffect, useState } from "react";
import mailsApiService from "./MailsService";
import { CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStyles } from "./styles";
import { UIViewButton } from "../../Components/ui/buttons";
import UploadTemplateForm from "../../Components/Forms/UploadTemplateForm/UploadTemplateForm";
import MailerTemplatePreviewPopup from "../../Components/Popups/MailerTemplatePreviewPopup/MailerTemplatePreviewPopup";
import MailerTemplateEditPopup from "../../Components/Popups/MailerTemplateEditPopup/MailerTemplateEditPopup";
import DefaultTemplate from "./constants";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";
import { InfoTooltip } from "../../Components/ui/tooltips";

const ApiService = new mailsApiService()

const MailsTemplate = () => {
    const classes = useStyles()
    const [ loading, setLoading ] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [ templates, setTemplates ] = useState([])
    const [ uploadDisabled, setUploadDisable ] = useState(false)
    const [ addNewTemplateOpen, setAddNewTemplateOpen ] = useState(false)
    const [ previewOpen, setPreviewOpen ] = useState(false)
    const [ editOpen, setEditOpen ] = useState(false)
    const [ deleteOpen, setDeleteOpen ] = useState(false)
    const [ deleteID, setDeleteID ] = useState(null)
    const [ source, setSource ] = useState("")
    const [ sourceData, setSourceData ] = useState({
        name: "",
        description: ""
    })
    const [ sourceLoading, setSourceLoading ] = useState(false)

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };
    
    const resetTemplates = () => {
        setLoading(true)
        ApiService._getTemplatesByCompanyId(localStorage.getItem("companyID"), (response) => {
            setTemplates(response.data[0])
            setLoading(false)
        }, (error) => {
            console.log(error)
            setLoading(false)
        })
    };

    const handleAddTemplate = (e, values, onError, onSuccess) => {
        setUploadDisable(true);
        ApiService._postUploadTemplate(values, () => {
            setUploadDisable(false);
            setAddNewTemplateOpen(false);
            onSuccess();
            resetTemplates();
        }, (error) => { 
            console.log(error);
            onError(error)
            setUploadDisable(false)
        })
    };


    // Open/Close Preview Popup
    const handlePreview = (e, id) => {
        setPreviewOpen(true)
        setSourceLoading(true)
        ApiService._getTemplateSourceHTML(id, (response) => {
            setSource(response.data.data)
            setPreviewOpen(true)
            setSourceLoading(false)
        }, (error) => {
            console.log(error)
            setSourceLoading(false)
        })
    }

    const handleClosePreview = () => {
        setPreviewOpen(false)
        setSource("")
        setLoading(false)
    }
    
    // Open/Close Close Popup
    const handleEdit = (e, values) => {
        setEditOpen(true)
        setSourceLoading(true)
        ApiService._getTemplateSourceHTML(values.template_id, (response) => {
            setSourceData({
                name: values.name,
                description: values.description
            })
            setSource(response.data.data)
            setSourceLoading(false)
        }, (error) => {
            console.log(error)
            setSourceLoading(false)
        })
    }

    // Create Close Popup
    const handleCreate = () => {
        setEditOpen(true)
        setSource(DefaultTemplate)
    }

    const handleCloseEdit = () => {
        setEditOpen(false)
        setSource("")
    }
    
    const handleCloseDelete = () => {
        setDeleteOpen(false)
    }

    // Open/Close Close Popup
    const handleDelete = (e, template_id) => {
        setDeleteOpen(true)
        setDeleteID(template_id)
    }
    
    const handleDeleteTemplate = () => {
        ApiService._deleteTemplate(deleteID, (response) => {
            handleCloseDelete()
            resetTemplates()
        }, (error) =>  console.log(error))
    }
   
    useEffect(() => {
        if (localStorage.getItem("companyID")) {
            resetTemplates()
        }
    }, [])


    return (        
        <Grid container>
            <TableContainer>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.controlsContainer}>
                    <Grid>
                        <Typography className={classes.tableTitle}>Availiable Templates</Typography>
                    </Grid>
                    <Grid>
                        <UIViewButton style={{ width: 140, height: 42, padding: "18px 0", marginRight: 6 }} onClick={handleCreate}>Create Template</UIViewButton>
                        <UIViewButton style={{ width: 120, height: 42, padding: "18px 0"}} onClick={() => setAddNewTemplateOpen(true)}>Add Template</UIViewButton>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell id="name" key="name">
                            Name
                        </TableCell>
                        <TableCell id="description" key="description">
                            Description 
                        </TableCell>
                        <TableCell id="preview" key="preview">Preview Template</TableCell>
                        <TableCell id="edit" key="edit">Edit Template</TableCell>
                        <TableCell id="delete" key="delete">Delete Template</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                    {
                        !loading &&
                        templates
                        ?.sort((a, b) => a.template_id < b.template_id ? 1 : -1)
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(({ template_id, name, description }) =>
                            <TableRow key={template_id} className={classes.tableRow}>
                            <TableCell className={classes.tableCell} key={`name-${template_id}`}>
                                {name}
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`description-${template_id}`}>
                                {description}
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`preview-${template_id}`}>
                                <InfoTooltip title="Preview Template">
                                    <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handlePreview(e, template_id)
                                            }}>
                                                <PreviewIcon/>
                                    </IconButton>
                                </InfoTooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`edit-${template_id}`}>
                                <InfoTooltip title="Edit Template">
                                    <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(e, { template_id, name, description })
                                            }}>
                                                <EditIcon/>
                                    </IconButton>
                                </InfoTooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`delete-${template_id}`}>
                                <InfoTooltip title="Delete Template">
                                    <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(e, template_id)
                                            }}>
                                                <DeleteIcon/>
                                    </IconButton>
                                </InfoTooltip>
                            </TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
                {
                    loading ? 
                    <Grid container justifyContent="center" className={classes.noResult}>
                      <CircularProgress style={{color: "#363ed3",width:96,height:96}}/>
                    </Grid>
                    : templates?.length === 0 &&
                    <Grid container justifyContent='center' className={classes.noResult}>
                        <Typography>No Templates</Typography>
                    </Grid>
                }
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={templates?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <UploadTemplateForm
                open={addNewTemplateOpen}
                handleClose={() => setAddNewTemplateOpen(false)}
                handleSubmit={handleAddTemplate}
                disabled={uploadDisabled}
            />

            <MailerTemplatePreviewPopup
                loading={sourceLoading}
                source={source}
                open={previewOpen}
                handleClose={handleClosePreview}
            />

            <MailerTemplateEditPopup
                loading={sourceLoading}
                source={source}
                open={editOpen}
                initialValues={sourceData}
                onSubmit={handleAddTemplate}
                disabled={uploadDisabled}
                handleClose={handleCloseEdit}
            />

            <InformationPopup
                open={deleteOpen}
                onClose={handleCloseDelete}
                onConfirm={handleDeleteTemplate}
                title="Are you sure you want to delete this template?"
                confirmText="Delete"
                closeText="Cancel"
                buttonDirection="right"
            />
        </Grid>
    )
}

export default MailsTemplate