import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Popover, TableCell, TableRow } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { MenuPopover } from '../MenuPopover/MenuPopover';
import { Status } from '../ui/Status';

// Redux
import { resetHeaders, setDate, setDuration, setEffectiveDate, setLoaded, setModel, setPolicies, setPotential, setPremium, setProductType, setProjectName, setRunName, setPoliciesFiltered, setRunId } from '../Run/RunSlice';
import { resetFilters, setOptimalTreshPrecision, setPrecisionConfig } from '../Run/Charts/ChartsSlice';
import { resetFilters as resetRunFilters } from '../Filters/FiltersSlice';
import { resetValues } from '../../Pages/NewProject/NewProjectSlice';
import { selectCampaignsCounts, selectRunsProgress, setSearch, selectDemoData, selectProjectMetadata } from '../../AppSlice';

// Common
import ProjectApiService from './ProjectService';
// import RunApiService from '../Run/RunService'
import { amplitudeService, getCountryCurrencySymbol, nFormatter } from '../../global/helpers';
import { checkPermission } from '../../global/user';
import { runsSortKeys } from '../../global/constants';

var moment = require('moment');

const useStyles = makeStyles({
  root: {
    minWidth: '70%',
    border: "solid 1px #ecf1f9",
    borderRadius: 6,
    marginBottom: 36,
    "& .MuiCardContent-root": {
      padding: "34px 44px 0 42px"
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 36
    }

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    marginBottom: 0,
    color: '#312E40',
    padding: "0 58px 0 16px"
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    color: '#6A6C71'
  },
  pos: {
    marginBottom: 12,
  },
  FloatDotsMenu: {
    position: 'relative',
    left: '97%',
  },
  newRunButton: {
    borderRadius: 8,
    textTransform: 'capitalize',
    fontSize: 12,
    paddingRight: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    "&:hover, &:focus": {
      backgroundColor: "white",
      cursor: "pointer"
    }
  },
  activeRow: {
    marginBottom: 0,
    // backgroundColor: "#f3f7ff",
    // "&:hover, &:focus": {
    //   backgroundColor: "#e7efff",
    //   cursor: "pointer"
    // },
    height: 58,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: '#363ed308'
    },
  },
  hoverCursor: {
    "&:hover, &:focus": {
      cursor: "pointer"
    },
  },
  disableRow: {
    marginBottom: 0,
    backgroundColor: "#f3f7ff",
    height: 58
  },
  dotsMenu: {
    "&:hover, &:focus": {
      backgroundColor: "#dee9fe"
    }
  },
  runStyles: {
    "& .MuiTableCell-root": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "22px",
      color: "#312E40",
      borderBottom: "2px solid #ECF1F9",
      background: "#FFFFFF"
    },
    "&:last-child .MuiTableCell-root": {
      borderBottom: "none"
    }
  },
  warningAlert: {
    justifyContent: "center",
    alignItems: "center",
    color: "#dd9c00 !important",
    backgroundColor: "#fff7c5 !important",
    fontSize: 13,
    height: 30,
    overflow: "hidden"
  },
  errorAlert: {
    justifyContent: "center",
    alignItems: "center",
    color: "#dd0000 !important",
    backgroundColor: "#ffdada !important",
    borderRadius: 6,
    fontSize: 13,
    width: 105,
    height: 30
  },
  boldText: {
    marginRight: 30,
    marginLeft: 12
  },
  primaryText: {
    marginRight: 30,
    marginLeft: 12,
    color: "#363ed3",
  },
  displayFlex: {
    display: 'inline-flex',
    alignItems: "center",
    minHeight: 42
  },
  runPopover: {
    width: 205,
    height: 51,
    margin: '9px 12px 8px 7px',
  },
  settingsPopover: {
    width: 205,
    //height: 132,
    padding: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee"
  },
  PopoverContent: {
    marginTop: 15,
    marginLeft: 18,
    marginBottom: 18,
    fontSize: 13,
    display: 'flex',
    "&:hover, &:focus": {
      cursor: "pointer",
    }
  },
  settingsPopoverContent: {
    paddingTop: 15,
    paddingLeft: 18,
    paddingBottom: 18,
    fontSize: 13,
    // border: "solid 1px #d9e1ee",
    display: 'flex',
    "&:hover, &:focus": {
      cursor: "pointer",
    }
  },
  PopoverIcon: {
    marginRight: 8,
    width: 18,
    height: 18
  },
  tableHeadCell: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "16px",
    color: "#232033",
    background: "#EEF0F4",
    borderBottom: "none",
    verticalAlign: "top",
    width: "6%",
    paddingTop: 14,
    paddingBottom: 14,
    "& div": {
      display: "flex",
    },
    "& p": {
      paddingRight: 5,
      margin: 0
    },
    "& svg": {
      cursor: "pointer",
      color: "rgba(82, 82, 82, 0.6)"
    }


  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',
    borderBottom: "none",
  }
});

const ApiService = new ProjectApiService();

export default function RunRow({ project, resetProjects }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  const progress = useSelector(selectRunsProgress)
  const campaignsCountById = useSelector(selectCampaignsCounts)
  const demo = useSelector(selectDemoData)
  const metadata = useSelector(selectProjectMetadata)
  const isCluster = project?.runs[0]?.model_type === "cluster"


  const currency = getCountryCurrencySymbol()

  const [OpenErrorPopover, setOpenErrorPopover] = React.useState(false);
  const ErrorPopoverRef = React.useRef({ top: 0, left: 0 });
  const ErrorHiderRef = React.useRef();

  const [sort, setSort] = React.useState('');
  const [order, setOrder] = React.useState(1);
  const isAnnuities = project?.runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities");

  const [showLoading, setShowLoading] = React.useState(false);


  const handleSortClick = (item) => {
      console.log(item);
      setSort(item)
      setOrder(order < 0 ? 1 : -1)
  }

  const compareProjects = (a, b, orderBy) => {
    if (orderBy) {
      const key = runsSortKeys[orderBy]
      if (orderBy === "Run Date" && orderBy === "Effective Date") {
        if (moment(a[key]).valueOf() < moment(b[key]).valueOf()) {
          return -1
        }
        if (moment(a[key]).valueOf() > moment(b[key]).valueOf()) {
          return 1
        }
        return 0
      }
      if (orderBy === "Covered by Campaigns") {
        if (campaignsCountById && a[key] && b[key]) {
          if (campaignsCountById[a[key]] < campaignsCountById[b[key]]) {
            return -1
          }
          if (campaignsCountById[a[key]] > campaignsCountById[b[key]]) {
            return 1
          }
          return 0
        }
        return 0
      }
      if (orderBy === "Run Status") {
        if (progress && a[key] && b[key]) {
          const statusA = progress?.find(({ id }) => id === a[key])
          const statusB = progress?.find(({ id }) => id === b[key])
          if (statusA) {
            return -1
          }
          if (statusB) {
            return 1
          }
          return 0
        }
        return 0
      }
      if (a[key] < b[key]) {
        return -1
      }
      if (a[key] > b[key]) {
        return 1
      }
      return 0

    }
    return 0
  }

  const sortedProjects = (a, b) => {
    return order * compareProjects(a, b, sort)
  }

  const updatePage = (error = undefined, eventName) => {
    if (error) {
      console.log(error)
    } else {
      sessionStorage.removeItem("atidot_platform_data")
    }
    resetProjects()
    dispatch(resetValues())
    dispatch(setSearch(""))
    dispatch(setLoaded(true))
    if (eventName) { amplitudeService._throwEvent(eventName) }
    setShowLoading(false)
  }

  const archiveRun = (runid) => {
    setShowLoading(true)
    ApiService._postArchiveRun(runid, () => {
      updatePage(false, "ARCHIVE_RUN")
    }, (error) => {
      updatePage(error)
    })

  }

  const stopRun = (runid) => {
    setShowLoading(true)
    ApiService._postStopRun(runid, () => {
      updatePage(false, "STOP_RUN")
    }, (error) => {
      updatePage(error)

    })

  }



  const MovetoRun = (run_id) => {
    dispatch(resetRunFilters())
    amplitudeService._throwEvent("RESULT_VIEW")
    dispatch(setPoliciesFiltered(0))
    dispatch(setRunId(run_id))
    dispatch(setLoaded(false))
    // dispatch(setActiveOption("Dashboard"))
    history.push(`/run?${run_id}${demo ? "&demo=true" : ""}`)
  }

  const loadRunData = (item) => {
    const date = moment(item.created_at).format("MMM DD, YYYY")
    dispatch(setLoaded(false))
    dispatch(resetFilters())
    dispatch(resetHeaders())
    dispatch(setProjectName(project.project_name))
    dispatch(setRunName(item.run_name))
    dispatch(setModel(item.model_type))
    dispatch(setDate(date))
    dispatch(setPolicies(project.total_policies))
    dispatch(setPrecisionConfig(item.precision_cfg))
    dispatch(setPotential(project.total_premium))
    const duration = item.period_months ? item.period_months : 3
    const effective_date = item.config?.effective_date ? moment(item.config.effective_date).format("MMM DD, YYYY") : date
    dispatch(setDuration(duration))
    dispatch(setEffectiveDate(effective_date))
    dispatch(setProductType(item.config?.product_type?.toLowerCase()))
    dispatch(setOptimalTreshPrecision(
      (item.config?.product_type?.toLowerCase() === "annuities" && project.project_name?.toLowerCase() === "phase 1") ?
        "low"
        : 'medium'
    )
    )
    sessionStorage.setItem(
      "run_data",
      JSON.stringify(
        {
          projectName: project.project_name,
          runName: item.run_name,
          model: item.model_type,
          date,
          effective_date,
          policies_in_run: project.total_policies,
          premium: project.total_premium,
          premium_loss: project.potential_premium_loss,
          cfg: item.precision_cfg,
          duration,
          type: item.config?.product_type?.toLowerCase()
        }
      )
    )
    if (item.model_type === 'lapse') {
      dispatch(setPremium(project.potential_premium_loss))
    }

    MovetoRun(item.run_id)

  }


  const handleCardClick = (event, item) => {
    const progressStatus = progress?.find(({ id }) => id === item.run_id)
    if (progressStatus?.progress !== undefined) return

    if (item.status === "ResultsUploaded") {
      loadRunData(item)
    } else {
      ErrorPopoverRef.current = {
        top: event.clientY,
        left: event.clientX
      }
      setOpenErrorPopover(true)
      ErrorHiderRef.current && clearTimeout(ErrorHiderRef.current)
      ErrorHiderRef.current = setTimeout(() => setOpenErrorPopover(false), 2500)
    }
  }



  const headNames = isCluster ? 
  [ 
    'Run Name',
    'Run Date',
    'Segmentation Date',
    'Number of Leads',
    "Number of Clusters",
    'Covered by Campaigns',
    'Run Status'
  ]
  : [
    'Run Name',
    'Run Date',
    'Prediction Date',
    'Number of Prospects',
    `${isAnnuities ? "AUM Upside" : "Premium Upside"}`,
    'Covered by Campaigns',
    'Run Status'
  ]

  const getHeadItem = (name) => {

    const splitedName = name.split(' ')
    const lastName = splitedName.pop()

    return <TableCell className={classes.tableHeadCell}>
      <div>
        <p>{splitedName.join(' ')}<br />{lastName}</p>
        <ArrowDropDownIcon
          onClick={() => handleSortClick(name)}
          style={order < 0 && sort === name ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
    </TableCell>
  }

  const getRunCell = (item, child) => {
    return (<TableCell onClick={(event) => handleCardClick(event, item)} className={classes.hoverCursor}>{child}</TableCell>)
  }

  return (

    <React.Fragment>

      <TableRow key={`rows_head_${project.project_id}`}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {headNames.map(name => getHeadItem(name))}
        <TableCell className={classes.tableHeadCell}></TableCell>
      </TableRow>

      {project?.runs
        ?.filter(({ is_archived }) => !is_archived)
        ?.sort(sortedProjects)
        ?.map((item) => {
          const progressStatus = progress?.find(({ id }) => id === item.run_id)
          return (
            <TableRow
              className={item.status === "ResultsUploaded" ? classes.activeRow : classes.disableRow}
              key={`project_${project.project_id}_row_${item.run_id}`}
              data-testid="projectCard"
            >
              <TableCell></TableCell>
              <TableCell></TableCell>

              <TableCell style={{ textTransform: "capitalize", color: "#363ed3" }} className={classes.hoverCursor}
                onClick={(event) => handleCardClick(event, item)}
              >
                {
                  isAnnuities ? "Surrender" :
                    item.model_type === "ui" ? "UI Model" : item.model_type?.replace("v2", "")
                }
              </TableCell>
              {getRunCell(item, moment(item.created_at).format("MMM DD, YYYY"))}
              {getRunCell(item, moment(item.config.effective_date).format("MMM DD, YYYY"))}
              {getRunCell(item, <NumberFormat value={metadata?.prospects_count_by_run[item.run_id] || 0} displayType={'text'} thousandSeparator={true} />)}
              {getRunCell(item, parseInt(item.potential_retained) ? nFormatter(item?.potential_retained, 2, currency) : 0)}
              {getRunCell(item, campaignsCountById[item.run_id] || 0)}
              {getRunCell(item,
                <Status item={item} progressStatus={progressStatus} />
              )}
              <TableCell scope="row" style={{ width: 20 }} >
                <MenuPopover menuItems={[
                  {
                    name: "View Report", onClick: (e) => progressStatus?.progress === undefined
                      ? handleCardClick(e, item)
                      : ''

                  },
                  { name: "Stop", onClick: (e) => stopRun(item.run_id) },
                  { name: "Archive", onClick: (e) => archiveRun(item.run_id) }
                ].filter(({ name }) => {
                  switch (name) {
                    case "Archive":
                      return checkPermission('archive_run')

                    case "Stop":
                      return ["Queued", "Predicting", "StartPrediction"].includes(item.status)

                    case "View Report":
                      return !["Stopped", "Failed "].includes(item.status)
                    
                    default:
                      return true
                  }
                })} />

                <Popover
                  id="simple-popover"
                  open={OpenErrorPopover}
                  anchorReference="anchorPosition"
                  anchorPosition={ErrorPopoverRef.current}
                  onClick={(e) => { e.stopPropagation(); setOpenErrorPopover(false) }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <Alert severity='warning' className={classes.warningAlert} data-testid="notCompleteAlert">Run Did Not Complete</Alert>
                </Popover>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={showLoading}

                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </TableCell>
            </TableRow>
          )
        }
        )}

    </React.Fragment>


  );
}