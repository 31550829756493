import React from "react";
import InformationPopup from "../InformationPopup/InformationPopup";
import { useHistory } from "react-router-dom";

const UnregisteredPopup = (props) => {
    const history = useHistory()

    return <InformationPopup 
        {...props}
        onConfirm={() => history.push("/registration")}
        title="Access to this functionality is limited to registered users."
        message="Please create an account to continue"
        closeText="Close"
        confirmText="Create Account"
        />
}

export default UnregisteredPopup