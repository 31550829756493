import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  bgDark: {
      borderRadius: 6,
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
      color: '#232033',        
      background: "#F9FAFB",
      paddingLeft: 14,
      height: 57,
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px",
      }
  },
  tableTitle: {
    "&.table-title--full-width": {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    "&.table-title--xs": {
      maxWidth: "73%",
      flexBasis: "73%",
      paddingRight: 5,
    },
    "&.table-title--xs-2": {
      maxWidth: "62%",
      flexBasis: "62%",
      paddingRight: 5,
    },
    "&.table-title--sm": {
      width: "27%",
      flexBasis: "27%",
      "& path": {
        fill: "#312E40"
      },
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
    },
    "&.table-title--sm-2": {
      width: "38%",
      flexBasis: "38%",
      "& path": {
        fill: "#312E40"
      },
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
    },
    "&.table-title--md": {
      "& path": {
        fill: "#312E40"
      },
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },

    },
  },
    
}));