import { ApiService } from "../../global/service"

export default class DashboardService {
    _getRecommendationById = (id, requestHandler, errorHandler) => ApiService.get(`/policies/${id}`, {}, requestHandler, errorHandler)
    
    _getTermAgeBank = (requestHandler, errorHandler) => ApiService.get("/policies/term-bank-age", {}, requestHandler, errorHandler)

    _getSuggested = (requestHandler, errorHandler) => ApiService.get("/policies/suggest", {}, requestHandler, errorHandler)

    _patchRecommendation = (id, data, requestHandler, errorHandler) => ApiService.patch(`/policies/${id}`, {}, requestHandler, errorHandler, data)

    _deleteRecommendation = (id, requestHandler, errorHandler) => ApiService.delete(`/policies/${id}/archive`, {}, requestHandler, errorHandler)

    _getDashboardData = (requestHandler, errorHandler) => ApiService.get("/dashboard", {}, requestHandler, errorHandler)
    
    _refreshAllDashboardsData = (requestHandler, errorHandler) => ApiService.get("/dashboard/refresh_all", {}, requestHandler, errorHandler)

}