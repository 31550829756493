import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

// MUI
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


// Redux
import { selectCampaignsCounts, selectProjectsSort, selectUserCompany, setProjectsSort, setSearch } from '../../AppSlice';
import { resetValues } from '../../Pages/NewProject/NewProjectSlice';

// Components
import RunRow from './RunRow';
import { MenuPopover } from '../MenuPopover/MenuPopover';

// Common
import ProjectApiService from './ProjectService';
import { amplitudeService, getCountryCurrencySymbol, nFormatter } from '../../global/helpers';
import { checkPermission } from '../../global/user';
import InformationPopup from '../Popups/InformationPopup/InformationPopup';
import newProjectApiService from '../../Pages/NewProject/NewProjectService'
import { IconButton } from '@material-ui/core';
import { projectsSortKeys } from '../../global/constants';


const useStyles = makeStyles({

  tableHead: {
    "& .MuiTableCell-root": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "16px",
      color: "#232033",
      background: "#EEF0F4",
      borderBottom: "none",
      verticalAlign: "top",
      width: "6%",
      paddingTop: 14,
      paddingBottom: 14,
      "& div": {
        display: "flex",
      },
      "& p": {
        paddingRight: 5,
        margin: 0
      },
      "& svg": {
        cursor: "pointer",
        color: "rgba(82, 82, 82, 0.6)"
      }
    }

  },

  summaryRow: { 
    background: "rgba(54, 62, 211, 0.12)",
    "& .MuiTableCell-root": { 
      fontWeight: 500, 
      fontSize: 14,
    }
  },
  formControl: {
    "& svg": {
      marginBottom: 0
    }
  }
})

const ApiService = new ProjectApiService();
const ApiServiceNew = new newProjectApiService()

function ProjectRow(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles();

  const campaignsCountById = useSelector(selectCampaignsCounts)
  const user = useSelector(selectUserCompany)
  const currency = getCountryCurrencySymbol()


  const { project, resetProjects } = props;
  const [open, setOpen] = React.useState(false);
  const [openNewRun, setOpenNewRun] = React.useState(false);
  const [modelType, setModelType] = React.useState('Lapse');
  
  const demo = history.location.search.includes("demo") || user === "platform-anonym" 

  const resetView = () => {
    resetProjects()
    dispatch(resetValues())
    dispatch(setSearch(""))
    setOpenNewRun(false)
    setOpen(false)
  }

  const archiveProject = (projectid) => {
    ApiService._postArchiveProject(projectid, () => {
      amplitudeService._throwEvent("ARCHIVE_PROJECT")
      sessionStorage.removeItem("atidot_platform_data")
      resetView()
    }, (error) => {
      console.log(error)
      resetView()
    })

  }

  const createNewRun = (projectId, modelType) => {
    ApiServiceNew._postNewRun({
      project_id: projectId,
      model_type: modelType,
      train_model: false,
      run_name: modelType + moment().format("DD-MM-YY")
    }, () => {
      amplitudeService._throwEvent("CREATE_NEW_RUN")
      sessionStorage.removeItem("atidot_platform_data")
      resetView()
    }, (error) => {
      console.log(error)
      resetView()
    })
  }

  useEffect(() => {
    if (project?.runs?.filter(r => !r.is_archived).length === 0) {
      archiveProject(project.project_id)
    }
  }, [project])

  return (
    <React.Fragment>

      <TableRow onClick={() => setOpen(!open)} sx={{ '& > *': { borderBottom: 'unset' }, '&:hover': { backgroundColor: '#363ed308', cursor: "pointer" } }} key={`project_${project.project_id}`}>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            color='#363ED3'
          >
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" align='left' style={{ color: '#363ED3' }}>
          {project.project_name}
        </TableCell>
        <TableCell></TableCell>
        <TableCell align="left">{moment(project.created_at).format("MMM DD, YYYY")}</TableCell>
        <TableCell align="left"><NumberFormat thousandSeparator value={project.total_policies} displayType='text'/></TableCell>
        <TableCell align="left"><NumberFormat thousandSeparator value={project.prospects}displayType='text'/></TableCell>
        <TableCell align="left">{nFormatter(project?.aum, 2, currency)}</TableCell>
        <TableCell align="left">{project.runs.reduce((a, run) => a + (campaignsCountById[run.run_id] || 0), 0)}</TableCell>
        <TableCell></TableCell>
        <TableCell>{(checkPermission('archive_project') && !demo) &&
          <MenuPopover menuItems={[
            // New run option has problem with mapping of models. Need to find solution in the future.
            // { name: "New Run", onClick: (e) => { e.stopPropagation(); setOpenNewRun(true) } },
            { name: "Archive", onClick: (e) => { e.stopPropagation(); archiveProject(project.project_id) } }
          ].filter(({ name }) => {
            if (name == "Archive" && !checkPermission('archive_run')) {
              return false
            }
            return true
          })} />}
          <InformationPopup
            open={openNewRun}
            onClose={() => setOpenNewRun(false)}
            onConfirm={() => { setOpenNewRun(false); createNewRun(project.project_id, modelType) }}
            title="Which model would you like to use?"
            closeText="Cancel"
            confirmText="Create"
            buttonDirection="right"
            children={

              <FormControl className={classes.formControl}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="UI" control={<Radio />} label="UI" onClick={() => setModelType("ui")} />
                  <FormControlLabel value="Lapse" control={<Radio />} label="Lapse" onClick={() => setModelType("lapse")} />
                  <FormControlLabel value="Cross-Sell" control={<Radio />} label="Cross-Sell" onClick={() => setModelType("cross-sell")} />
                </RadioGroup>
              </FormControl>

            }
          />
        </TableCell>

      </TableRow>

      {/* Runs rows */}
      {open && <RunRow key={project.project_id} project={project} resetProjects={resetProjects} />}

    </React.Fragment>
  );
}


export default function ProjectsCollapsibleTable({ projects, resetProjects }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currency = getCountryCurrencySymbol()
  const campaignsCountById = useSelector(selectCampaignsCounts)
  const sort = useSelector(selectProjectsSort)
  const isAnnuities = localStorage.getItem("accountType") === "annuities"

  const handleSortClick = (item) => {
    const sortKey = projectsSortKeys[item]
    dispatch(setProjectsSort(`${sortKey === sort ? "-" : ""}${sortKey}`))
  }

  // Generate table header
  const projectHeadNames = [
    'Creation Date',
    'Processed Records',
    'Number of Prospects',
    `${isAnnuities ? "Potential AUM Upside" : "Potential Premium Upside"}`,
    'Covered by Campaigns'
  ]

  const getHeadItem = (name) => {

    const splitedName = name.split(' ')
    const lastName = splitedName.pop()

    return <TableCell key={name}>
      <div>
        <p>{splitedName.join(' ')}<br />{lastName}</p>
        <ArrowDropDownIcon
          onClick={() => handleSortClick(name)}
          style={sort?.includes("-") && sort === "-"+projectsSortKeys[name] ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
    </TableCell>
  }

  // Calculate summary row
  let summary = {
    processed_records: 0,
    prospects: 0,
    aum: 0,
    covered_campaign: 0
  }

  projects.forEach((project) => {
    summary.processed_records += project.total_policies
    summary.aum += project?.aum
    summary.covered_campaign += project.runs.reduce((a, run) => a + (campaignsCountById[run.run_id] || 0), 0)
    summary.prospects += project.prospects
  })

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        {/* Header */}
        <TableHead className={classes.tableHead}>
          <TableRow key={0}>
            <TableCell key={100} />
            <TableCell key={101} />
            <TableCell />
            {projectHeadNames.map(name => getHeadItem(name))}
            <TableCell style={{ maxWidth: "1%" }} key={102}></TableCell>
            <TableCell style={{ maxWidth: "1%" }} key={103}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {/* Projects */}
          {projects.map((project) => (
            <ProjectRow key={project.project_id} project={project} resetProjects={resetProjects} />
          ))}

          {/* Summary row */}
          <TableRow className={classes.summaryRow}>
            <TableCell colSpan={3}>Summary </TableCell>
            <TableCell></TableCell>
            <TableCell><NumberFormat thousandSeparator value={summary.processed_records} displayType='text'/></TableCell>
            <TableCell><NumberFormat thousandSeparator value={summary.prospects} displayType='text'/></TableCell>
            <TableCell>
              {nFormatter(summary.aum, 2, currency)}
            </TableCell>
            <TableCell>{summary.covered_campaign}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  );
}