import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import InformationPopup from "../InformationPopup/InformationPopup";

const useStyles = makeStyles(() => ({
    previewPopup: {
        "& .MuiPaper-root": {
            width: "auto",
            height: "100%",
            textAlign: "unset",
            color: "unset",
        },
        "& .MuiDialogContent-root": {
            maxHeight: "unset",
            overflow: "hidden",
        },
    },
    previewContentContainer: {
        overflow: "auto",
        height: "100%",
        minWidth: 600,
        border: "1px solid #eef0f4",
        borderRadius: 6
    },
    loaderContainer: {
        height: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

}))


const MailerTemplatePreviewPopup = ({ open, handleClose, source, loading }) => {
    const classes = useStyles()

    return (
    <InformationPopup
        open={open}
        onClose={handleClose}
        closeControl={false}
        className={classes.previewPopup}
        title="Template Preview"
    >
        <Grid container justifyContent="center" className={classes.previewContentContainer}>
            {
                loading ?
                    <div className={classes.loaderContainer}>
                    <CircularProgress style={{color: "#363ed3",width:96,height:96}}/>
                    </div>
                :
                <div dangerouslySetInnerHTML={{ __html: source }} />
            }
        </Grid>
    </InformationPopup>
)}

export default MailerTemplatePreviewPopup