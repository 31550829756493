import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    title: '',
    message: ''
}
const name = 'AlertDialog'

export const AlertDialogSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setAlertDialogOpen: (state, action) => {
            state.open = action.payload.open
            state.title = action.payload.title
            state.message = action.payload.message
        }
    }
})

export const { setAlertDialogOpen } = AlertDialogSlice.actions

export const selectAlertDialogState = (state) => state.AlertDialog


export default AlertDialogSlice.reducer