import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    termAgeTableTitle: {
        padding: 0,
        margin: 0,
        color: "#312E40",
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.5
    },
    termAgeTableContainer: {
        fontFamily: "Roboto",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "40px 48px",
        background: "#FFFFFF",
        border: "1px solid #D9E1EE",
        borderRadius: 6,
        maxWidth: 888,
        gap: 34,
        "& .MuiTableRow-root td": {
            border: "1px solid #B9B9B9",
            borderBottom: "none",
            "&:nth-child(3n)": {
                borderRight: "none"
            },
            "&:nth-child(3n-2)": {
                borderLeft: "none"
            }
        },
        "& .MuiTableContainer-root": {
            borderRadius: 6,
            border: "1px solid #B9B9B9",
        }
    },
    termAgeHeader: {
        verticalAlign: "center",
    },
    termAgeHeaderCell: {
        border: "1px solid #B9B9B9",
        borderTop: "none",
        height: 67,
        padding: "10px",
        fontWeight: 700,
        fontSize: 16,
        color: "#000000",
        background: "#BDC0DC35",
        textAlign: "center",
        "&:first-child": {
            borderLeft: "none"
        },
        "&:last-child": {
            borderRight: "none"
        }
    },
    termAgeCell: {
        fontWeight: 400,
        fontSize: 16,
        padding: "9px 10px",
        color: "#000000",
        textAlign: "center",
    },
    termAgeCellDark: {
        background: "#BDC0DC35",
        textAlign: "left",
    },
    termAgeCellBold: {
        fontWeight: 700,
    },
    termAgeCellEnd: {
        textAlign: "right"
    },
}));

export default useStyles;