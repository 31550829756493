import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Magic from "../../Components/Magic/Magic";
import MapperComponent from "../../Components/Mappers/MapperComponent";

// Redux
import { selectAutoMapping,setAutoMapping } from "../NewProject/NewProjectSlice";

// Common
import axios from "axios";
import { processUserLoginData } from "../../global/helpers";
import { setAccountType, setCurrentUser, setMenuDisabled, setUserCompany, setUserInitials, setUserLimit, setUserPermissions, setUserRole } from "../../AppSlice";
import { useHistory } from "react-router";
import { demoMapping, demoMappingBroker } from "../../Components/Mappers/constants";

const DemoMapper = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const containerRef = useRef(null)
    const [ demoNextClicked, setDemoNextClicked ] = useState(false)
    const autoMapping = useSelector(selectAutoMapping)

    const handleExecute = (enableNextButton) => {   
      setDemoNextClicked(true)
      enableNextButton()
    }

    useEffect(() => {
        !autoMapping?.length && dispatch(setAutoMapping(history.location.pathname === "/mapper/broker" ? demoMappingBroker : demoMapping))
        axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/login", {
          email: history.location.pathname === "/mapper/broker" ? "anonym.broker@atidot.com" : "anonym@atidot.com",
          password: "Anonym123"
        }).then((response)=>{
          sessionStorage.removeItem("atidot_platform_data")
          processUserLoginData(response, dispatch, setUserInitials, setUserRole, setUserPermissions,
                              setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType)
        }).catch((error)=>{
          console.log(error.response.status);
          history.push("/login")
        })
    }, [dispatch, autoMapping])

    return(
        <div ref={containerRef}>
            { demoNextClicked 
              ? <Magic displayTime={2500} blinkInterval={200} demo/>
              : <MapperComponent outSideRef={containerRef} onExecute={handleExecute} demo/>
            }
        </div>
    )
}

export default DemoMapper