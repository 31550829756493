import React from "react";
import { Divider, Grid } from "@material-ui/core";
import RunHeaderItem from "./RunHeaderItem/RunHeaderItem";
import NumberFormat from "react-number-format";
import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";

const HeaderDashboard = ({ data, broker = false }) => {
    const currency = getCountryCurrencySymbol()

    return broker ? (
        <Grid container>
            <RunHeaderItem 
                title="Total Client"
                value={<NumberFormat value={data?.prospects} displayType="text" thousandSeparator/>}
                item={4}
                applyStyle
                />

            <Divider/>

            <RunHeaderItem 
                title="Recommended tasks completed"
                value={<span>{data.tasksCompleted}<span style={{ color: "#312E40" }}>/{data.tasksTotal}</span></span>}
                item={4}
                applyStyle
                />

            <Divider/>

            <RunHeaderItem 
                title="Premium Upside"
                value={nFormatter(data?.potential_premium, 2, currency)}
                item={4}
                applyStyle
                />

            <Divider/>

            <RunHeaderItem 
                title="Campaigns in process"
                value={<NumberFormat value={data?.campaigns_in_process} displayType="text" thousandSeparator/>}
                item={4}
                applyStyle
                />
            
        </Grid>
    ) : 
    (
        <>
            <RunHeaderItem 
                title="Latest Update"
                value={data?.date}
                toolTipInfoText="Latest model run or data update"
                />

            <Divider/>

            <RunHeaderItem 
                title={"Latest Prediction Date"}
                value={data.effectiveDate}
                toolTipInfoText="Latest model run or data update"
                 />         

        </>
    )
}

export default HeaderDashboard