import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import S3Icon from '../../../Assets/icon-aws-s3.svg';
import RdsIcon from '../../../Assets/icon-aws-rds.svg';
import BigQuery from '../../../Assets/bigquery-symbol.svg';
import AirTable from '../../../Assets/airtable-symbol.svg';
import SnowFlake from '../../../Assets/snowflake-symbol.svg';
import DataBrics from '../../../Assets/databrics-symbol.svg';
import DataHandlerApiService from '../../../services/datahandler'
import { useStyles } from "./styles";
import ConnectorCardBroker from "./ConnectorCardBroker/ConnectorCardBroker";

const connectorItems = [
    {
        key: "s3",
        displayName: "AWS S3",
        logo: S3Icon
    },
    {
        key: "rds",
        displayName: "AWS RDS",
        logo: RdsIcon
    },
    {
        key: "bigquery",
        displayName: "Big Query",
        logo: BigQuery
    },
    {
        key: "airtable",
        displayName: "Air Table",
        logo: AirTable
    },
    {
        key: "snowflake",
        displayName: "Snow Flake",
        logo: SnowFlake
    },
    {
        key: "databrics",
        displayName: "Databrics",
        logo: DataBrics
    }
]

const ApiService = new DataHandlerApiService()

const ConnectorsBroker = () => {
    const classes = useStyles()
    const rootRef = useRef(null)
    const [connectorsList, setConnectorsList] = useState({})

    const loadConnectors = () => {
        connectorItems.forEach(({ key }) => {
            let connectors = { ...connectorsList }
            if (["s3", "rds"].includes(key)) {
                ApiService._getConnectorsBroker(key, (response) => {
                    connectors[key] = response.data.connectors
                    setConnectorsList(connectors)
                }, (e) => console.error(e))
            } else {
                connectors[key] = {
                    connectors: [],
                    count: 0, 
                    disabled: true
                }
                setConnectorsList(connectors)
            }

        })

    }

    useEffect(() => {
        loadConnectors()
    },[])


    return (
        <Grid container ref={rootRef} className={classes.container}>
            {
                connectorItems.map((item) => (
                    <ConnectorCardBroker {...item} name={item.key} list={connectorsList[item.key]} onConnect={loadConnectors}/>
                ))
            }
        </Grid>
    )
}

export default ConnectorsBroker