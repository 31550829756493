import NumberFormat from "react-number-format"
import { getCountryCurrencySymbol } from "../../../global/helpers"

const currency = getCountryCurrencySymbol()

export const renderValue = (value, number = false, prefix = currency) => {
    if (!number) {
        return value !== null && value !== undefined ? value : "N/A" 
    } else {
       return value !== null && value !== undefined ? <NumberFormat value={Math.floor(value)} displayType={'text'} thousandSeparator={true} prefix={prefix}/> : "N/A" 
    }
}

export const priorityIndex = {
    3: "high",
    2: "medium",
    1: "low",
}

export const priorityColors = {
    3: "#FF4B62",
    2: "#FDEB48",
    1: "#0BCB47",
}