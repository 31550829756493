import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    rootContent: {
      flexGrow: 1,
      padding: 0,
      justifyContent: 'center',
      width: '100%',
    },
    noProjectsGrid: {
      opacity: 0.5,
      height: "50vh",
  
    },
    projectsWrapper: {
      gap: 36,
    },
    metaContainer: {
      minHeight: 373,
      background: "#FFFFFF",
      borderRadius: 6,
      boxShadow: "0px 4px 4px 0px #0000001F",
      padding: "24px 46px 24px 39px"
    },
    metaTitle: {
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px"
      }
    },
    backdrop: {
        position: "fixed",
        height: "100vh",
        background: "#00000095",
        top: 0,
        left: 80,
        zIndex: 1000
    }
  
}));

export default useStyles;