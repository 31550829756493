import { Button, Dialog, DialogActions, DialogTitle, Grid, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AlertTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import appApiService from "../../AppService";
import { selectUserRole } from "../../AppSlice";
import UploadFile from "../../Components/StepsComponents/UploadFile/UploadFile";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#F9FAFB",
        marginLeft: -15
    },
    tableHeader: {
        '& td': {
            borderRight: '1px solid #00000020'
        },
        '& td:last-child': {
            border: 'none'
        },
        '& .MuiTableCell-root': {
            padding: '8px 12px'
        }
    },
    tableDataSources: {
        backgroundColor: '#fff',
        borderRadius: 6,
    },
    dataSourceContainer: {
        borderBottom: "1px solid #eef0f4",
        marginLeft: 6,
        marginTop: 16,
        '& .MuiTableCell-root': {
            padding: '8px 12px'
        }
    },
    dataSourceName: {
        fontSize: 13,
        fontWeight: 500,
        color: "#312e40",
        marginRight: 6
    },
    dotsMenu: {
        "&:hover, &:focus": {
            backgroundColor: "#dee9fe"
        }
    },
    noProjectsGrid: {
      opacity: 0.5,
      height: "50vh",
  
    },
    headerTitle: {
      paddingTop: 15,
      paddingLeft: 25,    
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 32,
        fontWeight: 400,
        lineHeight: "43px",
        textAlign: "center"
      }
    }
}));

const ApiService = new appApiService()

const FileManagment = () => {
    const classes = useStyles();
    const history = useHistory();
    const userRole = useSelector(selectUserRole);
    const [error, setError] = useState(false)
    const [statusOpen, setStatusOpen] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])

    const handleUploadFile = () => {
            ApiService._getDataSources((response) => {
                setUploadedFiles(response.data.details)
            }, (error) => console.log(error))
            setError(false)
            setStatusOpen(true)
    }    
    
    const handleUploadError = () => {
            setError(true)
    }
    
    const handleBack = () => {
            setError(false)
            
    }

    useEffect(() => {
        ApiService._getDataSources((response) => {
            setUploadedFiles(response.data.details)
        }, (error) => console.log(error))
    }, [ApiService])

    useEffect(() => {
        if (userRole !== "file_upload_only") {
            history.push("/")
        }
    }, [userRole, history])

    return (
        <Grid container justifyContent="center" className={classes.root}>
            <UploadFile 
                title="" 
                onUpload={handleUploadFile} 
                onError={handleUploadError}
                onReload={handleBack}
                ws={false}
                spacing={false}
                anytype/>
            <Dialog 
            open={statusOpen}
            onClose={() => setStatusOpen(false)}
            aria-labelledby="status-dialog-window"
            aria-describedby="status-dialog-description">
                {
                    !error ? 
                    <div>
                        <DialogTitle id="status-dialog-title">
                            Your File Uploaded Successfully
                        </DialogTitle>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => setStatusOpen(false)}>
                                OK
                            </Button>
                        </DialogActions>
                    </div>
                    :
                    <div>
                        <DialogTitle id="status-dialog-title">
                            <Alert severity="error">
                                <AlertTitle>
                                    There has been an error uploading your file.
                                </AlertTitle>
                            </Alert>
                        </DialogTitle>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => setStatusOpen(false)}>
                                Try Another File
                            </Button>
                        </DialogActions>
                    </div>
                }
            </Dialog>
            {
            <Grid
                container
                direction="column"
                justifyContent="center"
            >
                {uploadedFiles.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead>
                                <TableRow className={classes.tableHeader}>
                                                <TableCell>                                
                                                    File Name
                                                </TableCell>
                                                <TableCell>                                
                                                    Date 
                                                </TableCell>
                                </TableRow>
                        </TableHead>
                        <div style={{ height: 12}}></div>
                        <TableBody className={classes.tableDataSources}>
                            {
                                uploadedFiles.map((item, index) => {
                                    return (
                                        <TableRow key={index} className={classes.dataSourceContainer}>
                                            <TableCell className={classes.dataSourceName}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.created_at}
                                            </TableCell>
                                            {/* <TableCell>
                                                <IconButton className={classes.dotsMenu} aria-label="moreVert" >
                                                    <MoreVertIcon onClick={() => { 
                                                        //setConfirmDeleteOpen(true); setSourceID(item.source_id) 
                                                        }}/>
                                                </IconButton>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Grid container className={classes.noProjectsGrid}
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="noProjects"
              >
                <Typography variant="h3"> No Files yet </Typography>
              </Grid>
                }
            </Grid>
            }
        </Grid>
    )
}

export default FileManagment