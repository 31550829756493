import React from 'react';
import { Grid,  makeStyles } from "@material-ui/core";
import MapperComponent from '../../Mappers/MapperComponent';
import  CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as BackIcon} from "../../../Assets/icon-arrow-left.svg"

const useStyles = makeStyles(()=> ({
    root: {
        display:"flex",
        justifyContent: 'center',
        flexGrow:1,
        minWidth:688,
        selected:{
            '&.Mui-selected':{
                backgroundColor:'red'
            }
        }
    },
    closeIcon: {
        top: 14,
        right: 30,
        position: "absolute",
        cursor: "pointer"
    },
    backIcon: {
        top: 14,
        left: 110,
        position: "absolute",
        height: 17,
        cursor: "pointer",
        "& path": {
            fill: "#000000",
        }
    }
}))

const Step3 = ({ outSideRef, handleExecute, handleClose, handleBack }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} direction="column" justifyContent="center" alignItems="center">
                <BackIcon className={classes.backIcon} onClick={handleBack}/>
                <CloseIcon className={classes.closeIcon} onClick={handleClose}/>
                <MapperComponent outSideRef={outSideRef} onExecute={handleExecute}/>
        </Grid>
        
    );
}

export default Step3;