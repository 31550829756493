import './TimelineChart.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLayoutEffect, useRef } from 'react';
import { getCountryCurrencySymbol } from '../../../../global/helpers';

am4core.useTheme(am4themes_animated);

const TimelineChart = ({paddingRight,chartData,title}) => {
  const currency = getCountryCurrencySymbol()

  const chart = useRef(null);

  useLayoutEffect(() => {
    let xyChart = am4core.create("TimelineChart", am4charts.XYChart);
    
    
    // Create X axe with category
    xyChart.strokeLinejoin="round"

    var categoryAxis = xyChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "product_name";

    // Style
    categoryAxis.renderer.labels.template.adapter.add("textOutput", function(text) {
      return text?.replace(/\(.*/, "");
    });
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.labels.template.fontFamily = "Roboto"; 
    categoryAxis.renderer.labels.template.fontSize = 12; 
    categoryAxis.renderer.labels.template.fontWeight = 700; 
    
    const categoryLabel = xyChart.createChild(am4core.Label);
    categoryLabel.text = "Prospects";
    categoryLabel.fontFamily = "Roboto"; 
    categoryLabel.fontSize = "0.8rem";
    categoryLabel.fontWeight = "700";
    categoryLabel.align = "left";
    categoryLabel.dy = -84
    categoryLabel.dx = 5
    categoryLabel.fill = am4core.color("#929292");

    // Create Y axe with values
    var valueAxis = xyChart.yAxes.push(new am4charts.ValueAxis());

    // Style
    valueAxis.renderer.grid.template.strokeOpacity = 0.05;
    valueAxis.renderer.labels.template.fill = am4core.color("#929292");
    valueAxis.renderer.labels.template.fontFamily = "Roboto"; 
    valueAxis.renderer.labels.template.fontSize = 14; 
    valueAxis.renderer.labels.template.fontWeight = 400;
    valueAxis.renderer.labels.template.textAlign = 'center';
    valueAxis.renderer.grid.template.align = 'center';  
    categoryAxis.renderer.minGridDistance = 10;
    valueAxis.numberFormatter = new am4core.NumberFormatter(); 
    valueAxis.numberFormatter.numberFormat = `'${currency}'#.a`;
    valueAxis.min= 0;
    valueAxis.title.align = "left";

    // Label
    let label = categoryAxis.renderer.labels.template.createChild(am4core.Label);
    label.text = "{prospects}";
    label.align = "center";
    label.width = "100%";
    label.fontSize = 14;
    label.numberFormatter = new am4core.NumberFormatter(); 
    label.numberFormatter.numberFormat = `'${currency}'#.###`; 
    label.fill = am4core.color("#36ACA0");
    label.y = 20;

    const colors = ["#36ACA0", "#9BE2EC", "#6D69C1", "#8E42BE"]
    let currentColor = 0

    // Series
    const addSeries = (product) => {
      var series = xyChart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = product;
      series.dataFields.categoryX = "product_name";
  
      series.name = product;
      series.columns.template.tooltipText = `${product}: [bold]{valueY}[/]`;
      series.columns.template.width = am4core.percent(80);
      series.columns.template.maxWidth = 6;
      series.fill = am4core.color(colors[currentColor]);
      currentColor =+ 1
  
      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 0;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.column.cornerRadius(60, 60, 0, 0);
      columnTemplate.width = 16;

    }

    let productsList = []
    for (const {product_name, date, prospects, ...item} of chartData) {
      for (const product in item) {
        if (!productsList.includes(product)) {
          productsList.push(product)
        }
      }
    }

    productsList.forEach(product => addSeries(product))

    // Group columns in center
    categoryAxis.renderer.cellStartLocation = 0.4
    categoryAxis.renderer.cellEndLocation = 0.6

    xyChart.data = [...chartData].sort((a,b) => a.date - b.date).slice(0,6)
    xyChart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ]

    let topContainer = xyChart.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.width = am4core.percent(100);
    
    let axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.text = title;
    axisTitle.fontFamily = "Roboto";
    axisTitle.fontWeight = 700;
    axisTitle.fontSize = 14;
    axisTitle.fill = am4core.color("#929292");
    axisTitle.align = "left";
    axisTitle.marginBottom = 24;
    axisTitle.marginLeft = 10;
    
    // Legend
    xyChart.legend = new am4charts.Legend();

    xyChart.legend.paddingTop = 25;
    xyChart.legend.useDefaultMarker = true;
    let marker = xyChart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    let markerTemplate = xyChart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    markerTemplate.valign = "middle"

    chart.current = xyChart;

    return () => {
      xyChart.dispose();
    };
  }, [chartData]);

   
   useLayoutEffect(() => {
    chart.current.paddingRight= paddingRight
}, [paddingRight]);
  
  return (
    <div className='content'>
      <div id="TimelineChart"></div>
      <div style={{position: 'relative', zIndex:2, bottom: 20, width:'6%', height:22, backgroundColor: 'white', borderRadius: '6px'}}></div>
    </div>
  );
}

export default TimelineChart