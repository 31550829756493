import { Checkbox, CircularProgress, Divider, Grid, IconButton, Popover, Typography } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import useStyles from "./styles";
import HeaderContainer from "../Headers/HeaderContainer/HeaderContainer";
import HeaderDashboard from "../Headers/HeaderDashboard";
import { ReactComponent as IconDelete } from "../../Assets/icon-trashcan.svg";
import { ReactComponent as IconCheck } from "../../Assets/icon-checkbox.svg";
import { ReactComponent as Check } from '../../Assets/icon-checked-flat.svg';
import { ReactComponent as Box } from '../../Assets/icon-unchecked-flat.svg';
import { getCountryCurrencySymbol, getDateString, getLapseRisk, getNameString, getPriorityFromRank, nFormatter } from "../../global/helpers";
import BookTablePagination from "../Tables/BookTablePagination/BookTablePagination";
import { MoreVert } from "@material-ui/icons";
import PolicyPopupBroker from "../Popups/PolicyPopup/PolicyPopupBroker";
import BookAnalysisService from "../../Pages/BookAnalysis/BookAnalysisService";
import TermAgeTable from "../Tables/TermAgeTable";
import PriorityTag from "../../Pages/BookAnalysis/Prospects/PriorityTag/PriorityTag";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { usStatesData } from "../../global/constants";
import SelectFilter from "../Filters/SelectFilter";
import { InfoTooltip } from "../ui/tooltips";
import moment from "moment";

const ApiService = new BookAnalysisService()

const DashboardBrokerComponent = ({ 
    data,
    tasks,
    termAgeData,
    onPolicyUpdate,
    onResetTasks 
}) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const rootRef = useRef(null)
    const [ page, setPage ] = useState(0)
    const [ policyType, setPolicyType ] = useState({
        "All": true,
        "annuity": false,
        "life": false,
        "retention opportunity ": false,
    })
    const [ priority, setPriority ] = useState({
        "high": false,
        "medium": false,
        "low": false,
    })
    const [ archive, setArchive ] = useState("notarchive")
    const [ selectedPolicy, setSelectedPolicy ] = useState(null)
    const [ stories, setStrories ] = useState([])
    const [ states, setStates ] = useState([])
    const [ notes, setNotes ] = useState([])
    const [ statusHistory, setStatusHistory ] = useState([])
    const [ selectedId, setSelectedId ] = useState(null)
    const [ selectedTasks, setSelectedTasks ] = useState({})
    const [openActionsPopover, setOpenActionsPopover] = useState(false)
    const [popoverAnchor, setPopoverAnchor] = useState({ left: 0, top: 0 })
    const [maxRank, setMaxRank] = useState(null)
    const [sourceID, setSourceID] = useState(null)
    const [ defaultStateFilter, setDefaultStateFilter ] = useState({ "All": true })
    const filteredPolicies = tasks?.policies
    ?.sort((a, b) => a.rank > b.rank ? 1 : -1)
    .filter(
        (item) => {
        const priorityFilter = Object.keys(priority).find((key) => priority[key])
        return (
            policyType["All"]
            || (policyType['annuity'] && item.product == "annuity")
            || (policyType['life'] && item.product !== "annuity")
        )
        && (
            !priorityFilter
            || (priority[getLapseRisk(item, tasks.precision_config)])
        )
        && (!states.length || states.includes(item.state))
        && (
            (archive === "notarchive" && !item?.processed_by_agent)
            || (archive === "archive" && item?.processed_by_agent)
        )}
    )

    const handleSelectTask = (e, task) => {
        e.stopPropagation()
        setSelectedTasks({
            ...selectedTasks,
            [task.id]: e.target.checked
        })
        handleDeleteTask(task.id)
    }

    const handleDeleteTask = (id) => {
        if (selectedId?.id || id) {
            onPolicyUpdate(id ? id : selectedId.id, true, onResetTasks)
            setSelectedId(null)
        } 
    }

    const handleRestoreTask = (id) => {
        if (selectedId?.id || id) {
            onPolicyUpdate(id ? id : selectedId.id, false, onResetTasks)
            setSelectedId(null)
        } 
    }

    const handleActionsClick = (e, item) => {
        e.stopPropagation();
        const position = e.target.getBoundingClientRect();
        setPopoverAnchor({ top: position.top, left: position.left });
        setSelectedId(item)
        setOpenActionsPopover((openActions) => !openActions);
    }
    
    const handleChangePage = (e = null, newPage) => {
        setPage(newPage);
    }
    
    const handleChangeTypeFilter = (item, value) => {
        setPolicyType(
            Object.assign(
                {},
                ...Object.keys(policyType)
                .map((key) => (
                    item === "all" || !value
                    ? { [key]: key === "all" }
                    : { [key]: key !== "all" && key === item }
                ))
            )
        );
    }

    const handleChangePriorityFilter = (item, value) => {
        setPriority(Object.assign(
            {},
            ...Object.keys(priority)
            .map((key) => ({ [key]: key === item ? value : false }))
        ));
    }

    const handleChangeArchive = (e, value) => {
        if (value !== null) {
            setArchive(value)
        }
    }

    const handleChangeStateFilter = (value) => {
        if(!value["All"]) {
            setStates(Object.keys(value).filter((item) => value[item]).map((item) => usStatesData.find(({ name }) => name.toUpperCase() === item)?.abbreviation))
        } else {
            setStates([])
        } 
    }

    const handleOpenPolicyPopup = (id, story_type) => {
        ApiService._getProspectNote(
          id,
          (response) => {
              setSelectedPolicy(id)
              setNotes(response.data.notes[0])
          },
          (error) => { 
            setSelectedPolicy(id)
            setNotes([])
          }
        )
        ApiService._getProspectStatusHistory(
          id,
          (response) => {
            setStatusHistory(response.data.history?.reverse())
          },
          (error) => { 
            setStatusHistory([])
          }
        )
        ApiService._putOpenAIPolicyStory(
          id,
          story_type,
          (response) => {
            const data = []
            const string = response.data?.gen_ai_upsell_story
              ?.replaceAll("**", "*")
              .split("*")
            string
              ?.map((item, idx) => {
                if (idx%2 === 0 && idx !== 0) data.push({ title: string[idx-1], text: item.replace(":", "") })
              })
            setStrories(data.length ? data : null);
          },
          (error) => {
            setStrories([]);
          }
        )
      }
  
      const resetNotes = (policy) => {
        ApiService._getProspectNote(
          policy,
          (response) => {
              setNotes(response.data.notes[0])
          },
          (error) => { 
            console.log(error);
          }
        )
      }
  
      const resetHistory = (policy) => {
        ApiService._getProspectStatusHistory(
          policy,
          (response) => {
              setStatusHistory(response.data.history?.reverse())
          },
          (error) => { 
            console.log(error);
          }
        )
      }

    useEffect(() => {
        if (!maxRank) {
            ApiService._getPoliciesRanks((response) => {
                setMaxRank(Math.floor(response.data.ranks.max))
            }, (error) => {})
        }
    }, [maxRank])

    useEffect(() => {
        if (!sourceID) {
            ApiService._getSource((response) => {
                if (response.data.details.length) {
                    setSourceID(response.data.details[0]?.source_id)
                }
            }, (error) => {})
        }
    }, [sourceID])

    useEffect(() => {
        if (tasks?.unique_states?.length && Object.keys(defaultStateFilter).length === 1) {
            const statesTmp = {
                "All": true,
            }
            const values = tasks.unique_states?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)
            values?.forEach((item) => Object.assign(statesTmp, {[item?.toUpperCase()]: false}))
            setDefaultStateFilter(statesTmp) 
        }
    }, [tasks])

    return (
        <Grid container>
            <HeaderContainer title="" helloBlock source={sourceID}>
                <HeaderDashboard data={{
                    ...data,
                    tasksTotal: tasks?.total_count ? tasks.total_count : 0,
                    tasksCompleted: tasks?.completed_count ? tasks.completed_count : 0,
                }}
                broker
                />
            </HeaderContainer>

            <Grid container ref={rootRef} className={classes.dashboardContainer}>
                <Grid container className={classes.recomendedContainer}>
                    <Grid container className={classes.recomendedContent} direction="column">
                        <Grid container className={classes.recomendedHeader} direction="column">
                            <Typography className={classes.recomendedHeaderTitle}>Top of the funnel opportunities</Typography>
                            <p className={classes.recomendedHeaderDescription}>Tasks to achieve the greatest impact</p>
                        </Grid>
                        <Grid container className={classes.filtersContainer}>
                            {
                                Object.keys(policyType)
                                .map((key) => (
                                    <div className={classes.filtersItems}>
                                        <Checkbox
                                            color="primary"
                                            checked={policyType[key]}
                                            onChange={(e) => handleChangeTypeFilter(key, e.target.checked)}
                                            checkedIcon={<IconCheck />}
                                        />
                                        <p>{key}</p>
                                    </div>
                                ))
                            }
                            <SelectFilter
                            title="State"
                            popoverText="State"
                            defaultValue={defaultStateFilter}
                            resetFilter={() => setStates([])}
                            onChange={handleChangeStateFilter}
                            dynamic
                            /> 
                        </Grid>
                        
                        <Grid container className={classes.statusTabsContainer}>
                            <ToggleButtonGroup
                                value={archive}
                                onChange={handleChangeArchive}
                                exclusive
                            >
                                <ToggleButton value="notarchive">Tasks <span className="task--count">{tasks?.total_count && tasks?.completed_count ? tasks.total_count-tasks.completed_count : 0}</span></ToggleButton>
                                <ToggleButton value="archive">Completed Tasks <span className="task--count">{tasks?.completed_count ? tasks.completed_count : 0}</span></ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        <Grid container className={classes.actionsList} direction="row">
                        {
                            !tasks && 
                            <Grid container justifyContent="center" alignItems="center" style={{height: 399}}>
                                <CircularProgress color="inherit" />
                            </Grid>
                        }    
                        {
                            filteredPolicies
                            ?.slice(page*6, (page+1)*6)
                            .map((item) => (
                                <Grid 
                                    container 
                                    className={classes.actionsListItem}
                                    key={item.id} 
                                    style={selectedTasks[item.id] ? { opacity: .6 } : {}}>
                                    <Checkbox
                                        className={classes.actionsCheckbox}
                                        value={selectedTasks[item.id]}
                                        onChange={(e) => handleSelectTask(e, item)}
                                        checkedIcon={<Check/>}
                                        icon={<Box/>}
                                    />
                                    <div className={classes.actionsListItemContent}
                                    onClick={() => handleOpenPolicyPopup(item.id, item.story_to_show ? item.story_to_show : "upsell")}>
                                        <div className="policy--container">
                                            <div className="policy--title">
                                            <p className={classes.actionsListItemLabel}>Needs attention</p>
                                            {item.first_name && item.last_name ? <p className={classes.actionsListItemTitle}>Connect with {item.first_name} {item.last_name}</p> : <p className={classes.actionsListItemTitle}>Policy {item.policy}</p>}
                                                <Grid container>
                                                    <PriorityTag 
                                                        value={
                                                            getPriorityFromRank(item.rank, maxRank)
                                                        }
                                                    />
                                                </Grid>
                                                {item.under_insured === 1 ?
                                                !item.story_upsell || item.story_upsell?.length <= 128 ? <p className={classes.actionsListItemDescription}>{item.story_upsell}</p> : <InfoTooltip title={item.story_upsell}><p className={classes.actionsListItemDescription}>{item.story_upsell.slice(0, 127)+"..."}</p></InfoTooltip>
                                                : !item.story_lapse || item.story_lapse?.length <= 128 ? <p className={classes.actionsListItemDescription}>{item.story_lapse}</p> : <InfoTooltip title={item.story_lapse}><p className={classes.actionsListItemDescription}>{item.story_lapse.slice(0, 127)+"..."}</p></InfoTooltip>
                                                }
                                            </div>

                                            <Grid container alignItems="center" style={{ display: "none" }}>
                                                <p className={classes.actionsListItemText}>Suggested Premium: <span>{item.annualized_premium_recommended > 9999 ? nFormatter(item.annualized_premium_recommended, 2, currency) : `$${Math.floor(item.annualized_premium_recommended)}`}</span></p>
                                            </Grid>
                                        </div>

                                    </div>
                                    <div className={classes.actionsButtonContainer}>
                                        <IconButton className={classes.actionsButton} onClick={(e) => handleActionsClick(e, item)}>
                                            <MoreVert/>
                                        </IconButton>
                                    </div>
                                </Grid>
                            ))
                        }
                        </Grid>
                        
                        <BookTablePagination 
                            component="div"
                            count={filteredPolicies?.length ? filteredPolicies.length : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={6}
                            rowsPerPageOptions={[6]}
                        />                    
                        <Popover
                        open={openActionsPopover}
                        anchorReference="anchorPosition"
                        anchorPosition={popoverAnchor}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        onClick={() => setOpenActionsPopover(false)}>
                            <IconButton className={classes.actionsButtonItem} onClick={() => archive ? handleRestoreTask(null) : handleDeleteTask(null)}>
                                <IconDelete/>
                                {archive === "archive" ? "Restore" : "Completed"}
                            </IconButton>
                        </Popover>
                        <PolicyPopupBroker
                            anchor={rootRef.current}
                            open={selectedPolicy !== null && tasks?.policies}
                            reset={onResetTasks}
                            onClose={() => {
                            setSelectedPolicy(null)
                            setStrories([])
                            }}
                            onNotesAdded={resetNotes}
                            onStatusChange={resetHistory}
                            policy={
                                tasks?.policies
                                ?.map((item) => ({ 
                                    ...item,
                                    current: item.annualized_premium_recommended,
                                    recommend: item.annualized_premium_recommended !== item.annualized_premium ? item.annualized_premium_recommended - item.annualized_premium : item.annualized_premium,
                                    client_name: getNameString(item.first_name, item.last_name),
                                    dob: moment(getDateString(item.dob)).format("MM/DD/YYYY"),
                                }))
                                ?.find((item) => item.id === selectedPolicy)
                            }
                            notes={notes}
                            history={statusHistory}
                            stories={stories}
                            maxRank={maxRank}
                            broker
                        />
                    </Grid>
                </Grid>

                <TermAgeTable data={termAgeData}/>
            </Grid>

        </Grid>
    )
}

export default DashboardBrokerComponent;
