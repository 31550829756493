import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
    tableHeader: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto",
        fontSize: 13,
        fontWeight: 700,
        lineHeight: "16px",
        color: "#232033",
        background: "#EEF0F4",
        borderBottom: "none",
        verticalAlign: "top",
        width: "10.5%",
        "& div": {
          display: "flex",
        },
        "& p": {
          paddingRight: 5,
          margin: 0
        },
        "& svg": {
          cursor: "pointer",
          marginTop: -5
        }
      },
      '& th:first-child': {
          borderRadius: '6px 0 0 6px'
      },
      '& th:last-child': {
          borderRadius: '0 6px 6px 0'
      },
    },
    tableUpsideHeader: {
      minWidth: 220
    },
    tableBody: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto",
        fontSize: 13,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        borderBottom: "1px solid #EEF0F4",
        textAlign: "left",
      },
      "&:hover .MuiTableCell-root": {
        background: "#fbfaff"
      }
    },
    tableRowBlurry: {
      filter: "blur(3px)",
      "&:hover .MuiTableCell-root": {
        background: "#ffffff"
      }
    },
    tableRowSelected: {
      "& .MuiTableCell-root": {
        background: "#D5D6EE"
      },
      "&:hover .MuiTableCell-root": {
        background: "#D5D6EE"
      }
    },
    sticky: {
      position: "sticky",
    },
    editPopup: {
      "& .MuiPaper-root": {
        padding: "0 12px",
        alignItems: "flex-start",
        width: 332,
        background: "#D9E1EE"
      },
      "& svg": {
        position: "relative",
        margin: 0,
        alignItems: "flex-start",
        top: 24
      },
      "& .MuiDialogContent-root": {
        width: "100%",
        maxWidth: 412,
        padding: "8px 0",
      },
    },
    editInput: {
      "& .MuiInputBase-root": {
        height: "unset",
      }
    },
    checkContainer: {
      display: "flex",
      width: 32,
      height: 64,
      alignItems: "center",
      justifyContent: "center"
    },
    checkedIcon: {
      width: 14,
      height: 14,
      marginLeft: 2
    },

}));