import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as IconUI } from '../../Assets/icon-ui.svg'
import { ReactComponent as IconLapse } from '../../Assets/icon-lapse.svg'
import { ReactComponent as IconCrossSell } from '../../Assets/icon-distribution.svg'
import { ReactComponent as IconModels } from '../../Assets/icon-model.svg'
import LibraryService from "./LibraryService";

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 61
    },
    modelCard: {
        minHeight: 118,
        border: "1px solid #EBEFF6",
        borderTopLeftRadius: 15,
        borderBottomRightRadius: 15,
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        marginTop: 15
    },
    modelCardFooter: {
        background: "#fff",
        minHeight: 42,
        borderBottomRightRadius: 15,
        padding: "0 12px"
    },
    modelCardTitle: {
        fontSize: 27,
        fontWeight: 500
    },
    modelCardText: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 400
    },
    modelCardFooterText: {
        paddingRight: 6
    },
    modelCardItem: {
        padding: 9,
        "& svg": {
            paddingRight: 4
        }
    },
    runningModels: {
        borderRadius: "44%",
        color: "#ffffff",
        width: 30,
        textAlign: "center",
        fontSize: 14,
        fontWeight: 500
    }
}))

const colors = {
    "Lapse": { color: "#ec444c", icon: <IconLapse/> },
    "UI": { color: "#eec82a", icon: <IconUI/> },
    "Cross Sell": { color: "#84d9eb", icon: <IconCrossSell/> },
}  

const Library = () => {
    const classes = useStyles()
    const ApiService = new LibraryService()
    const [models, setModels] = useState(null)

    useEffect(() => {
        ApiService._getModels((response) => {
            setModels(response.data["token_data"]["permissions"]?.filter(({ action }) => action !== "all_auth_endpoints"))
        }, (error) => console.log(error))
    }, [])

    return(
        <Grid container className={classes.root} justifyContent="space-between">
            {
                models?.map((item) => {
                return  (  
                <Grid container  style={{ maxWidth: "32.5%", flexBasis: "32.5%", flexGrow: 0}} direction="column" justifyContent="space-between" className={classes.modelCard}>
                    <Grid item container className={classes.modelCardItem} alignItems='center'>
                        <Grid item>
                            {colors[item.action] ? colors[item.action].icon : <IconModels/> }
                        </Grid>
                        <Grid item>
                            <Typography className={classes.modelCardTitle}>{item.action}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.modelCardFooter} alignItems='center' justifyContent="space-between">
                        <Grid item>
                            <Typography className={classes.modelCardFooterText}>Running Models</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.runningModels} style={{ background: colors[item.action] ? colors[item.action].color : "#31e388" }}>0</Typography>
                        </Grid>
                    </Grid>
                </Grid>)                   
                })
            }
        </Grid>
    )
}

export default Library