export const DefaultTemplate = `
<html lang="en">
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet">
        <title>Email Template</title>
        <style>
            img {
                border: none;
                margin: 0;
                padding: 0;
                max-width: 100%;
            }

            div#template {
                background-color: #FFFFFF;
                color: #171B59;
                font-family: 'Inter', sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0 auto;
                padding: 0;
                width: fit-content;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin: 0;
                padding: 0;
            }
            
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
            
            a {
                color: #353ED2;
                font-size: 12px;
            }

            section.template--cover {
                padding: 0;
            }

            section.template--content {
                padding: 40px 50px;  
                
                font-weight: normal;              
                                
                img {
                    margin-top: 30px;
                }
            }

            section.template--social {
                padding: 10px 50px;
                border: 2px solid #F7F8FA;
                border-left: none;
                border-right: none;

                .social-container {
                    display: inline-grid;
                    grid-template-columns: auto auto;
                }

                .social-links {
                    display: inline-grid;
                    grid-template-columns: auto auto auto;
                    gap: 25px;
                    padding-top: 19px;

                    img {
                        cursor: pointer;
                    }
                }

                .person-icon {
                    margin-right: 20px;
                }

                .insurance-license {
                    font-size: 10px;
                    padding-top: 5px;
                    padding-bottom: 10px;
                }
            }
            
            section.template--license {
                padding: 42px 50px 30px 50px;
                text-align: center;

                p {
                    font-size: 8px;
                    font-weight: 300;
                    color: #000;
                    padding-bottom: 20px;
                }
                
                a {
                    font-size: 8px;
                }                
                
                a.unsubscribe {
                    font-size: 10px;
                    font-weight: bold;
                    padding-bottom: 40px;
                }

                strong {
                    text-decoration: underline;
                    font-size: 8px;
                    font-weight: 300;
                }
            }
        </style>
    </head>
    <body>
        <div id="template">
            <section class="template--cover">
                <img src="https://i.ibb.co/s1FLZrn/image1.jpg" alt="cover image"/>
            </section>
            <section class="template--content">
                <p>
                    <strong>Hi {{{first_name}}},</strong></br>
                    </br>
                    This is a very important topic so feel free to print out this email and/or save</br>
                    it for future reference.</br>
                    </br>
                    Wondering about some best practices for sustaining family wealth today, as</br>
                    well as across generations?</br>
                    </br>
                    Think about communication.</br>
                    </br>
                    See below...</br>
                    </br>
                    <strong>The Pew Research Center's study "Wealth Transfer in America"</strong> (2012):</br>
                    Found that 41% of individuals experiencing difficulties transferring wealth</br>
                    cited "a lack of communication or information within their families" as a</br>
                    challenge.</br>
                    </br>
                    <strong>The Boston College Center on Aging & Retirement Studies' survey "Wealth</strong></br>
                    <strong>and Its Transfer Over Generations"</strong> (2017): Identified "insufficient</br>
                    communication about wealth and inheritance between generations" as one</br>
                    of the three main reasons for unsuccessful wealth transfer.</br>
                    </br>
                    <strong>The Credit Suisse Family 100 Report</strong> (2022): Highlighted "communication</br>
                    and trust" as one of the four key pillars for successful family businesses,</br>
                    noting that open and honest communication fosters greater understanding</br>
                    and reduces conflict around issues like succession planning.</br>
                    </br>
                    <strong>---</br>
                    COMMUNICATION GUIDE for families:</strong></br>
                    </br>
                    <strong>Start Talking Early:</strong></br>
                    - Begin young with age-appropriate discussions about money.</br>
                    - Don't wait for crisis or milestones to open financial talks.</br>
                    </br>
                    <strong>Keep it Real:</strong></br>
                    - Be honest and open about finances, including successes and challenges.</br>
                    - Use clear and simple language everyone can understand.</br>
                    </br>
                    <strong>Respect Differences:</strong></br>
                    - Acknowledge different values and goals around money.</br>
                    - Listen actively and practice empathy.</br>
                    </br>
                    <strong>Focus on Shared Goals:</strong></br>
                    - Frame discussions around family goals and legacy building.</br>
                    - Find common ground and celebrate mutual values.</br>
                    </br>
                    <strong>Make it a Two-Way Street:</strong></br>
                    - Encourage questions and open dialogue from everyone.</br>
                    - Share information readily and answer concerns patiently.</br>
                    </br>
                    <strong>Plan for the Future:</strong></br>
                    - Discuss estate planning early and involve key family members.</br>
                    - Regularly review and update financial plans together.</br>
                    </br>
                    <strong>Remember:</strong></br>
                    - Effective communication takes time and effort.</br>
                    - Celebrate progress and build on wins.</br>
                    - Seek professional guidance when needed.</br>
                    </br>
                    <strong>Bonus Tip:</strong></br>
                    Schedule regular "Money Meetings" to connect and discuss finances as a
                    </br>family.</br>
                    </br>
                    As always contact me anytime to discuss any goals or questions you have.
                </p>
                <img src="https://i.ibb.co/QCjHV80/image2.jpg" alt="family image"/>
            </section>
            <section class="template--social">
                <main class="social-container">
                    <img src="https://i.ibb.co/Jc94c0w/person.png" alt="person icon" class="person-icon"/>
                    <div class="social-content">
                        <h1 class="name-title">{{{agent}}}</h1>
                        <p class="insurance-license">CA Insurance License #0L94167. Pacific Advisors, CA Insurance</br>License #0K12914. Financial Advisor</p>
                        <a href="{{{agent_link}}}"><p>{{{agent_link}}}</p></a>
                        <div class="social-links">
                            <a href="{{{agent_phone}}}"><img src="https://i.ibb.co/NF9FHZj/Call.png" alt="phone icon" class="social-icon phone-icon"/></a>
                            <a href="{{{agent_mail}}}"><img src="https://i.ibb.co/wr47Fkd/Mail.png" alt="mail icon" class="social-icon mail-icon"/></a>
                            <a href="{{{agent_linkedin}}}"><img src="https://i.ibb.co/gzW5bv3/LN.png" alt="linkedin icon" class="social-icon linkedin-icon"/></a>
                        </div>
                    </div>
                </main>
            </section>
            <section class="template--license">
                <p>
                    This material is intended for general public use. By providing this content, Park Avenue Securities LLC and your financial</br>
                    representative are not undertaking to provide investment advice or make a recommendation for a specific individual or situation, or to</br>
                    otherwise act in a fiduciary capacity. Guardian, its subsidiaries, agents, and employees do not provide tax, legal, or accounting</br>
                    advice. Consult your tax, legal, or accounting professional regarding your individual situation.</br>
                    </br>
                    Securities products and advisory services offered through Park Avenue Securities LLC (PAS), member <strong>FINRA</strong>, <strong>SIPC</strong>. OSJ: 2875</br>
                    Michelle Dr. Suite 110 Irvine, CA 92606, 909-399-1100. PAS is a wholly owned subsidiary of Guardian. Pacific Advisors, LLC is not an</br>
                    affiliate or subsidiary of PAS or Guardian. Pacific Advisors is not registered in any state or with the U.S. Securities and Exchange</br>
                    Commission as a Registered Investment Advisor. Insurance products offered through One Pacific Financial & Insurance Solutions LLC,</br>
                    DBA of Pacific Advisors LLC. Pacific Advisors LLC is not registered in any state or with the U.S. Securities and Exchange Commission</br>
                    as a Registered Investment Advisor. One Pacific Financial & Insurance Solutions LLC CA Insurance License #0K12914.</br>
                    </br>
                    </br>
                    <a href="https://www.guardianlife.com/Disclosures/index.htm">Important Disclosures</a> | 
                    <a href="https://www.pacificadvisors.com/privacy-statement">Online Privacy Statement</a> | 
                    <a href="https://www.pacificadvisors.com/terms-of-use">Website Terms of Use</a>
                    </br>
                    </br>
                    2023-E156693 Exp 4/25
                    </br>
                    </br>
                    This informational email is an advertisement and you may opt out of receiving future emails.</br>
                    To opt out, please click the "Unsubscribe" link below. This message was sent by</br>
                    Pacific Advisors, CA Insurance License #0K12914</br>
                    (949) 216-5433</br>
                    2855 Michelle Drive</br>
                    Suite 320</br>
                    Irvine, CA 92606</br>
                </p>
                <a href="{{{unsubscibe}}}" class="unsubscribe">Unsubscribe</a>
            </section>
        </div>
    </body>
</html>
`

export default DefaultTemplate   