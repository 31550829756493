import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  popupContainer: {
    "& .MuiPaper-root": {
      width: 923
    },
    "& .MuiDialogContent-root": {
      width: "100%",
      paddingTop: 0
    },
    "& h2": {
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "22px",
      marginTop: 0
    },
    "& svg": {
        marginBottom: 0
    }
  },
  title: {
    position: "absolute",
    top: 39,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    margin: 0
  },
  spacingHeader: {
    width: 27
  },
  precisionTableHeade: {    
    "& .MuiTableCell-root": {
      borderBottom: "none",
      textAlign: "center",
    },
  },
  precisionTable: {    
    "& .MuiTableRow-root:nth-child(2n) .MuiTableCell-root": {
      background: "#363ED307",
      "&.cell-active": {
      },
    },
    "& .MuiTableCell-root": {
      padding: 0
    },
  },
  itemTitle: {
    minWidth: 215,
    "&.MuiTableCell-root": {
      textAlign: "left",
      paddingLeft: 12
    },
  }, 
  precisionItem: {
    "&.MuiTableCell-root": {
      textAlign: "center",
      padding: 0
    },
    "&.cell-active .cell-content": {
      background: "#363ED3",
      color: "#ffffff",
    },
    "&.cell-active .cell-container": {
      background: "#363ED3",
      color: "#ffffff",
      "& .MuiDivider-root": {
        background: "rgba(255, 255, 255, 0.25)"
      }
    },
    "& .cell-container": {
      "& .MuiDivider-root": {
        width: 118,
        margin: "0 auto",
        background: "rgba(0, 0, 0, 0.07)",
      }
    },
    "& .cell-content": {
      border: "1px solid rgba(0, 0, 0, 0.07)",
      padding: "15px 37px",
    },
  }, 
  policyItem: {
    "& .cell-content, .cell-container": {
      borderBottom: "none",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6
    },
  },
  tableItem: {
    "& .cell-content": {
      borderBottom: "none",
      borderTop: "none",
    },
  },
  impactItem: {
    "& .cell-content, .cell-container": {
      borderTop: "none",
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6
    },
  },
  surrenderRateContainer: {
    position: "absolute",
    textAlign: "left",
    bottom: 15,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    "& p": {
      margin: 0
    }
  },
    
}));