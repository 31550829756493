import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, makeStyles, Modal, styled, AppBar, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CampaignStep1 from "../../Components/StepsComponents/CampaignStep1/CampaigStep1";
import campaignApiService from "../Campaigns/CampaignService";
import { useDispatch, useSelector } from "react-redux";
import { selectCampaignData, resetValues, selectCampaignId, setDateStart, setCreatedCampaign, setOpenMailer, setCampaignProspects } from "./CreateCampaignSlice";
import { selectFiltersConfig } from "../../Components/Run/RunSlice";
import { resetQueryFilters, selectQueryFilters } from "../../Pages/BookAnalysis/BookAnalysisSlice";
import { setActiveOption } from "../../AppSlice";
import { Alert, AlertTitle } from "@mui/material";
import { amplitudeService } from "../../global/helpers";
import {ReactComponent as ArrowLeft} from '../../Assets/icon-arrow-left-alt.svg';
import {ReactComponent as CloseIcon} from '../../Assets/icon-close.svg';

// Alert dialog
import { setAlertDialogOpen } from '../../Components/AlertDialog/AlertDialogSlice';
import { uiViewButtonStyles } from "../../Components/ui/buttons";
import { ValidatorForm } from "react-material-ui-form-validator";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";
import FormInput from "../../Components/Forms/FormInput";
import BookAnalysisService from "../BookAnalysis/BookAnalysisService";
import RunApiService from "../../Components/Run/RunService";
import { SuccesStateComponent } from "../BookAnalysis/CreateCampaignsDialog/CreateCampaignsDialog";
import { User } from "../../global/user";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "100px",
        marginTop: 55,
      },
      button: {
        color: "#312e40",
        "&:hover": {
          backgroundColor: "#363ED3",
          color: "white",
        },
        textTransform: "capitalize",
      },
      horizontal: {
        maxWidth: "560px",
        margin: "0 auto",
      },
      stepHorizontal: {
        padding: 0,
      },
      instructions: {
        marginBottom: 28,
        background: "#FFFFFF",
        borderRadius: 6,
        marginTop: 10,
        padding: "36px 24px 36px 24px"
      },
      TitleProperties: {
        marginLeft: 77,
      },
      ExitProperties: {
        position: "absolute",
        right: 20,
      },
      floatRight: {
        float: "right",
      },
      headerBorder: {
        minHeight: 64,  
        borderBottom: "1px solid #d9e1ee",
      },
      footerBorder: {
        borderTop: "1px solid #d9e1ee",
        position: "absolute",
        bottom: 0,
        paddingTop: 5,
      },
      StepperContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F9FAFB",
        minHeight: "calc(100vh - 170px)",
      },
      StepperContainerBody: {
        maxWidth: "none",
        width: "100%",
      },
      customFooter: {
        display: "none",
        borderTop: '1px solid #d9e1ee',
        paddingLeft: "25%",
        paddingRight: "25%",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "white",
        height:'100px',
        position: "fixed",
        bottom: 0,
      },
      customButtonContainer: {
        gridColumn: "2",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "30px 0",
      },
      customNextButton: {
        backgroundColor: "#363ed3",
        color: "white !important",
        width: "148px",
        borderRadius: "8px",
        padding: "9px 10px",
        fontSize: "13px",
        fontWeight: "normal",
        textTransform: "none",
        cursor: "pointer",
        "&.Mui-disabled": {
          color: "#CBCACB !important",
          backgroundColor: "#E4E5E5 !important",
        },
      },
      customBackButton: {
        width: "120px",
        borderRadius: "8px",
        padding: "9px 10px",
        fontSize: "13px",
        fontWeight: "normal",
        textTransform: "none",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
          color: "#fff !important",
          backgroundColor: "#363ed3",
        },
      },
      customColorStreper: {
        backgroundColor: "#363ed3",
      },
      icon: {
        width: "32px",
        height: "32px",
        color: "white",
        border: 'solid 1px #d9e1ee',
        borderRadius:'50%',
        marginRight:'6px',
        "& text": { fill: "#242a8c !important", fontFamily: "'Roboto', sans-serif !important", fontSize: '14px' },
    
        "&$activeIcon": {
          color: "#363ed3",
          cx: '16px',
          cy: '16px',
          r: '16px',
          "& text": { fill: "white !important", fontFamily: "'Roboto', sans-serif !important", fontSize: '14px' },
        },
        "&$completedIcon": {
          color: "#363ed3",
          "& text": { fill: "white !important" }
        }
      },
      activeIcon: {},
      labelStyle:{
        color:'#312e40 !important',
        fontFamily: "'Roboto', sans-serif !important",
        fontWeight:'normal !important'
      },
      styleTitle:{
        fontFamily:'Roboto',
        fontSize:'18px',
        color:'#312e40',
      },
      alertModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 330,
      },
      nav: {
          backgroundColor: '#ffffff',
          color: '#000000',
          paddingLeft: 124,
          minHeight: 68,
          border: "none",
          boxShadow: "none",
          zIndex: 998,
          "& .MuiButtonBase-root": {
            padding: 0,
            marginRight: 14
          }
      },
      navTitles: {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: 24,
          lineHeight: "24px"
      },
      mailerForm: {
          textAlign: "left",
          width: 382,
          "& label": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "24px"
          },
          "& .MuiTextField-root": {
            paddingTop: 17,
          }
      },
      mailerPopup: {
        "& .MuiPaper-root": {
            width: 496
        },
        "& .MuiDialogTitle-root": {
          "& .MuiTypography-root": {
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "26px",
              color: "#515151",
          },
      },
      },
}))

const NextButton = styled(Button)({
  ...uiViewButtonStyles,
  width: 148,
  height: 36,
  borderRadius: 8
});

const StartJourneyButton =  styled(Button)(() => ({
    ...uiViewButtonStyles,
    maxWidth: 168,
    height: 41,
    marginTop: 24,
    padding: '12px 17px',
}));  

const ApiService = new campaignApiService()
const BookService = new BookAnalysisService()
const RunService = new RunApiService()

const CreateCampaign = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const timeoutRef = useRef(null);
    const [isNextEnabled, setIsNextEnabled] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [journeySuccess, setJourneySuccess] = useState(false);
    const { campaignName, campaignDescription, mailSubject, mailDescription, campaignType, campaignAction, dateStart, dateEnd, color, error, openMailer } = useSelector(selectCampaignData)
    const run_ids = history.location.search?.replace("?run_ids=", "").split(",")
    const filtersConfig = useSelector(selectFiltersConfig)
    const filtersConfigBook = useSelector(selectQueryFilters)
    const campaignId = useSelector(selectCampaignId)
    const handleExitCreateCampaing = () => {
        history.push("/")
    }
    const formRef = React.useRef();
    const popupRef = React.useRef();
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;

    const handleNext = () => {
      if(isNextEnabled) {
        setIsNextEnabled(false)
        const dateUI = new Date(dateStart)
        const monthprefix = dateUI.getMonth() + 1 < 10 ? "0" : ""
        const dayprefix = dateUI.getDate() < 10 ? "0" : ""
        const dateString = monthprefix + (dateUI.getMonth() + 1) + "/" + dayprefix + dateUI.getDate() + "/" + dateUI.getFullYear()

        if(!campaignId) {
          if (isBroker) {
            let payload = {
              "name": campaignName, 
              "color": color, 
              "starts_at": dateString, 
              "action": campaignAction, 
              "provider": campaignType, 
              "filter_string": filtersConfig.filters
            }            
            // set optional fields
            if (campaignDescription) {
              payload["description"] = campaignDescription
            }
            if (dateEnd !== '') {
              payload["ends_at"] = dateEnd
            }
            
            ApiService._postCreateCampaignBroker(payload, (response) => {
              amplitudeService._throwEvent("CREATE_CAMPAIGN")
              if(response?.data?.description?.toLowerCase() !== "0 new policies for given filter") {
                sessionStorage.removeItem("atidot_platform_data")
                response?.data["id"] && dispatch(setCreatedCampaign(response?.data["id"]))
                history.push("/campaigns")
                dispatch(setActiveOption("Campaigns"))
              } else {
                setAlertOpen(true)
              }
            }, (error) => {
              console.error(error)
              dispatch(setAlertDialogOpen({
                open:true,
                title: 'Error',
                message: error?.response?.data?.error || 'Something wrong'
              }))
              setIsNextEnabled(true)
            }, history.location.search?.replace("?", "") === "demo")
          } else {
            let payload = {
              "name": campaignName, 
              "color": color, 
              "starts_at": dateString, 
              "action": campaignAction, "provider": campaignType, 
              "filter_config": run_ids.length > 1 ? 
                {
                  lapse_filters: {
                    "precision": [
                      {
                          "operator": "precision",
                          "value": "medium"
                      }
                    ],
                    ...filtersConfigBook.lapse
                  },
                  ui_filters: filtersConfigBook.ui
                }
              : filtersConfig.filters
            }
              
            // set optional fields
            if (campaignDescription) {
              payload["description"] = campaignDescription
            }

            if (dateEnd !== '') {
              payload["ends_at"] = dateEnd
            }

            run_ids.length > 1 ? payload["run_ids"] = run_ids.join(",") : payload["run_id"] = run_ids[0]
            ApiService._postCreateCampagin(payload, (response) => {
              amplitudeService._throwEvent("CREATE_CAMPAIGN")
              if(response?.data?.description?.toLowerCase() !== "0 new policies for given filter") {
                sessionStorage.removeItem("atidot_platform_data")
                response?.data["id"] && dispatch(setCreatedCampaign(response?.data["id"]))
                history.push("/campaigns")
                dispatch(setActiveOption("Campaigns"))
              } else {
                setAlertOpen(true)
              }
            }, (error) => {
              console.error(error)
              dispatch(setAlertDialogOpen({
                open:true,
                title: 'Error',
                message: error?.response?.data?.error || 'Something wrong'
              }))
              setIsNextEnabled(true)
            }, history.location.search?.replace("?", "") === "demo")
          }
        } else {
          
          const dateUI = new Date(dateEnd)
          const monthprefix = dateUI.getMonth() + 1 < 10 ? "0" : ""
          const dayprefix = dateUI.getDate() < 10 ? "0" : ""
          const dateString = monthprefix + (dateUI.getMonth() + 1) + "/" + dayprefix + dateUI.getDate() + "/" + dateUI.getFullYear()
          let payload = { 
            "name": campaignName, 
            "color": color, 
            "starts_at": dateString, 
            "action": campaignAction, "provider": campaignType
          }
          
          // set optional fields
          if (campaignDescription) {
            payload["description"] = campaignDescription
          }
          if (dateEnd !== '') {
            payload["ends_at"] = dateEnd
          }

          if (isBroker) {
            ApiService._patchEditCampaginBroker(campaignId, payload, () => {
              sessionStorage.removeItem("atidot_platform_data")
              dispatch(setActiveOption("Campaigns"))
              history.push("/campaigns")
            }, (error) => {
              console.error(error)
              setIsNextEnabled(true)
            })
          } else {
            ApiService._patchEditCampagin(campaignId, payload, () => {
              sessionStorage.removeItem("atidot_platform_data")
              dispatch(setActiveOption("Campaigns"))
              history.push("/campaigns")
            }, (error) => {
              console.error(error)
              setIsNextEnabled(true)
            })
          }
        }
      }
    }

    const handleJourney = () => {
      if (campaignAction === "Send an Email") {
        BookService._postDemoEmail(
          { email, name },
          () => {
            setJourneySuccess(true)
            timeoutRef.current = setTimeout(() => {
              dispatch(setOpenMailer(false))
              setJourneySuccess(false)
            }, 3000)
          },
        (err) => console.log(err))        
      } else {
        BookService._postGptSession(
          { phone, name },
          () => {
            setJourneySuccess(true)
            timeoutRef.current = setTimeout(() => {
              dispatch(setOpenMailer(false))
              setJourneySuccess(false)
            }, 3000)
          },
        (err) => console.log(err))           
      }

    }

    const handleJourneyClose = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null     
        setJourneySuccess(false)
      }
      dispatch(setOpenMailer(false))
    }
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])    
    
    useEffect(() => {
      const run = history.location.search?.replace("?run_ids=", "")
      if(run) {
        RunService._postModelRunResultCount(
          run,
          { filters: filtersConfig?.filters ? filtersConfig.filters : {}},
          (res) => {
            dispatch(setCampaignProspects(res.data["count"]))
          }
          ,(error) => console.log(error)
        )
      }
    }, [history, dispatch])

    useEffect(() => {
      const date = new Date()
      !campaignId && dispatch(setDateStart((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()))
      return () => {
        setIsNextEnabled(false)
        dispatch(resetValues())
        dispatch(resetQueryFilters())
      }
    }, [dispatch, campaignId])

    useEffect(() => {
      setIsNextEnabled((
        campaignName?.replaceAll(" ", "") !== "" &&
        campaignAction &&
        color && 
        (
         (campaignAction === "Send an Email" && mailSubject?.replaceAll(" ", "") !== "" && mailDescription?.replaceAll(" ", "") !== "") ||
         (["Call Policyholder", "Send Direct Mail"].includes(campaignAction) && mailDescription?.replaceAll(" ", "") !== "") ||
          ["Schedule a Meeting", "Do Not Engage"].includes(campaignAction)
        ) 
        &&
        !error
      ))
    }, [campaignName, campaignDescription, mailSubject, mailDescription, campaignType, campaignAction, dateEnd, dateStart, color, error])


    return (
        <Grid container className={classes.root}>
        
          <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.StepperContainer}
              >
            
            <ValidatorForm ref={formRef} onSubmit={handleNext}>
                <Grid
                    item xs={5}
                    className={classes.StepperContainerBody}
                >
                      
                      <AppBar position="fixed" className={classes.nav}>
                              <Grid container justifyContent="space-between" alignItems="center" style={{minHeight: 68 }}>
                                <div style={{display:"flex"}}>                              
                                  <IconButton
                                  color="inherit"
                                  aria-label="open drawer"
                                  edge="start"
                                  onClick={() => history.goBack()}
                                  >
                                    <ArrowLeft style={{ padding: 0 }}/>
                                  </IconButton>
                                  <div></div>
                                  <Typography className={classes.navTitles}>
                                  New Campaign
                                  </Typography>
                                </div>
                                <div style={{display:"flex"}}>                                      
                                  <NextButton
                                    disabled={!isNextEnabled}
                                    data-testid="Execute"
                                    type="submit">
                                    {campaignId ? "Edit campaign" : "Start campaign"}
                                  </NextButton>
                        
                                  <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleExitCreateCampaing} 
                                    style={{width: 24}}
                                    >
                                      <CloseIcon />
                                  </IconButton>
                                </div>

                                
                              </Grid>
                      </AppBar>
                      <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.instructions}
                      >
                          <CampaignStep1/>
                      </Grid>
                      
                </Grid>

            </ValidatorForm> 

            <InformationPopup
                className={classes.mailerPopup}
                open={openMailer}
                onClose={handleJourneyClose}
                title={
                  journeySuccess ? "Your journey has successfully begun!"
                  : campaignAction === "Send an Email" ? 
                  <>Please enter your email address to receive<br/>an example of an email journey.</> :
                  <>Please enter your phone number to<br/>experience our fully interactive messaging<br/>journey of scheduling a meeting.</> 
                }
                closeControl={false}
                children={
                    !journeySuccess ? 
                    <ValidatorForm 
                        className={classes.mailerForm}
                        ref={popupRef} 
                        onSubmit={handleJourney}
                    >
                        <label>Name</label>
                        <FormInput
                            type="name"
                            name="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            validators={['required', 'matchRegexp:^(?=[a-zA-Z]).{2,}']}
                            errorMessages={['this field is required', 'Please enter a valid name']}
                            placeholder="Please enter your name here.."
                            data-testid="nameInput"/>
                        
                        <div style={{ height: 12 }}/>

                        {
                          campaignAction === "Schedule a Meeting" && <Grid container>
                            <label>Phone</label>
                            <FormInput
                                type="phone"
                                name="phone"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                validators={['required', 'matchRegexp:^[+][0-9]{11,12}$']}
                                errorMessages={['this field is required', 'Please enter a valid phone number with country code']}
                                placeholder="Please enter your phone here.."
                                data-testid="phoneInput"/>
                          </Grid>
                        }
                        {
                          campaignAction === "Send an Email" && <Grid container>
                            <label>Email</label>
                            <FormInput
                                type="email"
                                name="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'Please enter a valid email address']}
                                placeholder="Please enter your email here.."
                                data-testid="emailInput"/>
                          </Grid>
                        }
                        <Grid container justifyContent="center" alignItems="center">
                          <StartJourneyButton type="submit" disabled={campaignAction === "Send an Email" ? (!email || !name) : (!phone || !name)}>Start a Journey</StartJourneyButton>
                        </Grid>
                        
                    </ValidatorForm>
                    :
                    <SuccesStateComponent title="" message={campaignAction === "Send an Email" ? "You are about to receive emails soon." : "You are about to receive a message soon."}/>
    }
/> 
          </Grid>
          <Modal
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Grid item className={classes.alertModal}>
              <Alert severity="warning">
                <AlertTitle>0 New Policies For Given Filter</AlertTitle>
                All policies from this project are already in use on other active campaigns!  
              </Alert>
            </Grid>                
          </Modal>
        </Grid>
    )
}

export default CreateCampaign