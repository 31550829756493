import { RadioGroup } from "@material-ui/core";
import React from "react";
import ColorPickerItem from "./ColorPickerItem";

const colors = [
    '#D20247',
    '#FF0055',
    '#B200B2',
    '#7610B6',
    '#4A44B9',
    '#0090FB',
    '#00A7FA',
    '#00BFD9',
    '#009D8D',
    '#C3E42B',
    '#FEF11E',
    '#FF9300',
    '#805447',
]

const ColorPicker = ({handleChangeColor, value}) => {

    return (
    <RadioGroup row onChange={handleChangeColor} defaultValue={value} style={{maxWidth: 263, padding: '0 5px'}}>
        { colors.map((item) => <ColorPickerItem color={item}/>) }
    </RadioGroup>
    )
}

export default ColorPicker