import { Divider } from "@material-ui/core";
import React from "react";
import RunHeaderItem from "./RunHeaderItem/RunHeaderItem";
import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";
import ProjectHeaderItem from "./ProjectHeaderItem/ProjectHeaderItem";
import { useSelector } from "react-redux";
import { selectLabels } from "../../Pages/BookAnalysis/BookAnalysisSlice";
import NumberFormat from "react-number-format";

const HeaderProspects = ({ data, titles = ["Projects", "Prediction Effective Date", "Prospects", "Potential Impact"], isBook = false}) => {
    const currency = getCountryCurrencySymbol()
    const labels = useSelector(selectLabels)

    return (
        <>
            <ProjectHeaderItem 
                title={titles[0]}
                projectNames={labels}
                defaultLabel={isBook ? "All Book" : "All"}
                isTitleHidden={isBook}
                />

            <Divider/>

            <RunHeaderItem 
                title={titles[1]}
                value={data.effective_date}
                toolTipInfoText="Latest model run or data update"
                item={4}
                />

            <Divider/>

            <RunHeaderItem 
                title={titles[2]}
                value={<NumberFormat value={data.prospects} displayType="text" thousandSeparator/>}
                toolTipInfoText="All prospects discovered by Atidot models"
                item={4}
                applyStyle
                />

            <Divider/>

            <RunHeaderItem 
                title={titles[3]}
                value={nFormatter(data?.potential_premium ? data.potential_premium : 0, 2, currency).toUpperCase()}
                toolTipInfoText="Total AUM that will be created as a result of recommended opportunities"
                item={4}
                />

        </>
    )
}

export default HeaderProspects