import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useLayoutEffect } from "react";

const ConversionFunnel = ({ data, includingSeries, numOfPolicies }) => {

    useLayoutEffect(() => {
        let chart = am4core.create("ConversionFunel", am4charts.SlicedChart);
        
        chart.data = data?.filter(({ name }) => includingSeries[
                name.replace("-", "").toLowerCase()
            ]
        ).sort((a, b) => b.value - a.value);

        
        let series = chart.series.push(new am4charts.FunnelSeries());
        series.dataFields.value = "value";
        series.dataFields.category = "name";
        series.labels.template.adapter.add("text", slicePercent);
        series.tooltip.label.adapter.add("text", slicePercent);

        function slicePercent(text, target) {
            const max = target.dataItem.values.value.value - target.dataItem.values.value.startChange;
            const calculatedPrecent = Math.round(target.dataItem.values.value.value / max * 100)
            const percent = isNaN(calculatedPrecent) ? 0 : calculatedPrecent;
            return "[bold]{value}[/] " + percent + "%";
        }

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.brightnesses = [-0.5, 1, -0.5];
        series.alignLabels = true;
        series.slices.template.propertyFields.fill = "color";
        series.slices.template.propertyFields.stroke = "color";
        series.slices.template._bbox.height = 30;
        series.ticks.template.disabled = true;
        series.sliceLinks.template.background.fill = am4core.color("#fff");
        series.sliceLinks.template.width = '100%';
        series.sliceLinks.template.fillOpacity = 0;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "left";
        chart.legend.valign = "center";
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.labels.template.textAlign = "right";
        chart.legend.labels.template.text = "{name}";
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 0;
        markerTemplate.opacity = 0;
        chart.legend.itemContainers.template.paddingTop = 12;
        chart.legend.itemContainers.template.paddingBottom = 12;

        return () => {
            chart.dispose();
        };
    }, [data, includingSeries])

    return (
        <div style={{width: '100%'}}>
            <div id="ConversionFunel"  style={{ height: 72 + 46*(data?.filter(({ name }) => includingSeries[
                name.replace("-", "").toLowerCase()
            ]
        ).length - 1)}}></div>
            <div style={{position: 'relative', zIndex:2, bottom: 20, width:'98%', height:19, backgroundColor: 'white', }}></div>
        </div>
      );
}

export default ConversionFunnel;