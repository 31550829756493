import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
    editPopup: {
      "& .popup-head": {
        height: 24
      },
      "& .MuiPaper-root": {
        padding: "0 16px",
        paddingBottom: 24,
        alignItems: "flex-start",
        textAlign: "left",
        width: 350,
        background: "#FFFFFF"
      },
      "& svg": {
        position: "relative",
        margin: 0,
        alignItems: "flex-start",
        top: 31,
        width: 11,
        height: 11,
      },
      "& .MuiDialogContent-root": {
        width: "100%",
        maxWidth: 412,
        padding: "24px 0",
        paddingBottom: 0,
      },
      "& .MuiDialogTitle-root": {
        "& .MuiTypography-root": {
          fontSize: 20,
          fontWeight: 500,
          lineHeight: "24px",
          color: "#191D23",
        },
      },
      "& .MuiDialogActions-spacing > :not(:first-child)": {
          marginLeft: 0
      },
      "& .MuiDialogActions-root": {
        width: "100%",
        flexDirection: "row-reverse",
        gap: 16
      },
      "& .action-btn--close": {
        minWidth: 102,
        background: "#E7EAEE",
        borderColor: "#E7EAEE",
        borderRadius: 8,
        color: "#312E40",
      },
      "& .action-btn--confirm": {
        minWidth: 200,
        borderRadius: 8
      },
    },
    editInput: {
      "& .MuiInputBase-root": {
        height: "unset",
      },
      "& .MuiInputBase-input": {
        fontSize: 14,
      },
      "& .MuiOutlinedInput-multiline": {
        padding: "12px 8px"
      },
      "& .MuiOutlinedInput-input": {
        height: "87px!important",
        padding: 0
      }
    },
    editInputLabel: {
      "&.MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 500,
        lineHeight: "18px",
        color: "#191D23",
        paddingBottom: 4,
      }
    },

}));