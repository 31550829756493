import CryptoJS from "crypto-js";

function encryptData(data){
    let iv  = CryptoJS.enc.Base64.parse("");
    let key=CryptoJS.SHA256("Password");

    let encryptedString= ""
    if(typeof data=="string"){
        data=data.slice();
        encryptedString = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    }
    else{
        encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    }
    return encryptedString.toString();
}

function decryptData(encrypted) {
    let iv  = CryptoJS.enc.Base64.parse("");
    let key= CryptoJS.SHA256("Password");
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8)
}

export { encryptData, decryptData}