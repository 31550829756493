import React, { useState } from 'react'
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../styles'
import { UIViewButton } from '../../../../Components/ui/buttons'
import InformationPopup from "../../../../Components/Popups/InformationPopup/InformationPopup";
import ConnectsS3Form from '../../../../Components/Forms/ConnectForm/ConnectS3Form/ConnectS3Form';
import ConnectRdsForm from '../../../../Components/Forms/ConnectForm/ConnectRdsForm/ConnectRdsForm';
import DataHandlerApiService from '../../../../services/datahandler';

const ApiService = new DataHandlerApiService()

const ConnectorCardBroker = ({
    name,
    displayName,
    logo,
    list,
    onConnect
}) => {
    const classes = useStyles()
    const [ connectPopupOpen, setConnectPopupOpen ] = useState(false)
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false)

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handlePopupClose = () => setConnectPopupOpen(false)
    const handlePopupOpen = () => setConnectPopupOpen(true)

    const handleConnect = () => { 
        setDisabled(true)
        ApiService._postConnectorBroker(
            name, data,
            () => {
                onConnect()
                handlePopupClose()
            }, (error) => console.log(error)
        )
    }

    const getFormCompenent = () => {
        switch (name) {
            case "s3":
                return <ConnectsS3Form onSubmit={handleConnect} onChange={handleChange} values={data} disabled={disabled}/>
            case "rds":
                return <ConnectRdsForm onSubmit={handleConnect} onChange={handleChange} values={data} disabled={disabled}/>
        
            default:
                return
        }
    }

    return (
        <Grid item xs={3} key={name}>
            <Card sx={{ minWidth: 275 }} className={classes.card}>
                {list?.count > 0 &&
                    <div className={classes.connectedIndicatorContainer}>
                        <div className={classes.connectedIndicator}>Connected</div>
                    </div>
                }
                <CardContent>
                    <img src={logo} alt={name} className={classes.connectorLogo}/>
                    <Typography className={classes.connectorTitle}>
                        {displayName} 
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <UIViewButton
                        style={{ height: 40, width: 164, padding: 0 }}
                        onClick={handlePopupOpen}
                        disabled={!["s3", "rds"].includes(name)}
                    >{list?.count > 0 ? "Reconnect" : "Connect"}</UIViewButton>
                </CardActions>
            </Card>
            
            <InformationPopup
                className={classes.connectorFormPopup}
                open={connectPopupOpen}
                onClose={handlePopupClose}
                title={`Connect ${displayName}`}
                closeControl={false}
            >
                {getFormCompenent()}
            </InformationPopup>
        </Grid>
    )
}

export default ConnectorCardBroker