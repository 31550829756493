import { Divider, Grid, Typography, Paper, Button } from "@material-ui/core";
import React from "react";
import { useHistory } from 'react-router-dom';
import moment from "moment/moment";

import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";

import { ReactComponent as DollarIcon } from "../../Assets/icon-dollar.svg";
import { ReactComponent as MoneySaveIcon } from "../../Assets/icon-money-save.svg"
import { ReactComponent as HumanIcon } from "../../Assets/icon-people.svg";
import { User } from '../../global/user'

import HeaderDashboard from "../Headers/HeaderDashboard";
import HeaderContainer from "../Headers/HeaderContainer/HeaderContainer";
import clsx from "clsx";
import useStyles from "./styles";



const DashboardComponent = ({ data, refreshAll }) => {
    const classes = useStyles()
    const history = useHistory();

    const currency = getCountryCurrencySymbol()
    const isAnnuities = localStorage.getItem("accountType") === "annuities"

    return (
        <Grid container>
            <HeaderContainer title="Dashboard">
                <HeaderDashboard data={{
                    date:  data?.last_run_date ? moment(data.last_run_date).format('MMM DD, YYYY') : "-",
                    effectiveDate: data?.last_effective_date ? moment(data.last_effective_date).format('MMM DD, YYYY') : "-",
                }}/>
            
                {User().isDev &&<Grid item>
                     <button onClick={refreshAll}>refresh all</button>
                </Grid>}
            </HeaderContainer>

            <Grid container className={classes.metaContainer}>
                <Grid container>
                    <Typography className={classes.metaTitle}>Opportunities</Typography>
                </Grid>
                <Grid container direction="column" xs={4} className={clsx(classes.metaItemContainer,"meta--item--first")}>
                    <Typography className={classes.metaItemTitle}>In the Pipeline</Typography>
                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.prospects, 2)}</Typography></Grid>
                            <Grid item xs={7}><Typography>Prospects</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><MoneySaveIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.potential_premium, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Annual Deposits" : "Annual Premium"}</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><DollarIcon className={classes.tretiaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.assets, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Total AUM" : "Cash Value"}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="column" xs={4} className={classes.metaItemContainer}>
                    <Typography className={classes.metaItemTitle}>Campaigns in Process</Typography>

                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.targeted_prospects, 2)}</Typography></Grid>
                            <Grid item xs={7}><Typography>Targeted Prospects</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><MoneySaveIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.annual_deposit_premium, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Annual Deposits" : "Targeted Annual Premium"}</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><DollarIcon className={classes.tretiaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.targeted_aum, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Targeted AUM" : "Cash Value"}</Typography></Grid>
                        </Grid>
                    </Grid>

                </Grid>
                
                <Grid container direction="column" xs={4} className={classes.metaItemContainer}>
                    <Typography className={classes.metaItemTitle}>Results to Date</Typography>
                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item container justifyContent="center" xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={10}>
                                <Grid container alignItems="center">
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.retained_policies_count, 2)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Retained Policies" : "Retained Policies"}</Typography></Grid>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.rollover_policies_count, 2)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Rollover Policies" : "New Policies"}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item container justifyContent="center" xs={1}><DollarIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.retained_aum, 2, currency)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Retained AUM" : "Retained Premium"}</Typography></Grid>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.rollover_aum, 2, currency)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Rollover AUM" : "New Premium"}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>

            <Grid container direction="column" className={classes.operationsContainer}>
                <Typography>Operations</Typography>
                <Grid container style={{ opacity: .25 }}>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Prospect reached</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Engaged</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Closed deals</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">$0</Typography>
                        </Grid>
                        <Typography>Assets</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className={classes.operationsPeriodContaner}>
                    <ul>
                        <li style={{ color: "#363ED3" }}>All</li>
                        <li>Last day</li>
                        <li>Last 7 days</li>
                        <li>Last 30 days</li>
                        <li>Custom range</li>
                    </ul>
                </Grid>
                <Grid container justifyContent="center" className={classes.chartsContaner}>
                <Paper elevation={3} className={classes.dialogNewCampaign}>
                    <Typography>Please run a campaign to enable Operations Dashboard</Typography>
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.confirmButton}
                        data-testid="loginButton"
                        onClick={() => {
                            history.push("/")
                        }}
                    >
                        Create Campaign
                    </Button>
                </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DashboardComponent