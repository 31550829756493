import { styled } from "@material-ui/core";

export const PremiumSliderLabelStyles = styled('div')({
    textAlign: "center",
    '& .slider--title': {
        fontFamily: "Roboto",
        fontSize: 8,
        fontWeight: 400
    },
    '& .slider--value': {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 700,
        color: "#363ED3"
    },
}); 

const PremiumSliderLabel = ({ title, value, className }) => (
    <PremiumSliderLabelStyles className={className}>
        <div className="slider--title">{title}</div>
        <div className="slider--value">{value}</div>
    </PremiumSliderLabelStyles>
)

export default PremiumSliderLabel