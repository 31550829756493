import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import { InfoTooltip } from "../../ui/tooltips";
import { ReactComponent as InfoIcon } from '../../../Assets/icon-info-circle.svg';

const useStyles = makeStyles(() => ({
    headerContainer: {
        "&.run-header--item" : {
            maxWidth: "24%", 
            flexBasis: "24%",
        },
        "& .MuiTypography-root.MuiTypography-h1": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 12,
            lineHeight: "16px",
            color: 'inherit'
        },
    },
    infoContainer: {
        height: 0,
    },
    infoIconBox: {
        cursor: "pointer",
        position: "relative",
        bottom: 61, 
        marginRight: 5,
        "& @media (max-width:1280px)": {
        }
    },
    textWrapper: {
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "& .valueText": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "42px",
            color: '#363ED3'
        }
    },
    textWrapperShrink: {
        display: "flex",
        flexDirection: "column",
        "& .valueText": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 36,
            lineHeight: "42px",
            color: '#363ED3'
        }
    }

}))

const RunHeaderItem = ({ title, value, shrink = false, style, reference, toolTipInfoText = "", item = 5, applyStyle = false, isTitleHidden = false}) => {
    const classes = useStyles()
    const isHeaderEmpty = value === "-"

    return (
        <Grid
            ref={reference}
            container 
            justifyContent="center" 
            className={clsx(classes.headerContainer, `header--item-${item}`)} 
            style={ shrink ? { ...style } : { ...style, borderTopRightRadius: 6, borderBottomRightRadius: 6 }}>
            <Grid item className={classes.textWrapper}  direction={shrink ? "row" : "column"} alignItems={shrink || isHeaderEmpty ? "center" : "flex-start"} style={ !shrink ? isTitleHidden ? { minHeight: 70, justifyContent: "center" } : { minHeight: 70 } : {}}>
                <Grid item>
                   <Grid container>
                        <Grid item>
                            <Typography variant="h1" style={ shrink ? { paddingRight: 15 } : {}}>{title}</Typography>
                        </Grid> 
                   </Grid> 
                {typeof(value) === "string" ? <span className="valueText">{value}</span> : <div className={`${applyStyle && "valueText"}`}>{value}</div>}
                </Grid> 
                        { toolTipInfoText &&
                        <Grid item container justifyContent="flex-end" className={classes.infoContainer}>                    
                            <InfoTooltip title={toolTipInfoText} placement="top">
                                <div className={classes.infoIconBox}>
                                    <InfoIcon/>
                                </div>
                            </InfoTooltip>
                        </Grid>
                        }
            </Grid>
        </Grid>
    )
}

export default RunHeaderItem