import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import UploadFile from '../UploadFile/UploadFile';
import DatabaseConn from '../DatabaseConn/DatabaseConn';
import SnowFlakeConn from '../SnowFlake/snowflake'
import { useSelector } from 'react-redux';
import { selectStep2Component } from '../../../AppSlice';
import SelectDataSource from '../SelectDataSource/SelectDataSource';
import DataSource from '../../DataSource/DataSource';

const useStyles = makeStyles({
    root: {
        display:"flex",
        justifyContent: 'center',
        flexGrow:1,
    }
});

const Step2 = ({ onFileUpload, onDBLoad, onS3BucketLoad, onNext, onBack, back = true }) => {
    const classes = useStyles();
    const selectOption = useSelector(selectStep2Component)

    const handleSelectedOption = () => {
        switch(selectOption){
            case 'File':
                return <UploadFile title="Upload Your File" onUpload={onFileUpload} onNext={onNext} onBack={onBack} fileType=".csv" ws={false} back={back}/>
            case 'DB':
                return <DatabaseConn onDBConneted={onDBLoad} onS3Connected={onS3BucketLoad} onNext={onNext}/>
            case 'SF':
                return <SnowFlakeConn onDBConneted={onDBLoad} onS3Connected={onS3BucketLoad} onNext={onNext}/>
            case 'Uploaded':
                return <SelectDataSource onNext={onNext}/>
            default:
                return "";
        }
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            {
                selectOption === '' ?  
                    <DataSource/>
                : handleSelectedOption()
            }
        </Grid>
   );
}

export default Step2;