export const demoMapping = [
    {
        "column_name": null,
        "is_optional": true,
        "rename_to": "index",
        "title": "Index"
    },
    {
        "column_name": "policy",
        "is_optional": false,
        "rename_to": "policy",
        "title": "Policy ID"
    },
    {
        "column_name": "age",
        "is_optional": false,
        "rename_to": "age",
        "title": "Current Age"
    },
    {
        "column_name": "gender",
        "is_optional": false,
        "rename_to": "gender",
        "title": "Gender"
    },
    {
        "column_name": "zip_code",
        "is_optional": false,
        "rename_to": "zip_code",
        "title": "Zip Code"
    },
    {
        "column_name": "premium",
        "is_optional": false,
        "rename_to": "premium",
        "title": "Annual Premium"
    },
    {
        "column_name": "face_amount",
        "is_optional": false,
        "rename_to": "face_amount",
        "title": "Face Amount"
    },
    {
        "column_name": "product",
        "is_optional": false,
        "rename_to": "product",
        "title": "Product Type"
    },
    {
        "column_name": "issue_date",
        "is_optional": false,
        "rename_to": "issue_date",
        "title": "Issue Date"
    },
    {
        "column_name": "termdate",
        "is_optional": true,
        "rename_to": "termdate",
        "title": "Term"
    }
]

export const demoMappingBroker = [
    {
        "column_name": "policy",
        "is_optional": false,
        "rename_to": "policy",
        "title": "Policy ID"
    },
    {
        "column_name": "customer_id",
        "is_optional": false,
        "rename_to": "customer_id",
        "title": "Customer ID"
    },
    {
        "column_name": "current_age",
        "is_optional": false,
        "rename_to": "current_age",
        "title": "Current Age"
    },
    {
        "column_name": "gender",
        "is_optional": false,
        "rename_to": "gender",
        "title": "Gender"
    },
    {
        "column_name": "zip_code",
        "is_optional": false,
        "rename_to": "zip_code",
        "title": "Zip Code"
    },
    {
        "column_name": "product",
        "is_optional": false,
        "rename_to": "product",
        "title": "Product Type"
    },
    {
        "column_name": "face_amount",
        "is_optional": false,
        "rename_to": "face_amount",
        "title": "Face Amount"
    },
    {
        "column_name": "issue_date",
        "is_optional": false,
        "rename_to": "issue_date",
        "title": "Issue Date"
    },
    {
        "column_name": "premium",
        "is_optional": false,
        "rename_to": "premium",
        "title": "Annual Premium"
    },
    {
        "column_name": "cash_value",
        "is_optional": false,
        "rename_to": "cash_value",
        "title": "Cash Value"
    },
    {
        "column_name": "termdate",
        "is_optional": false,
        "rename_to": "termdate",
        "title": "Term"
    },
    {
        "column_name": "product_name",
        "is_optional": true,
        "rename_to": "MARKET_NAME_LAG",
        "title": "Product Name"
    },
    {
        "column_name": "state",
        "is_optional": true,
        "rename_to": "state",
        "title": "State"
    },
    {
        "column_name": "payment_interval",
        "is_optional": true,
        "rename_to": "payment_interval",
        "title": "Payment Interval"
    },
    {
        "column_name": "agent_id",
        "is_optional": true,
        "rename_to": "agent_id",
        "title": "Agent ID"
    },
    {
        "column_name": "first_name",
        "is_optional": true,
        "rename_to": "first_name",
        "title": "First Name"
    },
    {
        "column_name": "last_name",
        "is_optional": true,
        "rename_to": "last_name",
        "title": "Last Name"
    },
    {
        "column_name": "phone",
        "is_optional": true,
        "rename_to": "phone",
        "title": "Phone"
    },
    {
        "column_name": "email",
        "is_optional": true,
        "rename_to": "email",
        "title": "Email"
    },
    {
        "column_name": "address",
        "is_optional": true,
        "rename_to": "address",
        "title": "Address"
    }
]

export const demoCsv = {
    headers: [
      "",
      "policy",
      "termdate",
      "product",
      "issue_date",
      "age",
      "gender",
      "zipcode",
      "premium",
      "face_amount"
    ],
}

export const demoCsvBroker = {
    headers: [
      "policy",
      "termdate",
      "product",
      "issue_date",
      "current_age",
      "gender",
      "zipcode",
      "premium",
      "face_amount",
      "customer_id",
      "agent_id",
      "cash_value",
      "payment_interval",
      "state",
      "address",
      "first_name",
      "last_name",
      "phone",
      "email"
    ]
}