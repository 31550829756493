import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
   container: {
        marginTop: 0,
        "& .MuiGrid-item.MuiGrid-grid-xs-3": {        
            paddingRight: 19,
            marginTop: 20,
            "&:nth-child(4n)": {
                paddingRight: 0
            },
            "&:nth-child(-n+4)": {
                marginTop: 0
            }
        }
    },
    card: {
        textAlign: 'center',
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 24,
        padding: 32,
        "& .MuiCardContent-root": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            gap: 12,
        }
    },
    cardActions: {
        justifyContent: 'center',
    },
    connectorTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    connectedIndicatorContainer: {
        position: "absolute",
    },
    connectedIndicator: {
        position: "relative",
        padding: "4px 12px",
        background: "#CFFFD3",
        color: "#008F2D",
        fontSize: 10,
        fontWeight: 500,
        borderRadius: 6,
        right: 23,
        bottom: 88
    },
    connectorLogo: {
        width: 56,
        height: 56
    },
    connectorFormPopup: {
        "& .MuiPaper-root": {
            width: 440,
            padding: "32px 0",
            alignItems: "unset",
            "& .MuiDialogTitle-root": {
                top: 36,
                left: 40,
                position: "absolute",
            },
            "& .MuiDialogContent-root": {
                padding: "0 40px"
            },
            "& .MuiDialogContentText-root": {
                margin: 0
            }
        }
    },
}))