import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useLayoutEffect } from "react";

const Engagement = ({ data, inculdingSeries }) => {

    useLayoutEffect(() => {
        let chart = am4core.create("Engagement", am4charts.XYChart);

        chart.data = data;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.title.text = "";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "";

        if(data) {
            Object.keys(data[0])
                .filter((key) => inculdingSeries[key])
                .forEach((item) => {
                    let series  = chart.series.push(new am4charts.LineSeries());
                    series.dataFields.valueY = item;
                    series.dataFields.categoryX = "date";
                    series.name = item;
                    series.tooltipText = "{name}: [bold]{valueY}[/]";
                    series.tensionX = 1
                    series.tensionY = 1
                    series.strokeWidth = 2;
                    var bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.strokeWidth = 0;
                })
        }

        
        chart.cursor = new am4charts.XYCursor();

        return () => {
            chart.dispose();
        };
    }, [data, inculdingSeries])

    return (
        <div style={{width: '100%'}}>
            <div id="Engagement"  style={{height: 388, paddingTop: 16}}></div>
            <div style={{position: 'relative', zIndex:2, bottom: 20, width:'98%', height:19, backgroundColor: 'white', }}></div>
        </div>
      );
}

export default Engagement