import Template1 from "../../../Assets/mail-template1.jpg";
import Template2 from "../../../Assets/mail-template2.jpg";
import Template3 from "../../../Assets/mail-template3.jpg";
import Template4 from "../../../Assets/mail-template4.jpg";
import Template5 from "../../../Assets/mail-template5.jpg";
import Template6 from "../../../Assets/mail-template6.jpg";

export const emailTextPlaceHolder = "Hi, @name,\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
export const journeyTemplates = [
    {
      src: Template1
    },
    {
      src: Template2
    },
    {
      src: Template3
    },
];

export const directTemplates = [
  {
    src: Template4
  },
  {
    src: Template5
  },
  {
    src: Template6
  },
];
  