import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderButton } from "../../ui/buttons";
import { ReactComponent as CloseIcon} from "../../../Assets/icon-close.svg"
import { User } from "../../../global/user";
import { useSelector } from "react-redux";
import { selectUserSubscriptions } from "../../../AppSlice";
import { BuildCampaignButton } from "../../../Pages/BookAnalysis/Opportunities/BrokerOportunityItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles(() => ({
    headerContainer: {
        paddingBottom: 21
    },
    headerTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 24,
            fontWeight: 400,
            lineHeight: "24px",
            paddingBottom: 12 
        }
    },
    container: {
        background: "#FFFFFF",
        border: "1px solid #D9E1EE",
        borderRadius: 6,
        minHeight: 88,
        paddingTop: 10,
        "& .MuiDivider-root": {
           alignSelf: "center",
           height: 50,
           width: 1
        },
        "& .header--item-5" : {
            maxWidth: "19%", 
            flexBasis: "19%",
        },
        "& .header--item-4" : {
            maxWidth: "24%", 
            flexBasis: "24%",
        },
    },
    unlockBanner: {
        position: "absolute",
        background: "#0BCB47",
        minHeight: 40,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        top: 0,
        left: 0, 
        zIndex: 800, 
        "& .MuiTypography-root": {
            color: "#FFF",
            fontFamily: "Roboto",
            textTransform: "capitalize",
            fontSize: 20,
            fontWeight: 400,
            paddingLeft: 80, 
            paddingBottom: 0, 
        },
        "& .MuiIconButton-root": {
            position: "absolute",
            right: 0,
            top: 0,
            padding: 6
        },
        "& svg line": {
            stroke: "#FFF"
        }
    },
    helloBlock: {
        paddingBottom: 12, 
        "& h1": {
            fontFamily: "Roboto",
            fontSize: 30,
            fontWeight: 400,
            padding: 0, 
            margin: 0, 
            "& span": {
                fontWeight: 500,
            },
        },
        "& h2": {
            fontFamily: "Roboto",
            fontSize: 24,
            fontWeight: 400,
            padding: 0, 
            margin: 0, 
        }
    }, 
    reRun: {
        display: "flex", 
        alignItems: "flex-end"
    }, 

}));

const HeaderContainer = ({ 
    title = "",
    onActionClick = () => {},
    source = null, 
    actionText = "", 
    actionPermission = false, 
    actionDisabled = false, 
    helloBlock = false, 
    children
}) => {
    const classes = useStyles()
    const history = useHistory()
    const subscriptions = useSelector(selectUserSubscriptions)
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const isFreeSubcriptionPlan = !subscriptions?.find(({ name }) => name === "basic")
    const [isBannerHidden, setIsBannerHidden] = useState(!isBroker)

    const handleBannerHide = () => {
        sessionStorage.setItem("hide_broker_promotion_banner", "true")
        setIsBannerHidden(true)
    }

    useEffect(() => {
        if(sessionStorage.getItem("hide_broker_promotion_banner")) setIsBannerHidden(true)
    }, [])

    return (
        <Grid container className={classes.headerContainer}>
            <Grid container className={classes.headerTitle} justifyContent="space-between"  style={!isBannerHidden && isFreeSubcriptionPlan && (isBroker || !title) ? {paddingTop: 40} : {}}>
              {!isBannerHidden && isFreeSubcriptionPlan && isBroker && <Grid container alignItems="center" justifyContent="center" className={classes.unlockBanner}>
                <Typography>Unlock the Full Potential of Atidot Magnet Now!</Typography>
                <IconButton onClick={handleBannerHide}>
                    <CloseIcon/>
                </IconButton>
              </Grid>}  
              {title && 
               <Typography style={children ? {} : { paddingBottom: 0}}>
                {title}
              </Typography>
              }  

              {actionPermission && 
                <HeaderButton
                disabled={actionDisabled}
                onClick={onActionClick}>
                  {actionText}
                </HeaderButton>
              }    
            </Grid> 
            {
                helloBlock && 
                <Grid container justifyContent="space-between" className={classes.helloBlock}>
                    <Grid>
                        <Grid>
                            <h1>Hello, <span>{localStorage.getItem("name") !== "full name" ? localStorage.getItem("name")+"!" : ""}</span></h1>
                        </Grid>
                        <Grid>
                            <h2>Let’s optimize your time.</h2>
                        </Grid>
                    </Grid>
                    
                    <Grid className={classes.reRun}>
                        <BuildCampaignButton style={{ width: 157, height: 42}} onClick={() => history.push(`/upload/${source}`)} disabled={!source}>Rerun Data</BuildCampaignButton>
                    </Grid>
                </Grid>
            }
            {children && <Grid container alignItems="flex-start" className={classes.container}>{children}</Grid>}
        </Grid>
    )
}

export default HeaderContainer