import { createSlice } from "@reduxjs/toolkit";
import { getCountryCurrencySymbol, parseJwt, validateValue } from "../../../global/helpers";

const getTmpArray = (data) => {
    let tmp = []

    if (data?.length) {
        data.forEach((dataItem) => {
            const entries = Object.entries(dataItem)
            entries.forEach((item) => {
              if (validateValue(item[1])) {
                tmp.push({ id: item[0], value: item[1] })
              }
            })
        })
    } else {
        tmp = []
    }
    
    return tmp;
}

const getTmpGenderArray = (data) => {
    let tmp = []

    if (data?.length) {
        data.forEach((dataItem) => {
            const entries = Object.entries(dataItem)
            entries.forEach((item) => {
                if (validateValue(item[1])) {
                  if (item[0] === "F") {
                    tmp.push({ id: "Female", value: item[1], color: "#ff656f", real: 2 })
                  } else if (item[0] ===  "M") {
                    tmp.push({ id: "Male", value: item[1], color: "#4b54ff",real: 1 })
                  }
                }
            })
        })
    } else {
        tmp = []
    }
    
    return tmp;
}

const getTmpAgeArray = (data) => {
    let tmp = []

    if(data?.length > 0) {
        data.forEach((income) => {        
            const entries = Object.entries(income)
            entries.forEach((item) => {
              if (validateValue(item[1])) {
                const ranges = item[0].split("_")
                let id = "<50"
                if (ranges.length === 1 && item[0] !== "100") {
                  id = `${item[0]}<`
                  tmp.push({ id, value: item[1]})
                } else if (ranges[0] === '0') {
                  id = `<${ranges[1]}` 
                  tmp.push({ id, value: item[1]})
                } else if (ranges.length === 2) {
                  id = `${ranges[0]}-${ranges[1]}`
                  tmp.push({ id, value: item[1]})
                }
              }
            })
          })
        tmp.reverse()
      } else {
        tmp = []
      }
    
    return tmp;
}

const getTmpIncomeArray = (data) => {
    let tmp = []

    if(data?.length > 0) {
        data.forEach((ageItem) => {
          const entries = Object.entries(ageItem)
          entries.forEach((item) => {
            if (validateValue(item[1])) {
              const ranges = item[0].split("_")
              let id = ""
              if (ranges.length === 1) {
                id = `${item[0]}<`
              } else if (ranges[0] === '0') {
                id = `<${ranges[1]}`  
              } else {
                id = `${ranges[0]}-${ranges[1]}`
              }
              tmp.push({ id, value: item[1]})
            }
          })
        })
        tmp.reverse()
      } else {
        tmp = []
      }
    
    return tmp;
}

const populateChartData = (data) => {
    return Array.from(data.reduce((acc, { value, ...r }) => {
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());
}

const populateLocationData = (states, cities) => {
    let stateTemp = []
    let citiesTemp = []
    let mapTemp = []

    const contryCode = parseJwt(localStorage.getItem("token"))["country_code"]

    if(states?.length > 0 && cities?.length > 0) {
        stateTemp = []
        citiesTemp = []
        mapTemp = []
        states.forEach((state) => {        
          const entries = Object.entries(state)
          entries.forEach((item) => {
            mapTemp.push({ id: contryCode === "IL" ? "US-"+item[0] : contryCode +"-"+item[0], value: item[1] })
            stateTemp.push({ id: item[0], value: item[1]})
          })
        })
        cities.forEach((city) => {        
          const entries = Object.entries(city)
          entries.forEach((item) => {
            citiesTemp.push({ name: item[0], value: item[1]})
          })
        })
    } else {
        mapTemp = []
        stateTemp = []
        citiesTemp = []
    }

    const mapData = populateChartData(mapTemp)
    const stateData = populateChartData(stateTemp)
    const citiesData = populateChartData(citiesTemp)

    return {
        map: mapData,
        states: stateData,
        cities: citiesData
    }
}

const emptyValueCheck = (data) => {
    if (data) {
        const totalValue = data.reduce((total, current) => total + current, 0)
        return totalValue !== 0
    }

    return true
}

const initialState = {
    ageFilter:[0,99],
    faceAmountFilter: {"All":true},
    genderFilter :{"All":true,"Male":false,"Not specified":false,"Female": false},
    locationFilter : "",
    stateFilter : "",
    cityFilter : "",
    locationLabels: [],
    statesLabels: [],
    citiesLabels: [],
    uiFilter :[0,0],
    uiLimits:[0,0],
    uiLimitsMarks:[],
    uiMapFilter:"",
    ProductFilter:{},
    BehavioralFilter:{"All":true},
    optimalTresh:0,
    optimalTreshPrecision:0,
    precisionConfig: null,
    precisionLevel: 'medium',
    correlation:{},
    precision: [],
    hiddenHeaders:[],
    premiumMedian:0,
    moreFilters: [
        {
            name: "Product",
            isActive: false,
        },
        {
            name: "Household Income",
            isActive: false,
        }
    ],
    emptyValues: {
        age: true,
        gender: true,
        state: true,
        income: true,
        duration: true,
        agent: true,
        partner: true,
        cluster: true
    },
    chartData: {
        map: [],
        mapByCounties: [],
        byState: [],
        byGender: [],
        byDistribution: [],
        byAge: [],
        byIncome: [],
        byTopAgent: [],
        byBottomAgent: [],
        byPartnerData: [],
        byCluster: []
    }
}
const currency = getCountryCurrencySymbol()
const name = 'Charts'

export const ChartsSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setAgeFilter : (state,action) =>{
            state.ageFilter= action.payload 
        },
        setGenderFilter : (state,action) =>{
            state.genderFilter= action.payload
        },
        setLocationFilter : (state,action) =>{
            state.locationFilter= action.payload
        },
        setStateFilter : (state,action) =>{
            state.stateFilter= action.payload
        },
        setCityFilter : (state,action) =>{
            state.cityFilter= action.payload
        },
        setLocationLabels : (state,action) =>{
            state.locationLabels= action.payload
        },
        setStatesLabels : (state,action) =>{
            state.statesLabels= action.payload
        },
        setCitiesLabels : (state,action) =>{
            state.citiesLabels = action.payload
        },
        setUIFilter : (state,action) =>{
            state.uiFilter= action.payload
        },
        setUILimits : (state,action) =>{
            state.uiLimits = [0, action.payload]
        },
        setUILimitsMarks : (state,action) => {
            state.uiLimitsMarks = [{ value: 0, label: `${currency}0`}, action.payload]
        },
        setUIMapFilter : (state,action) =>{
            state.uiMapFilter = action.payload
        },
        setFaceAmountFilter: (state, action) => {
            state.faceAmountFilter = action.payload
        },
        setProductFilter :(state,action) =>{
            state.ProductFilter = action.payload
        },
        setBehavioralFilter :(state,action) => {
            state.BehavioralFilter = action.payload
        },
        setMoreFilters: (state,action) => {
            state.moreFilters = action.payload
        },
        setOptimalTresh: (state,action) => {
            state.optimalTresh = action.payload
        },
        setOptimalTreshPrecision: (state,action) => {
            state.optimalTreshPrecision = action.payload
        },
        setCorrelation: (state,action) => {
            state.correlation= action.payload
        },
        setPrecision: (state, action) => {
            state.precision = action.payload
        },
        setPremiunMedian: (state,action) => {
            state.premiumMedian = action.payload
        },
        setHiddenHeaders: (state,action) => {
            state.hiddenHeaders = action.payload
        },
        setPrecisionConfig: (state,action) => {
            state.precisionConfig = action.payload
        },
        setPrecisionLevel: (state,action) => {
            state.precisionLevel = action.payload
        },
        setEmptyValues: (state, action) => {
            state.emptyValues = {
                age: emptyValueCheck(action.payload?.age),
                gender: emptyValueCheck(action.payload?.gender),
                state: emptyValueCheck(action.payload?.location?.states),
                income: emptyValueCheck(action.payload?.income),
                duration: emptyValueCheck(action.payload?.duration),
                agent: emptyValueCheck(action.payload?.agent),
                partner: emptyValueCheck(action.payload?.partner),
                cluster: emptyValueCheck(action.payload?.cluster),
            }
        },
        setChartsMetaData: (state, action) => {
            const { map, cities, states } = populateLocationData(action.payload?.location?.states, action.payload?.location?.cities)
            
            const agent = populateChartData(getTmpArray(action.payload?.agent))
            const top = agent
                .sort((a, b) => a.value > b.value ? 1 : -1)
                .slice(0, agent.length > 4 ? 5 : agent.length - 1)
            const bottom = agent
                .sort((a, b) => a.value > b.value ? 1 : -1)
                .slice(0, agent.length > 4 ? 5 : agent.length - 1)

            state.chartData = {
                map: map,
                mapByCounties: cities,
                byState: states,
                byGender: populateChartData(getTmpGenderArray(action.payload?.gender)),
                byDistribution: populateChartData(getTmpArray(action.payload?.duration)),
                byAge: populateChartData(getTmpAgeArray(action.payload?.age)),
                byIncome: populateChartData(getTmpIncomeArray(action.payload?.income)),
                byTopAgent: top,
                byBottomAgent: bottom,
                byPartnerData: populateChartData(getTmpArray(action.payload?.partner)),
                byCluster: populateChartData(getTmpArray(action.payload?.cluster))
            }
        },
        resetEmptyValuess: (state) => {
          state.emptyValues = {
            age: true,
            gender: true,
            state: true,
            income: true,
            duration: true,
            agent: true,
            partner: true,
            cluster: true
          }
        },
        resetChartsMetaData: (state) => {
          state.chartData = {
            map: [],
            mapByCounties: [],
            byState: [],
            byGender: [],
            byDistribution: [],
            byAge: [],
            byIncome: [],
            byTopAgent: [],
            byBottomAgent: [],
            byPartnerData: [],
            byCluster: []
          }
        },
        resetFilters: (state) => {
            state.ageFilter = {"All":true}
            state.faceAmountFilter = {"All":true}
            state.genderFilter = {"All":true,"Male":false,"Not specified":false,"Female": false}
            state.locationFilter = ""
            state.stateFilter = ""
            state.cityFilter = ""
            state.locationLabels = []
            state.statesLabels = []
            state.citiesLabels = []
            state.uiFilter = [0,0]
            state.uiLimits = [0,0]
            state.uiLimitsMarks = []
            state.uiMapFilter = ""
            state.ProductFilter = {}
            state.BehavioralFilter = {"All":true}
            state.optimalTresh = 0
            state.optimalTreshPrecision = 0
            state.correlation = {}
            state.precision = []
            state.hiddenHeaders = []
            state.premiumMedian = 0
            state.moreFilters = [
                {
                    name: "Product",
                    isActive: false,
                },
                {
                    name: "Household Income",
                    isActive: false,
                }
            ]
          
        },
        resetAgeFilter: (state)=>{
            state.ageFilter =[0,99]
        },
        resetGenderFilter: (state)=>{
            state.genderFilter ={"All":true,"Male":false,"Not specified":false,"Female": false}
        },
        resetLocationFilter: (state)=>{
            state.locationFilter =""
        },
        resetUIFilter: (state)=>{
            state.uiFilter =state.uiLimits
            state.uiLimits=[0,0]
            state.uiLimitsMarks=[]
        },
        resetProductFilter: (state)=>{
            state.ProductFilter={}
        },
        resetMoreFilters: (state) => {
            state.moreFilters = [
                {
                    name: "Product",
                    isActive: false,
                },
                {
                    name: "Household Income",
                    isActive: false,
                }
            ] 
        },
     
    }
})

export const {
    setUIMapFilter,
    setAgeFilter,
    setGenderFilter,
    setLocationFilter,
    setStateFilter,
    setCityFilter,
    setLocationLabels,
    setStatesLabels,
    setCitiesLabels,
    setUIFilter,
    setOptimalTresh,
    setOptimalTreshPrecision,
    setPrecision,
    setUILimits,
    setUILimitsMarks,
    setProductFilter,
    setMoreFilters,
    resetFilters,
    setCorrelation,
    setPremiunMedian,
    setFaceAmountFilter,
    setBehavioralFilter,
    setHiddenHeaders,
    setPrecisionConfig,
    setPrecisionLevel,
    setEmptyValues,
    setChartsMetaData,
    resetEmptyValuess,
    resetChartsMetaData,
    resetAgeFilter,
    resetGenderFilter,
    resetLocationFilter,
    resetUIFilter,
    resetProductFilter,
    resetMoreFilters
} = ChartsSlice.actions


export const selectCharts = (state) => state.Charts
export const selectChartsMetaData = (state) => state.Charts.chartData
export const selectEmptyValues = (state) => state.Charts.emptyValues




export default ChartsSlice.reducer