import React from "react";
import { useDispatch } from "react-redux";
import { setModel } from "../../Components/Run/RunSlice";
import Run from "../../Components/Run/Run";

const RunPage = () => {
    const dispatch = useDispatch()

    
  React.useEffect(() => {
    if(sessionStorage.getItem("run_data")) {
      const runData = JSON.parse(sessionStorage.getItem("run_data"))
      dispatch(setModel(runData.model))
    }
  }, [dispatch])
    
    return (   
        <>
          <Run/>
        </>     
    )
}

export default RunPage;