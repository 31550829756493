import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@mui/material";
import { useStyles } from "./styles";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";
import SchedulerComponent from "../../Components/Scheduler/SchedulerComponent";


const Scheduler = () => {
    const classes = useStyles()
    const { agent_id, lead, name } = useParams();
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ leadID, setLeadID ] = useState(null)
    const [ agent, setAgent ] = useState(null)

    const handleCloseAlert = () => {
        setAlertOpen(false)
    }
    
    //get lead id by lead
    useEffect(() => {
        if (lead && !name) {
            axios.get(`${process.env.REACT_APP_AUTH_API_URL}/v1/hubspot/leads/lead?email=${lead}`, { headers: {"Access-Key": process.env.REACT_APP_LEADS_ACCESS_KEY}})
            .then((response) => {
                if (response.data?.count) {
                    const data = response.data.leads[0]
                    setLeadID(data.id)
                } else {
                    setAlertOpen(true)
                }
            })
            .catch((error) => {
                setAlertOpen(true)
                console.log(error)
            })
        }
    }, [lead])
    
    // get agent by id
    useEffect(() => {
        if (agent_id && !name) {
            axios.get(`${process.env.REACT_APP_API_URL}/scheduler/accounts?user_id=${agent_id}`, { headers: {"Access-Key": process.env.REACT_APP_ACCESS_KEY}})
            .then((response) => {
                if (response.data?.user) {
                    setAgent(response.data.user)
                } else {
                    setAlertOpen(true)
                }
            })
            .catch((error) => {
                setAlertOpen(true)
                console.log(error)
            })
        }
    }, [agent_id])
    

    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.schedulerRoot}>
            <Grid container direction="column" alignItems="center">
                <SchedulerComponent agent={agent} leadID={leadID}/>
            </Grid>

            
            <InformationPopup
                open={alertOpen}
                onConfirm={handleCloseAlert}
                closeControl={false}
                confirmText="OK"
                title={""}
            >
                <Alert severity="error" style={{ width: 520 }}>
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Incorrect lead or agent data. Couldn't find availiabal time slots</Typography>
                </Alert> 
            </InformationPopup>
        </Grid>
    )
}

export default Scheduler;