import ReactSelect from "react-select"
import InformationPopup from "../InformationPopup/InformationPopup"

const usersMock = [
    { value: "eyal_dev@atidot.com", label: "eyal_dev@atidot.com" }, 
    { value: "slava@atidot.com", label: "slava@atidot.com" },
    { value: "vlad@atidot.com", label: "vlad@atidot.com" },
]

 const UserViewPopup = (props) => {

    return (
        <InformationPopup
            {...props}
            confirmText="View"
            closeText="Cancel" 
            buttonDirection="right"
        >
            <ReactSelect options={usersMock} placeholder="Select account to view" 
              menuPortalTarget={document.body} styles={{
                container: (styles) => ({
                    ...styles,
                }),
                control: (base,state) => ({
                ...base,
                border: "1px solid #c3c3c3",
                borderRadius: 8,
                background: base.background,
                opacity: state.isDisabled ? .5 : 1,
                }),
                dropdownIndicator: (styles) => ({
                    ...styles,
                }),
                indicatorSeparator: (styles) => ({
                    ...styles,
                    display: "none"
                }),
                clearIndicator: (styles) => ({
                    ...styles,
                }),
                valueContainer : (styles) =>({
                    ...styles,
                    fontSize: 16,
                    fontFamily: "Roboto",
                }),
                menuList: (base, state) => ({
                    ...base,
                    fontSize: 16,
                    fontFamily: "Roboto",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }}/>
        </InformationPopup>
    )
 }

 export default UserViewPopup