import { Button, Checkbox, FormControlLabel, Grid, makeStyles, styled, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Alert, AlertTitle } from "@material-ui/lab";
import FormInput from "../../Components/Forms/FormInput";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { amplitudeService } from "../../global/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectUserType, setEmail, setPass, setUserType } from "../Login/LoginSlice";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaidIcon from '@mui/icons-material/Paid';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";

const TypeButton = styled(ToggleButton)({
    width: "50%",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white!important",
      backgroundColor: '#363ED3!important'
    }
})

const useStyles = makeStyles(() => ({
    backgroundImage: {
        position: "absolute",
        zIndex: 0,
    },
    buttonContainer:{
        textAlign: "center",
        paddingBottom: 15
    },
    buttonBlock:{
        background: '#0BCB47',
        width: 465,
        height: 55,
        borderRadius: 8,
        boxShadow: "none",
        textTransform: 'capitalize',
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
          backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        }
    },
    signInRegister:{
        color: '#312E40',
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        '& span': {
            color: '#363ED3',
            cursor: "pointer"
        }
    },
    typeContainer:{
        paddingTop: 12,
        '& .MuiToggleButtonGroup-root': {
            width: 465
        },
        '& p': {
            margin: 0,
            marginLeft: 5,
            lineHeight: "12px"
        }
    },
    termsContainer:{
        textAlign: 'center',
        color: '#232033',
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        '& a, span': {
            color: '#363ED3',
            cursor: "pointer",
            textDecoration: "none"
        }
    },
    termsCheckContainer: {
        display: "flex",
        paddingTop: 14,
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
        '& .MuiButtonBase-root': {
            paddingLeft: 0,
            paddingRight: 6,
            marginLeft: "-3px",
            width: 24
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: "white"
        },
        '& .MuiSvgIcon-root': {
            width: 16,
            height: 16
        }
    },
    termsCheckText: {
        display: "flex",
        alignItems: "center",
        '& .MuiTypography-root': {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "22px",
            color: '#636464',
        },
        '& a, span': {
            textDecoration: 'none',
            color: "#3444CB",
            cursor: "pointer"
        }
    },
    termsCheck: {
        color: "#c3c3c3",
        '&.MuiCheckbox-colorSecondary.Mui-checked svg': {
            color: "#363ed3"
        }
    },
    signupForm: {
        width: "100%",
        maxWidth: 900,
        zIndex: 1
    },
    registrationWrapper: {
        width: 465
    },
    registrationTitle: {
        color: "#363ED3",
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 24,
        fontWeight: 400,
        paddingTop: 20,
    },
    captchaContainer: {
        paddingTop: 8,
        "& html body .rc-anchor": {
            width: "99%"
        },
        "& html body .rc-anchor-logo-portrait": {
            margin: "10px 0 0 93px"
        },
    },
    passwordTipText: {
        color: "#312E40",
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 300,
        lineHeight: "150%",
        paddingTop: 4,
        "& span": {
            color: "#000000",
            fontWeight: 700
        }
    },
    signupControlsContainer: {
        paddingTop: 14,
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        },
        paddingTop: 34
    },
    termsAndConditionsPopup: {
        '& .MuiPaper-root': {
            width: "100%",
            maxWidth: 1280,
        },
    },
    termsAndConditionsContent: {
        textAlign: "left",
        gap: 24,
        '& h2, span': {
            fontSize: 22,
            fontWeight: 700,
            textDecoration: "underline",
        },
        '& h2, span, p': {
            margin: 0,
        },
        '& .terms-item--list': {
            gap: 12,
        },
    }
}));

const RegistrationPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const date = new Date();
    const [terms, setTerms] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [name, setName] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [company, setCompany] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [disabled, setDisabled] = React.useState(false)
    const type = useSelector(selectUserType)
    const formRef = React.useRef();

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')
    const [termsPopupOpen, setTermsPopupOpen] = React.useState(false)
    const isBroker = ['localhost', 'broker'].some(cond => window.location.host.includes(cond))
    //const [recaptchaToken, setRecaptchaToken] = React.useState(isBypassCaptcha ? 'WXkvSjqchv' : '')

    const handleTypeChange = (e, value) => {
        setUsername("")
        setPassword("")
        dispatch(setUserType(value))
    }

    const goToLogin = () => {
        history.push("/login")
    }

    function handleSubmit(){
        setDisabled(true)
        setShowAlert(false)
        axios.put(process.env.REACT_APP_AUTH_API_URL + "/v1/users/companies", {
            name: type !== "broker" ?  company : crypto.randomUUID().split("-")[0],
            account_id: type !== "broker" ?  6 : 15
        })
            .then((response) => {
                const id = response.data["company"]["id"]
                const payload = {
                    email: username,
                    name: type !== "broker" ? name : "full name",
                    password: password,
                    phone: type !== "broker" ? parseInt(phone) : 100000000000,
                    title,
                    company_id: id,
                    country_code: 'US',
                    role_ids: [4]
                }
                id && axios.post(
                    process.env.REACT_APP_AUTH_API_URL + "/v1/auth/register",
                    payload
                ).then((res)=>{
                    delete payload['password']
                    amplitudeService._throwEvent("REGISTRATION", payload)
                    setShowAlert(false)
                    setDisabled(false)
                    dispatch(setEmail(username))
                    dispatch(setPass(password))
                    history.push("/confirmaccount?time="+date.getTime())
                }).catch((error)=>{
                    setAlertTitle("Error!");
                    setAlertSeverity("error")
                    setAlertMessage(error.response.data.error)
                    setShowAlert(true)
                    setDisabled(false)
                })
            })
            .catch((error)=>{
                setAlertTitle("Error invalid company name!");
                setAlertSeverity("error")
                setAlertMessage("Please enter existing company")
                setShowAlert(true)
                setDisabled(false)
            })
    }

    
    // Set Default Type
    useEffect(() => {
        if (!type) {
            dispatch(setUserType(isBroker ? "broker" : "carriers"))
        }
    }, [dispatch])

    return (
        <LoginContainer> 
            <Grid container justifyContent="center" alignItems="center" className={classes.signupForm}>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <Typography className={classes.signInRegister}>
                                    Already have an account? <span onClick={goToLogin}>Log In</span>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.typeContainer}>
                                <ToggleButtonGroup
                                value={type}
                                exclusive
                                onChange={handleTypeChange}
                                aria-label="Platform"
                                >
                                <TypeButton value="broker"><AssessmentIcon/> <p>For Broker</p></TypeButton>
                                <TypeButton value="carriers"><PaidIcon/> <p>For Carriers</p></TypeButton>
                                </ToggleButtonGroup>
                            </Grid>                                       
                            <Grid container direction="column" className={classes.registrationWrapper}>
                               
                               <Typography className={classes.registrationTitle}>
                                   Account Information
                               </Typography>
                               <Grid item className={classes.inputContainer}>
                                   <FormInput
                                       label="E-mail"
                                       type="email"
                                       name="email"
                                       value={username}
                                       onChange={(event) => setUsername(event.target.value)}
                                       validators={['required', 'isEmail']}
                                       errorMessages={['this field is required', 'Please enter a valid email address']}
                                       required
                                       autoFocus
                                       autoComplete="off"
                                       data-testid="emailInput"/>
                               </Grid>
                               <Grid item className={classes.inputContainer}>
                                   <FormInput
                                       label="Password"
                                       showText={false}
                                       name="password"
                                       value={password}
                                       onChange={(event) => setPassword(event.target.value)}
                                       validators={['required', "matchRegexp:^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]*).{8,}"]}
                                       errorMessages={['this field is required', 'Must be 8 or more characters, should contain at least 1 number and contain both upper and lower case letters']}
                                       required
                                       autoComplete="off"
                                       data-testid="passwordInput"/>
                               </Grid>
                                {type !== "broker" && <Grid item className={classes.inputContainer}>
                                    <FormInput
                                        label="Full name"
                                        type="fname"
                                        name="fname"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                        validators={['required', 'matchRegexp:^[a-zA-Z ,.]{2,120}$']}
                                        errorMessages={['this field is required', 'Please enter a valid name']}
                                        required
                                        autoComplete="off"
                                        data-testid="fnameInput"/>
                                </Grid>}
                                <Grid item className={classes.inputContainer}>
                                        {type !== "broker" && <FormInput
                                        label="Phone number"
                                        type="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={(event) => setPhone(event.target.value)}
                                        validators={['matchRegexp:^[0-9]{11,12}$']}
                                        errorMessages={['Please enter a valid phone number with country code']}
                                        autoComplete="off"
                                        data-testid="phoneInput"/>}
                                </Grid>
                                <Grid item className={classes.inputContainer} style={{ display: "none"}}>
                                    <FormInput
                                        label="Title"
                                        type="title"
                                        name="title"
                                        value={title}
                                        onChange={(event) => setTitle(event.target.value)}
                                        optional
                                        validators={['matchRegexp:^[a-zA-Z].{2,}']}
                                        errorMessages={['Please enter a valid title']}
                                        autoComplete="off"
                                        data-testid="titleInput"/>
                                </Grid>
                                <Grid item className={classes.inputContainer}>
                                    {type !== "broker" && <FormInput
                                        label="Company Name"
                                        type="company"
                                        name="company"
                                        value={company}
                                        onChange={(event) => setCompany(event.target.value)}
                                        validators={['required', 'matchRegexp:^[a-zA-Z0-9].{1,}']}
                                        errorMessages={['this field is required', 'Please enter a valid company name']}
                                        required
                                        autoComplete="off"
                                        data-testid="companyInput"/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item justifyContent="center">
                            <div className={classes.termsCheckContainer}>
                                    <div>
                                        <FormControlLabel control={<Checkbox value={terms} onChange={() => setTerms(!terms)} className={classes.termsCheck} />}/>
                                    </div>
                                    <div
                                        className={classes.termsCheckText}>
                                        {
                                            type === "broker" ? <Typography>I agree to all <span onClick={() => setTermsPopupOpen(true)}>Terms And Conditions</span></Typography>
                                            : <Typography>I agree to all <a href="https://www.atidot.com/privacy-notice/" rel="noreferrer" target="_blank">Terms And Conditions</a></Typography>
                                        }
                                    </div>
                            </div>
                                
                        </Grid>                     
                        <Grid container justifyContent="center" direction="column" className={classes.signupControlsContainer}>
                            <Grid item className={classes.buttonContainer}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.buttonBlock}
                                data-testid="loginButton"
                                disabled={disabled || !terms}
                                >
                                    Create account
                                </Button>
                            </Grid>
                        </Grid>

                        <InformationPopup
                            open={termsPopupOpen}
                            onClose={() => setTermsPopupOpen(false)}
                            title="Terms & Conditions"
                            closeControl={false}
                            className={classes.termsAndConditionsPopup}    
                        >
                            <Grid container direction="column" className={classes.termsAndConditionsContent}>
                                <Grid item container direction="column" className="terms-item--list">
                                    <h2>
                                        1. Data; Proprietary Rights; Confidentiality
                                    </h2>
                                    <p>
                                    a.    The Broker shall solely own all rights, title and interest in and to the Data and Broker’s Confidential Information
                                    (as defined below). The Company may extract certain information, such as the time, dates and duration of the Services, and
                                    may use non-personal and non-identifiable information deriving from the Services, for the Company’s internal statistical
                                    and analytical purposes and in respect of operations and product development and enhancements.
                                    </p>
                                    <p>
                                    b.    The Company shall solely own all rights, title and interest in and to the Platform and the Services, and
                                    Company’s Confidential Information, including without limitation any improvements and derivatives thereof, and the
                                    Company reserves all rights in and relating to the Platform and Services not expressly granted to the Broker under this
                                    Agreement.
                                    </p>
                                    <p>
                                    c. The Broker shall not (i) decompile, disassemble, or otherwise reverse engineer or attempt to reconstruct or
                                    discover any source code, object code or underlying ideas, structure, know-how, algorithms, file formats or programming
                                    or interoperability interfaces relevant to the Platform by any means whatsoever; (ii) remove any identification, Company’s
                                    marks, or proprietary or copyright restrictions from the Platform or Services; (iii) copy, modify, incorporate into or with
                                    other software, or create a derivative work of any part of the Platform or Services; (iv) use similar processes and functions
                                    to develop competing software or services or to maintain interoperability between products, or any part thereof; (v) develop
                                    methods to enable unauthorized parties to use the Platform or Services; or (vi) separate any component part of the Platform
                                    or Services, or separately use any component part thereof on any equipment, machinery, hardware or system.
                                    </p>
                                    <p>
                                    d. Each party hereto acknowledges that the Confidential Information of the other party constitutes valuable
                                    confidential and proprietary information. Each party will (i) hold the Confidential Information of the other party in
                                    confidence, (ii) not disclose or use such Confidential Information except for performing pursuant to this Agreement or if
                                    compelled to do so under applicable law (and has delivered a written notice to that effect to the disclosing party and taken
                                    all reasonable steps to avoid the disclosure of such Confidential Information), and (iii) use at least the same degree of care
                                    with respect to the other party’s Confidential Information as it uses to protect its own Confidential Information of a similar
                                    nature, but not less than reasonable care. Each party will disclose the other party’s Confidential Information, to the extent
                                    such disclosure is permitted under the terms of this Agreement, to its employees and agents on a “need to know” basis, and
                                    only if such employees and agents are bound by confidentiality obligations similar to those in this Agreement.
                                    “Confidential Information” means non-public information excluding information that has become publicly known
                                    through no breach of this section, has been received from a third party authorized to make such disclosure, or is required to
                                    be disclosed by a competent legal or governmental authority.
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p>
                                    <span>
                                    2.    NO WARRANTY; LIMITATION OF LIABILITY. 
                                    </span>
                                    COMPANY MAKES NO WARRANTIES WITH
                                    RESPECT TO THE PLATFORM OR SERVICES OR RESULTS OF THE USE THEREOF, AND DISCLAIMS ALL
                                    STATUTORY AND IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE,
                                    MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NOTWITHSTANDING ANYTHING TO
                                    THE CONTRARY (I) IN NO EVENT WILL COMPANY OR ANYONE ON ITS BEHALF BE LIABLE WITH
                                    RESPECT TO ANY SUBJECT MATTER HEREOF UNDER ANY LEGAL OR EQUITABLE THEORY FOR ANY
                                    INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES; AND (II) COMPANY’S ENTIRE
                                    AGGREGATE LIABILITY ARISING FROM OR RELATING TO THE AGREEMENT OR THE SUBJECT MATTER
                                    HEREOF, UNDER ANY LEGAL OR EQUITABLE THEORY, SHALL BE LIMITED TO THE AMOUNTS
                                    ACTUALLY RECEIVED BY COMPANY UNDER THIS    
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p>
                                    <span>
                                    3.    GENERAL. 
                                    </span>
                                    This Agreement (i) may not be assigned by either party without the prior written approval of the
                                    other party, except to a successor of all or substantially all of its business or assets; (ii) constitutes the complete
                                    understandings between the parties with respect to its subject matter and supersedes all prior or contemporaneous
                                    understandings; (iii) may only be amended in writing signed by both parties; (iv) will be governed by the laws of the State
                                    of Delaware (excluding its conflict of law principles), and the competent courts of the State of Delaware shall have
                                    exclusive jurisdiction with respect to any dispute arising out of or in connection with this Agreement; and (v) may be
                                    terminated by either party at any time by a 7-day written notice to the other party, and sections 1.3 through 6 shall survive
                                    such termination. THE PARTIES IRREVOCABLY WAIVE, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                                    ANY RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THIS
                                    AGREEMENT.
                                    </p>
                                </Grid>
                            </Grid>
                        </InformationPopup>
                        {showAlert && <Grid item justifyContent="center" className={classes.alert}>
                            
                                <Alert variant="outlined" severity={alertSeverity}>
                                <AlertTitle>{alertTitle}</AlertTitle>
                                {alertMessage}
                                </Alert>
                        </Grid>}
                </ValidatorForm>
            </Grid>
        </LoginContainer>
    )
}

export default RegistrationPage