import { ApiService } from "../../global/service";

export default class mailsApiService {
    _postUploadTemplate = (data, requestHandler, errorHandler) => ApiService.post("/mailers/upload-email-template", {}, requestHandler, errorHandler, data)

    _deleteTemplate = (template_id, requestHandler, errorHandler) => ApiService.delete(`/mailers/templates/${template_id}`, {}, requestHandler, errorHandler)

    _getTemplatesByCompanyId = (company_id, requestHandler, errorHandler) => ApiService.get(`/mailers/templates?company_id=${company_id}`, {}, requestHandler, errorHandler)

    _getMailerConfig = (requestHandler, errorHandler) => ApiService.get("/mailers/config", {}, requestHandler, errorHandler)

    _postMailerConfig = (data, requestHandler, errorHandler) => ApiService.post("/mailers/config", {}, requestHandler, errorHandler, data)

    _patchMailerConfig = (data, requestHandler, errorHandler) => ApiService.patch("/mailers/config", {}, requestHandler, errorHandler, data)

    _getTemplateSourceHTML = (template_id, requestHandler, errorHandler) => ApiService.get(`/mailers/templates/${template_id}`, {}, requestHandler, errorHandler)
}