import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import InformationPopup from "../InformationPopup/InformationPopup";
import { useEffect, useRef, useState } from "react";
import { UIButton } from "../../ui/buttons";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormInput from "../../Forms/FormInput";
import { Editor } from '@tinymce/tinymce-react';
import { Alert, AlertTitle } from "@mui/material";

const useStyles = makeStyles(() => ({
    previewPopup: {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: 640,
            textAlign: "unset",
            color: "unset",
        },
        "& .MuiDialogContent-root": {
            maxHeight: "unset",
        },
    },
    editorContainer: {
        gap: 24,
    },
    editorContentContainer: {
        overflow: "auto",
        "& .tox-tinymce": {
            width: "100%",
            border: "1px solid #eef0f4",
            borderRadius: 6,
        }
        
    },
    loaderContainer: {
        height: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loadedAlertContainer:{
      "& .MuiTypography-root": {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left"
      },
      "& .MuiAlertTitle-root": {
        textAlign: "left!important"
      },
      "&.MuiAlert-root": {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingLeft: 36
      },
    },
    formContent: {
        gap: 12,
    },
    confirmOptions: {
        gap: 32,
        justifyContent: "flex-end"
    },

}))

const MailerTemplateEditPopup = ({ open, disabled, initialValues, handleClose, onSubmit, source = "", loading }) => {
    const classes = useStyles()
    const formRef = useRef()
    const editorRef = useRef()
    const [sourceStyle, setSourceStyle ] = useState("");
    const [data, setData ] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertSeverity, setAlertSeverity] = useState('')

    const onClose = () => {
        setAlertTitle("");
        setAlertMessage("");
        setAlertSeverity("");
        setShowAlert(false)
        handleClose()
        setData({
            name: "",
            description: ""
        })
    }

    const handleApiError = (error) => {
        if (error?.response?.status === 409) {
            setAlertTitle("Server Error");
            setAlertMessage(`Template with name '${data.name}' already exist, please enter another name`);
        } else if (error?.response?.data?.error) {
            setAlertTitle("Error");
            setAlertMessage(error.response.data.error);
        } else {
            setAlertTitle("Server Error");
            setAlertMessage("Sorry, something went wrong while trying to upload your template, try again later");
        }
        setAlertSeverity("error");
        setShowAlert(true)
    }

    const handleSubmit = (e) => {
      if (editorRef.current) {
        const content =  `
        <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@100..900&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@100..900&display=swap" rel="stylesheet">
        <style>
        ${sourceStyle}
        </style>
        </head>
        ${editorRef.current.getContent()}
        `
        const file = new Blob([content], {
            type: "text/html",
        })
        const bodyFormData = new FormData()
        bodyFormData.append("file", file, `${data.name?.replaceAll(" ", "_")}.html`)
        Object.keys(data).forEach((key) => bodyFormData.append(key, data[key]))
        onSubmit(e, bodyFormData, handleApiError, onClose)
      }
    };

    const handleChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    

    useEffect(() => {
        if (source.includes("<style>")) {
            const start = source.indexOf("<style>") + 7
            const end = source.indexOf("</style>")
            const templateStyle = source.slice(start, end)
            setSourceStyle(templateStyle);
        }
    }, [source])

    useEffect(() => {
        setData(initialValues)
    }, [initialValues])

    return (
    <InformationPopup
        open={open}
        onClose={onClose}
        closeControl={false}
        className={classes.previewPopup}
        title="Email Template Editor"
    >
        <Grid container justifyContent="center">
            {
                loading ?
                    <div className={classes.loaderContainer}>
                    <CircularProgress style={{color: "#363ed3",width:96,height:96}}/>
                    </div>
                :
                <Grid container className={classes.editorContainer}>
                    <Grid container item>
                        
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container direction="column" className={classes.formContent}>
                        {
                        (showAlert && alertSeverity === "error") && 
                        <Grid item container>
                            <Alert className={classes.loadedAlertContainer} severity={alertSeverity}>
                                <AlertTitle>{alertTitle}</AlertTitle>
                                {alertMessage}
                            </Alert>
                        </Grid>
                        }
                        <Grid item container>
                            <Typography className={classes.inputTitle}>Email Template Name *</Typography>
                            <FormInput
                                type="name"
                                name="name"
                                placeholder="Enter Template Name"
                                validators={['required']}
                                errorMessages={['Template Name is required']}
                                value={data.name}
                                onChange={handleChangeData}
                                autoFocus
                                data-testid="name" />
                        </Grid>
                        <Grid item container>
                            <Typography className={classes.inputTitle}>Description</Typography>
                            <FormInput
                                type="description"
                                name="description"
                                placeholder="Template Description"
                                value={data.description}
                                onChange={handleChangeData}
                                autoFocus
                                data-testid="description" />
                        </Grid>
                        <Grid container item className={classes.editorContentContainer}>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                            onInit={(_evt, editor) => editorRef.current = editor}
                            initialValue={source}
                            init={{
                            height: 444,
                            menubar: false,
                            plugins: [
                                'searchreplace', 'autolink', 'directionality', 'visualblocks', 
                                'visualchars', 'image', 'link', 'media', 'codesample', 'table', 'charmap',
                                'pagebreak', 'nonbreaking', 'anchor', 'insertdatetime', 'lists',
                                'wordcount', 'help',
                                'charmap', 'linkchecker', 'emoticons', 'autosave', 'advcode', 'code', 'fullscreen',
                            ],
                            toolbar: 'undo redo | blocks | fontfamily bold italic strikethrough backcolor | mergetags | link image | align bullist numlist | code ',
                            content_style: sourceStyle,
                            font_family_formats: 'Roboto=Roboto, sans-serif;Inter=Inter, sans-serif',
                            advcode_inline: true,
                            elementpath: false,
                            }}
                        />
                        </Grid>                   
                        <Grid container className={classes.confirmOptions}>
                            <UIButton
                                type="submit"
                                className={classes.confirmButton}
                                data-testid="submitButton"
                                disabled={disabled}
                            >
                                Save Mail
                            </UIButton>
                        </Grid>
                    </Grid>
                </ValidatorForm>

                    </Grid>
                </Grid>
            }
        </Grid>
    </InformationPopup>
)}

export default MailerTemplateEditPopup