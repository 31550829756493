import React from "react";
import { Grid } from "@material-ui/core";

const SchedulerSuccess = () => {

    return (
        <Grid container justifyContent="center" alignContent="center" style={{background: "#F9FAFB", height:"100vh", textAlign:"center"}}>
            <h1>Thank you for your call request!<br/>We will contact you at your selected time</h1>
        </Grid>
    )
}

export default SchedulerSuccess