import React from "react";
import { Button, Grid, Typography, makeStyles, styled } from "@material-ui/core";
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import { basicButtonStyles } from "../../../Components/ui/buttons";
import { ReactComponent as PersonIcon } from "../../../Assets/icon-user-outline.svg";
import { InfoTooltip } from "../../../Components/ui/tooltips";

const useStyles = makeStyles(() => ({
    opportunityItemContainer: {
        padding: 16,
        borderRadius: 6,
        border: "1px solid #EBEBEB",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "400",
        boxShadow: "0px 2px 3px 0px #0000001F",
        maxWidth: 316,
        gap: 16,
        "& p": {
            margin: 0
        }
    },
    opportunityItemContent: {
        gap: 8
    },
    opportunityTypeContainer: {
        display: "flex",
        alignItems: "center",
        "& div": {
            display: "flex",
            borderRadius: 6,
            gap: 2,
            lineHeight: "16px",
            padding: "2px 6px",
            alignItems: "center",
            "&.lapse": {
                background: "#C6DBE1",
                color: "#215A6C"
            },
            "&.ui": {
                background: "#D1FFDF",
                color: "#06481B"
            }
        }
    },
    opportunityTypeText: {
        paddingLeft: 4
    },
    opportunityRiskIndicator: {
        borderRadius: "50%",
        background: "#FF4B62",
        width: 12,
        height: 12
    },
    opportunityTitle: {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
        minHeight: 29,
        textTransform: "capitalize"
    },
    recomendedActionContainer: {
        display: "flex",
        background: "#FCECC8",
        color: "#8B3E41",
        padding: "2px 6px",
        fontSize: 12,
        lineHeight: "16px",
        borderRadius: 6
    },
    statsContainer: {
        paddingTop: 6,
        paddingBottom: 16,
        alignItems: "center"
    },
    premiumContainer: {
        "& span": {
            fontWeight: 600,
        },
    },
    prospectsContainer: {
        display: "flex",
        background: "#F2F2F2",
        padding: "2px 6px",
        borderRadius: 6,
        gap: 4
    },
    prospectsIcon: {
        width: 16,
        height: 16,
    },
    prospectsText: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
    },
    actions: {
        position: "absolute",
        "& .MuiIconButton-root": {
            position: "relative",
            padding: 0,
            left: 267,
            "& svg": {
                fill: "#3D3D3D"
            }
        },
    },
    detailsItem: {
        display: "flex",
        flexDirection: "column"
    },
    detailsTitle: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "12px",
        color: "#000000"
    },
    detailsText: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: "28px",
        color: "#383ECB",
    },
}))

export const BuildCampaignButton = styled(Button)(() => ({
    ...basicButtonStyles,
    borderRadius: 8,
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    width: 220,
    justifyContent: "center",
    alignItems: "center",
    '&:hover': {
      opacity: '0.85',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: '#1D2398',
      color: '#FFFFFF'
    },
    '&:active': {
      backgroundColor: '#1D2398'
    },
}));

const BrokerOportunityItem = ({ data, onClick }) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    
    return (
    <Grid item container direction="column" className={classes.opportunityItemContainer}>
        <Grid container className={classes.opportunityItemContent}>
            <Grid item className={classes.opportunityTypeContainer}>
            {data.type === "lapse" ? <div className={data.type}>
                    <p className={classes.opportunityTypeText}>Retention Opportunity</p> 
                </div> : 
                <div className={"ui"}>
                    <p className={classes.opportunityTypeText}>Sell Opportunity</p>
                </div>
            }    
            </Grid>
            <Grid item className={classes.prospectsContainer}>
                <PersonIcon className={classes.prospectsIcon}/>
                <p className={classes.prospectsText}>{data["prospects"]}</p>
            </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
            <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsTitle} style={{ paddingBottom: 4 }}>Potential Premium:</Typography>
                <div>
                    <p className={classes.detailsText}>{nFormatter(data["potential_premium"], 2, currency)}</p>
                </div>
            </Grid>
            <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsTitle} style={{ paddingBottom: 8 }}>Recomended action:</Typography>
                <div className={classes.recomendedActionContainer}>
                    <p>{data["action"]}</p>
                </div>
            </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="space-between" style={{ flexGrow: 1 }}>
            <p className={classes.opportunityTitle}>{data["name"]?.length > 70 ? <InfoTooltip title={data["name"]}><span>{data["name"].slice(0, 70).trim()+"..."}</span></InfoTooltip> : data["name"]}</p>
        </Grid>

        <Grid container direction="column" justifyContent="flex-end">
            <Grid container justifyContent="center">
                <BuildCampaignButton onClick={() => onClick(data["opportunity"], data.type === "lapse" ? "lapse" : "ui", data)}>Build Campaign</BuildCampaignButton>
            </Grid>
        </Grid>
    </Grid>
)}

export default BrokerOportunityItem;