import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    title: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "14px",
        color: "#7E7C87",
        marginBottom: 14,
    },     
    connectForm: {
        width: "100%"
    },      
    connectFormContent: {
        gap: 24
    },  
    connectFormInputContainer: {
        "& .MuiInputBase-root": {
            height: 36
        },
        "& .MuiOutlinedInput-input": {
            height: 36
        },
    },
    connectFormInputLabel: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        color: "#1B1C1E",
        marginBottom: 8
    },
}));