import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    inputTitle: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        paddingBottom: 6,
        paddingTop: 12,
        textTransform: "capitalize",
        color: "#1B1C1E"
    },
    formContainer: {
        gap: 24
    },
    formTitle: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "22px"
    },
    alertBox: {
      paddingTop: 12,
    },
    loadedAlertContainer:{
      "& .MuiTypography-root": {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left"
      },
      "& .MuiAlertTitle-root": {
        textAlign: "left!important"
      }
    },
    settingItemContainer:{
        gap: 20,
    },
    keyContainer:{
        width: "38%",
        flexBasis: "38%",
    },
    valueContainer:{
        width: "59%",
        flexBasis: "59%",
        flexDirection: "column"
    },
    addContainer:{
        paddingTop: 34,
        width: "7%",
        flexBasis: "7%",
        display: "none",
    },
    confirmOptions: {
        justifyContent: "flex-end"
    },
    buttonsContainer: {
      gap: 20
    },
    fieldButton: {
        cursor: "pointer"
    },
    selectContainer: {
      '& div': {
          width: "100%"
      },
    },
    select: {
      '& .MuiSelect-root': {
          background: "#FFFFFF",
          border: '1px solid #c3c3c3',
          borderRadius: 8,
          height: 44,
          fontSize: 16,
          fontWeight: 400
      },
      '& .MuiOutlinedInput-input': {
          padding: "0 14px",
          height: 44,
          display: "flex",
          alignItems: "center"
      },
      '& .MuiTypography-root': {
          padding: "12px 0",
          fontSize: "inherit"
      },
    },
}))

export default useStyles
