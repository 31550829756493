import { Grid } from "@material-ui/core"
import DistiributionMap from "../Charts/DistributionMap/DsitributionMap"
import UIbyState from "../Charts/UIbyState/UIbyState"
import UIbyGender from "../Charts/UIbyGender/UIbyGender"
import UIDistribution from "../Charts/UIDistribution/UIDistribution"
import UIbyIncome from "../Charts/UIbyIncome/UIbyIncome"
import UIbyAge from "../Charts/UIbyAge/UIbyAge"
import UIbyAgent from "../Charts/UIbyAgent/UIbyAgent"
import useStyles from "./PremiumBreakdown.styles"
import { getCountryCurrencySymbol } from "../../../global/helpers"

const PremiumBreakdown = ({ 
    data,
    emptyValues,
    type,
    isBroker = false
}) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const isCluster = type?.model?.toLowerCase() === "cluster"
  
    return (
      <Grid container>
                <Grid item xs={6} className={classes.ChartCointainer}>
  
                <DistiributionMap title={"Prospects Map"} paddingRight={10} mapData={data.mapData} mapDataByCounties={data.mapDataByCounties} hasData={emptyValues.state} currency={isCluster ? "" : currency}/>
  
                </Grid>
                
                <Grid item xs={5} className={classes.ChartCointainer}>
                  <UIbyState title={isBroker ? "By Zip Codes" : "By State"} paddingRight={10} chartData={data.uiByStateData} hasData={emptyValues.state} currency={isCluster ? "" : currency}/>
                </Grid>
  
                <Grid item xs={4} className={classes.ChartCointainer}>
                  
                <UIbyGender paddingRight={10} title={"By Gender"} chartData={data.uiByGenderData} hasData={emptyValues.gender} currency={isCluster ? "" : currency}/>
                </Grid>
  
                {!isCluster && <Grid item xs={8} className={classes.ChartCointainer}>
                  
                <UIDistribution paddingRight={10} title={"By Policy Year"} chartData={data.uiDistributionData} hasData={emptyValues.duration} currency={isCluster ? "" : currency}/>
                </Grid>}
  
                <Grid item xs={isCluster ? 8 : 6} className={classes.ChartCointainer}>
                  
                      <UIbyIncome paddingRight={10} title={"By Income Groups"} chartData={data.uiByIncomeData} hasData={emptyValues.income} currency={isCluster ? "" : currency}/>
                </Grid>
  
                <Grid item xs={5} className={classes.ChartCointainer}>
                  <UIbyAge paddingRight={10} title={"By Age"} chartData={data.uiByAgeData} hasData={emptyValues.age} currency={isCluster ? "" : currency}/>
                </Grid>
  
                {isCluster && <Grid item xs={6} className={classes.ChartCointainer}>
                      <UIbyAgent id='ByStatus' paddingRight={10} title={"By Cluster"} chartData={data.uiByClusterData} hasData={emptyValues.cluster} currency={isCluster ? "" : currency} label='Cluster'/>
                </Grid>}
                
                {isCluster && <Grid item xs={12} className={classes.ChartCointainer}>
                  <UIbyAgent id='ByAgency' paddingRight={10} title={"By Agency"} chartData={data.uiByPartnerData} hasData={emptyValues.partner} currency={isCluster ? "" : currency} label='Agency'/>
                </Grid>}
  
                {!isBroker && <>
                <Grid item xs={6} className={classes.ChartCointainer}>
                  <UIbyAgent id='ByAgentsTop' paddingRight={10} title={"By Agents: Top 5"} chartData={data.uiByTopAgentData} hasData={emptyValues.agent} currency={isCluster ? "" : currency}/>
                </Grid>
                <Grid item xs={5} className={classes.ChartCointainer}>
                  <UIbyAgent id='ByAgentsBot' paddingRight={10} title={"By Agents: Bottom 5"} chartData={data.uiByBottomAgentData}  hasData={emptyValues.agent} currency={isCluster ? "" : currency}/>
                </Grid>
                <Grid item xs={6} className={classes.ChartCointainer}>
                  <UIbyAgent id='ByIMOTop' paddingRight={10} title={"By IMO: Top 5"} chartData={[]} hasData={false} currency={isCluster ? "" : currency}/>
                </Grid>
                <Grid item xs={5} className={classes.ChartCointainer}>
                  <UIbyAgent id='ByIMOBot' paddingRight={10} title={"By IMO: Bottom 5"} chartData={[]} hasData={false} currency={isCluster ? "" : currency}/>
                </Grid>
                </>}
  
      </Grid>
    )
}

export default PremiumBreakdown