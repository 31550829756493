import React, { useEffect, useRef } from "react";
import { Box, Grid, TableCell, Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";
import { selectLeads, setLeads, setLoading } from "./LeadsSlice";
import TableContainer from "../../Components/Prospects/TableContainer/TableContainer";
import { leadsTableHeaders, sortKeysLeads } from "../../global/constants";
import LeadsService from "./LeadsService";
import moment from "moment/moment";
import FiltersContianer from "../../Components/Filters/FiltersContianer/FiltersContianer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGB from "date-fns/locale/en-GB";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FormInput from "../../Components/Forms/FormInput";
import { resetFilters, selectFilters, setDateEndFilter, setDateStartFilter, setStatusFilter } from "../../Components/Filters/FiltersSlice";
import SelectFilter from "../../Components/Filters/SelectFilter";
import { defaultLeadStatusFilter } from "../../Components/Book/constants";

const useStyles = makeStyles(() => ({
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px"
    },
    filtersContent: { 
      padding: '12px 0'
    },
    datePickerLabel: { 
      fontSize: 14
    },
    datePickerContent: { 
      display: 'flex',
      alignItems: 'center',
      "& .MuiInputAdornment-root": {
        position: "relative",
        right: 49
      },
      "& .MuiButtonBase-root": {
        color: "#363ED3",
      }
    }
}));

const ApiService = new LeadsService()

const LeadsPage = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const rootRef = useRef(null)
    const data = useSelector(selectLeads)
    const filters = useSelector(selectFilters)
    const [ clear, setClear ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ rowsPerPage, setRowsPerPage ] = useState(10)
    const [ sortColumn, setSortColumn ] = useState("-created_at")

    const handleChangePage = (e = null, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (value) => {
        setPage(0);
        setRowsPerPage(parseInt(value));
    };
  
    const handleSortClick = (item) => {
      if (item) {
        const orderBy = `${sortColumn.includes("-") ? "" : "-"}${sortKeysLeads[item]}`
        setSortColumn(orderBy)
      }
    }

    const renderRow = (rowData) => {
        return Object.keys(rowData)
          .map((key) => (
            <TableCell component="th" scope="row">
              {
                ["created_at", "updated_at"].includes(key) ? rowData[key] ? moment(rowData[key]).format("MM/DD/YYYY") : "N/A"
                : ["isagent"].includes(key) ? rowData[key].toString()
                : rowData[key] ? rowData[key] : "N/A"
              }
            </TableCell>
          ))
      }

    useEffect(() => {
            dispatch(setLoading(true))
            const filterValues = {
              "created_at": filters.date.query?.map(({ operator, value }) => `created_at:${operator}=${value}`).join("&"),
              "status": filters.status?.length ? `status${filters.status.length > 1 ? ":in" : ""}=`+filters.status.map(({ value }) => value).join(",") : "",
            }
            ApiService._getLeads(page, rowsPerPage, (response) => {
                dispatch(setLeads(response.data))
                dispatch(setLoading(false))
            }, 
            (error) => { console.log(error) },
            sortColumn, Object.keys(filterValues).filter((key) => filterValues[key]?.length > 0)?.map((key) => filterValues[key]).join("&"))
    }, [dispatch, page, rowsPerPage, sortColumn, filters])

    return (
        <Grid container direction="column" ref={rootRef}>            

            <HeaderContainer title={`Leads(${data.count ? data.count : 0})`}/>

            <Grid className={classes.main} style={{ maxWidth: window.innerWidth-143 }}>        
                <FiltersContianer
                  title="Filters"
                  size="full-width"
                  onReset={() => {
                    dispatch(resetFilters())
                    setClear(true)
                  }}
                >
                  <Grid container alignItems="flex-end" className={classes.filtersContent}>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                    
                                    <DatePicker
                                                value={filters.date.date_start}
                                                onChange={(value) => dispatch(setDateStartFilter(value))}
                                                mask="__/__/____"
                                                PopperProps={{
                                                placement: "bottom-start"
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps}) => (
                                                    <Box>
                                                        <Typography className={classes.datePickerLabel}>From:</Typography>                                    
                                                        <div className={classes.datePickerContent} ref={inputRef}>
                                                            <FormInput validate={false} {...inputProps}/>
                                                            {InputProps?.endAdornment}
                                                        </div>
                                                    </Box>
                                                )}/>
                      </LocalizationProvider>    
                    </Grid>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                    
                                    <DatePicker
                                                value={filters.date.date_end}
                                                onChange={(value) => dispatch(setDateEndFilter(value))}
                                                mask="__/__/____"
                                                PopperProps={{
                                                placement: "bottom-start"
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps}) => (
                                                    <Box>
                                                        <Typography className={classes.datePickerLabel}>To:</Typography>                                    
                                                        <div className={classes.datePickerContent} ref={inputRef}>
                                                            <FormInput validate={false} {...inputProps}/>
                                                            {InputProps?.endAdornment}
                                                        </div>
                                                    </Box>
                                                )}/>
                      </LocalizationProvider>    
                    </Grid>
                    <Grid item>
                      <SelectFilter
                            title="Status"
                            popoverText="Status"
                            defaultValue={defaultLeadStatusFilter}
                            reset={clear}
                            resetFilter={() => dispatch(setStatusFilter(defaultLeadStatusFilter))}
                            onChange={(value) => {
                              setClear(false)
                              dispatch(setStatusFilter(value))
                            }}
                      />
                    </Grid>
                  </Grid>
                </FiltersContianer>    
                <TableContainer
                    data={data} 
                    page={page} 
                    headers={leadsTableHeaders}
                    sort={sortColumn}
                    handleSort={handleSortClick}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRowsPerPage}
                    loaded={!data.isLoading}
                    divided={false}
                    renderRow={renderRow}
                    rowsPerPage={rowsPerPage}
                />
            </Grid>
        </Grid>
    )
}

export default LeadsPage