import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    totalData: {
        display: "flex",
        "& .page-data": {
          paddingRight: 30,
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "22px",
          "& span": {
            fontWeight: 500,
          }
        }
    },
    header: {        
      paddingBottom: 16
    },
    headerActions: {
      display: "flex",
      justifyContent: "space-between",
      gap: 48
    }
}));