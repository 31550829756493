import { Grid, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AtidotText } from "../../Assets/icon-atidot-text.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectCredentials, selectUserType } from "../Login/LoginSlice";
import { setUserCompany, setUserInitials, setUserPermissions, setUserLimit, setUserRole, setMenuDisabled, setCurrentUser, setAccountType } from "../../AppSlice";
import { processUserLoginData } from "../../global/helpers";
import CodeConfirm from "../../Components/Forms/CodeConfirm/CodeConfirm";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";

const useStyles = makeStyles(() => ({
    root: {
        background: "#F9FAFB",
        height: "100vh"
    },
    successTitle:{
        marginTop:5,
        marginBottom:3,
        fontWeight: 600,
        fontSize: 24,
        '& span': {
            color: '#3444CB',
            cursor: "pointer"
        }
    },
}))

const Confirm = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const creds = useSelector(selectCredentials)
    const type = useSelector(selectUserType)

    function handleConfirm(code) {
        axios.post(
            process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${creds.login}/activate`, {
                code
            }
        )
            .then(() => {
                axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/login", {
                    email: creds.login,
                    password: creds.password
                    }).then((response) => {
                        processUserLoginData(response, dispatch, setUserInitials, setUserRole, setUserPermissions, 
                            setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType);
                    
                        if (type === "broker" || localStorage.getItem("accountType") === "broker") {                        
                            axios.post(
                                process.env.REACT_APP_AUTH_API_URL + `/v1/users/${localStorage.getItem("userID")}/subscriptions/1`,
                                {},
                                {
                                    headers: {
                                        Authorization: "Bearer " + localStorage.getItem("token"),
                                        "Content-Type": "application/json"
                                    },
                                }
                            ).then(()=> {
                                localStorage.removeItem("registeredPassword")

                                setSuccess(true)
                                setAlertMessage("")
                                history.push("/upload")
                            }).catch((error)=> {
                                localStorage.removeItem("registeredPassword")

                                setSuccess(true)
                                setAlertMessage("")
                                console.log(error);
                                history.push("/upload")
                            })
                        } else {
                            localStorage.removeItem("registeredPassword")

                            setSuccess(true)
                            setAlertMessage("")
                            history.push(type !== "broker" ? "/firstlogin" : "/upload")
                        }

                    }).catch((error)=>{
                        console.log(error)
                        console.log(error.response.status);
                        setSuccess(false)
                        setAlertMessage("Error! Failed to login with given credentials!")
                    })
            })
            .catch((err) => {
                setSuccess(false)
                setAlertMessage("Given code is incorrect!")
            })
    }
    
    const handleResendCode = (callback) => {
        axios.get(
            process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${creds.login}/activation/code`
        )
            .then(() => {
                setAlertMessage("")
                callback()
                const date = new Date();
                history.push("/confirmaccount?time="+date.getTime())
            })
            .catch((err) => {
                setAlertMessage("Previous code has not expired yet!")
            })
    }  

    const goToLogin = () => {
        history.push("/login")
    }

    return (
        <LoginContainer>         
            <Grid container direction="column">
                <Grid container direction="column" justifyContent="center" alignContent="center">
                        <Grid align="center" item>
                            <AtidotText/>
                        </Grid>    
                </Grid>
                {!success ?
                <CodeConfirm
                    onConfirm={handleConfirm}
                    onResend={handleResendCode}
                    errorMessage={alertMessage}
                    timeToExpire={(date) => date.getHours() < 24}/>
                :
                <Grid item>
                    <Typography align="center" component="h1" variant="h5" className={classes.successTitle}>
                        User successfully created
                    </Typography>
                    <Typography align="center" component="p" className={classes.successTitle}>
                        Go back to? <span onClick={goToLogin}>Log In</span> 
                    </Typography>
                </Grid>
                }
            </Grid>
        </LoginContainer> 

    )
}

export default Confirm