import React, { useRef, useEffect, useState } from "react";
import { FormControl, Grid,  makeStyles, Typography } from "@material-ui/core";
import {ReactComponent as ArrowDropDownIcon} from '../../../Assets/icon-dropdown.svg'
import {ReactComponent as EyeIcon} from '../../../Assets/icon-eye2.svg' 
import rectangleSuperior3SVG from '../../../Assets/rectangleSuperior3.svg'
import rectangleInferior3SVG from '../../../Assets/rectangleInferior3.svg'
import rectangleCenter3SVG from '../../../Assets/rectangleCenter3.svg'
import rectangleSuperiorErrorSVG from '../../../Assets/rectangleSuperiorError.svg'
import rectangleInferiorErrorSVG from '../../../Assets/rectangleInferiorError.svg'
import rectangleCenterErrorSVG from '../../../Assets/rectangleCenterError.svg'
import Select from 'react-select';
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectAutoMapping } from "../../../Pages/NewProject/NewProjectSlice";
import { InfoTooltip } from "../../ui/tooltips";
import { ReactComponent as InfoIcon } from '../../../Assets/icon-info-circle.svg';
import { User } from "../../../global/user";

const useStyles = makeStyles(() => ({
    rectangle:{
        maxWidth: 740,
        width: '100%',
        minHeight:40,
        height: "45px",
        fontSize:13,
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignContent:'center',
    },
    rectangleSpacing:{
        marginBottom:5,
        margin: "1px 0",
    },
    topRect: {
        backgroundImage: `url(${rectangleSuperior3SVG})`
    },
    centerRect: {
        backgroundImage: `url(${rectangleCenter3SVG})`,
    },        
    bottomRect: {
        backgroundImage: `url(${rectangleInferior3SVG})`,
    },
    topRectError: {
        backgroundImage: `url(${rectangleSuperiorErrorSVG})`,
    },
    centerRectError: {
        backgroundImage: `url(${rectangleCenterErrorSVG})`,
    },        
    bottomRectError: {
        backgroundImage: `url(${rectangleInferiorErrorSVG})`,
    },
    selectContainer:{
        backgroundColor: "#f3f7ff",
        borderRadius : 6,
        paddingRight: 5,
        marginRight: 5,
        height: 23,
        display:"flex",       
        alignItems: "center"
    },
    topSelect: {       
        position: "relative",
        left: 8,
        top: 6 
    },
    centerSelect: {
        position: "relative",
        left: 8
    },        
    bottomSelect: {
        position: "relative",
        left: 8,
        bottom: 6    
    },
    border:{
        display:'flex',
        alignItems:'center',
        borderRight: "6px solid",
        borderRadius: 6,
        height:40,
        paddingRight:"11px !important" 
    },
    title: {
        fontFamily: "Roboto", 
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "17px",
        color: "#000000",
        textAlign: "right",
        textTransform: "capitalize"
    },
    optionalText: {
        fontFamily: "Roboto", 
        fontSize: 10,
        fontWeight: 500
    },
    selectBody: {
        fontWeight: 500,
        minWidth: 148,
        cursor:'pointer',
        "& .select__menu": {
            fontFamily: "Roboto", 
            fontWeight: 500
        },
        "& .select__menu-list": {
            width: "100%",
            minWidth: 250,
            background: "#FFFFFF!important"
        },
        "& .select__option--is-focused": {
            background: "#363ed305!important",
            "&:hover": {
                color: "#000000"
            }
        },
        "& .select__option--is-selected ": {
            background: "#363ed3"
        },
        "& .select__value-container": {
            fontFamily: "Roboto", 
            cursor:'pointer'
        },
        "& .select__single-value": {
            fontFamily: "Roboto", 
            fontSize: 12,
            fontWeight: 700,
            lineHeight: "16px",
            color: "#000000",
            textTransform: "capitalize",
            cursor:'pointer'
        },
        "& .select__placeholder": {
            fontFamily: "Roboto", 
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "17px",
            cursor:'pointer'
        },
    },
    dropdownIcon: {
        marginRight: 11,
        cursor:'pointer'
    },
    iconButton: {
        cursor:'pointer'
    }
}))


const customSelectStyles = {
    control: (base,state) => ({
      ...base,
      height: 20,
      minHeight: 16,
      fontSize:13,
      backgroundColor: "#f3f7ff",
      border: state.isFocused ? 'none': 'none',
      boxShadow: state.isFocused ? null : null,
      "&:hover &:": {
        border: state.isFocused ? 'none': 'none',
      },
    
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    valueContainer : (styles) =>({
        ...styles,
        fontSize:13,
        backgroundColor: "#f3f7ff !important",
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "#f3f7ff !important",
        fontSize:13,
        width:168,
        maxHeight:"none",
    }),
  };

const MapperItem = ({
    key,
    item, 
    fieldSelection,
    handleSelectChange,
    handlePopoverOpen,
    position,
    color,
    headers,
    outSideRef,
    tooltipIcon = false,
    optional = false,
    error = false
}) => {
    const classes = useStyles()
    const selectRef = useRef(null)
    const autoMapping = useSelector(selectAutoMapping)
    const [classNames, setClassNames] = useState({
        container: classes.centerRect,
        select: classes.centerSelect
    })
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;

    useEffect(() => {
        switch (position) {
            case "top":
                setClassNames({
                    container: error ? classes.topRectError : classes.topRect,
                    select: classes.topSelect                   
                })
                break;
            case "bottom":
                setClassNames({
                    container: error ? classes.bottomRectError : classes.bottomRect,
                    select: classes.bottomSelect                   
                })
                break;
                
            default:
                setClassNames({
                    container: error ? classes.centerRectError : classes.centerRect,
                    select: classes.centerSelect                   
                })
                break;
        }
    }, [position, error, classes])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (menuIsOpen && outSideRef?.current.contains(e.target)) {
                setMenuIsOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [menuIsOpen, outSideRef])
    
    return (
        <Grid container key={key} className={clsx(classes.rectangle, classNames.container, !tooltipIcon ? classes.rectangleSpacing : "")}  
        direction="row" 
        justifyContent="space-between" 
        alignItems="center">
                <Grid item className={clsx(classes.selectContainer, classNames.select)}>
                    <FormControl variant="filled" className={classes.formControl} onClick={(e) => {setMenuIsOpen(!menuIsOpen)}}>
                        <Select
                            id={isBroker ? item.id : item.label}
                            name={isBroker ? item.id : item.label}
                            className={classes.selectBody}
                            classNamePrefix="select" 
                            menuIsOpen={menuIsOpen} 
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            styles={customSelectStyles}
                            value={                                      
                                Object.keys(fieldSelection).map((selected, indexK) => {
                                    if (isBroker) {
                                        if(selected === item.id) {
                                            console.log(fieldSelection,item.id, fieldSelection[item.id]);    

                                            
                                            return {value: fieldSelection[item.id], label: fieldSelection[item.id], key: item.id}
                                        }
                                    } else{
                                        if(selected === item.label ) {    

                                            if(autoMapping[item.id]){ 
                                                return {value: fieldSelection[selected], label: fieldSelection[selected], key: indexK}
                                            } else {
                                                return {value: fieldSelection[item.label], label: fieldSelection[item.label], key: item.label}
                                            }
                                        }
                                    }

                                })
                            }                                   
                            isSearchable={false}
                            onChange={(e) => {handleSelectChange(e.value, isBroker ? item.id : item.label); setMenuIsOpen(!menuIsOpen)}}                                      
                            options={headers}
                            />
                    
                    </FormControl>
                    {optional && <Typography className={classes.optionalText}>(Optional)</Typography>}
                    <ArrowDropDownIcon className={classes.dropdownIcon} onClick={() => setMenuIsOpen(!menuIsOpen)}/>
                    <EyeIcon ref={selectRef} className={classes.iconButton} onClick={()=>{handlePopoverOpen(selectRef.current)}} />
                </Grid>
                {
                tooltipIcon ? 
                <Grid item className={classes.border} style={{ color: color }}>
                    <Typography className={classes.title} style={{ paddingRight: 5 }}>
                        {item.label}
                    </Typography>

                    <InfoTooltip title={item?.tooltip}>
                        <InfoIcon/>
                    </InfoTooltip>
                </Grid>
                :   
                <Grid item style={{ color: color, height: ["top", "bottom"].includes(position) ? 40 : 43, marginBottom: ["top", "bottom"].includes(position)  ? 2 : 0 }} className={classes.border}>
                    <InfoTooltip title={item?.tooltip}>
                        <Typography className={classes.title}>
                            {item.label}
                        </Typography>
                    </InfoTooltip>
                </Grid>
                } 
    </Grid>
    )
}

export default MapperItem