import { makeStyles } from "@material-ui/styles"
import React from "react"
// import { ReactComponent as AdminIcon } from "../../../Assets/icon-admin.svg"
// import { ReactComponent as EditIcon } from "../../../Assets/icon-pencil.svg"
// import { ReactComponent as EyeIcon } from "../../../Assets/icon-eye.svg"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    roleIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
        background: "#D9E1EE"
    },
    roleContainer: {
        padding: "15px 0 14px 0",
        display: "flex",
        "& .MuiTypography-root": {
            padding: 0,
            paddingLeft: 8,
            textTransform: "capitalize"
        },
    },
    editSvg: {
        width: 17
    },
    
}))

const UserRoleItem = ({ label, role }) => {
    const classes = useStyles()
    
    // const getIcon = () => {
    //     switch (role) {
    //         case 4:
    //             return <AdminIcon/>
    //         case 6:
    //             return <EditIcon className={classes.editSvg}/>
    //         case 7:
    //             return <EyeIcon/>
        
    //         default:
    //             return <AdminIcon/>
    //     }
    // }

    return (
        <div className={classes.roleContainer}>
            {/* <div className={classes.roleIcon}>
                {getIcon()}
            </div>  */}
            <Typography>{label}</Typography>
            
        </div>
        
    )

}

export default UserRoleItem