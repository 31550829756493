import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    controlsContainer: {
        gap: 18
    },
    tableTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "28px",
        paddingBottom: 16,
        "& span": {
            fontSize: 18,
            fontWeight: 300
        }
    },
    emailInputContainer: {
        display: "flex",
        maxWidth: "83%",
        flexBasis: "83%",
    },
    workingDayContainer: {
        maxWidth: "30%",
        flexBasis: "30%",
    },
    previewContainer: {
        maxWidth: "70%",
        flexBasis: "70%",
    },
    previewContent: {
        width: "100%",
        height: 600,
        boxShadow: "none",
        border: "none",
        borderRadius: 6
    },
    exampleContainer: {
        paddingTop: 12
    },
    exampleText: {
        color: "#6A6C71"
    },
    daysContainer: {
        width: "100%",
        gap: 12,
        "& .MuiFormControlLabel-root": {
            minWidth: 76,
            textTransform: "capitalize",
            "& .MuiFormControlLabel-label": {
                fontWeight: 600
            }
        }
    },
    timeContainer: {
        display: "flex",
        "&:nth-child(2)": {
            paddingTop: 12,
        },
        "& .MuiIconButton-root.Mui-disabled": {
            opacity: .5,
        },
    },
    timeSelect: {
        width: "100%",
        maxWidth: 110,
        height: 44,
        "& .MuiInputBase-root": {
            padding: "0 14px",
            border: "1px solid #c3c3c3",
            borderRadius: 6,
            "&.Mui-error": {
                borderColor: "red",
            },
            "&:after": {
                display: "none",
            },
        },
        "& input": {
            height: 30,
            width: "100%!important",
        },
    },
    timeCheckbox: {
        "& .MuiIconButton-root": {
            padding: "0 12px",
            borderRadius: "unset",
        },
        "& .MuiIconButton-label svg": {
            width: 26,
            height: 26,
            background: "#FFFFFF"
        },
        "& .MuiIconButton-label path": {
            fill: "#363ED3"
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: "#363ED3",
        },
    },
    nameContainer: {
        paddingTop: 24
    },
    nameInput: { 
        maxWidth: "fit-content"
    },
}));


export const timeSelectStyles = {
    control: (base,state) => ({
      ...base,
      border: "1px solid #c3c3c3",
      borderRadius: 5,
      background: state.isDisabled ? "unset" : base.background,
      opacity: state.isDisabled ? .5 : 1,
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: "none"
    }),
    clearIndicator: (styles) => ({
        ...styles,
    }),
    valueContainer : (styles) =>({
        ...styles,
        fontSize: 16,
        fontFamily: "Roboto",
    }),
    menuList: (base, state) => ({
        ...base,
        fontSize: 16,
        fontFamily: "Roboto",
    }),
};

export default useStyles