import ApiService from "../../global/service";

export default class UserManagmentService {
    _getUsers = (requestHandler, errorHandler, query = "") => ApiService.get(`/v1/users${query?.length !== 0 ? "?"+query.join("&") : ''}`, {}, requestHandler, errorHandler, process.env.REACT_APP_AUTH_API_URL)

    _getUsersRoles = (requestHandler, errorHandler) => ApiService.get("/v1/users/roles?name:like=platform", {}, requestHandler, errorHandler, process.env.REACT_APP_AUTH_API_URL)

    _postCreateUser = (data, requestHandler, errorHandler) => ApiService.post("/v1/users", {}, requestHandler, errorHandler, data, process.env.REACT_APP_AUTH_API_URL)

    _postAssignUserRole = (user_id, role_id, requestHandler, errorHandler) => ApiService.post(`/v1/users/${user_id}/roles/${role_id}`, {}, requestHandler, errorHandler, {}, process.env.REACT_APP_AUTH_API_URL)

    _patchEditUser = (id, data, requestHandler, errorHandler) => ApiService.patch(`/v1/users/${id}`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_AUTH_API_URL)

    _putCreateCompany = (data, requestHandler, errorHandler) => ApiService.put("/v1/users/companies", {}, requestHandler, errorHandler, data, process.env.REACT_APP_AUTH_API_URL)

    _deleteUser = (id, requestHandler, errorHandler) => ApiService.delete(`/v1/users/${id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_AUTH_API_URL)

    _deleteRemoveUserRole = (user_id, role_id, requestHandler, errorHandler) => ApiService.delete(`/v1/users/${user_id}/roles/${role_id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_AUTH_API_URL)
}