import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    ChartCointainer: {
      borderRadius: 6,
      border: "solid 1px #ecf1f9",
      backgroundColor: "#fff",
      marginBottom: 10,
      "&:nth-child(2n)": {
        marginLeft: 10,
      }
    }
}));

export default useStyles;