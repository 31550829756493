import { Box, Button } from "@material-ui/core";
import { Grid, Modal, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles"
import React, { useState, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormInput from "../FormInput";
import UserRoleSelector from "../../../Pages/UserManagement/UserRoleSelector/UserRoleSelector";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    p: '30px 50px',
    width: '100%',
    maxWidth: 800,
    borderRadius: 6,
    boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
};

const useStyles = makeStyles(() => ({
    cancelButton: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        background: "#fff",
        color: "#363ED3",
        border: "1px solid #363ED3",
        borderRadius: 8,
        padding: "14px 54px",
        margin: "0px 30px",
        textTransform: "none",
    },
    confirmButton: {
        color: "#FFF",
        background: "#363ED3",
        borderRadius: 8,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 54px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#363ED3",
            opacity: 0.85
        }
    },
    confirmOptions: {
        paddingTop: 24,
        justifyContent: "center"
    },
    createTitle: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        paddingBottom: 6,
        paddingTop: 12,
        textTransform: "capitalize",
        color: "#1B1C1E"
    },
    contributionContainer: {
        borderRadius: 6,
        border: "1px solid #D9E1EE",
        paddingLeft: 16,
        paddingBottom: 16
    },
    formTitle: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "22px"
    },
}))

const CreateEditUser = ({ onSubmit, onEditUser, setUserData, open, setOpen, disabled, roles, userData, isEdit }) => {
    const classes = useStyles()
    const formRef = useRef(null)

    const handleChangeUserData = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ValidatorForm ref={formRef} onSubmit={
                    () => isEdit ? onEditUser(userData) : onSubmit(userData)
                }>
                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Grid className={classes.formTitle}>{isEdit ? "Edit" : "Add new"} user:</Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography className={classes.createTitle}>Name</Typography>
                                <FormInput
                                    type="name"
                                    name="name"
                                    value={userData.name}
                                    onChange={handleChangeUserData}
                                    autoFocus
                                    data-testid="name" />
                            </Grid>
                            <Grid item style={isEdit ? {display: "none"} : {display: "block"}}>
                                <Typography className={classes.createTitle}>E-mail</Typography>
                                <FormInput
                                    type="email"
                                    name="email"
                                    value={userData.email}
                                    disabled={isEdit}
                                    onChange={handleChangeUserData}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['this field is required', 'Please enter a valid email address']}
                                    required
                                    autoFocus
                                    data-testid="email" />
                            </Grid>
                            {!isEdit && <Grid item>
                                <Typography className={classes.createTitle}>Password</Typography>
                                <FormInput
                                    showText={false}
                                    name="password"
                                    value={userData.password}
                                    onChange={handleChangeUserData}
                                    validators={['required', 'matchRegexp:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*[!@#$%^&*?():;}{~`]).{8,}']}
                                    errorMessages={['this field is required', 'Must be 8 or more characters, consist only numbers and letters and contain both upper and lower case letters']}
                                    required
                                    data-testid="passwordInput" />
                            </Grid>}
                            <Grid item>
                                <Typography className={classes.createTitle}>Phone</Typography>
                                <FormInput
                                    type="phone"
                                    name="phone"
                                    value={userData.phone}
                                    onChange={handleChangeUserData}
                                    validators={['required', 'matchRegexp:[0-9]{11,12}$']}
                                    errorMessages={['this field is required', 'Please enter a valid phone number with country code']}
                                    required
                                    autoFocus
                                    data-testid="phone" />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.createTitle}>Title</Typography>
                                <FormInput
                                    type="title"
                                    name="title"
                                    value={userData.title}
                                    onChange={handleChangeUserData}
                                    validators={['matchRegexp:^[a-zA-Z].{2,}']}
                                    errorMessages={['Please enter a valid name']}
                                    data-testid="titleInput" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item style={{display: "none"}}>
                                <Typography className={classes.createTitle}>Company</Typography>
                                <FormInput
                                    type="company"
                                    name="company"
                                    value={userData.company}
                                    disabled={isEdit}
                                    onChange={handleChangeUserData}
                                    validators={['required', 'matchRegexp:^[a-zA-Z0-9].{1,}']}
                                    errorMessages={['this field is required', 'Please enter a valid company name']}
                                    data-testid="companyInput" />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.createTitle}>Role</Typography>
                                <Grid container className={classes.contributionContainer}>
                                    <UserRoleSelector onChange={handleChangeUserData} roles={roles} defaultValue={userData.roleIds[0]} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className={classes.confirmOptions} container>
                                <Button className={classes.cancelButton} onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.confirmButton}
                                    data-testid="loginButton"
                                    disabled={disabled}
                                >
                                    Done
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Box>
        </Modal>
    )
}

export default CreateEditUser