import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    formRoot: {
      maxWidth: 416,
    },
    formContainer: {
      flexDirection: "column",
      paddingTop: 30,
      paddingBottom: 40
    },
    formInput: {
      height: 52
    },
    formTitle: {
        fontSize: 36,
        fontWeight: 600,
        lineHeight: "40px",
        paddingBottom: 24
    },
    formText: {
        fontSize: 16,
        lineHeight: "24px"
    },
    inputTitle: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      paddingBottom: 6,
      paddingTop: 12,
      textTransform: "capitalize",
      color: "#1B1C1E"
    },
    confirmOptions: {
        gap: 32,
        paddingBottom: 30,
        justifyContent: "center"
    },
    genderInput: {
        paddingTop: 24,
        gap: 8,
    },
    loadedAlertContainer:{
      width: "100%",
      "& .MuiTypography-root": {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left"
      },
      "& .MuiAlertTitle-root": {
        textAlign: "left!important"
      }
    },
}))

export default useStyles
