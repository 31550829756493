import React, { useRef, useState } from "react"
import useStyles from "./styles"
import { UIViewButton, cancelButtonStyles, basicButtonStyles } from "../../ui/buttons"
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { Grid, Typography, styled } from "@material-ui/core";
import FormInput from "../FormInput";
import { ValidatorForm } from "react-material-ui-form-validator";

const GenderButton = styled(ToggleButton)({
    "&.MuiButtonBase-root": {
        ...basicButtonStyles,
        ...cancelButtonStyles,
        width: 204,
        height: 40,
    },
    "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
        borderLeft: "1px solid #363ED3",
        borderRadius: "8px!important",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
        borderLeft: "1px solid #363ED3!important",
        borderRadius: "8px!important",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#FFFFFF!important",
      backgroundColor: '#363ED3!important'
    }
})

const SegmentationForm = ({ onSubmit }) => {
    const classes = useStyles()
    const formRef = useRef()
    const [ data, setData ] = useState({
        age: "",
        zipcode: ""
    })

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertSeverity, setAlertSeverity] = useState('')
    
    const handleSubmit = (e) => {
        onSubmit(data, handleSuccess, handleError)
    }
        
    const handleError = (errorCode) => {
        if (errorCode === 404) {
            setAlertMessage("Zip code not found")
        } else {
            setAlertMessage("Server error")
        }
        setAlertTitle("Error")
        setAlertSeverity("error")
        setShowAlert(true)
    }
        
    const handleSuccess = () => {
        setAlertMessage("")
        setAlertTitle("")
        setAlertSeverity("")
        setShowAlert(false)
    }

    const handleChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Grid className={classes.formRoot}>
            <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                        <Grid container direction="row" alignItems="flex-start">

                            <Grid item container className={classes.formTitle}>Recommendation Engine</Grid>
                            <Grid item container className={classes.formText}>
                                Please provide the following information for the calculation:
                            </Grid>
                                                        
                            <Grid item container className={classes.formContainer}>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Age *</Typography>
                                    <FormInput
                                        type="age"
                                        name="age"
                                        placeholder=""
                                        validators={['required']}
                                        errorMessages={['Age is required']}
                                        value={data.age}
                                        onChange={handleChangeData}
                                        className={classes.formInput}
                                        autoFocus
                                        data-testid="age" />
                                </Grid>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Zipcode *</Typography>
                                    <FormInput
                                        type="zipcode"
                                        name="zipcode"
                                        placeholder=""
                                        validators={['required']}
                                        errorMessages={['Zipcode is required']}
                                        value={data.zipcode}
                                        onChange={handleChangeData}
                                        autoFocus
                                        data-testid="zipcode" />
                                </Grid>
                                <Grid item container justifyContent="center" style={{ display: "none" }}>
                                    <ToggleButtonGroup
                                    className={classes.genderInput}
                                    //value={data.gender}
                                    exclusive
                                    onChange={handleChangeData}
                                    aria-label="gender"
                                    >
                                        <GenderButton value="M" name="gender">Male</GenderButton>
                                        <GenderButton value="F" name="gender">Female</GenderButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                          

                            <Grid container className={classes.confirmOptions}>
                                <UIViewButton type="submit" style={{ maxWidth: "unset", height: 52 }}>
                                    Calculate
                                </UIViewButton>
                            </Grid>
                            
                            <Grid item container justifyContent="center">
                                {
                                    (showAlert && alertSeverity === "error") && 
                                    <Alert className={classes.loadedAlertContainer} severity={alertSeverity}>
                                        <AlertTitle>{alertTitle}</AlertTitle>
                                        {alertMessage}
                                    </Alert>
                                }
                            </Grid>  
                        </Grid>
                </ValidatorForm>
        </Grid>
    )
}

export default SegmentationForm