import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Magic from "../../Components/Magic/Magic";
import MapperComponent from "../../Components/Mappers/MapperComponent";

// Redux
import { setLapseID, setMenuDisabled, setUiID } from "../../AppSlice";
import { resetValues, selectMapperOptions, selectSourceId, selectZipCheck, setMapperError, setProjectId, setRunIds, setSelectSourceMode } from "../NewProject/NewProjectSlice";

// Common
import newProjectApiService from "../NewProject/NewProjectService";
import { User } from "../../global/user";
import MapperComponentBroker from "../../Components/Mappers/MapperComponentBroker";

const ApiService = new newProjectApiService()

const Mapper = () => {
    const dispatch = useDispatch()
    const containerRef = useRef(null)
    const [ demoNextClicked, setDemoNextClicked ] = useState(false)
    const sourceId = useSelector(selectSourceId)
    const mapperOptions = useSelector(selectMapperOptions)
    const zipCheck = useSelector(selectZipCheck)
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;

    const handleExecute = (enableNextButton) => {     
        ApiService._postProjectMapper(sourceId, {
          model_type: "lapse",
          mapper: mapperOptions,
          zip: zipCheck ? "True" : "False"
          }, (res) => {  
          },
          (error) => {
            console.log(error);
            dispatch(setMapperError({present: true, message: ""}))
            dispatch(resetValues())
            enableNextButton()
            return;
          }
        )  
        !isBroker && ApiService._postProjectMapper(sourceId, {
          model_type: "ui",
          mapper: mapperOptions,
          zip: zipCheck ? "True" : "False"
          }, () => { 
            dispatch(setMapperError({present: false, message: ""})) 
          },
          (error) => {
            console.log(error);
            dispatch(setMapperError({present: true, message: ""}))
            dispatch(resetValues())
            enableNextButton()
            return;
          }
        )
        const date = new Date()
        ApiService._postNewProject({
          project_name: date.toISOString().split("T")[0] + "-" + date.getTime().toString().substring(7),
          source_id: sourceId.toString(),
          model_type: "lapse"      
        },
        (response) => {
          sessionStorage.setItem("current_id", response.data["project_id"])
          dispatch(setProjectId(response.data["project_id"]));
          const payloads = [
            {
              extra_parameters: {
                predict_date: `${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${date.getFullYear()}`
              },
              model_type: isBroker ? "lapsev2" :  "lapse",
              run_name: crypto.randomUUID().split("-")[0],
              period_months: 3,
              product_type: "Whole Life",
              train_model: false
            }
          ]
          !isBroker && payloads.push(
            {
              extra_parameters: {
                predict_date: `${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${date.getFullYear()}`
              },
              model_type: "ui",
              run_name: crypto.randomUUID().split("-")[0],
              period_months: 3,
              product_type: "Whole Life",
              train_model: false
            })
          ApiService._postCreateAllRuns(
            {
              project_id: parseInt(sessionStorage.getItem("current_id")),
              runs: payloads
            },
            (response) => {
              sessionStorage.removeItem("atidot_platform_data")
              if (response.data["failed"]?.length === 0) {
                const runs = response.data["runs"]?.map((run) => {
                  ["lapse", "lapsev2"].includes(run.model_type) && dispatch(setLapseID(run.run_id));
                  run.model_type === "ui" && dispatch(setUiID(run.run_id));
                  return { [run.model_type]: run.run_id }
                })
                dispatch(setRunIds(runs));
                dispatch(setSelectSourceMode(false));
                dispatch(setProjectId(''));
                dispatch(setMenuDisabled(false))
                setDemoNextClicked(true)
                enableNextButton()
              } else {
                dispatch(setMapperError({present: true, message: ""}))
                dispatch(resetValues())
                enableNextButton()
              }
            },
            (error) => {
              console.log(error)
              dispatch(setMapperError({present: true, message: ""}))
              dispatch(resetValues())
              enableNextButton()
            }
          )
          }, (error) => {
            dispatch(setMapperError({present: true, message: ""}))
            console.log(error)
        })   

    }

    return(
        <div ref={containerRef}>
            {
              demoNextClicked 
              ? <Magic displayTime={2500} blinkInterval={1500} demo={false}/>
              : isBroker ? <MapperComponentBroker outSideRef={containerRef} onExecute={handleExecute} demo={false}/> : <MapperComponent outSideRef={containerRef} onExecute={handleExecute} demo={false}/>
            }
        </div>
    )
}

export default Mapper