import React, { useEffect, useState } from "react";

import DashboardComponent from "../../Components/Dashboard/Dashboard";
import { User } from "../../global/user";
import DashboardBrokerComponent from "../../Components/Dashboard/DashboardBroker";
import DashboardService from "./DashboardService";
import BookAnalysisService from "../BookAnalysis/BookAnalysisService";
import { Dialog, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@mui/material";
import { selectRunError, setRunProgressError } from "../../AppSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ErrorIcon } from "../../Assets/icon-stop-sign.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icon-close-alt.svg";

const ApiService = new DashboardService();
const BookApiService = new BookAnalysisService();

export const useStyles = makeStyles(() => ({
    alert: {
        "&.MuiPaper-root": {
            background: "#FFE5E8",
            padding: "16px",
            width: 513,
            "& .MuiAlert-message": {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "21px",
                color: "#000000",
                "& a": {
                    color: "#000000",
                    textDecoration: "underline",
                    cursor: "pointer",
                },
            },
            "& .MuiAlert-icon": {
                marginRight: 8
            },
            "& .MuiAlertTitle-root": {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "16px",
                color: "#11151F",
            }
        },
    },
    closeContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        height: 0
    },
    close: {
        position: "relative",
        width: 24,
        height: 24,
        top: 16,
        right: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        background: "rgba(255, 255, 255, 0.35)",
        cursor: "pointer",
        "& svg": {
            width: 9,
            height: 9,
        },
        "& path": {
            fill: "#445275",
        },
    },
}));

const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [data, setData] = useState();
  const [termAgeData, setTermAgeData] = useState(null);
  const [ tasks, setTasks ] = useState()
  const runError = useSelector(selectRunError)
  const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnProd

  const resetRecommdations = () => {
    ApiService._getSuggested((response) => {
        const states = []
        response.data?.policies?.forEach(({ state }) => {
            if (state && !states.includes(state)) {
                states.push(state)
            }
         })
        setTasks({ 
            ...response.data,
            unique_states: states
        })
    }, () => {})
  }
  
  const handleUpdatePolicy = (id, value, callback = () => {}) => {
    if (id) {
        ApiService._patchRecommendation(id, { processed_by_agent: value }, () => {
            callback()
        }, () => {})
    }
  }

  useEffect(() => {
    if (isBroker) {
        resetRecommdations()
        if (!data) {
            BookApiService._getPolicies(false, (response) => {
                setData(response.data)
            }, (error) => console.log(error))
            ApiService._getTermAgeBank((response) => {
                setTermAgeData(response.data)
            }, (error) => {
                setTermAgeData({
                    policies: {}
                })
                console.log(error)
            })
        }
    } else if (!data) {
       ApiService._getDashboardData((response) => {
           setData(response.data)
       })
  }
   }, [data])

   useEffect(() => {
     if (runError) {
        setTermAgeData({
            policies: {}
        })
     }
    }, [runError])

   const refreshAll = () => {
        ApiService._refreshAllDashboardsData((response) => {
            alert(response.data.message)
        })
   }

   return (
   <>
    {runError && <Dialog open onClose={() => dispatch(setRunProgressError(""))}>
        <div className={classes.closeContainer}>
            <div className={classes.close} onClick={() => dispatch(setRunProgressError(""))}>
                <CloseIcon />
            </div>
        </div>
        <Alert
            className={classes.alert}
            severity="error"
            iconMapping={{
                error: <ErrorIcon fontSize="inherit" />,
            }}
        >
            <AlertTitle>Run Request Unsuccessful:</AlertTitle>
            We encountered a problem processing your data. Please try uploading<br/>again or contact <a href="mailto:info@atidot.com">info@atidot.com</a> for assistance
        </Alert>
    </Dialog> }  
    <div style={runError ? { opacity: .75 } : {}}>
    {
    isBroker
    ? <DashboardBrokerComponent data={data} tasks={tasks} termAgeData={termAgeData} onPolicyUpdate={handleUpdatePolicy} onResetTasks={resetRecommdations}/>
    : <DashboardComponent data={data} refreshAll={refreshAll}/>
    }
    </div>
   </>
   )
}

export default Dashboard