import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { ClearButton } from "../../ui/buttons"
import { useStyles } from "./styles";
import clsx from "clsx";
import { ReactComponent as SettingIcon } from '../../../Assets/icon-preference.svg';

const FiltersContianer = ({ title = "", size = "xs", children, onReset = null, openSettings = null }) => {
    const classes = useStyles()

    return (
        <Grid 
            container 
            direction="column" 
            className={clsx(
                classes.tableTitle,
                `table-title--${size}`
            )}
        >
            {/* Filter title */}
            <Grid container className={classes.bgDark} justifyContent="space-between" alignItems="center" style={{ paddingRight: 30 }}>
                <Typography>{title}</Typography>    
                { onReset !== null &&
                <ClearButton variant="text" onClick={onReset}>
                    Clear filters
                </ClearButton>
                }

                { openSettings !== null && <SettingIcon onClick={openSettings}/> }
            </Grid>

            {/* Filter container */}
            {children}

        </Grid>
    ) 
}

export default FiltersContianer