import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";


import Container from '@mui/material/Container';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    "text-align": "center"
  },
  textCenter: { 
    "text-align": "center",
    "padding": "2em"
  },
  textBold: { "font-weight": "bold"},
  mainText: { "padding": "11px 0px"},
  borderedText: {
    "border": "solid 2px",
    "width": "100%",
    "display": "block",
    "text-align": "center",
    "border-radius": "6px",
    "line-height": "3em",
    "margin": "11px 0px"
  }
}))

function createData(name, description, examples) {
  return { name, description, examples};
}

const rows = [
  createData("Snapshot Date", "The date the data sample was taken in mm/dd/yyyy format", "1/29/23"),
  createData("Policy ID ", "Anonymized unique identification number ", "123fff456"),
  createData("Birthdate", "Common date format or number", "1/1/70"),
  createData("Gender", "Female, Male, Other, N/A", "Male"),
  createData("Zip code", "5-digit number or 9-digit number", "10019, 00501-0002"),
  createData("Issue Date", "Policy issue date", "8/29/03"),
  createData("Annual Premium ", "Number", "$5,000 "),
  createData("Product Type", "Text", "Term 10, IUL, GUL"),
  createData("Face Amount", "Number", "$500,000 "),
  createData("Term period ", "5,10,20,30 (for term products)", "20"),
  createData("Cash Value ", "For permanent products", "$555,555 "),
];


export const SampleInfo = () => {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Container maxWidth="md">
      <Button variant="text" onClick={history.goBack}><ArrowBackIcon/> Back</Button>
        <Typography variant="h4" className={classes.title}> Upload instructions page </Typography>
        <div className={classes.textCenter}>
          This flow is designed to present you with Atidot AI predictive capabilities to identify potential
           lapsers and underinsured policyholders and help optimize the value of your existing policyholders.
        </div>
        <div className={classes.mainText}>
          <div className={classes.textBold}>HOW DOES IT WORK</div>
          Please upload a sample of your existing book of business (anonymized). <br />
          Atidot will identify actionable insights on how to increase your book profitability.* <br /><br />
          The total size of the uploaded file must be <b>under 10 MB</b> and <b>couldn’t include more than 10K records.</b> <br />
          The file must be a CSV and have the extension “<b>.csv.</b>” <br />
          Compressed files such as zip and tar files are not supported. <br />
          <span className={classes.borderedText}>
            You can download the <a href="sample_file.csv" download="sample_file.csv">example file here</a>. <br />
          </span>
          Please use the exact column headers as defined in the table below. <br />
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Column name</TableCell>
                <TableCell align="left">Description/definition</TableCell>
                <TableCell>Examples</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="left">{row.examples}</TableCell>
                </TableRow>
              ))}
              <TableRow><TableCell><b>Optional Fields</b></TableCell></TableRow>
              <TableRow>
                <TableCell>Payment Interval</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Quarterly, Paidup</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payment Interval</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Quarterly, Paidup</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Distribution Channel</TableCell>
                <TableCell>Distribution channel type: Brokers, DTC, Banks</TableCell>
                <TableCell>DTC</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Agency ID</TableCell>
                <TableCell>Distribution Channel name or ID</TableCell>
                <TableCell>B456</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Term Period</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>20</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Loan value</TableCell>
                <TableCell>For permanent products</TableCell>
                <TableCell>$45,000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.mainText}>
        * It is important to note that for this basic analysis, Atidot uses an ‘out of the box’ model,
         which was trained on general industry data and includes general Atidot know-how. We suggest 
         contacting us and discussing the options to customize the modes to your specific data and 
         substantially increase the accuracy.
        </div>
    </Container>

  )
}


export default SampleInfo