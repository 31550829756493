import { createSlice } from "@reduxjs/toolkit";
import { usStatesData } from "../../global/constants";

const initialState = {
    count: 0,
    total_policies: 0,
    projectName: '',
    runName: '',
    model: '',
    date: '',
    runId: '',
    policies: 0,
    policiesActual: 0,
    policiesFiltered: 0,
    policiesIndustry: 0,
    underinsured: 0,
    potential: 0,
    impact: 0,
    potentialLapsers: 0,
    lapseRate: 0,
    potentialPremium: 0,
    premium: 0,
    loaded: false,
    isDataLoaded: null,
    headerHeigh: 250,
    faceAmountRanges: null,
    duration: '',
    cashValue: 0,
    behavioralClusterValues: null,
    productValues: null,
    marketLagValues: null,
    statusValues: null,
    stateValues: null,
    durationValues: null,
    productType: '',
    zeroValuesByGender: [],
    hitRate: null,
    avg_suggested_premium: 0,
    max_suggested_premium: 0,
    filtersConfig: {
    },
    hasLeads: false,
    precisionParams: {
        recall: {},
        lapseRateImprovement: {},
    },
    precision_metadata: null,
    effectiveDate: ''
}
const name = 'run'

export const RunSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setRunMetaData: (state, action) => {
            state.count = action.payload["initial_prospects_count"]
            state.total_policies = action.payload["total_policies"]
            state.underinsured = action.payload["count"]
            state.potentialPremium = action.payload["potential_premium_retained"]
            state.impact = action.payload["potential_impact"]
            state.lapseRate = action.payload["upsell_potential"]
            state.hitRate = action.payload["hit_rate"]
            state.precision_metadata = action.payload["precision_metadata"]
           
            // set dynamic filter values
            state.marketLagValues = action.payload["unique_product_names"]
            state.behavioralClusterValues = action.payload["unique_stories"]
            state.productValues = action.payload["unique_product_types"]
            state.statusValues = action.payload["policy_statuses"]
            state.durationValues = action.payload["duration_segments"]
            state.stateValues = action.payload["unique_states"]
                ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)

        },
        setRunMetaDataBroker: (state, action) => {
            // set dynamic filter values
            state.marketLagValues = action.payload["unique_product_names"]
            state.behavioralClusterValues = action.payload["unique_stories"]
            state.productValues = action.payload["unique_product_types"]
            state.statusValues = action.payload["policy_statuses"]
            state.durationValues = action.payload["duration_segments"]
            state.stateValues = action.payload["unique_states"]
                ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)

        },
        setPoliciesNumber: (state, action) => {
            state.total_policies = action.payload
        },
        setTotal: (state, action) => {
            state.count = action.payload
        },
        setCashValue: (state, action) => {
            state.cashValue = action.payload
        },
        setDuration: (state, action) => {
            state.duration = action.payload
        },
        setProjectName: (state, action) => {
            state.projectName = action.payload
        },
        setModel: (state, action) => {
            state.model = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setRunId: (state, action) => {
            state.runId = action.payload
        },
        setRunName: (state, action) => {
            state.runName = action.payload
        },
        setPolicies: (state, action) => {
            state.policies = action.payload
        },
        setPoliciesFiltered: (state, action) => {
            state.policiesFiltered = action.payload
        },
        setPoliciesActual: (state, action) => {
            state.policiesActual = action.payload
        },
        setPoliciesIdustry: (state, action) => {
            state.policiesIndustry = action.payload
        },
        setPremium: (state, action) => {
            state.premium = action.payload
        },
        setUnderinsured: (state, action) => {
            state.underinsured = action.payload
        },
        setPotential: (state, action) => {
            state.potential = action.payload
        },
        setImpact: (state, action) => {
            state.impact = action.payload
        },
        setPotentialLapsers: (state, action) => {
            state.potentialLapsers = action.payload
        },
        setPotentialPremium: (state, action) => {
            state.potentialPremium = action.payload
        },
        setLapseRate: (state, action) => {
            state.lapseRate = action.payload
        },
        setLoaded: (state, action) => {
            state.loaded = action.payload
        },
        setIsDataLoaded: (state, action) => {
            state.isDataLoaded = action.payload
        },
        setHeaderHeight: (state, action) => {
            state.headerHeigh = action.payload
        },
        setFaceAmountRanges: (state, action) => {
            state.faceAmountRanges = action.payload
        },
        setBehavioralClusterValues: (state, action) => {
            state.behavioralClusterValues = action.payload
        },
        setProductValues: (state, action) => {
            state.productValues = action.payload
        },
        setMarketLag: (state, action) => {
            state.marketLagValues = action.payload
        },
        setStatusValues: (state, action) => {
            state.statusValues = action.payload
        },
        setStateValues: (state, action) => {
            state.stateValues = action.payload
        },
        setDurationValues: (state, action) => {
            state.durationValues = action.payload
        },
        setProductType: (state, action) => {
            state.productType = action.payload
        },
        setZeroValuesByGender: (state, action) => {
            state.zeroValuesByGender = action.payload
        },
        setFiltersConfig: (state, action) => {
            state.filtersConfig = action.payload
        },
        setHasLeads: (state, action) => {
            state.hasLeads = action.payload
        },
        setHitRate: (state, action) => {
            state.hitRate = action.payload
        },
        setAvg: (state, action) => {
            state.avg_suggested_premium = action.payload
        },
        setMaxSuggested: (state, action) => {
            state.max_suggested_premium = action.payload
        },
        setPrecisionParams: (state, action) => {
            state.precisionParams = {
                recall: action.payload["recall"],
                lapseRateImprovement: action.payload["lapse_rate_improvement"]
            }
        },
        setPrecisionMeta: (state, action) => {
            state.precision_metadata = action.payload
        },
        setEffectiveDate: (state, action) => {
            state.effectiveDate = action.payload
        },
        resetRun: (state) => {
            state.projectName = ''
            state.model = ''
            state.date = ''
            state.runId = ''
            state.runName = ''
            state.policies = 0
            state.underinsured = 0
            state.potential = 0
            state.impact = 0
            state.lapseRate = 0
            state.loaded = false
            state.policiesActual = 0
            state.count = 0
            state.avg_suggested_premium = 0
            state.max_suggested_premium = 0
        },
        resetHeaders: (state) => {
            state.policiesFiltered = 0
            state.potentialPremium = 0
            state.cashValue = 0
        }

    }
})

export const { 
    setRunMetaData,
    setRunMetaDataBroker,
    setTotal,
    setPoliciesNumber,
    setProjectName,
    setModel, 
    setDate, 
    setRunId, 
    setRunName, 
    setPolicies, 
    setUnderinsured, 
    setPotential, 
    setImpact,
    setPotentialLapsers, 
    setPotentialPremium, 
    setPoliciesFiltered, 
    setPoliciesActual,
    setPoliciesIdustry,
    setPremium,
    setLapseRate,
    setLoaded, 
    setIsDataLoaded, 
    setHeaderHeight, 
    setFaceAmountRanges, 
    setBehavioralClusterValues,
    setProductValues,
    setMarketLag,
    setProductType, 
    setZeroValuesByGender,
    setFiltersConfig,
    setHasLeads, 
    setDuration,
    setCashValue,
    setHitRate,
    setPrecisionParams,
    setPrecisionMeta,
    setEffectiveDate,
    setStatusValues,
    setStateValues,
    setDurationValues,
    setAvg,
    setMaxSuggested,
    resetRun,
    resetHeaders
} = RunSlice.actions


export const selectRun = (state) => state.run
export const selectModel = (state) => state.run.model
export const selectRunId = (state) => state.run.runId
export const selectHeaderHeight = (state) => state.run.headerHeigh
export const selectFaceAmountRanges = (state) => state.run.faceAmountRanges
export const selectBehavioralClusterValues = (state) => state.run.behavioralClusterValues
export const selectProductValues = (state) => state.run.productValues
export const selectMarketLag = (state) => state.run.marketLagValues
export const selectStatusValues = (state) => state.run.statusValues
export const selectStateValues = (state) => state.run.stateValues
export const selectDurationValues = (state) => state.run.durationValues
export const selectProductType = (state) => state.run.productType
export const selectFiltersConfig = (state) => state.run.filtersConfig
export const selectPrecisionParamss = (state) => state.run.precisionParams





export default RunSlice.reducer