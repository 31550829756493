import { Button, Grid, MenuItem, Popover, Typography } from "@material-ui/core"
import { Select } from "@mui/material"
import React, { useState, useRef } from "react"
import PoliciesTable from "../../Tables/BookTablePagination/PoliciesTable/PoliciesTable"
import StoryIcon from "../../Book/StoryIcon/StoryIcon"
import PriorityIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator"
import BookTablePagination from "../../Tables/BookTablePagination/BookTablePagination"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useStyles } from "./styles"
import { useSelector } from "react-redux"
import { selectUserSubscriptions } from "../../../AppSlice"
import { User } from "../../../global/user"

const TableContainer = ({
    editable,
    data,
    page,
    headers,
    sort,
    handleSort,
    handleChangePage,
    handleChangeRows,
    handleSelectPolicy,
    handleExportAll,
    handleExport,
    loaded,
    divided,
    divider,
    renderRow,
    frozenColumns,
    frozenPositions,
    hiddenColumns = [],
    rowsPerPage,
    filters = null,
    exportOptions = [], 
    paginated = false, 
}) => {
    const classes = useStyles()
    const exportRef = useRef()
    const [openExportOptions, setOpenExportOptions] = useState(false)
    const subscriptions = useSelector(selectUserSubscriptions)

    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const isFreeSubcriptionPlan = isBroker && !subscriptions.find(({ name }) => name === "basic")

    return (
        <Grid container>
            <PoliciesTable
                data={paginated ? data?.prospects?.slice(rowsPerPage*page, (page*rowsPerPage)+rowsPerPage) : data?.prospects } 
                headers={headers}
                sort={sort}
                onSort={isFreeSubcriptionPlan ? () => {} : handleSort}
                renderRow={renderRow}
                loaded={loaded}
                columnsToHide={["model_type", "lapse", ...hiddenColumns]}
                page={page} 
                onRowClick={(row) => { handleSelectPolicy(row[row["model_type"] === "cluster" ? "ID" : "policy"], row["id"], row["story_to_show"] ? row["story_to_show"] : "upsell"); }}
                editable={editable && !isFreeSubcriptionPlan}
                divided={divided}
                divideIndex={divider}
                frozenColumns={frozenColumns}
                frozenPositions={frozenPositions}
            />

            <Grid container  justifyContent="space-between" alignItems="center">
                <div className={classes.gridItem}>
                    <Select value={rowsPerPage} className={classes.showOnPageSelect} prefix="Show on page" onChange={(e) => handleChangeRows(e.target.value)}
                    style={{width: '76px'}}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    {!isFreeSubcriptionPlan && <div className={classes.exportToCSVButtonContainer} ref={exportRef}>
                      <Button
                        variant="outlined"
                        disableElevation={true}
                        disableFocusRipple={true}
                        className={classes.exportToCSVButton}
                        endIcon={<ArrowRightAltIcon className={classes.exportToCSVIcon} />}
                        onClick={() => setOpenExportOptions(true)}
                      >
                        Export to CSV
                      </Button>
                    </div>}
                </div>
            {filters && <Grid item className={classes.typeFilterContainer} alignItems="center">
                {
                (!filters.storyType || filters.storyType.includes("lapse")) 
                &&
                <div className={classes.modelIndicator} 
                >
                    <StoryIcon storyType="lapse"/>
                    <Typography style={{ marginRight: 24 }}>Retention</Typography>
                </div>
                }
                {
                (!filters.storyType || filters.storyType.includes("ui"))
                &&
                <div className={classes.modelIndicator} 
                >
                    <StoryIcon storyType="ui"/>
                    <Typography style={{ marginRight: 24 }}>New Business</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="high"/>
                    <Typography style={{ marginRight: 24 }}>High</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="mid"/>
                    <Typography style={{ marginRight: 24 }}>Medium</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="low"/>
                    <Typography>Maintained</Typography>
                </div>
                }
            </Grid>}
            <Grid item>
            {!isFreeSubcriptionPlan && data?.prospects?.length > 0 && <BookTablePagination
                component="div"
                count={data.count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}
            />
            }
            </Grid>
            </Grid>
            <Popover
            anchorEl={exportRef.current}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={openExportOptions && !isFreeSubcriptionPlan}
            onClose={() => setOpenExportOptions(false)}>
                {exportOptions.length === 0 ? <Grid container className={classes.actionsPopoverContainer}>
                    <Grid item className={classes.actionButtonContainer} onClick={handleExportAll}>
                        <Button className={classes.actionButton}>
                            Export All
                        </Button>
                    </Grid>
                    <Grid item className={classes.actionButtonContainer} onClick={handleExport}>
                        <Button disabled={!data.count} className={classes.actionButton}>
                            Export Filtered
                        </Button>
                    </Grid>
                </Grid> : 
                <Grid container className={classes.actionsPopoverContainerXL}>
                    {
                        exportOptions?.map(({ run_id, name, model }) => (
                            <Grid item className={classes.actionButtonContainer} onClick={() => handleExport(run_id, model, name)}>
                                <Button className={classes.actionButton}>
                                    Export {name}
                                </Button>
                            </Grid>
                        ))
                    }
                </Grid>              
                }
            </Popover> 
    </Grid>
    )
}

export default TableContainer