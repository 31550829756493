import { Tooltip, styled } from "@material-ui/core";
import { tooltipClasses } from "@mui/material";


export const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow/>
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontFamily: "Roboto!important",
      fontSize: 14,
      fontWeight: 400,
      color: "#FFFFFF",
      background: "#0F1828",
      textAlign: "left",
      borderRadius: 6,
      border: "none",
      boxShadow: "none",
      opacity: 1,
      padding: 12
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#0F1828",
      opacity: 1
    },
}));