import React from 'react';

// MUI
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  
  completedAlert: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#def9e6 !important",
    color: "#00b733 !important",
    fontSize: 13,
    borderRadius: 6,
    width: 105,
    height: 30
  },
  runningAlert: {
    justifyContent: "center",
    alignItems: "center",
    color: "#dd9c00 !important",
    backgroundColor: "#fff7c5 !important",
    fontSize: 13,
    borderRadius: 6,
    width: 105,
    height: 30
  },
  warningAlert: {
    justifyContent: "center",
    alignItems: "center",
    color: "#dd9c00 !important",
    backgroundColor: "#fff7c5 !important",
    fontSize: 13,
    height: 30,
    overflow: "hidden"
  },
  errorAlert: {
    justifyContent: "center",
    alignItems: "center",
    color: "#dd0000 !important",
    backgroundColor: "#ffdada !important",
    borderRadius: 6,
    fontSize: 13,
    width: 105,
    height: 30
  },
});


const displayStatuses = {
  "Queued": "Queued",
  "Predicting": "Predicting",
  "StartPrediction": "Start Prediction",
  "ResultsUploaded": "Completed",
  "FinishedPrediction": "Finished Prediction",
  "Failed ": "Failed ",
  "Stopped": "Stopped"
}

const runningStatuses = ["Predicting", "StartPrediction", "Queued"]

const getStatusMessage = (item, progressStatus) => {
  if (runningStatuses.includes(item.status)) {
    if (progressStatus?.progress !== undefined) {
      return `${progressStatus.progress}%`;
    } else if (item.status === "Queued") {
      return 'Started';
    }
  } else if (item.status === "Failed") {
    return item.status;
  } else if (item.status !== "ResultsUploaded") {
    return displayStatuses[item.status];
  }
  return 'Completed';
};

const getStatusSeverity = (item) => {
  if (runningStatuses.includes(item.status)) {
    return "warning";
  } else if (item.status === "Failed") {
    return "error";
  } else if (item.status !== "ResultsUploaded") {
    return "warning";
  }
  return "success";
};

export const Status = ({item, progressStatus}) => {
  const classes = useStyles();

  const message = getStatusMessage(item, progressStatus);
  const severity = getStatusSeverity(item);

  return (
      <Alert
        icon={false}
        className={
          severity === "warning"
            ? classes.runningAlert
            : severity === "error"
              ? classes.errorAlert
              : severity === "success"
                ? classes.completedAlert
                : ""
        }
        severity={severity}
        data-testid={
          severity === "warning"
            ? "runningAlert"
            : severity === "error"
              ? "errorAlert"
              : severity === "success"
                ? "successAlert"
                : ""
        }
      >
        {message}
      </Alert>
  );
};
