import { useCallback, useMemo, useRef, useState } from "react"
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import { CloseButton, ConfirmButton } from "../../Popups/InformationPopup/InformationPopup";
import useStyles, { acceptDropZoneStyle, activeDropZoneStyle, baseDropZoneStyle, paperStyle, rejectDropZoneStyle } from "./styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormInput from "../FormInput";
import { ReactComponent as CloseIcon } from "../../../Assets/icon-circle-cross.svg";
import { ReactComponent as IconFolder } from '../../../Assets/icon-folder.svg'
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle } from "@mui/material";

const UploadTemplateForm = ({ open, handleClose, handleSubmit, disabled }) => {
    const classes = useStyles()
    const formRef = useRef()
    const [ data, setData ] = useState({
        name: "",
        description: ""
    })
    const [fileName, setFileName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertSeverity, setAlertSeverity] = useState('')
    const bodyFormData = new FormData()
    
    const handleChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

        
    const onClose = () => {
        setAlertTitle("");
        setAlertMessage("");
        setAlertSeverity("");
        setFileName("");
        setShowAlert(false)
        handleClose()
        setData({
            name: "",
            description: ""
        })
    }

    const handleApiError = () => {
        setAlertTitle("Server Error");
        setAlertMessage("Sorry, something went wrong while trying to upload your template, try again later");
        setAlertSeverity("error");
        setShowAlert(true)
    }

    const onSubmit = (e) => {
        setShowAlert(false)
        Object.keys(data).forEach((key) => bodyFormData.append(key, data[key]))
        handleSubmit(e, bodyFormData, handleApiError, onClose)
    }

    const onDrop = useCallback((acceptedFiles) => {
        const handleUploadFile = async (file) => {
            if (file[0]?.type === "text/html") {
                setData({
                    ...data,
                    file: file[0]
                })
                setFileName(file[0].name);
                setAlertTitle("");
                setAlertMessage("");
                setAlertSeverity("");
                setShowAlert(false)
            } else {
                setAlertTitle("Incorrect file format");
                setAlertMessage("Please select a file of .html format");
                setAlertSeverity("error");
                setShowAlert(true)
            }
        }
        handleUploadFile(acceptedFiles)
    }, [data])

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({ accept: ".html", maxFiles:1, onDrop});

    const style = useMemo(() => ({
        ...baseDropZoneStyle,
        ...(isDragActive ? activeDropZoneStyle : {}),
        ...(isDragAccept ? acceptDropZoneStyle : {}),
        ...(isDragReject ? rejectDropZoneStyle : {})
      }), 
      [
        isDragActive,
        isDragReject,
        isDragAccept
      ]
    );

    return (
        <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="template-modal-title"
        aria-describedby="template-modal-description"
        >
            <Box sx={paperStyle}>
                <ValidatorForm ref={formRef} onSubmit={onSubmit}>
                        <Grid container direction="row" alignItems="flex-start" className={classes.formContainer}>
                            <Grid item container justifyContent="space-between">
                                <Grid item className={classes.formTitle}>Upload Template</Grid>
                                <Grid item>
                                    <CloseIcon onClick={onClose} className={classes.closeButton}/>
                                </Grid>
                            </Grid>                            
                            <Grid item container>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Template Name *</Typography>
                                    <FormInput
                                        type="name"
                                        name="name"
                                        placeholder="Enter Template Name"
                                        validators={['required']}
                                        errorMessages={['Template Name is required']}
                                        value={data.name}
                                        onChange={handleChangeData}
                                        autoFocus
                                        data-testid="name" />
                                </Grid>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Description</Typography>
                                    <FormInput
                                        type="description"
                                        name="description"
                                        placeholder="Template Description"
                                        value={data.description}
                                        onChange={handleChangeData}
                                        autoFocus
                                        data-testid="description" />
                                </Grid>
                            </Grid>
                            <Grid 
                                item 
                                container 
                                justifyContent='center' 
                                alignItems='center' 
                                className={classes.dragnDropRoot} 
                                {...getRootProps({style})} data-testid="uploadCsvInput"
                            >
                                <input {...getInputProps()}/>
                                <IconFolder/>
                                <Typography className={classes.dragnDropText}>{fileName ? fileName : "Select Template File"}</Typography>
                                <Grid item container justifyContent='center' alignItems='center' className={classes.alertBox}>         
                                    {
                                    (showAlert && alertSeverity === "error") && 
                                    <Alert className={classes.loadedAlertContainer} severity={alertSeverity}>
                                        <AlertTitle>{alertTitle}</AlertTitle>
                                    {alertMessage}
                                    </Alert>
                                    }
                                </Grid>

                            </Grid>
                            <Grid container className={classes.confirmOptions}>
                                    <CloseButton className={classes.cancelButton} onClick={handleClose}>
                                        Cancel
                                    </CloseButton>
                                    <ConfirmButton
                                        type="submit"
                                        className={classes.confirmButton}
                                        data-testid="submitButton"
                                        disabled={!fileName || disabled}
                                    >
                                        Save Template
                                    </ConfirmButton>
                            </Grid>
                        </Grid>
                </ValidatorForm>
            </Box>
        </Modal>
    )

}

export default UploadTemplateForm