import { ValidatorComponent } from "react-material-ui-form-validator";
import Select from 'react-select';


export default class TimeSelect extends ValidatorComponent {

    renderValidatorComponent() {
        const {
            errorMessages,
            validators,
            requiredError,
            styles,
            ...rest 
        } = this.props;


        return (
            <div>
                <Select
                    {...rest}
                    styles={{
                        ...styles,
                        control: (base,state) => ({
                            ...styles.control(base, state),
                            border: this.getValid(rest.value) || state.isDisabled ? "1px solid #c3c3c3" : "1px solid red",
                        }),
                    }}
                    ref={(r) => { this.input = r; }}
                />
            </div>
        );
    }

    getValid(current) {

        return current !== undefined;
    }
}