import React from "react"
import { Grid } from "@material-ui/core"
import { useStyles } from "./styles";
import NumberFormat from "react-number-format"
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers"
import { ClearButton } from "../../ui/buttons"
import { UICreateButton } from "../ProspectsContainer/ProspectsContainer"
import { ReactComponent as InfoIcon } from '../../../Assets/icon-info-circle.svg';
import { InfoTooltip } from "../../ui/tooltips";

const ProspectsTotal = ({ count, premium, disabled, onCreateCampaignClick = null, onAccuracyClick, accuracy = false, totals = true, model}) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isCluster = model?.toLowerCase() === "cluster"

    return (
        <Grid container justifyContent={totals ? "space-between" : "flex-end"} alignItems="center" className={totals ? classes.header : ""}>
            {totals && <Grid item className={classes.totalData}>
            <p className="page-data">
                Prospects Within Your Selected {isCluster ? "Cluster" : "Criteria"}: <NumberFormat value={count} displayType="text" thousandSeparator/>
            </p> 
            {!isCluster &&
            <p className="page-data">
                {isAnnuities ? "With Total AUM" : "With Total Premium"}:<span>{` ${nFormatter(premium, 2, currency)}`}</span>
            </p>
            }  
            </Grid>}

            <Grid item className={classes.headerActions}>
                {accuracy && <ClearButton variant="text" onClick={onAccuracyClick}>
                    Model Accuracy
                    <InfoTooltip title={"Displays the model's accuracy rate, measured in terms of Precision and Recall."} placement="top">
                    <div className={classes.infoIconBox}>
                        <InfoIcon/>
                    </div>
                    </InfoTooltip>
                </ClearButton>}
                {onCreateCampaignClick && <UICreateButton onClick={onCreateCampaignClick} disabled={disabled}>
                Create Campaign
                </UICreateButton>}                      
            </Grid>
        </Grid>
    )
}

export default ProspectsTotal