import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles";
import { ReactComponent as PremiumIcon } from "../../../Assets/icon-annual.svg";
import { ReactComponent as CoverageIcon } from "../../../Assets/icon-coverage.svg";
import { ReactComponent as YearsIcon } from "../../../Assets/icon-years.svg";
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import { CloseButton } from "../../Popups/InformationPopup/InformationPopup";
import clsx from "clsx";

const SegmentCard = ({ 
    title = "",
    onClick = () => {},
    premium,
    coverage,
    years,
    cash_value
}) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()

    return (
    <Grid container className={clsx(classes.cardRoot)}>
        <Grid container>
            <Typography className={classes.cardTitle}>{title}</Typography>
        </Grid>
        <Grid container direction="column" className={classes.cardContent}>
            <Grid container className={classes.cardItem}>
                <PremiumIcon/>
                <Grid item>
                    <Typography className={classes.cardItemTitle}>Annual Premium:</Typography>
                    <Typography className={classes.cardItemText}>{nFormatter(premium, 2, currency)}</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardItem}>
                <CoverageIcon/>
                <Grid item>
                    <Typography className={classes.cardItemTitle}>Coverage:</Typography>
                    <Typography className={classes.cardItemText}>{nFormatter(coverage, 2, currency)}</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.cardItem}>
                <YearsIcon/>
                {cash_value ? 
                <Grid item>
                    <Typography className={classes.cardItemTitle}>Cash Value After 20 Years:</Typography>
                    <Typography className={classes.cardItemText}>{nFormatter(cash_value, 2, currency)}</Typography>
                </Grid>
                : <Grid item>
                    <Typography className={classes.cardItemTitle}>Years of Coverage:</Typography>
                    <Typography className={classes.cardItemText}>{years}</Typography>
                </Grid>
                }
            </Grid>
        </Grid>
        <CloseButton className="segment-card--button" onClick={onClick}>Apply Now</CloseButton>
    </Grid>
    )
}

export default SegmentCard