import React, { useEffect, useState } from "react";
import mailsApiService from "../MailsTemplate/MailsService";
import { useStyles } from "../MailsTemplate/styles";
import { Grid, Typography } from "@material-ui/core";
import MailerConfigForm from "../../Components/Forms/MailerConfigForm/MailerConfigForm";

const ApiService = new mailsApiService()

const MailsConfig = () => {
    const classes = useStyles()
    const [ data, setData ] = useState({ provider_type: "sendgrid" })
    const [ edit, setEdit ] = useState(false)
    const [ disabled, setDisabled ] = useState(false)

    const onSubmit = (e, names, values) => {
        setDisabled(true)
        const provider_settings = {}
        Object.assign(provider_settings, ...Object.keys(values).map((item) => {
            if(names[`${item}_name`]) {
                return { [names[`${item}_name`]]: values[item] }
            }
        }))
        const payload = {
            provider_type: data.provider_type,
            provider_settings
        }
        
        if (edit) {
            ApiService._patchMailerConfig(payload, () => {
                setDisabled(false)
            }, (error) => {
                setDisabled(false)
                console.log(error);
            })
        } else {
            ApiService._postMailerConfig(payload, () => {
                setDisabled(false)
            }, (error) => {
                setDisabled(false)
                console.log(error);
            })
        }
    }
   
    useEffect(() => {
        ApiService._getMailerConfig((response) => {
            setData(response.data)
            setEdit(true)
        }, () => {
            setEdit(false)
        })
    }, [])

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.controlsContainer}>
                    <Grid>
                        <Typography className={classes.tableTitle}>Mail Settings</Typography>
                    </Grid>
            </Grid>
            <MailerConfigForm
                data={data}
                setData={setData}
                handleSubmit={onSubmit}
                disabled={disabled}
            />
        </div>
    )
}

export default MailsConfig