import React from "react";
import {FormControlLabel, makeStyles, Radio} from "@material-ui/core";

const useStyles = makeStyles({
    colorInput: {
        width: 13,
        height: 13,
        border: 'none',
        backgroundColor: props => props.color,
        '&.Mui-checked': {
          backgroundColor: props => props.color,
          outline: '2px solid #363ED3'
        },
        '&:hover': {
          backgroundColor: props => props.color,
          outline: '2px solid #363ED3'
        }
    },    
    colorContainer: {
        '& .MuiSvgIcon-root': {
        display: 'none'
        },
        padding: 5 
    },
})

const ColorPickerItem = ({ ...props }) => {
    const classes = useStyles(props)

    return <FormControlLabel 
            className={classes.colorContainer} 
            value={props.color} 
            control={<Radio className={classes.colorInput}/>}/>
}

export default ColorPickerItem