import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as IconCheck } from "../../Assets/icon-checkbox.svg"
import Filter from './Filter';
import { deepEqual, amplitudeService } from '../../global/helpers';

const useStyles = makeStyles((theme) => ({
    selectValue: {
        display: "flex",
        alignItems: "center",
        borderRadius: 6,
        marginLeft: 10,
        backgroundColor: "#eef0f4",
        padding: "0 18px 0 18px;",
        fontFamily: "Roboto",
        fontSize: 11,
        fontWeight: 500,
        lineHeight: "13px",
        minHeight: 21
    },
    selectValueActive: {
        padding: "0 20px 0 20px;",
        textTransform: "capitalize",
        color: "#312E40",
        "& svg": {
            fill: "rgba(82, 82, 82, 0.6)",
            marginBottom: 6,
        },
    },
    UISlider: {
        width: 200,
        marginLeft: 26,
        marginTop: 40,
        '& .MuiSlider-valueLabel': {
            fontSize: '10px',
        },
    },
    checkBoxProps: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px"
        },
        "& .MuiSvgIcon-root": {
            width: 14,
            height: 14,
        }
    },
    checkLabel: {
        fontSize: 13,
        textTransform: "capitalize",
        "& .label--icon": { 
            display: "flex",
            alignItems: "center",
            "& svg": {
                marginRight: 8
            }
        }
    },
    customResetIcon: {
        height: "12px !important",
        color: '#8F95AD',
        cursor: "pointer",
        marginTop: '0px',
        position: "relative",
        top: "3px",
        lef: "3px",
        borderRadius: 85,
        width: "12px !important",
        '&:hover': {
            backgroundColor: '#BAC2E0',
        }
    },
    popoverRoot: {
        minWidth: 273,
        padding: '20px 25px',
    },
    filterTitle: {
        margin: 0,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 700,
        lineHeight: "12px",
        textTransform: "capitalize",
        paddingBottom: 13
    },

}));

const defaultControl = (item) => {
    if (item === "All")
        return "All"
    else {
        return item
    }
}

const SelectFilter = (props) => {
    const {
        title,
        popoverText,
        defaultValue,
        resetFilter,
        onChange,
        renderControl = defaultControl,
        zeroValues = [],
        spacing = 5,
        reset = false,
        multiSelect = true,
        icons = null,
        labeled = true,
        dynamic = false,
        customDropdown = false,
        disabled = false,
    } = props
    const classes = useStyles();
    const [filterTempValue, setFilterTempValue] = React.useState(defaultValue);

    const handleFilterChange = (item, newValue) => {
        if (!disabled) {
            amplitudeService._throwEvent("FILTER_CHANGE", {'title': title, 'value': item, 'page': window.location.pathname})
            if (item === 'All' && newValue) {
                setFilterTempValue(prevState => {
                    let newState = {
                        ...prevState,
                        "All": false,
                        [item]: newValue
                    }
                    for (const key in prevState) {
                        if (key !== 'All') {
                            newState[key] = false
                        } else {
                            newState[key] = true
                        }
                    }
                    //callback(null, newState)
                    return newState
                })
            } else {
                setFilterTempValue(prevState => {
                    let newState = {
                        ...prevState,
                        "All": false,
                        [item]: newValue
                    }
                    if (multiSelect) {
                        const hasValues = Object.keys(newState).find((key) => newState[key])
                        const allAgesAreChecked = Object.keys(newState).reduce(
                            (acc, key) => key === 'All' ? acc : acc && newState[key],
                            true
                        )
                        if (!hasValues) {
                            for (const key in newState) {
                                if (key === 'All') {
                                    newState[key] = true
                                } else {
                                    newState[key] = false
                                }
                            }
                        } else if (allAgesAreChecked) {
                            for (const key in newState) {
                                if (key !== 'All') {
                                    newState[key] = true
                                } else {
                                    newState[key] = false
                                }
                            }
                        }
                    } else {
                        for (const key in prevState) {
                            if (key !== 'All' && key === item && newValue) {
                                newState[key] = true
                            } else {
                                newState[key] = false
                            }
                        }
                    }
                    //callback(null, newState)
                    return newState
                })
            }
        }
    }

    const onFilterApply = (value) => onChange(value)

    const handleClear = (e) => {
        e.stopPropagation();
        resetFilter()
        setFilterTempValue(defaultValue)
    }

    React.useEffect(() => {
        if (reset) {
            setFilterTempValue(defaultValue)
        }
    }, [reset, defaultValue])

    React.useEffect(() => {
        if (dynamic) {
            setFilterTempValue(defaultValue)
        }
    }, [defaultValue, dynamic])

    const selectedValues = Object.keys(filterTempValue)
        .filter((element) => filterTempValue[element] === true)

    return <Filter
        {...props}
        disabled={disabled}
        title={title}
        popoverText={popoverText}
        resetFilter={handleClear}
        onFilterApply={onFilterApply}
        value={filterTempValue}
        spacing={spacing}
        labeled={!customDropdown}
        controlComponent={
            customDropdown
            ? props.children :
            <Typography className={clsx(classes.selectValue, !deepEqual(filterTempValue, defaultValue) ? classes.selectValueActive : "")}>
                {
                    !selectedValues.includes("All") ? selectedValues
                        .map((item) => renderControl(item))
                        .join(",")
                        : "All"
                }
                {deepEqual(filterTempValue, defaultValue) ? null :
                    <CloseIcon
                        className={classes.customResetIcon}
                        onClick={handleClear}
                    />
                }
            </Typography>
        }
        PopoverComponent={
            () => (<div className={classes.popoverRoot}>
                <Typography className={classes.filterTitle} gutterBottom>
                    {popoverText}
                </Typography>

                <FormGroup className={classes.checkBoxProps} style={!labeled ? { gap: 6 } : {}}>
                    {
                        Object.keys(filterTempValue)
                            .filter((key) => !zeroValues.includes(key))
                            .sort((a, b) => {
                                if(a === "All") return -1
                                if(b === "All") return 1
                                return 0
                            })
                            .map((item, index) => {
                                return <FormControlLabel
                                    style={item === "All" ? { paddingBottom: 10 } : {}}
                                    key={index}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={filterTempValue[item]}
                                            onChange={(e) => handleFilterChange(item, e.target.checked)}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.checkLabel}
                                            style={item === "All" ? { color: "#363ED3" } : {}}
                                        >
                                            <div className='label--icon'>
                                            {(icons && item !== "All") && icons[item]}
                                            {(labeled || item === "All") && <Typography>{item}</Typography>}
                                            </div>
                                        </Typography>
                                    }
                                />
                            })
                    }
                </FormGroup>
            </div>)} />

}

export default SelectFilter