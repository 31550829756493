import * as am4maps from "@amcharts/amcharts4/maps";

export const mapChartConfigByISOCode = {
    "US": { name: "usa", regionDatalink: "", type: "states", countyKeyId: "", projection: new am4maps.projections.AlbersUsa()},
    "IL": { name: "usa", regionDatalink: "", type: "states", countyKeyId: "", projection: new am4maps.projections.AlbersUsa()},
    "GB": { name: "ukCounties", regionDatalink: "ukCounties", type: "counties", countyKeyId: "id", projection: new am4maps.projections.Miller()},
    "FR": { name: "france", regionDatalink: "franceDepartments", type: "departments", countyKeyId: "ID_REGION", projection: new am4maps.projections.Miller()},
    "ES": { name: "spain", regionDatalink: "spainProvinces", type: "provinces", countyKeyId: "COMMUNITY", projection: new am4maps.projections.Miller(), zoomDefault: { 
      zoomLevel: 1.495839001190856, 
      homeGeoPoint: {
        latitude: 40.07184845920223,
        longitude: -4.126429657347824
      }
    }}
}