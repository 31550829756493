import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles(() => ({
    root: {
        display:"flex",
        justifyContent: 'center',
        flexGrow: 1,
    },
    userDemoWrapper: {
        height: "97vh"
    },
    userDemoContainer: {
        maxWidth: 446,
    },
    stepsContainer: {
        gap: 15
    },
    newUserDemoClose: {
        position: "relative",
        right: -465,
        width: 16,
        height: 16,
        cursor: "pointer"
    },
    stepCard: {
        background: "#6750A403",
        borderRadius: 6,
        border: "1px solid #E5E5E5",
        padding: "20px 24px",
        gap: 18
    },
    stepHeader: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        color: "#312E40"
    },
    stepText: {
        margin: 0,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "16px",
        color: "#808080"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 57,
        height: 57,
        background: "#FFF",
        borderRadius: 6,
        border: "1px solid #E5E5E5",
        "& svg": {
            width: 20,
            height: 20,
        }
    },
    footerText: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
        textAlign: "center",
        color: "#808080",
        paddingTop: 24,
        "& a, span": {
            fontWeight: 700,
            color: "#383ECB"
        }
    },
    headerTitle:{
        fontSize: 26,
        fontWeight: 600,
        lineHeight: "28px",
        paddingBottom: 25,
    },
    button: {
      color: "#312e40",
      "&:hover": {
        backgroundColor: "#363ED3",
        color: "white",
      },
      textTransform: "capitalize",
    },
    nextButtonContainer: {
        paddingTop: 40
    },
    nextButton: {
        color: "#fff",
        background: "#363ED3",
        width: "100%",
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "24px",
        textAlign: "center"
    }

}));

export default useStyles