import { ApiService } from "../../global/service";

export default class StatusesApiService {

    _getStatusesList = (requestHandler, errorHandler) => ApiService.get(`/statuses/`, {}, requestHandler, errorHandler)

    _postStatus = (data, requestHandler, errorHandler) => ApiService.post(`/statuses`, {}, requestHandler, errorHandler, data)

    _patchStatus = (status_id, data, requestHandler, errorHandler) => ApiService.patch(`/statuses/${status_id}`, {}, requestHandler, errorHandler, data)

    _deleteStatus = (status_id, requestHandler, errorHandler) => ApiService.delete(`/statuses/${status_id}`, {}, requestHandler, errorHandler)

    _patchPolicyStatus = (policy_id, status, requestHandler, errorHandler) => ApiService.patch(`/policies/${policy_id}`, {}, requestHandler, errorHandler, { status_name: status })
}