export const median = (numbers) => {
    if(numbers.length==0){
        return 0
    }
    const sorted = numbers.sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
}

export const checkData = (item) => {
    if (item) {
      try {
        if (Object.keys(item).length !== 0) {
          return true
        }
        return false
      } catch (error) {
        return false
      }
    }
    return false
  }