import ApiService from "../../global/service";

export default class campaignApiService {

    _postCreateCampagin = (data, requestHandler, errorHandler, demo = false) => ApiService.post(`/v1/metadata${demo ? "/demo" : ""}`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _postCreateCampaignBroker = (data, requestHandler, errorHandler) => ApiService.post("/v1/campaigns", {}, requestHandler, errorHandler, data, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _patchEditCampagin = (campaign_id, data, requestHandler, errorHandler) => ApiService.patch(`/v1/metadata/${campaign_id}`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _patchEditCampaginBroker = (campaign_id, data, requestHandler, errorHandler) => ApiService.patch(`/v1/campaigns/${campaign_id}`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _patchDeleteCampagin = (campaign_id, data, requestHandler, errorHandler) => ApiService.patch(`/v1/metadata/${campaign_id}/archive`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _patchUpdateCampaginStatus = (campaign_id, status, requestHandler, errorHandler) => ApiService.patch(`/v1/metadata/${campaign_id}/status/${status}`, {}, requestHandler, errorHandler, {}, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getAllCampaigns = (requestHandler, errorHandler) => ApiService.get("/v1/metadata", {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getCampaignsList = (requestHandler, errorHandler) => ApiService.get("/v1/campaigns", {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getCampaignById = (id, requestHandler, errorHandler) => ApiService.get(`/v1/campaigns/${id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getCampaignsByRunid = (id, requestHandler, errorHandler) => ApiService.get(`/campaign?type=run&id=${id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getCampaignData = (id, requestHandler, errorHandler) => ApiService.get(`/v1/metadata/${id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getExportCsvById = (id, requestHandler, errorHandler) => ApiService.get(`/v1/data/campaign_id=${id}/export/csv`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getExportCsvDownload = (id, requestHandler, errorHandler) => ApiService.get(`/v1/data/campaign_id=${id}/download`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getExportCsv = (id, requestHandler, errorHandler) => ApiService.get(`/policies/export/csv?campaign_id=${id}`, {}, requestHandler, errorHandler)

    _getViewCampaign = (id, requestHandler, errorHandler, page) => ApiService.get(`/v1/data/campaign_id=${id}?skip=${page*10}&take=10`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getViewCampaignList = (id, requestHandler, errorHandler, page) => ApiService.get(`/policies?campaign_id=${id}&skip=${page*10}&take=10`, {}, requestHandler, errorHandler)
}