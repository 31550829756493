import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import UploadFile from "../../Components/StepsComponents/UploadFile/UploadFile";
import { setAutoMapping, setCsvInfo, setSourceId } from "../NewProject/NewProjectSlice";
import newProjectApiService from "../NewProject/NewProjectService";
import { User } from "../../global/user";

const ApiService = new newProjectApiService()

const Upload = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { source_to_clear } = useParams()
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;

    const handleUploadCsv = (response) => {
        dispatch(setCsvInfo(response.data['csv_info']))
        dispatch(setSourceId(response.data['source_id']))  
        if (parseInt(response.data['source_id'])) {   
            ApiService._getCsvHeaders(response.data['source_id'], (res) => {
                const data = res.data['csv_info']
                if (isBroker && data?.broker_auto_mapping) {
                  dispatch(setAutoMapping(data.broker_auto_mapping))
                } else if (data?.lapse_auto_mapping) {
                  dispatch(setAutoMapping(data.lapse_auto_mapping))
                } else if (data?.ui_auto_mapping) {
                  dispatch(setAutoMapping(data.ui_auto_mapping))
                }
              }, (error) => {
                console.log(error);
              })
            
        }   
    }

    const handleNext = () => {
        history.push("/newmapper")
    }
    
    const handleBack = () => {
        history.push("/firstlogin")
    }

    useEffect(() => {
      if (source_to_clear) {
          ApiService._deleteReRunClearSource(parseInt(source_to_clear), () => {
              history.push("/upload")
          }, (error) => {})
      }
    }, [source_to_clear])

    return (
        <Grid container justifyContent="center">
            <UploadFile 
            title="Upload Your File"
            onNext={handleNext} 
            onBack={handleBack} 
            onUpload={handleUploadCsv} 
            fileType=".csv"
            ws={false}/>
        </Grid>
    )
}

export default Upload