import ApiService from "../../global/service";

export default class ProjectApiService {

    _postArchiveProject = (projectId, requestHandler, errorHandler) => ApiService.post(`/projects/${projectId}/archive`, {}, requestHandler, errorHandler)

    _postArchiveRun = (runId, requestHandler, errorHandler) => ApiService.post(`/runs/${runId}/archive`, {}, requestHandler, errorHandler)
    _postStopRun = (runId, requestHandler, errorHandler) => ApiService.post(`/runs/${runId}/stop`, {}, requestHandler, errorHandler)

    _getRunDetails = (projectId, requestHandler, errorHandler) => ApiService.get(`/runs/project/${projectId}`, {}, requestHandler, errorHandler)
}