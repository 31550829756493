import { Drawer, Grid } from "@material-ui/core"
import SegmentationForm from "../Forms/SegmentationForm/SegmentationForm"
import { ReactComponent as CloseIcon } from "../../Assets/icon-circle-cross.svg";
import { ReactComponent as ArrowLeft } from '../../Assets/icon-arrow-left-alt.svg';
import useStyles from "./styles"
import segmentationApiService from "../../Pages/Segmentation/SegmentationService";
import { useState } from "react";
import SegmentCard from "./SegmentCard/SegmentCard";

const ApiService = new segmentationApiService()

const Segmentation = ({ open, container, onClose }) => {
    const classes = useStyles()
    const [ data, setData ] = useState(null)

    const handleSubmit = (values, onSuccess, onError) => {
      ApiService._getSegment(values, (response) => {
        const period = response.data?.top_segments?.term_life?.period[0]?.segment
        let value = 0
        if (period?.split("_")?.length) {
            const split = period?.split("_")
            value = parseInt(split[1]) ? parseInt(split[1]) : 0
        }
        setData({ 
          ...response.data, 
          period: value
      })
        onSuccess();
      }, (error) => {
        onError(error.response.status);
        console.log(error);
      })
    }

    const handleClose = () => {
      setData(null)
      onClose()
    }

    const handleBack = () => {
      setData(null)
    }
    const handleAplly = () => {
      window.open("https://atidot.life/", "_blank", "noreferrer")
    }

    return (
     <Drawer
        container={container}
        variant="temporary"
        anchor='left'
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.segmentationDrawer,
          root: classes.segmentationDrawerContainer
        }}
      >
        {data && <ArrowLeft onClick={handleBack} className={classes.backButton}/>}
        <CloseIcon onClick={handleClose} className={classes.closeButton}/>
        <div className={classes.segmentationRoot}>
            {
              data ? 
              <Grid container direction="column" className={classes.segmentationContent}>
                <SegmentCard
                  title="Term Life Insurance"
                  premium={data?.top_segments?.term_life?.premium[0]?.avg > -1 ? data.top_segments.term_life.period[0].prem_wtd : 0}
                  coverage={data?.top_segments?.term_life?.fa[0]?.avg > -1 ? data.top_segments.term_life.period[0].fa_wtd : 0}
                  years={data?.period}
                  onClick={handleAplly}
                />                
                <SegmentCard
                  title="Whole Life Insurance"
                  premium={data?.top_segments?.whole_life?.premium[0]?.avg > -1 ? data.top_segments.whole_life.premium[0].prem_wtd : 0}
                  coverage={data?.top_segments?.whole_life?.fa[0]?.avg > -1 ? data.top_segments.whole_life.premium[0].fa_wtd : 0}
                  cash_value={data?.top_segments?.whole_life?.premium[0]?.cash_20 > -1 ? data.top_segments.whole_life.premium[0].cash_20 : 0}
                  onClick={handleAplly}
                />                
                <SegmentCard
                  title="Universal Life Insurance"
                  premium={data?.top_segments?.universal_life?.premium[0]?.prem_wtd > -1 ? data.top_segments.universal_life.premium[0].prem_wtd : 0}
                  coverage={data?.top_segments?.universal_life?.premium[0]?.fa_wtd > -1 ? data.top_segments.universal_life.premium[0].fa_wtd : 0}
                  cash_value={data?.top_segments?.universal_life?.premium[0]?.cash_20 > -1 ? data.top_segments.universal_life.premium[0].cash_20 : 0}
                  onClick={handleAplly}
                />
              </Grid>
              :
              <SegmentationForm
                onSubmit={handleSubmit}
              />
            }
        </div>
      </Drawer>
    )
}

export default Segmentation