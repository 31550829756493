import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import moment from "moment";

import {
  Button, Grid, IconButton,
  Radio, styled, TableCell, Typography
} from '@material-ui/core';

import { Table, TableBody, TableContainer, TableHead, TableRow, } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as DatasourceIcon } from '../../Assets/icon-datasource.svg';
import { ReactComponent as FileDownloadOutlinedIcon } from '../../Assets/icon-download-alt.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import clsx from 'clsx';
import { CsvBuilder } from 'filefy';

import appApiService from '../../AppService';
import { selectSourceMode, setSourceId } from '../../Pages/NewProject/NewProjectSlice';
import { selectUserPermissions, } from '../../AppSlice'

import { reverseFormatToBytes } from '../../global/helpers';

import { useStyles } from './styles'
import { checkPermission } from '../../global/user';
import HeaderContainer from '../Headers/HeaderContainer/HeaderContainer';
import InformationPopup from '../Popups/InformationPopup/InformationPopup';
import { InfoTooltip } from '../ui/tooltips';
import { uiButtonStyles } from '../ui/buttons';

const AddDataSourceButton = styled(Button)(() => ({
  ...uiButtonStyles,
  width: 172,
  height: 40
}));

export default function DataManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectMode = useSelector(selectSourceMode);
  const permissions = useSelector(selectUserPermissions)
  const history = useHistory();
  const [dataSources, setDataSources] = useState({});
  const [search, setSearch] = useState('')
  const [searchBy, setSearchBy] = useState('')
  const [activeFilter, setActiveFilter] = useState("created_at")
  const [filterOrientationDate, setFilterOrientationDate] = useState(false)
  const [filterOrientationSize, setFilterOrientationSize] = useState(false)
  const [filterOrientationPolicies, setFilterOrientationPolicies] = useState(false)
  const [filterOrientationName, setFilterOrientationName] = useState(false)
  const [activeTab, setActiveTab] = useState("all")
  const [sourcesCount, setSourcesCount] = useState(0)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [sourceID, setSourceID] = useState(null)
  const [selectedFile, setSelectedFile] = useState(0)
  const [demo, setDemo] = useState(false)
  const ApiService = new appApiService()


  const isNewprospects = permissions?.find(({ action }) => action === "new_prosepcts_tab")


  const resetDataSources = () => {
    ApiService._getDataSources((response) => {
      response.data.details = response.data.details.sort(SortDataSource)
      const data = response.data?.count ? response.data : { "count": 1, "description": "files been found", "details": [{ "created_at": "Thu, 20 Apr 2023 13:11:50 GMT", "extra_details": "desktop", "name": "sample_file.csv", "num_of_policies": 43, "size": "2KB", "source_id": 3301, "type": "file" }] }
      setDemo(!response.data?.count)
      setDataSources(data)
      setSearch("")
      setSourcesCount(response.data?.count ? response.data.count : 1)
    }, (error) => console.log(error))

  }
  const handleOpenConfirmDelete = (e, id) => {
    setSourceID(id)
    setConfirmDeleteOpen(true)
  }
  
  const handleCloseConfirmDelete = () => {
    setSourceID(null)
    setConfirmDeleteOpen(false)
  }

  const handleDeleteDataSource = () => {
    sourceID && ApiService._patchDeleteDataSource(sourceID,
      () => {
        setSourceID(null)
        resetDataSources()
        setConfirmDeleteOpen(false)
      }, (error) => console.log(error))
  }

  const handleExportDataSource = (e, id) => {
    id && ApiService._getDownloadDataSource(id,
      (response) => {
        const data = response.data.split('\n')
        const csvHeaders = data.shift().split(',')
        const csvData = data.map((row) => {
          const rowValue = row.split(",")
          return rowValue
        })

        const fileName = dataSources?.details?.find(({ source_id }) => sourceID === source_id)?.name

        let csvBuilder = new CsvBuilder(fileName ? fileName : "download.csv")
          .setColumns(csvHeaders)
          .addRows(csvData)
        csvBuilder.exportFile()
        setSourceID(null)
      }, (error) => console.log(error))
  }

  const handleRadioChange = (event) => {
    setSelectedFile(parseInt(event.target.value))
    dispatch(setSourceId(event.target.value))
  }

  React.useEffect(() => {
    resetDataSources()
    //
  }, [])





  const filterSearch = (item) => {

    if (search === "") {
      return item
    } else if (
      item[searchBy]?.toString().includes(search)
    ) {
      return item
    }

  }

  const filterType = (item) => {
    if (item.type === activeTab || activeTab === "all") {
      return item
    }
  }

  function SortDataSource(a, b) {
    let filterOrientation = false
    switch (activeFilter) {
      case 'created_at':
        filterOrientation = filterOrientationDate
        if (!filterOrientation) {
          if (a[activeFilter] > b[activeFilter]) {
            return -1
          } else {
            return 1
          }
        } else {
          if (a[activeFilter] < b[activeFilter]) {
            return -1
          } else {
            return 1
          }

        }
      case 'size':
        filterOrientation = filterOrientationSize
        if (!filterOrientation) {
          if (reverseFormatToBytes(a[activeFilter]) > reverseFormatToBytes(b[activeFilter])) {
            return -1
          } else {
            return 1
          }
        } else {
          if (reverseFormatToBytes(a[activeFilter]) < reverseFormatToBytes(b[activeFilter])) {
            return -1
          } else {
            return 1
          }

        }
      case 'num_of_policies':
        filterOrientation = filterOrientationPolicies
        if (!filterOrientation) {
          console.log(activeFilter);
          if (a[activeFilter] > b[activeFilter]) {
            return -1
          } else {
            return 1
          }
        } else {
          if (a[activeFilter] < b[activeFilter]) {
            return -1
          } else {
            return 1
          }

        }
      case 'name':
        filterOrientation = filterOrientationName
        if (!filterOrientation) {
          if (a[activeFilter] > b[activeFilter]) {
            return -1
          } else {
            return 1
          }
        } else {
          if (a[activeFilter] < b[activeFilter]) {
            return -1
          } else {
            return 1
          }

        }
      default:
        break;
    }

  }

  React.useEffect(() => {
    if (dataSources.count > 0) {
      setDataSources(prevState => ({ ...prevState, details: dataSources.details.sort(SortDataSource) }));
    }

  }, [activeFilter, filterOrientationDate, filterOrientationSize, filterOrientationName, filterOrientationPolicies])


  const handleFilterClick = (clickedOption) => {
    setActiveFilter(clickedOption)
    if (clickedOption === activeFilter) {
      switch (activeFilter) {
        case 'created_at':
          setFilterOrientationDate(!filterOrientationDate)
          break;
        case 'size':
          setFilterOrientationSize(!filterOrientationSize)
          break;
        case 'num_of_policies':
          setFilterOrientationPolicies(!filterOrientationPolicies)
          break;
        case 'name':
          setFilterOrientationName(!filterOrientationName)
          break;
        default:
          break;
      }

    }
  }

  const handleAddDataSource = () => {
    if (checkPermission('add_datasource')) {
      ApiService._getDataSources((response) => {
        if (!response.data?.count) {
          history.push("/firstlogin")
        } else {
          // dispatch(setStep2Component(''))
          history.push("/NewDataSource")
        }
      }, (error) => console.log(error))
    }
  }

  return (
    <Grid container className={selectMode ? classes.rootContentAlt : classes.rootContent} spacing={0} alignContent="flex-start" justifyContent="flex-start">
      <HeaderContainer
        title="Datasources"
      />

      <Grid item xs={12} className={classes.tableCointainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.dataSourcesHead}
        >
          <Grid item xs={2} className={classes.sourcesCount}>
          {`${sourcesCount} Data source${sourcesCount > 1 ? "s" : ""} found`}
          </Grid>

          {checkPermission('add_datasource') && !selectMode &&
            <AddDataSourceButton onClick={handleAddDataSource} className={classes.addNewDataButton}>Add New Data</AddDataSourceButton>
          }

        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
        >
          <TableContainer className={classes.table}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" style={{borderCollapse: 'collapse'}}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {selectMode && <TableCell>
                  </TableCell>}
                  <TableCell style={{ width: 300 }}>
                    <div className={classes.FilterContainer} onClick={() => handleFilterClick("name")}>
                      Name
                      {activeFilter === "name" && <ArrowDropDownIcon  className={clsx(classes.filterButton, filterOrientationName ? classes.filterButtonInverted : '')} />}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.FilterContainer} onClick={() => handleFilterClick("created_at")}>
                      Date uploaded
                      {activeFilter === "created_at" && <ArrowDropDownIcon  className={clsx(classes.filterButton, filterOrientationDate ? classes.filterButtonInverted : '')} />}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.FilterContainer} onClick={() => handleFilterClick("num_of_policies")}>
                      Size
                      {activeFilter === "num_of_policies" && <ArrowDropDownIcon  className={clsx(classes.filterButton, filterOrientationPolicies ? classes.filterButtonInverted : '')} />}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.FilterContainer} onClick={() => handleFilterClick("num_of_policies")}>
                      Number of Records
                      {activeFilter === "num_of_policies" && <ArrowDropDownIcon  className={clsx(classes.filterButton, filterOrientationPolicies ? classes.filterButtonInverted : '')} />}
                    </div>
                  </TableCell>
                  <TableCell style={{ maxWidth: 120 }}>
                    <div className={classes.FilterTypeContainer}>
                      Source
                    </div>
                  </TableCell>
                  {!selectMode && <TableCell>
                      Actions
                  </TableCell>}
                </TableRow>
              </TableHead>
              
              <TableBody className={classes.tableDataSources}>
                {
                  (dataSources.count > 0 && dataSources.details.filter(filterSearch).filter(filterType).length > 0) &&
                  dataSources.details.filter(filterSearch).filter(filterType).map((item, index) => {
                    return (
                      <TableRow key={index} className={classes.dataSourceContainer}>
                        {selectMode &&
                          <TableCell>
                            <Radio
                              checked={selectedFile === item.source_id}
                              onChange={handleRadioChange}
                              value={item.source_id}
                              name="radio-buttons"
                              inputProps={{ 'aria-label': "File " + item.source_id }}
                              color="primary"
                            />
                          </TableCell>
                        }
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <DatasourceIcon/>
                            <span>{item.name}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          {moment(item.created_at).format('DD-MMM-YYYY')}
                        </TableCell>
                        <TableCell>
                          <span>{item.size}</span>
                        </TableCell>
                        <TableCell>
                          {<NumberFormat value={item?.num_of_policies ? item.num_of_policies : 0} displayType={'text'} thousandSeparator={true} />}
                        </TableCell>
                        <TableCell>
                          <span>{item.type} - {item.extra_details}</span>
                        </TableCell>
                        {
                          !selectMode &&
                          <TableCell style={{ textAlign: "center" }}>
                            <InfoTooltip placement="top" title="Download">
                            {!demo ? 
                              <IconButton  style={isNewprospects ? { visibility: "hidden"} : {}}  onClick={(e) => {
                                e.stopPropagation();
                                handleExportDataSource(e, item.source_id)
                              }}>
                                <FileDownloadOutlinedIcon/>
                              </IconButton>
                            : 
                              <IconButton href="sample_file.csv" download="sample_file.csv">
                                <FileDownloadOutlinedIcon/>
                              </IconButton>
                            }
                            </InfoTooltip>
                            
                            {
                            !demo &&
                            <InfoTooltip placement="top" title="Delete">
                              <IconButton style={isNewprospects ? { visibility: "hidden"} : {}}>
                                <DeleteOutlineIcon onClick={(e) => handleOpenConfirmDelete(e, item.source_id)} />
                              </IconButton>
                            </InfoTooltip>
                            }
                          </TableCell>

                        }
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          {
            (dataSources.count === 0 || dataSources?.details?.filter(filterSearch)?.filter(filterType).length === 0) &&
            <Grid container justifyContent='center' className={classes.noResult}>
              <Typography variant="h3">No Results</Typography>
            </Grid>
          }
        </Grid>
      </Grid>
      <InformationPopup
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        onConfirm={handleDeleteDataSource}
        title="Are you sure you want to delete this datasource?"
        closeText="Cancel"
        confirmText="Delete"
        buttonDirection="right"
        />
    </Grid>
  )
}