import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fileName: '',
    projectName: '',
    firstRunName: '',
    runModel:'',
    runModels:[],
    csvInfo:{},
    success: false,
    isSelectSourceMode: false,
    projectId:'',
    projectIds:[],
    tableName:'',
    runId:'',
    runIds:[],
    sourceId:'',
    models:{},
    mapperOptions:{},
    emptyKeys: [],
    mapperError:{present: false, message: ""},
    validationError:{present: false, message: ""},
    dataEffectiveYear: "",
    dataEffectiveMonth: "",
    zipCheck: true,
    dbType: "postgres",
    duration: 3,
    productType: "Whole Life",
    autoMapping: {},
    validateCheck: true
}
const name = 'setNewProject'

export const NewProjectSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setProjectName : (state,action) =>{
            state.projectName= action.payload
        },
        setFirstRunName:(state,action) =>{
            state.firstRunName= action.payload
        },
        setRunModel:(state,action) =>{
            state.runModel=action.payload
        },
        setRunModels:(state,action) =>{
            state.runModels=action.payload
        },
        setCsvInfo:(state,action) =>{
            state.csvInfo =action.payload
        },
        setSuccess:(state,action) =>{
            state.success =action.payload
        },
        setSelectSourceMode:(state,action) =>{
            state.isSelectSourceMode =action.payload
        },
        setProjectId :(state,action) =>{
            state.projectId =action.payload
        },
        setProjectIds :(state,action) =>{
            state.projectIds =action.payload
        },
        setRunId :(state,action) =>{
            state.runId =action.payload
        },
        setRunIds:(state,action) =>{
            state.runIds =action.payload
        },
        setSourceId :(state,action) =>{
            state.sourceId =action.payload
        },
        setFileName: (state,action) =>{
            state.fileName = action.payload
        },
        setTableName :(state,action) =>{
            state.tableName =action.payload
        },
        setModels :(state,action) =>{
            state.models =action.payload
        },
        setMapperOptions :(state,action) =>{
            state.mapperOptions =action.payload
        },
        setMapperError :(state,action) =>{
            state.mapperError =action.payload
        },
        setValidationError :(state,action) =>{
            state.validationError = action.payload
        },
        setDataEffectiveYear :(state,action) =>{
            state.dataEffectiveYear = action.payload
        },
        setDataEffectiveMonth :(state,action) =>{
            state.dataEffectiveMonth = action.payload
        },
        setZipCheck :(state,action) =>{
            state.zipCheck = action.payload
        },
        setDBType :(state,action) =>{
            state.dbType = action.payload
        },
        setDuration :(state,action) =>{
            state.duration = action.payload
        },
        setProductType :(state,action) =>{
            state.productType = action.payload
        },
        setAutoMapping: (state, action) => {
            state.autoMapping = action.payload
        },
        setEmptyKeys: (state, action) => {
            state.emptyKeys = action.payload
        },
        setValidateCheck: (state, action) => {
            state.validateCheck = action.payload
        },
        resetValues:(state) =>{
            state.projectName=""
            state.firstRunName=""
            state.runModel=""
            state.csvInfo={}
            state.tableName=""
            state.runId=""
            state.sourceId=""
            state.isSelectSourceMode=false
            state.models=[]
            state.mapperOptions={}
            state.mapperError={present: false, message: ""}
            state.validationError={present: false, message: ""}
            state.zipCheck= true
            state.dataEffectiveYear= ""
            state.dataEffectiveMonth= ""
            state.validateCheck= true
        },
    }
})

export const { setProjectName,setFirstRunName,setRunModel,setCsvInfo,setMapperOptions, setMapperError, setValidationError, setTableName, setZipCheck,setDataEffectiveYear,setDataEffectiveMonth,setModels,setProjectId, setRunId, setProjectIds, setRunIds, setSourceId, setDBType, setDuration, setAutoMapping, setEmptyKeys, setFileName, setRunModels, setSuccess, setProductType, setSelectSourceMode, setValidateCheck, resetValues } = NewProjectSlice.actions

export const selectProjectName = (state) => state.newProject.projectName
export const selectFirstRunName = (state) => state.newProject.firstRunName
export const selectRunModel = (state) => state.newProject.runModel
export const selectRunModels = (state) => state.newProject.runModels
export const selectCsvInfo = (state) => state.newProject.csvInfo
export const selectSuccess = (state) => state.newProject.success
export const selectSourceMode = (state) => state.newProject.isSelectSourceMode
export const selectProjectId = (state) => state.newProject.projectId
export const selectRunId = (state) => state.newProject.runId
export const selectProjectIds = (state) => state.newProject.projectIds
export const selectRunIds = (state) => state.newProject.runIds
export const selectTableName = (state) => state.newProject.tableName
export const selectSourceId = (state) => state.newProject.sourceId
export const selectModels = (state) => state.newProject.models
export const selectMapperOptions = (state) => state.newProject.mapperOptions
export const selectMapperError = (state) => state.newProject.mapperError
export const selectValidationError = (state) => state.newProject.validationError
export const selectZipCheck = (state) => state.newProject.zipCheck
export const selectDataEffectiveYear = (state) => state.newProject.dataEffectiveYear
export const selectDataEffectiveMonth = (state) => state.newProject.dataEffectiveMonth
export const selectDBType = (state) => state.newProject.dbType
export const selectDuration = (state) => state.newProject.duration
export const selectProductType = (state) => state.newProject.productType
export const selectAutoMapping = (state) => state.newProject.autoMapping
export const selectEmptyKeys = (state) => state.newProject.emptyKeys
export const selectFileName = (state) => state.newProject.fileName
export const selectValidateCheck = (state) => state.newProject.validateCheck



export default NewProjectSlice.reducer