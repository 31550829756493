import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./NewUserDemo.styles";
import { ReactComponent as IconUpload } from '../../Assets/icon-upload.svg'; 
import { ReactComponent as IconConnector } from '../../Assets/icon-connector.svg'; 
import { ReactComponent as IconCalendar } from '../../Assets/icon-calendar.svg';
import  CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { useHistory } from "react-router-dom";
import { User } from "../../global/user";

const NewUserDemo = () => {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser } = User()

    const handleUploadClick = () => {
        history.push("/upload")
    }

    const handleClose = () => {
        history.push("/book")
    }

    return(
        <Grid container justifyContent="center" alignItems="center" className={classes.userDemoWrapper}>
            <Grid container justifyContent="center" direction="column" className={classes.userDemoContainer}> 
                <CloseIcon className={classes.newUserDemoClose} onClick={handleClose}/>

                <Typography variant="h4" className={classes.headerTitle}>
                    {!localStorage.getItem("name") || localStorage.getItem("name") === "full name" ? currentUser.email : localStorage.getItem("name")}, only 3 steps are needed to test your data
                </Typography>

                {/* Steps container */}
                <Grid container justifyContent="center" direction="column" className={classes.stepsContainer}>
                    <Grid container alignItems="center" className={classes.stepCard}>
                        <Grid item>
                            <Grid item className={classes.iconContainer}><IconUpload/></Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.stepHeader}>1. Upload Your File*</Typography>
                            <p className={classes.stepText}>Only 8 fields needed. Please review instructions</p>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" className={classes.stepCard}>
                        <Grid item>
                            <Grid item className={classes.iconContainer}><IconConnector/></Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.stepHeader}>2. Hook your data to our application</Typography>
                            <p className={classes.stepText}>The results demonstrate 1 year upside potential,<br/>most of which will be recurring</p>
                        </Grid>
                    </Grid> 

                    <Grid container alignItems="center" className={classes.stepCard}>
                        <Grid item>
                            <Grid item className={classes.iconContainer}><IconCalendar/></Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.stepHeader}>3. View Results and Run Campaigns</Typography>
                            <p className={classes.stepText}>See more meeting added to your Calendar</p>
                        </Grid>
                    </Grid>

                </Grid>

                
                <Grid item container justifyContent="center" className={classes.nextButtonContainer}>
                    <Button className={classes.nextButton} onClick={handleUploadClick}>
                        Got it!
                    </Button>
                </Grid>

                
                <Grid item container justifyContent="center">
                    <Typography className={classes.footerText}>
                        *If you have any questions or difficulties, please, contact us at
                        <br/><a href="mailto:support@atidot.com">support@atidot.com</a> or call <span>1-800</span> to discuss with our
                        <br/>team if needed
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
    )

}

export default NewUserDemo