import React from 'react'
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../style'

const ConnectorCard = ({
    name,
    logo,
    list,
    handleConfigure
}) => {
    const classes = useStyles()

    return (
        <Grid item xs={3} key={name}>
            <Card sx={{ minWidth: 275 }} className={classes.card}>
                <CardContent>
                    <img src={logo} alt={name} className={classes.connectorLogo} />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {list[name] && "Configured for account: " + list[name]?.credentials?.account} 
                        {!list[name] && "not configured"}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleConfigure(e, name)}
                        className={classes.confirmButton}
                        disabled={name !== "snowflake"}
                    >Configure</Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default ConnectorCard