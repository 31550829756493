import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useLayoutEffect, useRef } from 'react';
import {useStyles} from "./styles";
import { ReactComponent as InfoIcon } from '../../../../Assets/icon-info2.svg';
import { getCountryCurrencySymbol, nFormatter } from "../../../../global/helpers";
import { InfoTooltip } from "../../../ui/tooltips";

am4core.useTheme(am4themes_animated);

export const colorsDefault = ["#ff656f", "#ffe63e", "#4F58FF", "#DCDCDC","#4F58FF"];

export default function UIDistribution({
  paddingRight,
  chartData,
  id,
  totalText = 'Total',
  toolTipText = 'tooltip',
  innerRadius = 90,
  colorList = colorsDefault,
  emptyValue = "",
  isData = true
}) {
  const classes = useStyles();
  const currency = getCountryCurrencySymbol()

  const chart = useRef(null);

  const [totalValue, setTotalValue] = React.useState(0)

  useLayoutEffect(() => {
    let x = am4core.create(`UIDistribution_${id}`, am4charts.PieChart);
    

    x.data=chartData
    x.innerRadius = am4core.percent(innerRadius);  

    var series = x.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "id";
    series.name = "Value";
    series.labels.template.disabled = true;
    series.slices.template.stroke = am4core.color("#fff");
    series.slices.template.strokeWidth = 2;
    series.slices.template.strokeOpacity = 1;
    series.slices.template.zIndex = 2000;
    series.zIndex = 2000;
    series.tooltip.zIndex = 2000;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#FFFFFF");
    series.tooltip.label.fill = am4core.color("#1C1C1C");
    series.tooltip.fontFamily = 'Montserrat';
    series.tooltip.fontSize = 11;
    series.tooltip.fontWeight = 400;

    var colorSet = new am4core.ColorSet();
    const colors = chartData?.length > 0  && chartData[0]?.id !== "N/A" ? colorList : ["#c1c1c1"];
    colorSet.list =colors.map(function(color) {
      return new am4core.color(color);
    });

    series.colors = colorSet;

    series.hiddenState.properties.opacity = 1;
    series.hiddenState.properties.endAngle = -90;
    series.hiddenState.properties.startAngle = -90;

    if (chartData?.length > 0) {
      // Create custom legend
        x.events.on("ready", function(event) {
          event.target.tooltip.numberFormatter.numberFormat = '#,###.'
          event.target.tooltip.zIndex = Infinity
        
        const total = chartData.reduce((acc, cur) => {
          if (!isNaN(cur.value)) {
            acc = acc + +cur.value;
          }
          return acc;
        }, 0);

        const tmp = nFormatter(total, 3, currency)
        setTotalValue(tmp.includes(".") ? tmp.substring(0, tmp.indexOf(".")+3)+tmp[tmp.length-1] : tmp)
      });
    }
    
    if (chartData?.length === 0) {
      series.tooltipText = "N/A"
      series.tooltip.disabled = true
      x.data = [{ id: 'N/A', value: 1}]
    }

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [chartData, id]);

  useLayoutEffect(() => {
    chart.current.paddingRight= paddingRight
  }, [paddingRight]);

  useLayoutEffect(() => { // Remove amCharts logo inside svg
    setTimeout(() => {
      [...document.querySelectorAll("title")]
          .filter(a => a.textContent.includes("Chart created using amCharts library"))
          .forEach(element => {
            const fullElement = element.closest('g');
            fullElement.classList.add('hidden-chart-blocker');
            fullElement.parentElement.removeChild(fullElement);
          })
    }, 100)
  }, [chartData, id]);

  return (
    <div className={classes.BarsBox}>
      <div id={`UIDistribution_${id}`} style={isData ? {width: "100%", height: "100%"} : { opacity: .2, width: "100%", height: "100%" }}/>
      <div className={classes.labelText} style={{ zIndex: 0}}>
        {totalText &&
        <div className={classes.labelBox}>
          <p>{totalText}</p>
            <InfoTooltip title={toolTipText} placement="top">
              <div className={classes.infoIconBox}>
                  <InfoIcon/>
              </div>
            </InfoTooltip>
        </div>
        }
        <span id={`value_${id}`}>{!totalValue && emptyValue ? emptyValue : totalValue}</span>
      </div>
      <div style={{position: "absolute"}}><div style={{ position: "relative", background: "#fff", width: 54, height: 20, top: -18, left: 6 }}/></div>
    </div>
  );
}
