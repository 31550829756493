import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { Alert } from "@mui/material"
import StatusesApiService from "./StatusesApiService"
import PolicyStatusesForm from "../../Components/Forms/PolicyStatusesForm/PolicyStatusesForm"
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup"

const ApiService = new StatusesApiService()

const StatusesConfig = () => {
    const [ statuses, setStatuses ] = useState([])
    const [ values, setValues ] = useState([])
    const [ deleteItems, setDeleteItems ] = useState([])
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ alertMessage, setAlertMessage ] = useState('')
    const [ status, setStatus ] = useState('')

    const handleCloseAlert = () => setAlertOpen(false)

    const handleAddStatus = () => {
        setStatuses([
            ...statuses,
            {
                id: crypto.randomUUID(),
                status: "",
            }
        ])
    }

    const handleDelete = (id) => {
        setDeleteItems([ ...deleteItems, id ])
        setValues([
            ...values,
            statuses.find((item) => item.id === id),
        ])
        setStatuses([
            ...statuses.filter((item) => item.id !== id),
        ])
    }

    const handleChangeStatus = (e) => {
        const statusId = statuses.findIndex(({id}) => `${id}` === e.target.name)
        const valueId = values.findIndex(({id}) => `${id}` === e.target.name)
        const newStatuses = [ ...statuses ]
        const newValues = [ ...values ]
        newStatuses.splice(statusId, 1, {
            ...statuses[statusId],
            status: e.target.value
        })
        if (valueId > -1) {
            newValues.splice(valueId, 1, {
                ...values[valueId],
                status: e.target.value
            })
        }
        console.log(valueId, statusId);
        setValues(valueId > -1 ? newValues :
        [
            ...values,
            {
                ...statuses[statusId],
                status: e.target.value
            }
        ]
        )
        setStatuses(newStatuses)
    }
    console.log(values);

    const handleSubmitSuccess = () => {
        setValues([])
        setStatus("success")
        setAlertMessage("Policy statuses are updated")
        setAlertOpen(true)
    }

    const handleSubmit = () => {
        values.forEach(({ created_at, id, status }, index) => {
            console.log(created_at);
            if (created_at) {
                if (deleteItems.includes(id)) {
                    ApiService._deleteStatus(id, () => {
                        if (index === values.length-1) {
                            handleSubmitSuccess() 
                        }
                    }, (error) => console.log(error))                    
                } else {
                    ApiService._patchStatus(id, { status }, () => {
                        if (index === values.length-1) {
                            handleSubmitSuccess() 
                        }
                    }, (error) => console.log(error))
                }
            } else {
                ApiService._postStatus({ status }, () => {
                    if (index === values.length-1) {
                        handleSubmitSuccess() 
                    }
                }, (error) => console.log(error))
            }
        })
    }

    useEffect(() => {
        ApiService._getStatusesList((response) => {
            setStatuses(response.data.statuses)
        }, (error) => console.log(error))
    }, [])
   
    return (
        <Grid container>
            <PolicyStatusesForm
                statuses={statuses}
                onChange={handleChangeStatus}
                onAdd={handleAddStatus}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
                disabled={!Object.keys(values)?.length}
            />
            
            <InformationPopup
                open={alertOpen}
                onClose={handleCloseAlert}
                onConfirm={handleCloseAlert}
                closeControl={false}
                confirmText="OK"
                title={status === "success" ? "Success" : "Error"}
            >
                <Alert severity={status} style={{ width: 600 }}>
                    {alertMessage}
                </Alert> 
            </InformationPopup>
        </Grid>
    )
}

export default StatusesConfig