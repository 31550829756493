import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { useStyles } from "../styles";
import { ReactComponent as LogoIcon } from '../../../Assets/icon-logo.svg';

const Calendar = ({ fullWidth = false, name, date, handleDateChange }) => {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            className={clsx(
                classes.schedulerItem,
                classes.schedulerCalendar,
                fullWidth ? classes.schedulerItemFullWidth : ""
            )}
        >
            <Grid container justifyContent="center" className={classes.schedulerLogo}>
                <LogoIcon/>
            </Grid>
            <Grid container justifyContent="center">
                <Typography className={classes.calendarTitle}>{name}</Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker date={date} onChange={(NewValue) => handleDateChange(NewValue)} disablePast/>
            </LocalizationProvider>
        </Grid>
    )
}

export default Calendar