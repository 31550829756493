import React from "react";
import LoginForm from "../../Components/Forms/Login/LoginForm";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";

const Login = () => (
        <LoginContainer> 
            <LoginForm/>
        </LoginContainer>   
)

export default Login;