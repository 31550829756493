import axios from "axios"
import { Manager } from "socket.io-client"


export class ApiService {
     refreshCalls = 0
     defaultHeaders = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
     }
     webSocketInstance = null

     getDefaultHeaders = () => {
        return {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
        }
     }

     openWebSocket = (path = "/", baseUrl = process.env.REACT_APP_API_URL) => {
        const manager = new Manager(baseUrl, {
            extraHeaders: this.getDefaultHeaders()
        })
        this.webSocketInstance = manager.socket(path)
        manager.open((err) => {
          if (err) {
            console.log(err);
          }
        });

        this.webSocketInstance.io.on("error", (err) => {
          console.log(err);
        });

        this.webSocketInstance.emit("join_company_room", 'asd') 

        this.webSocketInstance.io.on("close", () => {
          console.log("close");
        }); 

        return this.webSocketInstance
     }

     refreshAccessToken = (callback = () => {}, errorHandler = () => {}, options = {}) => {
        axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/token/refresh", {}, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("refresh"),
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                localStorage.setItem("token", response.data["access_token"])
                if(this.refreshCalls < 5) {
                    if (this.webSocketInstance) {
                        this.webSocketInstance.emit("leave_company_room", 'asd')
                        this.webSocketInstance = null
                        this.openWebSocket()
                    }
                    callback({headers: {...this.getDefaultHeaders(), ...options}})
                } else {
                    this.refreshCalls = 0
                }
            })
            .catch((error) => {
                errorHandler(error);
            })      
    }

    cacheResponse = (id, data) => {
        if (data) {
            const storage = sessionStorage.getItem("atidot_platform_data") ? JSON.parse(sessionStorage.getItem("atidot_platform_data")) : {}
            const encodedData = JSON.stringify(
                {
                    ...storage,
                    [id]: {
                        ...data,
                        micro: new Date().getTime()
                    }
                }
            )
            sessionStorage.setItem("atidot_platform_data", encodedData)

        }
    }
    
    getCachedData = (id, callback) => {

        const storage = sessionStorage.getItem("atidot_platform_data") ? JSON.parse(sessionStorage.getItem("atidot_platform_data")) : {}
        if (!storage[id]) {
            return null
        }
        const date = new Date().setTime(storage[id].micro)
        const now = new Date().getTime()
        const timeElapsed = new Date(now - date).getMinutes()
        if (timeElapsed > 5) {
            return null
        }
        callback({ data: storage[id] })
        return true
    }

    get = (url, config, responseHandler, errorHandler, baseUrl = process.env.REACT_APP_API_URL, data = {}, cached = false) => {
        const cache = cached ? this.getCachedData("GET" + baseUrl + url, responseHandler) : null
        if (!cache) {
            axios.get(baseUrl + url, { headers: { ...config, ...this.getDefaultHeaders()}, params: data})
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
                    cached && this.cacheResponse("GET" + baseUrl + url, response?.data)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.get(url, headers, responseHandler, errorHandler, baseUrl), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
        }

    } 

    post = (url, config, responseHandler, errorHandler, data = {}, baseUrl = process.env.REACT_APP_API_URL, cached = false) => {
        const cache = cached ? this.getCachedData("POST" + baseUrl + url, responseHandler) : null
        if (!cache) {
            axios.post(baseUrl + url, data,{ headers: {  ...this.getDefaultHeaders(), ...config }})
                .then((response) => {
                        this.refreshCalls = 0
                        responseHandler(response)
                        cached && this.cacheResponse("POST" + baseUrl + url, response?.data)
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        this.refreshCalls++
                        this.refreshAccessToken((headers) => this.post(url, headers, responseHandler, errorHandler, data, baseUrl), errorHandler, config)
                    } else {
                        errorHandler(error)
                    }
                })

        }
    }

    patch = (url, config, responseHandler, errorHandler, data = {}, baseUrl = process.env.REACT_APP_API_URL, cached = false) => {
        const cache = cached ? this.getCachedData("PATCH" + baseUrl + url, responseHandler) : null
        if (!cache) {
            axios.patch(baseUrl + url, data, { headers: { ...config, ...this.getDefaultHeaders()}})
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
                    cached && this.cacheResponse("PATCH" + baseUrl + url, response?.data)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.patch(url, headers, responseHandler, errorHandler, data, baseUrl), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
        }
    }
    
    put = (url, config, responseHandler, errorHandler, data = {}, baseUrl = process.env.REACT_APP_API_URL, cached = false) => {
        const cache = cached ? this.getCachedData("PUT" + baseUrl + url, responseHandler) : null
        if (!cache) {
            axios.put(baseUrl + url, data, { headers: { ...config, ...this.getDefaultHeaders()}})
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
                    cached && this.cacheResponse("PUT" + baseUrl + url, response?.data)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.put(url, headers, responseHandler, errorHandler, data, baseUrl), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
        }
    }

    delete = (url, config, responseHandler, errorHandler, baseUrl = process.env.REACT_APP_API_URL, cached = false) => {
        const cache = cached ? this.getCachedData("DELETE" + baseUrl + url, responseHandler) : null
        if (!cache) {
            axios.delete(baseUrl + url, { headers: { ...config, ...this.getDefaultHeaders()}})
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
                    cached && this.cacheResponse("DELETE" + baseUrl + url, response?.data)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.delete(url, headers, responseHandler, errorHandler, baseUrl), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })

        }
    }
}

export default ApiService = new ApiService()