import { Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
    container: {
        height: 0,
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 24,
            color: "#6A6C71"
        },
    },
    text: {
        position: "relative",
        top: 184
    }
}))

const NoDataIndicator = ({ title = "No Data Provided" }) => {
    const classes = useStyles()

    return (
        <Grid container justifyContent="center" className={clsx(classes.container, "nodata-indicator--container")}>
            <Typography className={clsx(classes.text, "nodata-indicator--text")}>{title}</Typography>
        </Grid>
    )
}

export default NoDataIndicator