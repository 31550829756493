import ApiService from "./global/service";

export default class appApiService {
    _getRuns = (id, requestHandler, errorHandler) => ApiService.get(`/projects?company_id=${id}&metadata=true&sort=created_at`, {}, requestHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getRunsCount = (id, requestHandler, errorHandler) => ApiService.get(`/projects/count?company_id=${id}`, {}, requestHandler, errorHandler)

    _getRunsList = (id, requestHandler, errorHandler) => ApiService.get(`/projects?company_id=${id}&sort=created_at`, {}, requestHandler, errorHandler)

    _getRunsDataByUser = (id, requestHandler, errorHandler) => ApiService.get(`/projects?user_id=${id}&metadata=true`, {}, requestHandler, errorHandler)

    _getRunsListByUser = (id, requestHandler, errorHandler) => ApiService.get(`/projects?user_id=${id}`, {}, requestHandler, errorHandler)

    _getDemoRunsData = (requestHandler, errorHandler) => ApiService.get(`/projects/demo?metadata=true`, {}, requestHandler, errorHandler)

    _getLastRun = (requestHandler, errorHandler) => ApiService.get('/runs/last', {}, requestHandler, errorHandler)

    _getDataSources = (requestHandler, errorHandler) => ApiService.get('', {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)

    _getDownloadDataSource = (source_id, requestHandler, errorHandler) => ApiService.get(`data/${source_id}/download`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)
    
    _patchDeleteDataSource = (source_id, requestHandler, errorHandler) => ApiService.patch('', {}, requestHandler, errorHandler, {
        source_id
    }, process.env.REACT_APP_DH_API_URL)
    
    _getAllCampaigns = (requestHandler, errorHandler) => ApiService.get("/v1/metadata", {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getCampaignsList = (requestHandler, errorHandler) => ApiService.get("/v1/campaigns", {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _getRuningCampaignsById = (runs, requestHandler, errorHandler) => ApiService.get(`/v1/metadata/count/run_ids=${runs.join(",")}?status=30`, {}, requestHandler, errorHandler, process.env.REACT_APP_CAMPAIGNS_API_URL, {}, true)
    
    _openProgressWebSocket = () => ApiService.openWebSocket()
}