import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, makeStyles, styled} from "@material-ui/core";
import IconLife from "../../../Assets/icon-life.svg";
import IconHubSpot from "../../../Assets/icon-hubspot.svg";
import IconSmartOffice from "../../../Assets/icon-smartoffice.svg";
import IconSalesForce from "../../../Assets/icon-salesforce.svg";
import IconRemark from "../../../Assets/icon-remark.svg";
import IconZinnia from "../../../Assets/icon-zinnia.svg";
import { ReactComponent as IconSuccess } from "../../../Assets/icon-campaigns-success.svg";
import  CloseIcon from '@mui/icons-material/Close';
import { Link, useHistory } from "react-router-dom";
import { UIViewButton } from "../../../Components/ui/buttons";

const ProviderButton = styled(Button)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 167,
    height: 55,
    background: "#FFFFFF",
    border: "1px solid #D9E1EE",
    borderRadius: 8,
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)"
})

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: "Roboto",
        "& .MuiDialogActions-root": {
            justifyContent: "center",
            paddingTop: 16
        },
        "& .MuiPaper-root": {
            width: 650,
            padding: "41px 27px 40px 27px",
            textAlign: "center",
            color: "#515151",
            "& .MuiTypography-root": {
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 700,
                lineHeight: "26px",
                "& span": {
                    fontWeight: 400
                }
            }
        }
    },
    providerContainer: {
        maxWidth: "33%",
        flexBasis: "33%",
        paddingLeft: 18,
        paddingBottom: 11,
        "&:nth-child(3n+1)": {
            paddingLeft: 0
        },
    },
    demoText: {
        cursor: "pointer",
        color: "#363ED3",
        textDecoration: "underline",
        "&:hover": {
            textDecoration: "none"
        }
    },
    closeButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "absolute",
        top: 16,
        right: 36,
        width: 29,
        height: 29,
        border: "1px solid",
        borderRadius: "50%",
        opacity: .5,
        "& svg": {
            width: 14,
            height: 14
        }
    }
}))

const SuccesStateComponent = ({ title = "", message = "" }) => {

    return (
        <DialogContent className="succes-dialog--container">
            <IconSuccess/>
            <DialogContentText id="campaign-dialog-text">
                {title}<br/><span>{message}</span>
            </DialogContentText>
        </DialogContent>
    )
}

const CreateCampaignsDialog = ({ open, provider, setProvider, onClose, onConfirm, demo = true, confirmText = "Confirm", succeesState = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const [ success, setSuccess ] = useState(succeesState)

    const handleSelectChange = (value) => {
        setProvider(value)
    }

    const handleConfirm = () => {
        if (demo) {
            history.push("/registration")
        } else {
            if (!success) {
                onConfirm(() => setSuccess(true))
            }
            history.push("/campaigns") 
        }
    }

    return (
        <div>
          <Dialog
            className={classes.root}
            open={open}
            onClose={onClose}
            aria-labelledby="campaign-dialog-title"
            aria-describedby="campaign-dialog-description"
          >
            <div className={classes.closeButton} onClick={onClose}>
                <CloseIcon/>
            </div>
            {!success ? 
                <DialogContent>
                    <DialogContentText id="campaign-dialog-text">
                        Please choose one of our partners or a CRM<br/>provider that will execute campaigns.
                    </DialogContentText>
                    <Grid container>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="atidot" onClick={() => handleSelectChange(6)} style={provider === 6 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconLife} alt="Atidot Life"/>
                            </ProviderButton>
                        </Grid>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="hubspot" onClick={() => handleSelectChange(2)} style={provider === 2 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconHubSpot} alt="Hub Spot"/>
                            </ProviderButton>
                        </Grid>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="smartoffice" onClick={() => handleSelectChange(4)} style={provider === 4 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconSmartOffice} alt="Smart Office"/>
                            </ProviderButton>
                        </Grid>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="salesforce" onClick={() => handleSelectChange(3)} style={provider === 3 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconSalesForce} alt="Sales Force"/>
                            </ProviderButton>
                        </Grid>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="remark" onClick={() => handleSelectChange(1)} style={provider === 1 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconRemark} alt="ReMark"/>
                            </ProviderButton>
                        </Grid>
                        <Grid item container className={classes.providerContainer}>
                            <ProviderButton id="zinnia" onClick={() => handleSelectChange(5)} style={provider === 5 ? { borderColor: "#363ED3" } : {}}>
                                <img src={IconZinnia} alt="Zinnia"/>
                            </ProviderButton>
                        </Grid>
                    </Grid>
                </DialogContent> 
                :
                <SuccesStateComponent title="All campaigns were successfully created!" message="Operations will start immediately."/>
             }
            <DialogActions>
              <UIViewButton onClick={handleConfirm} autoFocus disabled={!provider}>
                {success ? "Go To Campaigns List" : confirmText}
              </UIViewButton>
            </DialogActions>
            {!succeesState && <Link to="/campaigns" className={classes.demoText} style={{ visibility: "hidden"}}>Click here for demo campaign</Link>}
          </Dialog>
        </div>
    )
}

export default CreateCampaignsDialog

export { SuccesStateComponent }