import React, { useRef } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useStyles } from "./styles";
import { ReactComponent as MinusIcon } from "../../../Assets/icon-minus-alt.svg";
import { ReactComponent as PlusIcon } from "../../../Assets/icon-plus.svg";
import FormInput from "../FormInput";
import { UIViewButton } from "../../ui/buttons";
 
const PolicyStatusesForm = ({ statuses = [], onSubmit, onChange, onDelete, onAdd, disabled }) => {
    const classes = useStyles()
    const formRef = useRef()

    return (
        <Grid item container>
            <Grid item container>
                <Typography className={classes.title}>Policy Statuses:</Typography>
            </Grid>
            <Grid item container>
                <ValidatorForm ref={formRef} onSubmit={onSubmit} className={classes.statusForm}>
                    <Grid item container direction="column" className={classes.statusFormContent}>
                    {
                        statuses.map(({ id, status }, index) => (
                            <Grid item container alignItems="center" className={classes.statusContainer}>
                                <Grid item className={classes.statusInputContainer}>
                                    <FormInput
                                        name={id}
                                        placeholder="Enter status name"
                                        value={status}
                                        autoFocus
                                        data-testid={id}
                                        onChange={onChange}
                                        validators={['required']}
                                        errorMessages={['required']}
                                    />    
                                </Grid>
                                <Grid item className={classes.statusButtonContainer}>
                                    <IconButton onClick={() => onAdd(id)} disabled={index+1 !== statuses.length}>
                                        <PlusIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item className={classes.statusButtonContainer}>
                                    <IconButton onClick={() => onDelete(id)} disabled={statuses.length === 1}>
                                        <MinusIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))
                    }
                    <Grid item container>
                        <UIViewButton
                            type="submit"
                            style={{ height: 42, width: 120 }}
                            data-testid="submitButton"
                            disabled={disabled}
                        >
                            Save
                        </UIViewButton>
                    </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    )
}

export default PolicyStatusesForm