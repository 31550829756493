import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AtidotText } from "../../Assets/icon-atidot-text.svg";
import SendCodeForm from "../../Components/Forms/SendCodeForm/SendCodeForm";
import axios from "axios";

const useStyles = makeStyles(() => ({
    bg: {
        background: "#F9FAFB",
        width: "100%",
        height: "100vh"
    },
    root: {
        height: "100vh",
        paddingTop: 15,
        "@media (min-width:1440px)": {
            paddingTop: 40,
        }
    },
    emailText:{
        paddingTop:56,
        paddingBottom:27,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 32,
        lineHeight: "32px",
        color: '#312E40',
        "@media (min-width:1440px)": {
            paddingBottom: 49,
        }
    },
    formContainer:{
        width: "100%",
        maxWidth: 395
    },
}))

const SendCode = () => {
    const classes = useStyles()
    const history = useHistory()
    
    const handleResendCode = (handleSuccess, handleError, email) => {
        axios.get(
            process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${email}/activation/code`
        )
            .then((res) => {
                handleSuccess()
                const date = new Date();
                history.push("/confirmaccount?time="+date.getTime())
            })
            .catch((err) => {
                handleError(err)
            })
    }
    


    return (
        <Paper variant="elevation" elevation={2} className={classes.bg}>
            <Grid container direction="column" className={classes.root}>
                <Grid container direction="column" justifyContent="center" alignContent="center">
                        <Grid align="center" item>
                            <AtidotText/>
                            <Typography align="center" component="p" className={classes.emailText}>
                                Please Enter Your Email
                            </Typography>
                        </Grid>    
                </Grid>
                <Grid container direction="column" justifyContent="center" alignContent="center">
                    <Grid item className={classes.formContainer}>
                        <SendCodeForm
                            onSend={handleResendCode}/>
                    </Grid>    
                </Grid>    
            </Grid>
        </Paper>
    )
}

export default SendCode