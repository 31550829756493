import React from "react";
import { ReactComponent as High } from "../../../Assets/icon-priority-high.svg"
import { ReactComponent as Mid } from "../../../Assets/icon-priority-mid.svg"
import { ReactComponent as Low } from "../../../Assets/icon-priority-low.svg"

const PriorityIndicator = ({ priority }) => {
    const getPriorityItem = () => {
        switch (priority) {
            case "high": case "h": case 3:
                return <High/>    
            case "mid": case "medium": case ",": case 2:
                return <Mid/>  
            case "low": case "l": case 1:
                return <Low/>
            case undefined: case "N/A":
                return "N/A"
        
            default:
                return <Low/>
        }
    }

    return getPriorityItem()

}

export default PriorityIndicator