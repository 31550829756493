import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "center",
        paddingBottom: 16
    },     
    statusForm: {
        width: "100%"
    },      
    statusFormContent: {
        gap: 12
    },  
    statusContainer: {
        gap: 12,
        "& .MuiIconButton-root.Mui-disabled": {
            opacity: .5,
        },
    },
    statusInputContainer: {
        width: "100%",
        maxWidth: 350
    },
    statusButtonContainer: {
        width: 45
    },
}));