import { styled } from "@material-ui/core"
import { useEffect, useState } from "react"

const StatusContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 16,
    height: 16,
    borderRadius: "50%",
    fontFamily: "Roboto",
    fontSize: 10,
    fontWeight: 700,
    color: "#FFF",
    paddingRight: 2
})

const RiderStatusIndicator = ({ status }) => {
    const [ color, setColor ] = useState("")

    useEffect(() => {
        switch (status) {
            case "active (no fee)": case "active (fee)":
                setColor("#0BCB47")
                break;

            case "non active (no fee)": case "non active (fee)": case "not active (fee)": case "not active (no fee)":
                setColor("#FF4B62")
                break;
        
            default:
                break;
        }
    }, [status])

    return (
        status ? <StatusContainer style={{ background: color, }}>
            {status.includes("(fee)") ? "F" : ""}
        </StatusContainer>
        : "N/A"
    )

}

export default RiderStatusIndicator