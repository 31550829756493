import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    count: 0,
    prospects: [],
}
const name = 'leads'

export const leadsSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setLeads: (state, action) => {
            state.count = action.payload.count
            state.prospects = action.payload?.leads?.map(({
                email,
                firstname,
                lastname,
                phone,
                company,
                income,
                created_at,
                updated_at,
                isagent,
                status,
                utm_campaign,
                utm_medium,
                utm_source
            }) => ({
                "client_name": !firstname ? "" : lastname ? `${firstname} ${lastname}` : firstname,
                email,
                phone,
                created_at,
                updated_at,
                company,
                income,
                isagent,
                status,
                utm_campaign,
                utm_medium,
                utm_source
            }))
        },
    }
})

export const { setLoading, setLeads } = leadsSlice.actions

export const selectLeads = (state) => state.Leads

export default leadsSlice.reducer