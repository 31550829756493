import { ApiService } from "../../global/service";

export default class schedulerApiService {
    _getTimeSlots = (agent, lead, start_date, end_date, duration_minutes, requestHandler, errorHandler) => ApiService.get(`/scheduler/availability?start_date=${start_date}&end_date=${end_date}&participants=${agent},${lead}&duration_minutes=${duration_minutes}&interval_minutes=${duration_minutes}`, {}, requestHandler, errorHandler)

    _postShedulerEvent = (lead_id, agent_id, time, timezone, duration, requestHandler, errorHandler) => ApiService.post(`/scheduler`, {}, requestHandler, errorHandler, { 
        lead_id,
        agent_id,
        title: "test",
        description: "",
        time,
        timezone,
        duration
    })

    _postCreateShedulerAccount = (email, owner, requestHandler, errorHandler) => ApiService.post(`/scheduler/accounts`, {}, requestHandler, errorHandler, { email, owner })

    _getShedulerAccount = (email, requestHandler, errorHandler) => ApiService.get(`/scheduler/accounts?email=${email}`, {}, requestHandler, errorHandler)

    _patchShedulerAccount = (user_id, data, requestHandler, errorHandler) => ApiService.patch(`/scheduler/accounts?user_id=${user_id}`, {}, requestHandler, errorHandler, data)

    _getOAuthCode = (code, requestHandler, errorHandler) => ApiService.get(`/scheduler/accounts/callback?code=${code}`, {}, requestHandler, errorHandler)

    _getWorkingHours = (user_id, requestHandler, errorHandler) => ApiService.get(`/scheduler/accounts/working-hours/?user_id=${user_id}`, {}, requestHandler, errorHandler)

    _postWorkingHours = (user_id, data, requestHandler, errorHandler) => ApiService.post(`/scheduler/accounts/working-hours/?user_id=${user_id}`, {}, requestHandler, errorHandler, data)

    _patchWorkingHours = (user_id, data, requestHandler, errorHandler) => ApiService.patch(`/scheduler/accounts/working-hours/${user_id}`, {}, requestHandler, errorHandler, data)

    _deleteWorkingHours = (user_id, requestHandler, errorHandler) => ApiService.delete(`/scheduler/accounts/working-hours/${user_id}`, {}, requestHandler, errorHandler)
}