import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    controlsContainer: {
        paddingBottom: 18
    },
    tableTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "center",
        paddingBottom: 15
    },
    tableCointainer: {
        borderRadius: 6,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        padding: 30,
        paddingTop: 0,
    },
    tableHeader: {
        borderRadius: 6,
        backgroundColor: "#eef0f4",
        height: 44,
        '& .MuiTableCell-root': {
            borderBottom: "none"
        },
        '& .MuiTableCell-stickyHeader': {
            textAlign: "left",
            backgroundColor: "#eef0f4",
        },
        '& th:first-child': {
            borderRadius: '6px 0 0 6px'
        },
        '& th:last-child': {
            borderRadius: '0 6px 6px 0'
        },
    },
    tableCell: {
        borderBottom: "none !important",
        fontFamily: "Roboto",
        fontSize: 14,
        color: "#312E40",
        fontWeight: 400,
        paddingTop: 0,
        paddingBottom: 0
    },
    tableBody: {
        backgroundColor: '#fff',
        borderRadius: 6,
        borderCollapse: 'collapse'
    },
    tableRow: {
        borderBottom: "2px solid #ECF1F9;",
        marginLeft: 6,
        marginTop: 16,
        fontSize: 14,
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        }
    },
    noResult: {
        minHeight: 330,
        alignItems: "center",
        '& .MuiTypography-root': {
            fontSize: 32,
            fontWeight: 500
        }
    },
}));