import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import clsx from "clsx"

// MUI
import {
  Button, Dialog,
  DialogActions, Grid, InputAdornment, Popover, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton
} from "@material-ui/core"
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SearchIcon from '@mui/icons-material/Search'
import TablePagination from '@mui/material/TablePagination'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


// import { ReactComponent as EditIcon } from "../../Assets/icon-pencil.svg"
// import { ReactComponent as DeleteIcon } from "../../Assets/icon-trashcan.svg"

// Components
import SelectFilter from "../../Components/Filters/SelectFilter"
import CreateEditUser from "../../Components/Forms/CreateEditUser/CreateEditUser"
import UserRoleItem from "./UserRoleItem/UserRoleItem"
import UserRoleSelector from "./UserRoleSelector/UserRoleSelector"

// Redux
import { selectUserRole, selectUserCompany } from "../../AppSlice"
import UserManagmentService from "./UserManagementService"
import { amplitudeService } from "../../global/helpers"

// Common
import { useStyles } from './styles'
import { checkPermission } from "../../global/user"

const defaultRoleFilter = { "All": true }

const userInitData = {
  name: "",
  email: "",
  phone: "",
  title: "",
  password: "",
  country_code: "US",
  company: "",
  roleIds: [6]
}

const headers = [
  "name",
  "email",
  "company",
  "role",
  "actions"
]

const headerSort = {
  "name": "name",
  "email": "email",
  "role": ""
}

const ApiService = new UserManagmentService()

const UserManagement = () => {
  const classes = useStyles()
  const history = useHistory()

  const [users, setUsers] = useState(null)
  const [userRoles, setUserRoles] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [orderBy, setOrderBy] = useState("-created_at")
  const [search, setSearch] = useState(null)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [roleFilter, setRoleFilter] = useState(null)
  const [userUpdates, setUserUpdates] = useState(null)
  const [roleUpdates, setRoleUpdate] = useState(null)
  const [openRolesPopover, setOpenRolesPopover] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [userData, setUserData] = useState(userInitData)
  const [isEdit, setIsEditUser] = useState(true)
  const [openSnackbar, setSnackbarOpen] = React.useState({ open: false, message: "" });
  const [openActionsPopover, setOpenActionsPopover] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState({ left: 0, top: 0 })
  const [disabled, setDisabled] = React.useState(false)

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const userRole = useSelector(selectUserRole)
  const userCompany = useSelector(selectUserCompany)

  userInitData['company'] = userCompany

  const resetUsers = () => {
    const queryParams = []
    if (orderBy) {
      queryParams.push(`sort=${orderBy}`)
    }
    ApiService._getUsers((response) => {
      setUserUpdates(null)
      setRoleUpdate(null)
      setSelectedUsers([])
      setUsers(response.data.users)
    }, (error) => { console.log(error) }, queryParams)
  }

  const userFilter = (user) => {
    // Role filter
    let inRole = true
    if (roleFilter && !roleFilter["All"]) {
      const currentUserRoles = user.roles.map((role) => role.name)
      const filteredRoles = Object.keys(roleFilter).filter((key) => roleFilter[key])
      inRole = currentUserRoles.map((role) => filteredRoles.includes(role.replace("platform:", ""))).includes(true)
    }

    // Name/email search
    if (inRole) {
      return search ? user.email.includes(search) || (user?.name && user.name.includes(search)) : true
    }
    return false
  }


  const handleSort = (e) => {
    setOrderBy(headerSort[e.target.id])
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionsClick = (e, id) => {
    e.stopPropagation();
    const position = e.target.getBoundingClientRect();
    setPopoverAnchor({ top: position.top, left: position.left });
    setSelectedUsers([id]);
    setOpenActionsPopover((openActions) => !openActions);
  }

  const handleUpdateUsers = (e) => {
    if (e.target.name !== "roleIds") {
      setUserUpdates({
        ...userUpdates,
        [e.target.name]: e.target.value
      })
    } else {
      setRoleUpdate(parseInt(e.target.value))
    }
  }

  function handleCreateUser({ company, roleIds, ...data }) {
    setDisabled(true)
    ApiService._putCreateCompany({
      name: company,
      account_id: 6
    }, (res) => {
      ApiService._postCreateUser({
        ...data,
        role_ids: [parseInt(roleIds)],
        company_id: res.data["company"]["id"]
      }, () => {
        setOpenCreateModal(false)
        setDisabled(false)
        resetUsers()
      }, (error) => {
        console.log(error);
        setSnackbarOpen({ open: true, message: error?.response?.data?.error || "User wasn't created", severity: "error" })
        setDisabled(false)
      })

    }, (error) => { console.log(error) })
    amplitudeService._throwEvent("USER_CREATE", { "company": company })


  }

  const handleDeleteUsers = () => {
    selectedUsers?.forEach((item, index) => {
      ApiService._deleteUser(item, () => {
        if (index === selectedUsers.length - 1) {
          setOpenDeleteModal(false)
          resetUsers()
        }
      }, (error) => { console.log(error) })
    })
  }

  const handleEditUsers = () => {
    selectedUsers?.forEach((item, index) => {
      const subject = users.find(({ id }) => item === id)

      // Update user data
      if (userUpdates) {
        ApiService._patchEditUser(item, userUpdates)
      }

      // Update user role
      if (subject?.roles[0]?.id !== roleUpdates) {
        subject?.roles[0]?.id && ApiService._deleteRemoveUserRole(item, subject.roles[0].id,
          () => { }, (error) => { console.log(error) })
        ApiService._postAssignUserRole(item, roleUpdates,
          () => { console.log(roleUpdates); }, (error) => { console.log(error) })
      }

      if (index === selectedUsers.length - 1) {
        setOpenEditModal(false)
        resetUsers()
      }

    }, (error) => { console.log(error) })
  }

  const handleSetEditUser = (user) => {
    setUserData({
      id: user.id,
      name: user.name || "",
      email: user.email,
      phone: String(user.phone) || "",
      title: user.title || "",
      password: "",
      country_code: "US",
      company: user.membership.company.name,
      roleIds: user.roles.map((r) => r.id)
    })
  }


  const handleEditUser = ({ company, ...user }) => {

    const { id, password, roleIds, ...subject } = user

    ApiService._patchEditUser(
      id,
      subject,
      () => {
        const prevData = users.filter((user) => user.id === id)[0]
        if (prevData?.roles[0]?.id !== roleIds[0]) {
          prevData?.roles[0]?.id && ApiService._deleteRemoveUserRole(id, prevData.roles[0].id,
            () => { }, (error) => { console.log(error) })
          ApiService._postAssignUserRole(id, roleIds[0],
            () => { console.log(roleIds); }, (error) => { console.log(error) })
        }

        setOpenCreateModal(false)
        setUserData(userInitData)
        resetUsers()
        setSnackbarOpen({ open: true, message: `User (${subject.email}) was updated`, severity: "success" })

      },
      (error) => {
        setSnackbarOpen({ open: true, message: error?.response?.data?.error || "User wasn't updated", severity: "error" })
      }
    )

  }

  // const handleRolesClick = (e) => {
  //   e.stopPropagation();
  //   const position = e.target.getBoundingClientRect();
  //   setPopoverAnchor({ top: position.bottom + 25, left: position.left });
  //   setOpenRolesPopover((openActions) => !openActions);
  // }

  useEffect(() => {
    if (checkPermission('view_settings_page')) {
      !userRoles && ApiService._getUsersRoles((response) => {
        const roles = response.data.roles[0].sort((p,n)=>p.id > n.id ? 1 : -1)

        roles.forEach((role) => { defaultRoleFilter[role.name.replace("platform:", "")] = false })

        setUserRoles(
          roles?.map((item) => ({
            ...item,
            label: item.name.replace("platform:", "")
          }))
        )
      }, (error) => { console.log(error) })
    } else {
      history.push("/")
    }
  }, [history, userRole, userRoles])

  useEffect(() => {
    if (checkPermission('view_settings_page')) {
      const queryParams = []
      if (orderBy) {
        queryParams.push(`sort=${orderBy}`)
      }
      userRoles && ApiService._getUsers((response) => {
        setUsers(response.data.users)
        setPage(0)
      }, (error) => { console.log(error) }, queryParams)
    } else {
      history.push("/")
    }
  }, [history, userRole, userRoles, orderBy])

  useEffect(() => {
    const queryParams = []
    if (orderBy && orderBy !== "-created_at") {
      queryParams.push(`sort=${orderBy}`)
    }
    if (search) {
      // We use search on frontend.
      // Because we have multiple selecting and editing of users.
      // queryParams.push(`email:like=${search}`)
    }
    if (queryParams.length > 0) {
      ApiService._getUsers((response) => {
        setUsers(response.data.users)
      }, (error) => console.log(error), queryParams)
      setPage(0)
    }
  }, [search, orderBy, roleFilter])

  return (
    <Grid container>
      <TableContainer className={classes.root}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.controlsContainer}>
          <Grid>
            <Typography className={classes.tableTitle}>Account Contributions</Typography>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center">
            {/* <Grid>
                            <Button className={classes.editButton} onClick={() => setOpenEditModal(true)} disabled={selectedUsers.length === 0}><EditIcon/>Edit users</Button>
                        </Grid>
                        <Grid>
                            <Button className={classes.editButton} onClick={() => {setOpenDeleteModal(true)}} disabled={selectedUsers.length === 0}><DeleteIcon/>Delete users</Button>
                        </Grid> */}
            <Grid>
              <SelectFilter
                title="Role"
                popoverText="select role"
                defaultValue={defaultRoleFilter}
                resetFilter={
                  () => {
                    setRoleFilter(defaultRoleFilter)
                  }
                }
                onChange={(value) => {
                  setRoleFilter(value)
                  setPage(0)
                }}
                // renderControl={
                //   (item) => {
                //     if (item === "Show All")
                //       return "All"
                //     else {
                //       return item
                //     }
                //   }
                // }
              />
            </Grid>
            <Grid className={clsx(classes.textInput, classes.searchBox)}>
              <TextField

                onChange={handleSearch}
                placeholder="Search user list"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>,
                }} />
            </Grid>
            <Grid>
              <Button className={classes.addButton} variant="contained"
                color="primary" onClick={
                  () => { setUserData(userInitData); setIsEditUser(false); setOpenCreateModal(true) }
                }>Add a user</Button>
            </Grid>
          </Grid>
        </Grid>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell className={clsx(classes.tableHeader, classes.checkBox)}>
                                <Checkbox checked={selectAll} onChange={handleSelectAll} className={classes.checkBox}/>
                            </TableCell> */}
              {
                headers.map((header) => (
                  <TableCell id={header} key={header} className={classes.tableHeader} onClick={handleSort}>
                    {header}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users?.filter(userFilter)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ id, name, email, roles, membership }) =>

                  <TableRow key={id} className={classes.tableRow}>
                    {/* <TableCell className={clsx(classes.checkBox, classes.tableItem)}>
                                        <Checkbox
                                            onChange={handleSelectUser} 
                                            value={id} 
                                            checked={false}
                                            />
                                    </TableCell> */}
                    <TableCell className={classes.tableItem}>
                      <p>{name}</p>
                    </TableCell>
                    <TableCell className={classes.tableItem}>
                      <p>{email}</p>
                    </TableCell>
                    <TableCell className={classes.tableItem}>
                      <p>{membership.company.name}</p>
                    </TableCell>
                    <TableCell className={classes.tableItem}>
                      <UserRoleItem role={roles[0]?.id} label={userRoles.find(({ id }) => id === roles[0]?.id)?.label} />
                    </TableCell>
                    <TableCell className={classes.tableItem}>
                      <IconButton className={classes.dotsMenu} aria-label="moreVert" >
                        <MoreVertIcon onClick={(e) => handleActionsClick(e, id)} />
                      </IconButton>
                      {/* <Button className={classes.editButton} onClick={() => { handleSetEditUser(users.filter((u) => u.id == id)[0]); setIsEditUser(true); setOpenCreateModal(true) }}><EditIcon /></Button>
                        {id !== parseInt(localStorage.userID) && <Button className={classes.editButton} onClick={() => { selectedUsers.push(id); setOpenDeleteModal(true) }} ><DeleteIcon /></Button>} */}
                    </TableCell>
                  </TableRow>

                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users?.filter(userFilter).length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Popover
        open={openActionsPopover}
        anchorReference="anchorPosition"
        anchorPosition={popoverAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popoverContainer}
        onClick={() => setOpenActionsPopover(false)}>
        <Grid container className={classes.actionsPopoverContainer}>
          <Grid item className={classes.actionButtonContainer} onClick={() => {
            handleSetEditUser(users.filter((u) => u.id === selectedUsers[0])[0]); setIsEditUser(true); setOpenCreateModal(true)
          }}>
            <Typography className={classes.actionButton}>
              Edit
            </Typography>
          </Grid>
          {selectedUsers[0] !== parseInt(localStorage.userID) &&
            <Grid item className={classes.actionButtonContainer} onClick={() => { setOpenDeleteModal(true) }}>
              <Typography className={classes.actionButton}>
                Delete
              </Typography>
            </Grid>}
        </Grid>
      </Popover>

      {/* Edit user role popover */}
      <Popover
        open={openRolesPopover}
        anchorReference="anchorPosition"
        anchorPosition={popoverAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={() => setOpenRolesPopover(false)}>
        <Grid container className={classes.rolePopover}>
          <UserRoleSelector roles={userRoles} onChange={handleUpdateUsers} defaultValue={roleUpdates} />
        </Grid>
      </Popover>

      {/* Create modal form */}
      <CreateEditUser
        onSubmit={handleCreateUser}
        onEditUser={handleEditUser}
        setUserData={setUserData}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        disabled={disabled}
        roles={userRoles}
        userData={userData}
        isEdit={isEdit} />

      {/* Edit users dialog */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
      >
        {openEditModal &&
          <Grid className={classes.editModal} container justifyContent="center" direction="column">
            {
              selectedUsers.length > 0 ?
                <Grid>
                  <Grid className={classes.tableTitle}>Edits will occur to all users listed:</Grid>
                  <Grid item>
                    <Typography className={classes.editTitle}>Selected Users</Typography>
                    <TextField
                      className={clsx(classes.textInput, classes.editInput)}
                      value={
                        users?.filter(({ id }) => selectedUsers?.includes(id))?.map(({ name, email }) => name + ` (${email})`)?.join(",")
                      }
                      disabled
                      fullWidth />
                  </Grid>
                  {userUpdates &&
                    Object.keys(userUpdates).filter((item) => item !== "roleIds").map((header) => (
                      <Grid item>
                        <Typography className={classes.editTitle}>{header}</Typography>
                        <TextField className={clsx(classes.textInput, classes.editInput)} value={userUpdates[header]} fullWidth variant="outlined" />
                      </Grid>
                    ))
                  }
                  {
                    roleUpdates &&
                    <Grid container>
                      <Typography className={classes.editTitle}>role</Typography>
                      <Grid container className={classes.contributionContainer}>
                        <UserRoleSelector roles={userRoles} onChange={handleUpdateUsers} defaultValue={roleUpdates} />
                      </Grid>
                    </Grid>
                  }
                  <DialogActions className={classes.confirmOptions} >
                    <Button className={classes.cancelButton} onClick={() => setOpenEditModal(false)}>Cancel</Button>
                    <Button className={classes.confirmButton} variant="contained"
                      color="primary" onClick={handleEditUsers}>Done</Button>
                  </DialogActions>
                </Grid> :
                <Grid>
                  <Grid className={classes.tableTitle}>No users selected</Grid>
                </Grid>
            }
          </Grid>
        }
      </Dialog>

      {/* Delete users modal */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid className={classes.deleteModal} container justifyContent="center" direction="column">
          <Grid className={classes.tableTitle}>Delete user</Grid>
          Are you sure you want to delete user {users?.filter(({ id }) => selectedUsers.includes(id))[0]?.email}?
          <br />
          <DialogActions className={classes.dialogActions}>
            <Button className={classes.confirmButton} variant="contained"
              color="#363ED3" onClick={handleDeleteUsers}>Delete</Button>
            <Button className={classes.cancelButton} variant="outlined" onClick={() => { setSelectedUsers([]); setOpenDeleteModal(false) }}>Cancel</Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <Snackbar open={openSnackbar.open} autoHideDuration={6000} onClose={() => setSnackbarOpen({ open: false, message: "" })}>
        <Alert onClose={() => setSnackbarOpen({ open: false, message: "" })} severity={openSnackbar.severity ? openSnackbar.severity : "success"} sx={{ width: '100%' }}>
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default UserManagement