import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  labelText: {
    fontSize: 16,
    lineHeight: '22.88px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    '& p': {
      margin: 0,
      paddingRight: 2,
      fontFamily: "Roboto",
      fontSize: 22,
      fontWeight: 400,
      lineHeight: "26px",
      color: "#312E40",
    },
    '& span': {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: 32,
      color: '#312E40',
    },
    '& span#value_big': {
      fontSize: 48,
      lineHeight: '56px',
    },
  },
  BarsBox: {
    textAlign: 'start',
    width: '100%',
    height: '100% !important',
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  infoIconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #C2C2C2",
    borderRadius: "50%",
    minWidth: 16,
    height: 16,
    cursor: "pointer"
  },
  labelBox: {
    display: "flex",
    alignItems: "center"
  },
}));
