import React, { useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useStyles } from "../styles";
import FormInput from "../../FormInput";
import { UIViewButton } from "../../../ui/buttons";
 
const ConnectRdsForm = ({ values, onSubmit, onChange, disabled }) => {
    const classes = useStyles()
    const formRef = useRef()

    return (
        <Grid container>
            <Grid item container>
                <Typography className={classes.title}>Fill out the data</Typography>
            </Grid>
            <Grid item container>
                <ValidatorForm ref={formRef} onSubmit={onSubmit} className={classes.connectForm}>
                    <Grid container direction="column" className={classes.connectFormContent}>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>Endpoint</Typography>
                            <FormInput
                                name={"endpoint"}
                                value={values["endpoint"]}
                                autoFocus
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Endpoint is required!']}
                            />    
                        </Grid>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>Port</Typography>
                            <FormInput
                                name={"port"}
                                value={values["port"]}
                                autoFocus
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Port is required!']}
                            />    
                        </Grid>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>User</Typography>
                            <FormInput
                                name={"user"}
                                value={values["user"]}
                                autoFocus
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['User is required!']}
                            />    
                        </Grid>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>Region</Typography>
                            <FormInput
                                name={"region"}
                                value={values["region"]}
                                autoFocus
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Region is required!']}
                            />    
                        </Grid>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>DB Name</Typography>
                            <FormInput
                                name={"dbname"}
                                value={values["dbname"]}
                                autoFocus
                                onChange={onChange}
                                errorMessages={['DB Name is required!']}
                            />    
                        </Grid>
                        <Grid item container className={classes.connectFormInputContainer}>
                            <Typography className={classes.connectFormInputLabel}>Database Type</Typography>
                            <FormInput
                                name={"database_type"}
                                value={values["database_type"]}
                                autoFocus
                                onChange={onChange}
                                errorMessages={['Database Type is required!']}
                            />    
                        </Grid>
                        <Grid item container justifyContent="center">
                            <UIViewButton
                                type="submit"
                                style={{ height: 40, width: 150, padding: 0 }}
                                data-testid="submitButton"
                                disabled={disabled}
                            >
                                Connect
                            </UIViewButton>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    )
}

export default ConnectRdsForm