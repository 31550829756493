import { Popover } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const PolicyPopupContainer = ({ open, anchor, model, classes, onClose, children }) => (
    <Popover
    open={open}
    onClose={onClose}
    anchorEl={anchor}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }} 
    className={clsx(
        classes.popoverRoot, 
        model === "ui" ? classes.popoverNewbuisness : classes.popoverRetention
    )}>
        <div className={classes.rootContainer}>{children}</div>
    </Popover>    
)

export default PolicyPopupContainer;