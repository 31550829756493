import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    schedulerContainer: {
        background: "#ffffff",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        maxWidth: 1040,
        minWidth: 690,
        minHeight: 570,
        borderRadius: 6
    },
    schedulerLogo: {
        paddingBottom: 16,
        "& svg": {
        }
    },
    schedulerItem: {
        padding: 24,
        width: "50%",
        flexBasis: "50%"
    },
    schedulerItemFullWidth: {
        width: "100%",
        maxWidth: 369,
        borderRadius: "6px!important"
    },
    schedulerItemBg: {
        background: "#FFFFFF"
    },
    schedulerSlots: {
        gap: 24
    },
    schedulerCalendar: {
        background: "#363ed3",
        color: "#ffffff",
        borderRadius: 6,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        "& .MuiPickersDay-root": {
            backgroundColor: "#363ed3",
            color: "#ffffff",
            "&:hover": {
                backgroundColor: "#ffffff",
                color: "#000"
            },
            "&.Mui-selected": {
                backgroundColor: "#ffffff!important",
                color: "#000",
                "&:hover": {
                    backgroundColor: "#ffffffeb",
                    color: "#000"
                },
            }
        },
        "& .MuiPickersCalendarHeader-switchViewButton,.MuiPickersArrowSwitcher-button": {
            "& svg": {
                color: "#ffffff"
            },
        },
        "& .MuiDayPicker-weekDayLabel": {
            color: "#ffffff"
        }
    },
    calendarTitle: {
        fontSize: 24
    },
    slotTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#363ed3"
    },
    slotText: {
        fontSize: 18,
        "& span": {
          fontWeight: 600,
          color: "#363ed3"
        }
    },
    schedulerDuration: {
        paddingTop: 8
    },
    slotsContainer: {
        gap: 8
    }
}));