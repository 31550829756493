import { makeStyles } from "@material-ui/styles"
export const useStyles = makeStyles(() => ({
    root: {
        background: "#fff",
        padding: "24px 46px",
        borderRadius: "6px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
    },
    tableHead: {
        background: "#F9FAFB",
        borderBottom: "none",
        borderRadius: "6px 6px 0px 0px"
    },
    deleteTable: {
        padding: "30px 0px"
    },
    tableHeader: {
        textTransform: "capitalize",
        cursor: "pointer",
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "19px",
        borderBottom: "none",
        padding: "31px 23px",
        "&#role": {
            cursor: "default"
        },
        "&#contribution": {
            cursor: "default"
        }
    },
    tableHeaderSm: {
        padding: "10px 0 5px 23px"
    },
    tableRow: {
        borderBottom: "1px solid #EEF0F4"
    },
    tableItem: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        padding: 0,
        paddingLeft: 23,
        "& p": {
            padding: "15px 0 14px 0",
            margin: 0
        }
    },
    tableItemEdit: {
        paddingLeft: 0,
        paddingRight: 23
    },
    tableTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "center",
        paddingBottom: 15
    },
    controlsContainer: {
        paddingBottom: 18
    },
    textInput: {
        border: "1px solid #D9E1EE",
        borderRadius: "8px",
        "& .MuiOutlinedInput-input": {
            padding: "7px 0 7px 23px",
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "16px"
        },
        "& .MuiInputBase-root.Mui-disabled": {
            color: "#312E40",
        }
    },
    searchBox: {
        marginLeft: 10,
        // width: "50%",
        maxWidth: 278,
        "@media (min-width:1440px)": {
            maxWidth: 315,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8px 0 7px 14px",
            border: "none",
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400
        },
        "& .MuiInputAdornment-positionStart": {
            margin: 0
        },
        "& svg": {
            fill: "#525252"
        }
    },
    editButton: {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#312E40",
        "&:hover": {
            background: "#fff"
        },
        "& svg": {
            marginRight: 3
        }
    },
    addButton: {
        marginLeft: 22,
        color: "#FFF",
        background: "#0BCB47",
        borderRadius: 8,
        boxShadow: "none",
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 33px",
        height: 42,
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
          backgroundColor: '#239948',
          color: '#FFFFFF'
        },
    },
    checkBox: {
        padding: 0,
        paddingLeft: 23,
        "& .MuiCheckbox-colorSecondary.Mui-checked svg": {
            fill: "#363ED3"
        },
        "& .MuiCheckbox-colorSecondary svg": {
            fill: "#DEE5F0"
        },
        "& .MuiButtonBase-root": {
            padding: 0,
        }
    },
    roleIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
        background: "#D9E1EE"
    },
    roleContainer: {
        padding: "15px 0 14px 0",
        display: "flex",
        "& .MuiTypography-root": {
            padding: 0,
            paddingLeft: 8,
            textTransform: "capitalize"
        },
    },
    roleSelect: {
        display: "flex",
        cursor: "pointer",
        "& svg": {
            fill: "#8F93AE"
        }
    },
    rolePopover: {
        border: "1px solid #D9E1EE",
        borderRadius: "6px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        width: 338,
        padding: "20px 0 20px 16px"
    },
    roleCheckbox: {
        width: "100%"
    },
    checkDescription: {
        paddingLeft: 61,
        "& .MuiTypography-body1": {
            fontFamily: "Roboto",
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "14px",

        }
    },
    deleteModal: {
        background: "#fff",
        padding: "44px 34px 19px 34px",
        borderRadius: "6px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        width: 500,
        textAlign: "center"
    },
    editModal: {
        background: "#fff",
        padding: "44px 40px 32px 40px",
        borderRadius: "6px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: 600
    },
    editTitle: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        paddingBottom: 12,
        paddingTop: 24,
        textTransform: "capitalize",
        color: "#1B1C1E"
    },
    editInput: {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        "& .MuiInputBase-input": {
            height: 44,
            padding: 0,
            paddingLeft: 16
        }
    },
    dialogActions: {
        padding: 20,
        justifyContent: "center"
    },
    cancelButton: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        background: "#fff",
        color: "#363ED3",
        border: "1px solid #363ED3",
        borderRadius: 8,
        padding: "14px 54px",
        margin: "0px 30px",
        textTransform: "none",
    },
    confirmButton: {
        color: "#FFF",
        background: "#363ED3",
        borderRadius: "8px",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 54px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#363ED3",
            opacity: 0.85
        }
    },
    contributionContainer: {
        borderRadius: "6px",
        border: "1px solid #D9E1EE",
        paddingLeft: 16
    },
    confirmOptions: {
        padding: 0,
        paddingTop: 24,
    },
    popoverContainer: {
        "& .MuiPaper-root": {
            background: "#fff",
            borderRadius: 6,
            border: "1px solid #D9E1EE",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)"
        }
    },
    actionsPopoverContainer: {
        borderRadius: 6,
        backgroundColor: "#fff",
        padding: 12,
    },
    actionButtonContainer: {
        width: '100%',
        textAlign: "left",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0000001f"
        }
    },
    actionButton: {
        color: "#312E40",
        padding: "3px 0",
        margin: 0,
        fontFamily: "Roboto",
        fontSize: "14px!important",
        fontWeight: "400!important",
    }
}));