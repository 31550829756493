import { Box, Button } from "@material-ui/core";
import { Grid, Modal, Typography, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import React, { useState, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FormInput from "../../../../Components/Forms/FormInput";

import DataHandlerApiService from '../../../../services/datahandler'
import { style, useStyles } from './style'

const CreateEditConnector = ({ onSubmit, open, setOpen, isEdit, config }) => {
    const classes = useStyles()
    const formRef = useRef(null)
    const [data, setData] = useState({ user: "atidot_user", account: "", password: "TESTtest123" })

    const ApiService = new DataHandlerApiService()

    const handleChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        const parsedData = { ...data, account: data?.account.replace('.', '-') }
        ApiService.postConnector({}, parsedData,
            () => setOpen(false),
            (e) => {
                console.log(e)
            }
        )
        onSubmit({})
    }

    const handleClickCopyToClipboard = () => {
        navigator.clipboard.writeText(
            "CREATE OR REPLACE USER ATIDOT_USER PASSWORD='TESTtest123' LOGIN_NAME='atidot_user' DEFAULT_ROLE='sysadmin'"
        )
    }

    const handleClickCopyToClipboard2 = () => {
        navigator.clipboard.writeText("GRANT role sysadmin to user ATIDOT_USER")
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Grid item xs={4}>
                            <Grid className={classes.formTitle}><b>{isEdit ? "Edit" : "Add new"} connector:</b></Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <img src={config?.logo} alt={config?.key} className={classes.connectorLogo} />
                        </Grid>

                        <Grid item xs={4}>
                            <Grid item>
                                <Typography className={classes.createTitle}>Account</Typography>
                                <FormInput
                                    type="text"
                                    name="account"
                                    value={data.account}
                                    onChange={handleChangeData}
                                    autoFocus />
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item>

                                <div className={classes.smallText}>
                                    Please execute this SQL request in your {config?.displayName} account: <br /> <br />
                                </div>

                                <div>
                                    <OutlinedInput
                                        id="outlined-adornment-copytoclipboard"
                                        type='text'
                                        className={classes.codeBox}
                                        multiline
                                        endAdornment={
                                            <InputAdornment position="top end">
                                                <IconButton
                                                    aria-label="copy to clipboard"
                                                    onClick={handleClickCopyToClipboard}
                                                    edge="end"
                                                >
                                                    <ContentCopyIcon fontSize='small' />
                                                </IconButton>
                                            </InputAdornment>
                                        }

                                        value={"CREATE OR REPLACE USER ATIDOT_USER \
                                                PASSWORD='TESTtest123' \
                                                LOGIN_NAME='atidot_user' \
                                                DEFAULT_ROLE='sysadmin' \
                                                "}
                                    />

                                    <OutlinedInput
                                        id="outlined-adornment-copytoclipboard"
                                        type='text'
                                        className={classes.codeBox}
                                        multiline
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="copy to clipboard"
                                                    onClick={handleClickCopyToClipboard2}
                                                    edge="end"
                                                >
                                                    <ContentCopyIcon fontSize='small' />
                                                </IconButton>
                                            </InputAdornment>
                                        }

                                        value={"GRANT role sysadmin to user ATIDOT_USER"}
                                    />
                                </div>
                            </Grid>



                        </Grid>
                        <Grid item xs={12}>
                            <Grid className={classes.confirmOptions} container>
                                <Button className={classes.cancelButton} onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.confirmButton}
                                >
                                    Done
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>

            </Box>
        </Modal>
    )
}

export default CreateEditConnector