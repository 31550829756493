import clsx from "clsx";
import { Button, CircularProgress, Grid, MenuItem, Select, Typography, styled } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import timezones from "timezones-list"
import { getLocalTime } from "../../../global/helpers";
import { useStyles } from "../styles";
import { cancelButtonStyles } from "../../ui/buttons";

const TypeButton = styled(ToggleButton)({
    width: "auto",
    height: 35,
    textTransform: "none",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white!important",
      backgroundColor: '#363ED3!important'
    }
})

const SubmitButton = styled(Button)({
    ...cancelButtonStyles,
    width: "100%",
    height: 45,
    '&:hover': {
      opacity: '0.85',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      color: '#FFF',
      boxShadow: 'none',
    },
    '&.active': {
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      color: '#FFF',
      boxShadow: 'none',
    },
})

const TimeSlotPicker = ({ 
    fullWidth = false,
    name,
    date,
    timezone,
    duration,
    slots,
    loading,
    time,
    maxLength,
    handleDurationChange,
    handleTimezoneChange,
    handleSlotClick,
    handleRenderSelectedTimezone 
}) => {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            className={clsx(
                classes.schedulerItem,
                classes.schedulerSlots,
                fullWidth ? classes.schedulerItemFullWidth : " ",
                fullWidth ? classes.schedulerItemBg : ""
            )}
        >
            <Grid item container direction="column">
                <Typography className={classes.slotTitle}>How long do you need?</Typography>
                <Grid item className={classes.schedulerDuration}>
                    <ToggleButtonGroup
                            value={duration}
                            exclusive
                            onChange={handleDurationChange}
                            aria-label="Platform"
                            >
                        <TypeButton value="30">30 mins</TypeButton>
                        <TypeButton value="45">45 mins</TypeButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item container direction="column">
                <Typography className={classes.slotTitle}>What time works best?</Typography>
                <Typography className={classes.slotText}>
                    Showing times for <span>{`${date.toLocaleString('default', { month: 'long' })} ${date.getDate()},  ${date.getFullYear()}`}</span>
                </Typography>
            </Grid>
            <Grid item container direction="column" className={classes.slotsContainer}>
                <Select value={timezone} onChange={handleTimezoneChange} renderValue={handleRenderSelectedTimezone}>
                    {
                        timezones?.map((item) => (
                            <MenuItem value={item.tzCode} key={item.tzCode}>{item?.name?.length > maxLength || !maxLength ? item.name.slice(0, maxLength)+"..." : item.name}</MenuItem>
                        ))
                    }
                </Select>
                {
                    loading ?
                    <Grid container justifyContent="center" alignContent="center" style={{ height:310 }}>
                        <CircularProgress style={{color: "#363ed3",width:64,height:64}}/>
                    </Grid>
                    : !slots?.length ?
                    <Grid container justifyContent="center" alignContent="center" style={{ height:310 }}>
                        <Typography className={classes.slotTitle}>No time slots are available for selected date.<br/>Please try another date</Typography>
                    </Grid>
                    : slots.map(({ start_time }) => (
                        <Grid item container>
                            <SubmitButton key={start_time} onClick={() => handleSlotClick(start_time)} className={time === start_time ? "active" : ""} disabled={name ? true : false}>
                                {getLocalTime(start_time*1000, timezone)}
                            </SubmitButton>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default TimeSlotPicker