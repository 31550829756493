import { Box, IconButton, makeStyles, TablePagination } from "@material-ui/core";
import { ReactComponent as KeyboardArrowLeft } from "../../../Assets/icon-left-arrow.svg"
import { ReactComponent as KeyboardArrowRight } from "../../../Assets/icon-right-arrow.svg"
import { ReactComponent as KeyboardArrowDoubleLeft } from "../../../Assets/icon-doublearrow-left.svg"
import { ReactComponent as KeyboardArrowDoubleRight } from "../../../Assets/icon-doublearrow-right.svg"
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSlice, setSlice } from "../TableSlice";

const useStyles = makeStyles(() => ({
    root: {
      "& .MuiTablePagination-caption": {
        display: "none",
      },
      "& span": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#232033"
      },
      "& .MuiIconButton-root": {
        borderRadius: 8
      },
      "& .MuiIconButton-root:hover": {
        background: "rgba(222, 230, 240, 0.5)"
      },
      "& svg": {
        color: "#363ED3"
      }
    }

}));

const PaginationActions = ({ count, page, rowsPerPage, onPageChange, pagesToShow = 4, loading }) => {
  const pagesCount = Math.ceil(count/rowsPerPage);
  const slice = useSelector(selectSlice);
  const dispatch = useDispatch();
  
  const handleFirstPageButtonClick = (e) => {
    dispatch(setSlice(0))
    onPageChange(e, 0);
  };
  
  const handleLastPageButtonClick = (e) => {
    dispatch(setSlice(Math.ceil(pagesCount/pagesToShow)-1))
    onPageChange(e, count%rowsPerPage === 0 ? Math.floor(count/rowsPerPage)-1 : Math.floor(count/rowsPerPage));
  };  

  const handleBackButtonClick = (e) => {
    if ((page-1)%pagesToShow === pagesToShow-1 && page-2 < slice*pagesToShow && slice !== 0) {
      dispatch(setSlice(slice-1))
    }
    onPageChange(e, page - 1);
  };

  const handleNextButtonClick = (e) => {
    if ((page+1)%pagesToShow === 0 && page+2 > slice*pagesToShow+pagesToShow) {
      dispatch(setSlice(slice+1))
    }
    onPageChange(e, page + 1);
  };

  const handleSelectPage = (e, newPage) => {
    onPageChange(e, newPage);
  };

  const getItems = () => {
    const quotient = Math.floor(pagesCount/pagesToShow);
    const remainder = pagesCount % pagesToShow;
    const items = []
    const max = slice > quotient ? slice*pagesToShow + remainder : slice*pagesToShow + pagesToShow

    if (count <= rowsPerPage) {
      return (
        <IconButton
        style={{background: "rgba(222, 230, 240, 0.5)"}}
        onClick={(e) => handleSelectPage(e, 0)}
        id={1}
        >
            {1}
        </IconButton>
      )
    } else {
      for (let index = slice*pagesToShow; index < max; index++) {
        items.push(
          <IconButton
          key={`button-page-${index}`}
          style={
            page === index ? 
              ((index+1)*rowsPerPage) - count >= rowsPerPage ? { visibility: "hidden" } 
              : {background: "rgba(222, 230, 240, 0.5)"} 
            : ((index+1)*rowsPerPage) - count >= rowsPerPage ? { visibility: "hidden" } 
            : {}
          }
          onClick={(e) => handleSelectPage(e, index)}
          id={index+1}
          disabled={((index+1)*rowsPerPage) - count >= rowsPerPage || loading}
          >
              {index+1}
          </IconButton>
        )
      }
  
      return items.map((item) => (item))
    }


  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        style={{ padding: "12px 0", height: 41 }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0 || loading}
        aria-label="first page"
      >
        {<KeyboardArrowDoubleLeft />}
      </IconButton>
      <IconButton
        style={{ padding: "12px 0", height: 41 }}
        onClick={handleBackButtonClick}
        disabled={page === 0 || loading}
        aria-label="prev page"
      >
        {<KeyboardArrowLeft />}
      </IconButton>
      {
        getItems()
      }
      <IconButton
        style={{ padding: "12px 0", height: 41 }}
        onClick={handleNextButtonClick}
        disabled={page+1 >= pagesCount || loading}
        aria-label="next page"
      >
        {<KeyboardArrowRight />}
      </IconButton>
      <IconButton
        style={{ padding: "12px 0", height: 41 }}
        onClick={handleLastPageButtonClick}
        disabled={page+1 >= pagesCount || loading}
        aria-label="last page"
      >
        {<KeyboardArrowDoubleRight />}
      </IconButton>
      <span style={{ padding: 12, color: "#363ED3", fontWeight: 500 }}>of {pagesCount} Pages</span>
    </Box>
  );
}

const BookTablePagination = (props) => {
    const classes = useStyles()
    
    return (
        <TablePagination {...props} ActionsComponent={PaginationActions} className={classes.root}/>
    )
}

export default BookTablePagination