import ApiService from "../../global/service";

export default class BookAnalysisService {
    _getProducts = (responseHandler, errorHandler) => ApiService.get("/projects/summary", {}, responseHandler, errorHandler)

    _getProductsByIDs = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/metadata${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getPolicies = (demo = false, responseHandler, errorHandler) => ApiService.get(`/policies/metadata${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getDemoProducts = (responseHandler, errorHandler) => ApiService.get("/projects/summary/demo", {}, responseHandler, errorHandler)

    _getImpactByProducts = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/summary/products${demo ? "?is_demo=true " : ""}`, {}, responseHandler, errorHandler)

    _getStoriesByPolicies = (demo = false, responseHandler, errorHandler) => ApiService.get(`/campaigns/suggest${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getImpactByStories = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/summary/stories${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getProspectNote = (policy_id, responseHandler, errorHandler) => ApiService.get(`/notes/policy_id=${policy_id}`, {}, responseHandler, errorHandler)

    _getProspectStatusHistory = (policy_id, responseHandler, errorHandler) => ApiService.get(`/histories/policy_id=${policy_id}`, {}, responseHandler, errorHandler)
    
    _postAddProspectNote = (data, responseHandler, errorHandler) => ApiService.post("/notes", {}, responseHandler, errorHandler, data)

    _patchRunColumn = (run_ids, columns, responseHandler, errorHandler) => ApiService.patch(`/runs/run_ids=${run_ids.join(",")}`, {}, responseHandler, errorHandler, { columns_to_display: {columns} })

    _postProspectsData = (demo = false, run_ids, payload, responseHandler, errorHandler, model_type = null, page = 0, take = 10, sort = 'policy') => ApiService.post(
        `/results/run_ids=${run_ids.join(",")}${demo ? "/demo" : ""}?metadata=true&skip=${page*take}&take=${take}&sort=${sort}${model_type ? `&model_type=${model_type}` : ""}`, 
        {}, responseHandler, errorHandler, payload
    )
    
    _getRecommendations = (requestHandler, errorHandler, query = '', model_type = null, page = 0, take = 10, sort = 'rank', search = '') => ApiService.get(`/policies?is_archived:ne=true&${search ? `or=first_name:like:${search},last_name:like:${search},policy:like:${search}&` : ""}${query}skip=${page*take}&take=${take}&sort=${sort}${model_type ? `&model_type=${model_type}` : ""}`, {}, requestHandler, errorHandler)

    _getPoliciesRanks = (requestHandler, errorHandler) => ApiService.get("/policies/ranks", {}, requestHandler, errorHandler)

    _patchRecommendation = (id, data, requestHandler, errorHandler) => ApiService.patch(`/policies/${id}`, {}, requestHandler, errorHandler, data)

    _postProspects = (run_ids, payload, responseHandler, errorHandler) => ApiService.post(
        `/results/run_ids=${run_ids.join(",")}`, 
        {}, responseHandler, errorHandler, payload
    )
    
    _postExportCsv = (run_ids, data, requestHandler, errorHandler) => ApiService.post(`/results/${run_ids.join(",")}/file/download`, {}, requestHandler, errorHandler, data)

    _getExportCsv = (query = '', requestHandler, errorHandler) => ApiService.get(`/policies/export/csv${query ? "?"+query : ""}`, {}, requestHandler, errorHandler)

    _getLastRun = (requestHandler, errorHandler) => ApiService.get(`/runs/last`, {}, requestHandler, errorHandler)

    _postDemoEmail = (data = {}, responseHandler, errorHandler) => ApiService.post("/v1/metadata/emails/demo", {}, responseHandler, errorHandler, { ...data }, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _postGptSession = (data = {}, responseHandler, errorHandler) => ApiService.post("/v1/gpt/session", {}, responseHandler, errorHandler, { ...data }, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _putOpenAIPolicyStory = (policy_id, type, responseHandler, errorHandler) => ApiService.put(`/openai/policies/${policy_id}/story/${type}`, {}, responseHandler, errorHandler)

    _putOpenAIStoryByPolicy = (policy_id, responseHandler, errorHandler) => ApiService.put(`/openai/stories/${policy_id}`, {}, responseHandler, errorHandler)

    _postCampaignsStories = (data = {}, responseHandler, errorHandler) => ApiService.post("/campaigns/stories", {}, responseHandler, errorHandler, { ...data })

    _patchProspectNote = (note_id, text, responseHandler, errorHandler) => ApiService.patch(`/notes/${note_id}`, {}, responseHandler, errorHandler, { note: text})

    _deleteProspectNote = (note_id, responseHandler, errorHandler) => ApiService.patch(`/notes/${note_id}`, {}, responseHandler, errorHandler)

    _getSource = (requestHandler, errorHandler) => ApiService.get('', {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)
}