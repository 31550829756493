import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import MapperItem from './MapperItem/MapperItem';
import { useSelector } from 'react-redux';
import { selectEmptyKeys } from '../../Pages/NewProject/NewProjectSlice';

const useStyles = makeStyles(() => ({
    errorContainer: {
        maxWidth: 740,
        paddingLeft: 17,
        visibility: "hidden",
        "& .MuiTypography-root": {
            padding: 0,
            margin: 0,
            fontSize: 9,
            fontWeight: 400,
            lineHeight: "100%",
            color: "#FF4B62"
        }
    },
    mapperContentContainer: {
        gap: 2
    }
}))

const colors = {
    "red": "#CACACA",
    "yellow": "#CACACA",
    "blue": "#CACACA",
    "gray": "#CACACA",
    "purple": "#CACACA",
    "cayan": "#CACACA",
    "violet": "#CACACA",
    "pink": "#CACACA",
    "green": "#CACACA",
}

const MapperContentContainer = ({ 
    data, 
    menuIsOpen,
    fieldSelection,
    headers,
    handleMenuOpen,
    handleMenuClose,
    handleSelectChange,
    handlePopoverOpen,
    outSideRef,
    optional = false
}) => {
    const classes = useStyles()
    const [error, setError] = useState(false)
    const emptyKeys = useSelector(selectEmptyKeys)

    useEffect(() => {
        const buckets = []
        Object.keys(data).forEach((key) => buckets.push(...data[key].data))
        setError(
            buckets?.reduce((a, i) => emptyKeys.includes(i.id) || a, false)
        )
    }, [emptyKeys, data])
    
    return (
            <Grid item container direction="column" justifyContent="center" alignItems="center" className={classes.mapperContentContainer}>
                {
                    Object.keys(data).map((key) => (
                        <>
                            {
                                data[key]?.data?.map(
                                    (item, idx) => 
                                    <MapperItem
                                        key={idx}
                                        item={item}
                                        error={emptyKeys?.includes(item.id)}
                                        position="center"
                                        color={colors[key]}
                                        menuIsOpen={menuIsOpen}
                                        fieldSelection={fieldSelection} 
                                        headers={headers}
                                        handleMenuOpen={handleMenuOpen}
                                        handleMenuClose={handleMenuClose}
                                        handlePopoverOpen={handlePopoverOpen}
                                        handleSelectChange={handleSelectChange}
                                        autoMapping={handleSelectChange}
                                        outSideRef={outSideRef}
                                        optional={optional}
                                        tooltipIcon/>
                                )
                            }
                        </>
                    ))
                }
                <Grid container justifyContent="flex-start" className={classes.errorContainer} style={error ? { visibility: "visible"} : {}}>
                    <Typography>Required Field for Selected Model</Typography>
                </Grid>
            </Grid>
    )
}

export default MapperContentContainer