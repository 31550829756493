import './UIDistribution.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useLayoutEffect, useRef } from 'react';
import { nFormatter } from '../../../../global/helpers';
import NoDataIndicator from '../NoDataIndicator/NoDataIndicator';

am4core.useTheme(am4themes_animated);

export default function UIDistribution({
  paddingRight,
  chartData,
  title,
  currency,
  showTable = true,
  totalText = 'Total',
  hasData = true
}) {
  const chart = useRef(null);

  const [elements,setElements] = React.useState([])

  useLayoutEffect(() => {
    const labels = [
      "First Year",
      "Second Year",
      "3rd to 5th",
      "6th and Over",
      "6th to 10th",
      "11th and over"
    ]

    let x = am4core.create("UIDistribution", am4charts.PieChart);
    

    x.data = labels.filter(
      (label) => chartData.find(({ id }) => id === label) !== undefined)
        .map((label) => {
          return chartData.find(({ id }) => id === label)
        }
    )

    x.innerRadius = am4core.percent(90);  

    var series = x.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "id";
    series.name = "Value";
    series.labels.template.disabled = true;
    series.slices.template.stroke = am4core.color("#fff");
    series.slices.template.strokeWidth = 2;
    series.slices.template.strokeOpacity = 1;

    var colorSet = new am4core.ColorSet();
    const colors = chartData?.length > 0  && chartData[0]?.id !== "N/A" ? ["#ff656f", "#ffe63e","#c56cf0", "#487eb0","#4b54ff"] : ["#000000"];
    colorSet.list =colors.map(function(color) {
      return new am4core.color(color);
    });

    series.colors = colorSet;

    series.hiddenState.properties.opacity = 1;
    series.hiddenState.properties.endAngle = -90;
    series.hiddenState.properties.startAngle = -90;

    var label = series.createChild(am4core.Label);
    label.html = chartData?.length > 0  && chartData[0]?.id !== "N/A"
      ? currency + "{values.value.sum} <br/><div id='labelText'>" + totalText + "</div>"
      : `0 <br/><div id='labelText'>${totalText}</div>`;
    label.height=60  
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.textAlign="center"; 
    label.fontSize = 25;
    label.numberFormatter.numberFormat = `#,###.`;

    if (chartData?.length > 0 && chartData[0]?.id !== "N/A") {
      // Create custom legend
      x.events.on("ready", function() {
        let tempElements=[]
        let Colortemps=["#ff656f", "#ffe63e","#c56cf0", "#487eb0","#487eb0"]
        series.dataItems.each(function(row, i) {
          var color = Colortemps[i];
          var percent = Math.round(row.values.value.percent * 100) / 100;
          var value = row.value;
          tempElements.push({category: row.category,value: value, percent:percent,color:color })
        });   

        setElements(labels.filter((label) => chartData.find(({ id }) => id === label) !== undefined).map((label) => {
            return tempElements.find(({ category }) => category === label)
        }))
        //x.customLegend.innerHTML+='</table>'
      });
    } else {
      setElements([{category: '', value: 0, percent:0,color:"#000000" }])
    }

    if (chartData?.length === 0) {
      series.tooltipText = "N/A"
      series.tooltip.disabled = true
      x.data.push({ id: 'N/A', value: 1})
    }

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [chartData]);

   useLayoutEffect(() => {
        chart.current.paddingRight= paddingRight
    }, [paddingRight]);

  useLayoutEffect(() => { // Remove amCharts logo inside svg
    [...document.querySelectorAll("title")]
      .filter(a => a.textContent.includes("Chart created using amCharts library"))
      .forEach(element => {
        const fullElement = element.closest('g');
        fullElement.parentElement.removeChild(fullElement);
      }, 100)
  }, []);
  
  return (
    <div className='BarsBox'>
      {!hasData && <NoDataIndicator/>}
      <div style={hasData ? {} : { opacity: .2 }}>
        {title && (
          <div id="chartTitle">{title}</div>
        )}
        {showTable && (
          <div id="legend">
            <table id="tableLegend">
              <tbody>
              <tr className="tableHeader">

                <th align="left" className="tableHeaderText">POLICY DURATION</th>
                <th align="left" className="tableHeaderText">PREMIUM IMPACT</th>
                <th align="left" className="tableHeaderText">TOTAL</th>

              </tr>

              {elements.map((row,index) => { return (
                <tr
                  key={index} className="legend-item" id={"legend-item"+index}
                >
                  <td component="th" className="tableRowText" scope="row" align="left" style={{display: 'flex'}}>
                    <div className="legend-marker" style={{background: row.color, minWidth: 12}}></div>
                    <div style={{marginTop:-3}}>
                      {
                        row.category.charAt(0).toUpperCase()+row.category.slice(1)
                      }
                    </div>
                  </td>
                  <td  align="left" className="tableRowText">{row.percent}%</td>
                  <td align="left" className="tableRowText">{nFormatter(row.value,0,currency).toUpperCase()}</td>
                </tr>
              )})}
              </tbody>
            </table>
            {/*  {elements.map((el,index)=>{
              return (

                <div key={index} className="legend-item" id={"legend-item"+index}
                style={{color: el.color}}>{el.category }<div className="legend-value">{el.value} | {el.percent}</div></div>
              )
            })} */}
          </div>
        )}
        <div id="UIDistribution" />
      </div>

    </div>
  );
}
